<script>
import Heading from "../Heading/Heading.vue";
import logoWriteBackground from "../../assets/logo-write-background.svg";
import Button from "../Button/Button.vue";
export default {
  name: "SubheaderTwo",
  components: { Heading, Button },
  props: {
    showSuaw: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    imageSource: {
      type: String,
    },
    imageAlt: {
      type: String,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Create Event Series",
    },
  },
  data() {
    return {
      logoWriteBackground,
    };
  },
};
</script>

<template>
  <div class="suaw-subheader">
    <div class="suaw-subheader__main-container">
      <div class="suaw-subheader__cta-container">
        <div class="suaw-subheader__heading-wrapper">
          <Heading
            v-if="showSuaw"
            class="suaw-subheader__heading"
            level="1"
            alignment="center"
            content="Shut"
          />
          <Heading
            v-if="showSuaw"
            class="suaw-subheader__heading"
            level="1"
            alignment="center"
            content="Up"
          />
          <Heading
            v-if="showSuaw"
            class="suaw-subheader__heading"
            level="1"
            alignment="center"
            content="&amp;"
          />
          <div v-if="showSuaw" class="suaw-subheader__heading-write-wrapper">
            <b class="suaw-subheader__heading-write">Write!</b>
            <img
              class="suaw-subheader__logo-background"
              :src="logoWriteBackground"
              alt="Logo Write Background"
            />
          </div>
          <Heading
            class="suaw-subheader__heading"
            level="1"
            alignment="center"
            :content="title"
          />
        </div>
        <div v-if="description" class="suaw-subheader__description-container">
          <p class="suaw-subheader__description">
            {{ description }}
          </p>
        </div>
        <div v-if="showButton" class="suaw-subheader__button-container">
          <Button
            :button-text="buttonText"
            class="suaw-subheader__button"
            size="large"
            type="primary-light"
            iconLeft="IconCalendarPlus"
            @click="$emit('click')"
          />
        </div>
      </div>
      <div v-if="imageSource" class="suaw-subheader__image-container">
        <img class="suaw-subheader__image" :src="imageSource" :alt="imageAlt" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-subheader {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__main-container {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  &__cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    max-width: 720px;
  }
  &__button-container {
    display: flex;
    align-items: center;
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__heading-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    @media (max-width: 650px) {
      justify-self: center;
    }
  }

  &__heading {
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 100%;
    font-weight: 500;
    z-index: 1;
    font-family: var(--secondary-font-family);
    @media (max-width: 650px) {
      font-size: 40px;
    }
  }

  &__heading-write-wrapper {
    position: relative;
  }
  &__heading-write {
    margin: 0px 6px 0px 2px;
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 700;
    font-family: var(--secondary-font-family);
    z-index: 1;
    @media (max-width: 650px) {
      font-size: 40px;
    }
  }

  &__logo-background {
    position: absolute;
    width: 170px;
    height: auto;
    left: -11%;
    top: 28%;
    @media (max-width: 650px) {
      font-size: 40px;
      top: 25%;
      left: -7%;
      width: 145px;
    }
  }

  &__description {
    color: var(--sem-color-neutral-medium);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    @media (max-width: 650px) {
      font-size: 18px;
    }
  }

  &__image-container {
    display: flex;
    @media (max-width: 1165px) {
      display: none;
    }
  }
}
</style>
