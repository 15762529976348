<script>
import { SuawMainContent, SuawParagraph, SuawDivider, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "FiveGeekyHobbiesForWritersThatArentReading",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawDivider,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FiveGeekyHobbiesForWritersThatArentReading">
      <SuawParagraph
        text="Characters. Plot lines. Descriptions. Page after page of words. Whether you do it by yourself in your writing nook, among strangers at a coffee house, or around friends at a meetup, writing is certainly fun. But it calls for long hours in front of a computer screen or with pen and paper in hand, and sometimes you need to take a break. Here are five geeky hobbies (that aren’t reading!) to try on your off time:"
      />
      <SuawHeading level="3" content="Try Your Hand at Playing Role Playing Games (RPG)" />
      <SuawParagraph
        text="Okay, this might push you outside your comfort zone a bit and I know not everyone is into role-playing games, but give it a try. You’ll be hanging out with friends, using your imagination, crafting characters, flexing your improvisation skills, and joint storytelling. It’s a great way to get immersed into your creative side. For example, I recently joined a D&D campaign. In order to do so, I had to create a character so I could join the campaign. It was such a fun experience—the Players Handbook gives you a selection of races, classes, characteristics, and backgrounds to choose from—that I probably went overboard. My friend, the DM or Dungeon Master, complimented my character creation skills, though. I’ve only played one session thus far, but it’s been such an inspiration, storytelling wise!"
      />
      <SuawHeading level="3" content="Take Some Photographs" />
      <SuawParagraph
        text="Photography forces you to look critically at things. And it (usually) forces you outside, something I’ll discuss down below! It’s a great hobby for writers because it makes you stop and look at things in a new light. It can make you patient (Like when you’re trying to wait for that ~perfect~ sunset.), notice the little details (It’s fall, take photos of the leaves!) and even be a source of inspiration (Maybe the creepy tree can belong to a dark nymph?). Plus, if you’re on social media, you can use the photography to make media assets out of them by taking pictures of your own books or your favorite books—hello #bookstagram and #bookbentos!"
      />
      <SuawHeading level="3" content="Go For a Hike or Bike Ride, or Bird Watching (Do Anything Outside)" />
      <SuawParagraph
        text="Really, do anything outside. I prefer hiking because it allows me to go places I’ve never been, is a bit of a workout, and is usually out in a forest or wooded area. Bike riding and bird watching are also good options. Outdoor volunteering is, too. Really, anything outside is good for the creative part of your brain. I could cite the scientific papers that prove this fact, but just trust me. If you’re stuck on something or are frustrated with a scene, take a walk. The fresh air and exercise will do you some good!"
      />
      <SuawHeading level="3" content="Do Some Baking" />
      <SuawParagraph
        text="If you’re more a homebody type (and honestly, who doesn’t enjoy spending the day at home in yoga pants?), try your hand at baking. It’s a good hobby for a couple of reasons. One, it’ll keep you busy and use your hands in a different way. Two, you can invite other people to help. Three, your home will smell amazing. Four, you’ll get yummy muffins or pies or cakes or breads to eat when you’re done. (Five, you don’t need to wear jeans!) It’s a win-win-win-win-win situation all around, really. I recently volunteered at the Portland Fruit Tree Project and harvested a bunch of apples and pears. My parents and I made the best homemade pies out of them! Plus, baking takes a while in the oven so it’ll give you some time to think about your story."
      />
      <SuawHeading level="3" content="Play Some Video Games" />
      <SuawParagraph
        text="Yes, go play video games! Right now! Immersing yourself in another world, seeing how the storytelling unfolds, and being part of how that story unfolds is quite beneficial. It’s a different kind of storytelling. Video games tell a visual story that allows you to see the creativity of others, especially in the form of worldbuilding. The rich details in those video games—like Zelda, BioShock, Dark Souls, etc.—would probably inspire you to beef up your own world."
      />
      <SuawParagraph
        text="Writing is fun, but it’s always good to step away for a little while. Finding other hobbies like these would help you recharge, see the world in a different way, and spark some creativity in your work. My advice? Pick one of these that you like the best and try it out! If anything, it’ll give you a good story later on."
      />
      <SuawDivider color="medium" top-distance="default" bottom-distance="default" />
    </ArticleFrame>
  </SuawMainContent>
</template>
