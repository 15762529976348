<script>
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";
export default {
  name: "DashboardSummary",
  components: { Heading, Button },
  props: {
    title: {
      type: String,
      default: "My Events",
    },
  },
};
</script>

<template>
  <div class="suaw-dashboard-summary">
    <div class="suaw-dashboard-summary__heading-container">
      <Heading level="2" :content="title" />
    </div>
    <div class="suaw-dashboard-summary__action-container">
      <div class="suaw-dashboard-summary__button-container">
        <Button
          class="suaw-dashboard-summary__button"
          buttonText="Find In-Person Events"
          size="large"
          type="primary"
          @click="$emit('in-person-click')"
        />
      </div>
      <div class="suaw-dashboard-summary__button-container">
        <Button
          class="suaw-dashboard-summary__button online-button"
          buttonText="Find Online Events"
          size="large"
          @click="$emit('online-click')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-dashboard-summary {
  max-width: 1264px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 16px;
  }
  @media (max-width: 429px) {
    grid-template-rows: auto auto;
  }
  &__heading-container {
    display: inline-flex;
    align-items: center;
  }
  &__action-container {
    display: inline-flex;
    justify-content: end;
    gap: 16px;
    @media (max-width: 429px) {
      flex-wrap: wrap;
    }
  }
  .online-button {
    background: #6737ff;
  }
  &__button-container {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
