<script>
import { SuawMainContent, SuawSection, SuawSummary, SuawInputGroup, SuawHeading, SuawParagraph, SuawLabel } from "@/components";
import { UserSettingsById } from "../../operations.gql";
import * as UserApi from "../../api.js";
export default {
  name: "DashboardSettingsView",
  components: {
    SuawMainContent,
    SuawSection,
    SuawSummary,
    SuawInputGroup,
    SuawHeading,
    SuawParagraph,
    SuawLabel
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserSettingsById: {
        id: "",
        email: "",
        postal_code: "",
        city: "",
        region: "",
        country: "",
        unit_system: "",
        is_preferred_chapter_discussions: [
          // {
          //   is_preferred: true
          // }
        ],
        is_preferred_series_discussions: [
          // {
          //   is_preferred: true
          // }
        ]
      }
    };
  },
  apollo: {
    resultUserSettingsById: {
      query: UserSettingsById,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      update(data) {
        if (data.resultUserSettingsById.length > 0) {
          return data.resultUserSettingsById[0];
        }
        return this.resultUserSettingsById; //the non-null default
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasSettings() {
      return !!this.resultUserSettingsById.id;
    },
    userSettings() {
      return this.hasSettings ? this.resultUserSettingsById : null;
    },
    email() {
      return this.userSettings?.email || null;
    },
    city() {
      return this.userSettings?.city || null;
    },
    region() {
      return this.userSettings?.region || null;
    },
    country() {
      return this.userSettings?.country || null;
    },
    postalCode() {
      return this.userSettings?.postal_code || null;
    }
  },
  mounted() {
    const { token: refreshToken } = this.$route.params;
    if (refreshToken) {
      this.loginByToken(refreshToken);
      this.$root.$emit("universal-success-message", "Your email has been confirmed.");
    }
  },
  methods: {
    async loginByToken(token) {
      const result = await UserApi.loginByToken(token);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      const { accessToken, user } = result.result.value || {};
      this.$auth.refreshAuthUser({ accessToken, user });
      return result;
    },
    onUpdateClicked() {
      this.$router.push({ name: "DashboardSettingsEdit" });
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawSection>
      <SuawSummary summary-type="form" heading="Settings" button-label="Update Settings" @click="onUpdateClicked" />
    </SuawSection>
    <SuawSection section-style="border">
      <SuawInputGroup direction="column">
        <SuawHeading level="4" content="Account Details" />
        <SuawLabel size="medium" weight="bold" label-text="Email" />
        <SuawParagraph :text="email" />
        <SuawLabel size="medium" weight="bold" label-text="Password" />
        <SuawParagraph text="**************" />
      </SuawInputGroup>
    </SuawSection>
    <SuawSection section-style="border">
      <SuawInputGroup direction="column" group-gap="double">
        <SuawHeading level="4" content="Location" />
        <SuawParagraph text="Entering this information will help us show you the most relevant events near you." />
        <SuawLabel size="medium" weight="bold" label-text="Country" />
        <SuawParagraph :text="country" />
        <SuawLabel v-if="region" size="medium" weight="bold" label-text="State/Province" />
        <SuawParagraph v-if="region" :text="region" />
        <SuawLabel size="medium" weight="bold" label-text="City" />
        <SuawParagraph :text="city" />
        <SuawLabel size="medium" weight="bold" label-text="Postal Code" />
        <SuawParagraph :text="postalCode" />
      </SuawInputGroup>
    </SuawSection>
  </SuawMainContent>
</template>
