<script>
import { SuawWriteWithUsSection } from "@/components";
export default {
  name: "WriteWithUs",
  components: { SuawWriteWithUsSection },
  methods: {
    onJoinClick() {
      this.$router.push({
        name: "SignIn",
        query: {
          tab: "signup"
        }
      });
    }
  }
};
</script>

<template>
  <SuawWriteWithUsSection @click="onJoinClick" />
</template>
