<script>
import { SuawHeading, SuawParagraph, SuawButton } from "@/components";
import heroArrow from "../../../../public/img/home/hero-long-arrow.png";
import heroImage from "../../../../public/img/home/hero-image-hand-pencil.png";
import clouds from "../../../../public/img/home/clouds-2.png";
import StatsSection from "./StatsSection.vue";

export default {
  name: "HeroSection",
  components: { SuawHeading, SuawParagraph, SuawButton, StatsSection },
  data() {
    return {
      heroArrow,
      heroImage,
      clouds
    };
  }
};
</script>

<template>
  <div>
    <div class="suaw-hero-section">
      <div class="suaw-hero-section__main-container">
        <img class="suaw-hero-section__hero-image-background" :src="heroImage" alt="Large hand with pencil in the middle of two people." />
        <div class="suaw-hero-section__cta-container">
          <div class="suaw-hero-section__heading-container">
            <SuawHeading class="suaw-hero-section__heading" content="Gather. Write. Repeat. " level="1" />
          </div>
          <div class="suaw-hero-section__subheading-container">
            <SuawParagraph
              class="suaw-hero-section__subheading"
              text="Shut Up & Write! is a <b>totally free</b> community-driven nonprofit initiative designed to help writers get their writing done."
            />
          </div>
          <div class="suaw-hero-section__button-container">
            <SuawButton class="suaw-hero-section__button" type="primary" size="hero" button-text="Join Today!" @click="$emit('click')" />
          </div>
        </div>
        <div class="suaw-hero-section__image-container">
          <img class="suaw-hero-section__hero-image" :src="heroImage" alt="Large hand with pencil in the middle of two people." />
        </div>
        <div class="suaw-hero-section__arrow-container">
          <img class="suaw-hero-section__arrow" :src="heroArrow" alt="A decorative yellow arrow pointing towards the Join Today button." />
        </div>
        <div class="suaw-hero-section__clouds-container">
          <img class="suaw-hero-section__clouds" :src="clouds" alt="two small clouds" />
        </div>
      </div>
    </div>
    <StatsSection />
  </div>
</template>

<style lang="scss" scoped>
.suaw-hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ebf0ff;
  overflow: hidden;
  @media (max-width: 1321px) {
    display: block;
  }
  @media (max-width: 821px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &__main-container {
    position: relative;
    width: 1336px;
    margin-left: 24px;
    padding-left: 24px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    @media (max-width: 1321px) {
      padding-left: 0px;
    }
    @media (max-width: 821px) {
      width: 100%;
      margin: 0;
    }
    @media (max-width: 650px) {
      display: flex;
    }
  }
  &__cta-container {
    z-index: 1;
    max-width: 720px;
    padding: 110px 0px;
    grid-column: 1/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    @media (max-width: 821px) {
      padding-top: 64px;
      padding-bottom: 72px;
      margin: 0 16px;
    }
    @media (max-width: 650px) {
      gap: 48px;
      padding: 48px 0px;
    }
  }
  &__heading {
    font-size: 72px;
    line-height: 100%;
    font-family: var(--secondary-font-family);
    font-weight: bold;
    z-index: 1;
    @media (max-width: 768px) {
      font-size: 64px;
    }
    @media (max-width: 650px) {
      font-size: 52px;
    }
  }
  &__subheading {
    font-size: 24px;
  }
  &__image-container {
    width: 624px;
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    align-items: flex-end;
    @media (max-width: 821px) {
      display: none;
    }
  }
  &__hero-image {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 624px;
    height: auto;
  }
  &__hero-image-background {
    display: none;
    @media (max-width: 821px) {
      display: block;
      position: absolute;
      top: 0;
      left: 35px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.1;
    }
    @media (max-width: 650px) {
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
  &__arrow-container {
    grid-column: 1/2;
    grid-row: 1/2;
    position: relative;
    @media (max-width: 900px) {
      display: none;
    }
  }
  &__arrow {
    position: absolute;
    top: 265px;
    left: 133px;
  }
  &__clouds-container {
    grid-column: 1/2;
    grid-row: 1/2;
    position: relative;
    @media (max-width: 821px) {
      display: none;
    }
  }
  &__clouds {
    position: absolute;
    top: 50px;
    left: 200px;
  }
  &__button {
    width: var(--comp-hero-button-width);
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
