<script>
import Button from '../Button/Button.vue';
import Checkbox from '../Checkbox/Checkbox.vue';
import Icon from '../Icon/Icon.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import TextArea from '../TextArea/TextArea.vue';

export default {
  name: "EditorBox",
  components: {
    Button,
    Checkbox,
    Icon,
    Paragraph,
    TextArea,
  },
  props: {
    value: {
      type: Object,
    },
    readOnly: Boolean,
    checkText: String,
    formId: String,
    userMessage: String,
    inputMessage: String,
    buttonText: {
      type: String,
      default: "Submit",
    },
    secondaryButtonText: String,
    size: String,
    useTipTap: {
      type: Boolean,
      default: true,
    },
    useFormatting: {
      type: Boolean,
      default: false,
    },
    clickMethod: {
      type: Function,
      default: () => {},
    },
    item: {
      default: () => {},
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    immediate: true,
    deep: true,
  },
  computed: {
    iconColor() {
      return this.readOnly ? 'gray' : 'blue';
    },
    buttonSize() {
      return this.size ? this.size : "large";
    },
  },
  data() {
    return {
      localValue: this.value,
    }
  },
  methods: {
    updateValue(newVal) {
      this.$emit("input", newVal);
    },
    handleImageUpload(base64Data) {
      this.$emit("image-upload", base64Data);
    },
    handleSubmit() {
      if (this.$refs.textArea.isEmpty()) {
        return;
      } else {
        this.$emit("submit", this.localValue);
        this.localValue = { type: "doc", content: [{ type: "paragraph" }] };
      }
    }
  }
};
</script>

<template>
  <div class="suaw-editor-box">
    <div class="suaw-editor-box__content">
      <Icon
        v-if="!useTipTap"
        icon="IconEdit"
        :color="iconColor"
        class="suaw-editor-box__icon"
      />
      <TextArea
        ref="textArea"
        v-model="localValue"
        @input="updateValue"
        @image-upload="handleImageUpload"
        :placeholder="inputMessage"
        borderStyle="ghost"
        :use-tip-tap="useTipTap"
        :use-formatting="useFormatting"
        :allow-images="false"
      />
    </div>
    <footer
      v-if="!readOnly"
      class="suaw-editor-box__footer"
    >
      <Paragraph
        v-if="userMessage"
        :text="userMessage"
      />
      <Checkbox
        v-if="checkText"
        :id="formId"
        :label="checkText"
      />
      <Button
        v-if="secondaryButtonText"
        type="secondary"
        :buttonText="secondaryButtonText"
        :size="buttonSize"
        @click="$emit('secondary-click')"
      />
      <Button
        type="primary-light"
        :buttonText="buttonText"
        :size="buttonSize"
        :click-method="clickMethod"
        @click="handleSubmit"
      />
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-editor-box {
  background-color: var(--sem-color-background-lightest);
  border: 1px solid var(--sem-color-border-medium);
  border-radius: var(--sem-radius-sm);
  flex-direction: column;
  padding: var(--sem-space-double);

  &,
  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    min-height: 100px;
  }

  &__footer {
    align-items: center;
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: flex-end;
    padding-top: var(--sem-space-double);
  }
}
</style>
