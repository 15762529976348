<script>
export default {
  name: 'LogoMeet',
}
</script>

<template>
<svg width="80" height="66" viewBox="0 0 80 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M61 45.9759V22.0742L63.4783 16.9167L75.8696 7.1009C77.5217 5.7145 80 6.8791 80 9.0419V55.9581C80 58.1209 77.4667 59.2855 75.8145 57.8991L61 45.9759Z" fill="#00AC47"/>
<path d="M20 0L0 20H20V0Z" fill="#FE2C25"/>
<path d="M20 20H0V46H20V20Z" fill="#2684FC"/>
<path d="M0 46V59.3333C0 63 3 66 6.6667 66H20V46H0Z" fill="#0066DA"/>
<path d="M63.5 6.5902C63.5 2.9656 60.5638 0 56.975 0H20V20H44.5V33L63.5 32.4016V6.5902Z" fill="#FFBA00"/>
<path d="M44.5 46H20V66H56.975C60.5638 66 63.5 63.0387 63.5 59.4194V33H44.5V46Z" fill="#00AC47"/>
<path d="M63.5 17V48L44.5 33L63.5 17Z" fill="#00832D"/>
</svg>
</template>

<style scoped>
</style>
