<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import becomeAnOrganizerHeroImage from "../../../../public/img/resources/become-an-organizer-hero.png";
import { SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail, SuawButton } from "@/components";
export default {
  name: "ResourcesBecomeAnOrganizer",
  components: { ResourceFrame, SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail, SuawButton },
  data() {
    return {
      becomeAnOrganizerHeroImage
    };
  },
  methods: {
    onApplyClick() {
      window.open("https://shutupwrite.typeform.com/to/DS7ugm?typeform-source=shutupwrite.com", "_blank", "noopener,noreferrer");
    }
  }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="general">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-become-an-organizer-heading">Become an <b>Organizer</b></h1>
      <SuawHeading
        class="suaw-become-an-organizer-intro"
        level="3"
        content="Becoming a Shut Up & Write organizer is about more than just leading a writing group—it's about bringing people together and helping each other stay on track. As an organizer, you'll help writers stay focused and motivated using our simple, effective approach. You'll create a welcoming space where writers of all levels can connect, support each other, and get things done, week after week. It's a chance to make a real difference in your writing community and in your own writing life."
      />
      <SuawThumbnail class="suaw-become-an-organizer-thumbnail" :content="becomeAnOrganizerHeroImage" />
      <h2 class="suaw-become-an-organizer-subtitle">
        What You'll Do:
      </h2>
      <SuawParagraph
        text="As a Shut Up & Write! organizer, you'll be the driving force behind creating engaging and effective writing events for our community. Your responsibilities will include:"
      />
      <div class="suaw-become-an-organizer-guide">
        <ul class="suaw-become-an-organizer-guide__list">
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Event Planning:</b> You'll be in charge of picking the time and place for your events, whether they're in-person meetups or online sessions. This includes finding venues or setting up virtual spaces that work for your group, making sure everything runs smoothly."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Facilitation:</b> You'll lead the writing sessions, guiding everyone through our proven method. Your role is to offer motivation, keep the group focused, and create a positive and inclusive atmosphere where everyone feels welcome and inspired to write."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Community Building:</b> You'll be the glue that holds your writing group together. From coordinating with members before the event to fostering connections during and after, you'll create a space where writers can share, learn from each other, and build lasting relationships."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Promotion:</b> You'll help spread the word about your events, using social media and local networks to attract new participants. Whether it's a post on Facebook, a flyer at a local bookstore, or a mention in a community newsletter, your promotion efforts will help grow your writing community."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Ongoing Support:</b> Your role doesn't end when the session does. You'll encourage writers to keep coming back, helping them establish a regular writing practice. By maintaining the energy and momentum over time, you'll create a loyal and engaged group that looks forward to writing together each week."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-become-an-organizer-subtitle">
        Who You Are:
      </h2>
      <SuawParagraph text="We recognize that our most successful organizers share specific traits. These include:" />
      <div class="suaw-become-an-organizer-guide">
        <ul class="suaw-become-an-organizer-guide__list">
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>A Welcoming Presence:</b> You're great at making people feel comfortable and at ease. Whether it's greeting newcomers or setting the tone for a productive session, your ability to create a warm, inviting atmosphere is key to building a supportive writing community."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Active Writing Projects:</b> You have your own writing projects that you're passionate about, whether it's a novel, short stories, essays, or anything else. This keeps you connected with fellow writers and engaged in the writing process alongside your group."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Natural Host Vibes:</b> You love connecting with people and enjoy bringing them together. Your enthusiasm for building relationships helps create a strong, supportive writing community where everyone feels encouraged."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Reliability:</b> Your group members need to know they can count on you. Being reliable and consistent in hosting sessions helps maintain trust and creates a dependable space for everyone to write."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Long-Term Commitment:</b> You're in this for the long haul. Building a strong, connected writing community takes time, and you're ready to make a long-term commitment to your group."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Positive and Professional Attitude:</b> Your positive outlook and professional approach set the tone for the group. Whether you're dealing with challenges or celebrating wins, your attitude helps inspire and motivate everyone."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Conversational English Skills:</b> While you don't need to be fluent, having a good grasp of conversational English helps you communicate effectively with your group."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-become-an-organizer-subtitle">
        Why Join Us:
      </h2>
      <SuawParagraph text="We recognize that our most successful organizers share specific traits. These include:" />
      <div class="suaw-become-an-organizer-guide">
        <ul class="suaw-become-an-organizer-guide__list">
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Make an Impact:</b>  You'll help writers achieve their goals, overcome writer's block, and build a steady writing routine. Your support will make a real difference in their creative journeys."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Grow Personally:</b> You'll enhance your leadership, communication, and organizational skills, all while making a positive impact on others."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Build Community:</b> You'll connect with fellow writers and create strong relationships. Your efforts will help foster a supportive and motivating environment for everyone."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph text="<b>Set Your Schedule:</b> You'll have the freedom to organize events that suit your schedule and preferences, whether online or in person." />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="<b>Access Our Resources:</b> Benefit from our years of experience in facilitating writing groups. You'll have access to an organizer toolkit full of resources and tips to help you plan and run your events smoothly."
            />
          </li>
        </ul>
      </div>

      <h2 class="suaw-become-an-organizer-subtitle">
        How This Works:
      </h2>
      <SuawParagraph text="We recognize that our most successful organizers share specific traits. These include:" />
      <div class="suaw-become-an-organizer-guide">
        <ul class="suaw-become-an-organizer-guide__list">
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph text='You&#39;ll promise to uphold our <a href="/resources/code-of-conduct">Code of Conduct</a>.' />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph text='You&#39;ll familiarize yourself with our <a href="/resources/method">method</a> and make sure you&#39;re ready to use it.' />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph text="You'll agree not to market any other affiliations, including any services you provide, in your writing events." />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph
              text="We require our hosts to use their real names. Ensure that the information you provided in your Shut Up & Write! account is accurate. If not, please update your account information before applying."
            />
          </li>
          <li class="suaw-become-an-organizer-guide__item">
            <SuawParagraph text="You'll fill out this form and we'll be in touch with next steps!" />
          </li>
        </ul>
      </div>
      <div>
        <SuawButton class="suaw-become-an-organizer-button" size="large" button-text="Apply to be an Organizer!" @click="onApplyClick" />
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-become-an-organizer-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-become-an-organizer-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-become-an-organizer-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-become-an-organizer-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-become-an-organizer-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
//list
.suaw-become-an-organizer-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
