<script>
import { SuawHeading, SuawButton } from "@/components";
import writingPartnersLogo from "../../../../public/img/home/writing-partners-logo.png";

export default {
  name: "WritingPartnersSection",
  components: { SuawHeading, SuawButton },
  data() {
    return {
      writingPartnersLogo
    };
  }
};
</script>

<template>
  <div class="suaw-wp-section">
    <div class="suaw-wp-section__main-container">
      <div class="suaw-wp-section__card-container">
        <div class="suaw-wp-section__card-text-container">
          <SuawHeading
            class="suaw-wp-section__card-text"
            level="4"
            content="Shut Up & Write! is an initiative of Writing Partners, a nonprofit committed to cultivating communities that foster intentional connections. Writing regularly with others nurtures personal growth, critical thinking, and a sense of belonging."
          />
        </div>
        <div class="suaw-wp-section__card-logo-container">
          <img class="suaw-wp-section__card-logo" :src="writingPartnersLogo" />
        </div>
      </div>
      <div class="suaw-wp-section__cta-container">
        <div class="suaw-wp-section__cta-heading-container">
          <SuawHeading
            class="suaw-wp-section__cta-heading"
            level="2"
            alignment="center"
            content="Help us ensure all writers have access to inclusive writing communities everywhere."
          />
        </div>
        <div class="suaw-wp-section__cta-button-container">
          <SuawButton class="suaw-wp-section__cta-button" type="primary" size="hero" button-text="Support Our Cause" @click="$emit('click')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-wp-section {
  background: var(--sem-color-info-light);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 88px;
  @media screen and (max-width: 1024px) {
    padding-top: var(--sem-space-octuple);
  }
  @media screen and (max-width: 600px) {
    padding-top: var(--sem-space-sextuple);
  }
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 48px;
    @media (max-width: 767px) {
      margin: 0 16px;
    }
    @media (max-width: 650px) {
      row-gap: 32px;
    }
  }

  &__card-container {
    background-color: var(--sem-color-background-lightest);
    box-shadow: var(--soft-shadow-draft);
    border-radius: var(--sem-radius-md);
    padding: var(--sem-space-triple) var(--sem-space-sextuple);
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    column-gap: 32px;
    @media (max-width: 1024px) {
      padding: var(--sem-space-quadruple) var(--sem-space-sextuple);
    }
    @media (max-width: 850px) {
      grid-template-columns: 1fr;
      row-gap: 24px;
    }
    @media (max-width: 850px) {
      padding: 16px;
    }
  }

  &__card-text-container {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: center;
    @media (max-width: 850px) {
      grid-column: auto;
      grid-row: 2/3;
    }
  }
  &__card-logo-container {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
    @media (max-width: 850px) {
      grid-column: auto;
      grid-row: 1/2;
      margin: 0 64px;
    }
    @media (max-width: 650px) {
      margin: 0 16px;
    }
  }
  &__cta-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
  }
  &__cta-heading-container {
    max-width: 800px;
    justify-self: center;
  }
  &__cta-button-container {
    justify-self: center;
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__cta-button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &__card-text {
    color: var(--sem-color-text-medium);
    @media (max-width: 850px) {
      text-align: center;
    }
  }

  &__card-logo {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__cta-heading {
    font-family: var(--secondary-font-family);
    font-weight: 700;
    color: var(--sem-color-text-medium);
  }
}
</style>
