import Vue from "vue";
import VueMeta from "vue-meta";
Vue.use(VueMeta);
import App from "./App.vue";
import VuePluralize from "vue-pluralize";
import router from "./router";
import build_info from "../build-info.json";

// Import base styles
import "./assets/base.css";

// Import local components
import * as components from "./components";

// Register all components globally
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
// GraphQL API / Apollo Client
import VueApollo from "vue-apollo";
import x5GMaps from "x5-gmaps";
import VueGtag from "vue-gtag";

import { hasuraClient } from "./utils/graphql/apollo";
// Import the plugin here
import { AuthPlugin } from "./utils/auth";
import "./utils/formatting/filters";
import { default as log_plugin, Logger, DEBUG } from "./utils/plugins/logging";

const $log = new Logger("main.js", { level: DEBUG });
Vue.use(log_plugin, { level: DEBUG });
Vue.prototype.matchMedia = arg => {
  return window.matchMedia(arg);
};
Vue.use(x5GMaps, {
  key: window.config.GOOGLE_MAPS_KEY,
  libraries: ["places"]
});
Vue.use(VuePluralize);
Vue.use(VueGtag, {
  config: {
    pageTrackerExcludedRoutes: ["challenges_prompt_qa"],
    id: window.config.GOOGLE_ANALYTICS_KEY //for www.shutupwrite.com
  }
});

let dateInterval;

// Global Mixins
Vue.mixin({
  data() {
    const mqMobile = this.matchMedia("(max-width: 768px)");
    const mqTablet = this.matchMedia("(max-width: 1023px)");
    //using 'suw' prefix to avoid conflicts with 3rd party components
    return {
      mqMobile: mqMobile,
      mqTablet: mqTablet,
      suwMobile: mqMobile.matches,
      suwTablet: mqTablet.matches,
      suwDesktop: !mqTablet.matches && !mqMobile.matches,
      nowTimer: new Date()
    };
  },
  created() {
    dateInterval = setInterval(() => (this.nowTimer = new Date()), 1000);
  },
  destroyed() {
    clearInterval(dateInterval);
  },
  mounted() {
    this.mqMobile.addListener(() => {
      this.suwMobile = this.mqMobile.matches;
      this.suwTablet = this.mqTablet.matches;
      this.suwDesktop = !this.suwMobile && !this.suwTablet;
    });
  },
  methods: {
    display404Page() {
      this.$emit("not-found");
    }
  }
});

window.build_info = build_info;

// Install the authentication plugin here
Vue.use(AuthPlugin, {
  apolloClient: hasuraClient,
  router: router
});

const apolloProvider = new VueApollo({
  defaultClient: hasuraClient,
  defaultOptions: {
    $query: {
      //loadingKey: "loading",
      // the skip key can take a responsive var from vue, so we wait til the
      // auth system is done "loading" before doing any GQL queries
      skip: vm => vm.$auth.loading // TODO: this has a 60s timeout by default, make it smarter
    }
  }
});
Vue.use(VueApollo);

Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  // errorHandler: (error, vm, info) => {
  //   console.log("vue fucked up", error, vm, info);
  // },
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
