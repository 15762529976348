<script>

export default {
  name: "Tabs",
  components: {
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabs: Array,
    tabsType: {
      type: String,
      default: "lines",
      validator: function (value) {
        return ["lines", "buttons"].indexOf(value) !== -1;
      },
    },
    tabsStyle: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "border", "shadow", "border-shadow"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
  },

  methods: {
    changeTab(index) {
      if (!this.disabled) {
        this.$emit("input", index);
        this.$emit("change", index);
      }
    },
    getComponentType(tab) {
      return typeof tab.component === 'string' ? 'div' : tab.component;
    },
  },
};
</script>

<template>
  <div
    class="suaw-tabs"
    :class="['suaw-tabs--' + tabsStyle, className]"
  >
    <nav
      class="suaw-tabs__nav"
      :class="'suaw-tabs__nav--' + tabsType"
    >
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        class="suaw-tabs__item"
        :class="[
          'suaw-tabs__item--' + tabsType,
          {
            'suaw-tabs__item--active': value === index,
            'suaw-tabs__item--inactive': value !== index
          }
        ]"
        :aria-controls="'tabpanel-' + index"
        :disabled="disabled"
        @click="changeTab(index)"
      >
        <span class="suaw-tabs__label">{{ tab.label }}</span>
      </button>
    </nav>
    <div
      class="suaw-tabs__content"
      :class="'suaw-tabs__content--' + tabsStyle"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        v-show="value === index"
        class="suaw-tabs__block"
      >
        <slot :name="'tab-' + index" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-tabs {
  flex-grow: 1;

  &:not(.suaw-tabs--section-sidebar) {
    max-width: 100%;
  }

  &--section-sidebar {
    @media screen and (min-width: 1025px) {
      max-width: calc(100% - 400px);
    }
    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &--border-shadow {
    border: 1px solid var(--sem-color-border-medium);
  }

  &--border {
    border: 1px solid var(--sem-color-border-regular);
  }

  &--border-shadow,
  &--shadow,
  &--border {
    --comp-tabs-height: 40px;
    background-color: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-sm);
  }

  &--shadow,
  &--border-shadow {
    box-shadow: var(--date-square-shadow);
  }

  &--default {
    --comp-tabs-height: 32px;
  }

  &__nav {
    align-items: flex-start;
    display: flex;
    height: var(--comp-tabs-height);
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    width: 100%;
  }

  &__nav--buttons {
    --comp-tabs-height: 40px;
  }

  &__nav--lines::after {
    bottom: 0;
    content: '';
    border-bottom: 2px solid var(--sem-color-border-regular);
    height: 2px;
    position: absolute;
    width: 100%;
    z-index: 8;
  }

  &__item {
    align-items: center;
    display: inline-flex;
    height: var(--comp-tabs-height);
  }

  &__item:disabled {
    border-color: var(--sem-color-border-regular);

    .suaw-tabs__label {
      color: var(--sem-color-inactive-medium);
    }
  }

  &__item:disabled:hover {
    pointer-events: none;

    .suaw-tabs__label {
      color: var(--sem-color-inactive-medium);
    }
  }

  &__item--buttons {
    --comp-tabs-label-font-weight: 500;
    --comp-tabs-height: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--sem-color-border-regular);
    min-height: 40px;
    justify-content: center;
    padding: var(--sem-space-base);
    width: 100%;
  }

  &__item--lines {
    --comp-tabs-label-font-weight: normal;
    --comp-tabs-item-lines-padding: 0;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    margin-bottom: -2px;
    padding: var(--comp-tabs-item-lines-padding) var(--sem-space-double) var(--sem-space-base);
    position: relative;
    transition-property: border-color;
    z-index: 9;

    .suaw-tabs--border &,
    .suaw-tabs--shadow &,
    .suaw-tabs--border-shadow & {
      --comp-tabs-item-lines-padding: var(--sem-space-base);
      justify-content: center;
      text-align: center;
      width: 100%;
    }
  }

  &__item,
  &__label {
    transition-duration: .25s;
    transition-timing-function: linear;
  }

  &__label {
    white-space: nowrap;
    font-size: var(--p-font-size);
    font-weight: var(--comp-tabs-label-font-weight);
    transition-property: color;
  }

  &__item--inactive {
    border-color: var(--sem-color-border-regular);
  }

  &__item--inactive.suaw-tabs__item--lines:hover {
    border-color: var(--sem-color-border-darkest);
  }

  &__item--active.suaw-tabs__item--lines {
    border-color: var(--sem-color-info-medium);
  }

  &__item--inactive .suaw-tabs__label {
    color: var(--sem-color-inactive-medium);
  }

  &__item--inactive:hover .suaw-tabs__label {
    color: var(--sem-color-text-darkest);
  }

  &__item--active {
    &.suaw-tabs__item--buttons {
      border-color: var(--sem-color-info-medium);
    }

    .suaw-tabs__label {
      color: var(--sem-color-info-medium);
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-quadruple);
  }

  &__content {
    margin-top: var(--sem-space-triple);
  }

  &__content--border,
  &__content--shadow,
  &__content--border-shadow {
    padding: var(--sem-space-base) var(--sem-space-quintuple) var(--sem-space-quintuple);
  }

  &__headline {
    font-weight: 500;
  }
}
</style>
