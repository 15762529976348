<script>
import { SuawInputGroup, SuawLabel, SuawAgenda } from "@/components";
import { GetEventSeriesAgendaById } from "../../operations.gql";
import { parse, format, isValid } from "date-fns";

export default {
  name: "AgendaPipe",
  components: { SuawInputGroup, SuawLabel, SuawAgenda },
  props: {
    seriesId: {
      type: String,
      required: true
    }
  },
  apollo: {
    resultGetEventSeriesAgendaById: {
      query: GetEventSeriesAgendaById,
      variables() {
        return {
          seriesId: this.seriesId
        };
      },
      skip() {
        return !this.seriesId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasSeries() {
      return !!this.resultGetEventSeriesAgendaById?.id;
    },
    series() {
      return this.hasSeries ? this.resultGetEventSeriesAgendaById : null;
    },
    agendaItems() {
      if (!this.hasSeries || !this.series.event_series_agendas) return [];
      return this.series.event_series_agendas.map(item => {
        const parsedTime = parse(item.time_at, "HH:mm:ss", new Date());
        const formattedTimeAt = isValid(parsedTime) ? format(parsedTime, "hh:mm a") : "Invalid date";
        return {
          ...item,
          time_at: formattedTimeAt
        };
      });
    }
  }
}
</script>

<template>
  <SuawInputGroup v-if="agendaItems.length > 0" direction="column" group-gap="double">
    <SuawLabel size="large" label-text="Agenda" weight="bold" />
    <SuawAgenda :items="agendaItems" />
  </SuawInputGroup>
</template>
