<script>
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardArticleExpanded",
  components: {
    Thumbnail,
    Heading,
    Paragraph,
    Chip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    author: {
      type: String,
    },
    avatarType: {
      type: String,
    },
    avatarContent: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    heading: {
      type: String,
    },
    description: {
      type: String,
    },
    route: {
      type: String,
    },
  },
}
</script>

<template>
  <div 
    class="suaw-card__content"
    @click.stop="$emit('click', $props)"
  >
    <Thumbnail
      size="large"
      :content="thumbnail"
      class="suaw-card__thumbnail"
    />
    <div class="suaw-card__data">
      <header class="suaw-card__header">
        <Heading
          level="3"
          :content="heading"
        />
        <Paragraph
          v-if="description"
          weight="normal"
          size="medium"
          :text="description"
        />
        <footer class="suaw-card__footer">
          <span>by</span>
          <Chip
            size="medium"
            status="default"
            :label="author"
            chipType="avatar"
            :avatarType="avatarType"
            :avatarContent="avatarContent"
          />
        </footer>
      </header>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content {
    gap: var(--sem-space-triple);
    transition: transform .25s linear;

    @media screen and (max-width: 680px) {
      flex-wrap: wrap;
    }
  }

  &__content:hover {
    cursor: pointer;
    transform: scale(0.99);
  }

  &__content,
  &__header,
  &__footer,
  &__data {
    align-items: flex-start;
    display: flex;
  }

  &__footer {
    gap: var(--sem-space-base);
  }

  &__header {
    gap: var(--sem-space-double);
  }

  &__header,
  &__chips,
  &__data {
    flex-direction: column;
  }

  &__distance {
    position: absolute;
    top: var(--sem-space-base);
    left: var(--sem-space-base);
    z-index: 2;
  }

  &__thumbnail {
    min-width: 300px;
    min-height: 200px;
  }

  &__footer {
    align-items: center;
  }
}
</style>
