<script>
export default {
  name: "Thumbnail",
  props: {
    size: {
      type: String,
      validator: function (value) {
        return [
          "small",
          "medium",
          "large",
          "fill",
          "fill-aspect",
          "fit",
        ].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
    radius: {
      type: String,
      default: "rounded",
      validator: function (value) {
        return [
          "sharp",
          "rounded",
          "circle",
        ].indexOf(value) !== -1;
      },
    },
    content: {
      type: String,
      default: "",
    },
    imageAlt: {
      type: String,
      default: "Image Description",
    }
  },
};
</script>

<template>
  <picture
    class="suaw-thumbnail"
    :class="[
      'suaw-thumbnail--' + size,
      'suaw-thumbnail--' + radius,
      className
    ]"
  >
    <img
      class="suaw-thumbnail__image"
      :src="content"
      :alt="imageAlt"
    />
  </picture>
</template>

<style lang="scss" scoped>
.suaw-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &--rounded {
    border-radius: var(--sem-radius-md);
  }

  &--circle {
    border-radius: var(--sem-radius-max);
  }

  &--fit {
    min-width: 100%;
    // changed height from auto to 100
    height: 100%;
  }

  &--fill {
    height: 350px;
    width: 100%;
  }

  &--fill-aspect {
    width: 100%;
    aspect-ratio: 3.2;
    @media (max-width: 767px) {
      aspect-ratio: 2.5;
    }
    @media (max-width: 650px) {
      aspect-ratio: 2;
    }
  }

  &--large {
    height: 146px;
    width: 216px;
  }

  &--sidebar {
    min-height: 240px;
  }

  &--medium {
    border-radius: var(--sem-radius-sm);
    height: 80px;
    min-width: 80px;
    width: 80px;
  }

  &--small {
    height: 56px;
    min-width: 56px;
    width: 56px;
  }

  &__image {
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}
</style>
