<script>
export default {
  name: "Heading",
  props: {
    level: {
      type: [String, Number],
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    alignment: {
      type: String,
      default: "left",
      validator: function (value) {
        return ["left", "center", "right"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
  },
  methods: {
    headingLevel() {
      // Ensure the level is within the valid range (1 to 6)
      const validLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
      const level = `h${Math.max(1, Math.min(6, parseInt(this.level, 10)))}`;

      // If the provided level is not valid, default to 'h6'
      return validLevels.includes(level) ? level : 'h6';
    },
  },
};
</script>

<template>
  <component 
    :is="headingLevel()"
    :class="[
      'suaw-heading',
      'suaw-heading--' + level,
      'suaw-heading--' + alignment,
      className
    ]"
  >
    {{ content }}
  </component>
</template>

<style lang="scss" scoped>
.suaw-heading {
  color: var(--sem-color-text-dark);
  font-family: var(--font-family);
  line-height: var(--base-line-height);
  white-space: pre-line;

  &--1 {
    font-size: var(--h-1-font-size);
    font-weight: var(--h-1-font-weight);
  }

  &--2 {
    font-size: var(--h-2-font-size);
    font-weight: var(--h-2-font-weight);
  }

  &--3 {
    font-size: var(--h-3-font-size);
    font-weight: var(--h-3-font-weight);
    color: var(--sem-color-neutral-dark);
  }

  &--4 {
    font-size: var(--h-4-font-size);
    font-weight: var(--h-4-font-weight);
  }

  &--5 {
    font-size: var(--h-5-font-size);
    font-weight: var(--h-5-font-weight);
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}
</style>
