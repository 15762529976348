<script>
import Heading from '../Heading/Heading.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardPrompt",
  components: {
    Heading,
    Chip,
  },
  props: {
    promptHeading: {
      type: String,
    },
    promptResponses: {
      type: String,
    },
    promptLikes: {
      type: String,
    },
    showPromptLikes: {
      type: Boolean,
      default: true
    },
    datePosted: {
      type: String,
    },
    hostName: {
      type: String,
    },
  },
}
</script>

<template>
  <div class="suaw-card">
    <div class="suaw-card__content">
      <header class="suaw-card__header">
        <div class="suaw-card__block">
          <Chip
            size="medium"
            chipType="text"
            status="default"
            shape="square"
            label="Prompt"
          />
          <small>{{ datePosted }}</small>
          <slot name="card-header"></slot>
        </div>
        <Heading
          level="5"
          :content="promptHeading"
        />
        <Chip
          size="medium"
          chipType="avatar"
          :label="hostName"
          avatarType="orange"
          status="default"
        />
        <slot name="card-content"></slot>
      </header>
      <footer class="suaw-card__footer">
        <Chip
          size="medium"
          chipType="icon"
          status="info"
          icon="IconComment"
          shape="square"
          :label="promptResponses + ' Responses'"
        />
        <Chip
          v-if="showPromptLikes"
          size="medium"
          chipType="icon"
          status="critical"
          icon="IconHeartFill"
          shape="square"
          :label="promptLikes"
        />
        <slot name="card-footer"></slot>
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-sm);
  display: inline-flex;
  flex-direction: column;
  padding: var(--sem-space-triple);
  max-width: 256px;

  &,
  &__header,
  &__content,
  &__block,
  &__footer {
    display: flex;
  }

  &,
  &__content,
  &__block,
  &__footer {
    justify-content: space-between;
  }

  &__header,
  &__content {
    align-items: flex-start;
    flex-direction: column;
  }

  &__header,
  &__block,
  &__footer {
    gap: var(--sem-space-base);
  }

  &__header {
    text-align: left;
  }

  &__content {
    flex-grow: 1;
    gap: var(--sem-space-double);
    min-height: 100%;
  }

  &__block,
  &__footer {
    align-items: center;
    width: 100%;
  }
}
</style>
