<script>
import Button from "../Button/Button.vue";
import Heading from "../Heading/Heading.vue";

export default {
  name: "ModalConfirmation",
  components: {
    Button,
    Heading,
  },
  props: {
    toggleButtonConfirm: {
      type: Function,
      required: true,
      default: () => { }
    },
    toggleButtonCancel: {
      type: Function,
      required: true,
      default: () => { }
    },
    modalTitle: {
      type: String,
    },
    clickMethod: {
      type: Function,
    },
    modalActionTextLeft: {
      type: String,
      default: 'Cancel'
    },
    modalActionTextRight: {
      type: String,
      default: 'Confirm'
    },
  },
  methods: {},
};
</script>

<template>
  <div class="suaw-modal__content suaw-modal__content--small">
    <Heading
      level="4"
      :content="modalTitle"
    />
    <slot></slot>
    <footer class="suaw-modal__actions">
      <Button
        size="large"
        type="secondary-outline"
        iconLeft=""
        :buttonText="modalActionTextLeft"
        @click="toggleButtonCancel"
      />
      <Button
        size="large"
        type="primary"
        iconLeft=""
        :buttonText="modalActionTextRight"
        :click-method="clickMethod"
        @click="toggleButtonConfirm"
      />
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-modal__actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
