var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suaw-input-group",class:['suaw-input-group--' + _vm.groupStyle, _vm.isCentered],style:({
    '--field-1-size': _vm.fieldOneSize,
    '--field-2-size': _vm.fieldTwoSize,
    '--direction': _vm.direction,
    '--alignment': _vm.alignmentOptions,
    '--field-width': _vm.fieldWidth,
    '--group-gap': 'var(--sem-space-' + _vm.groupGap + ')',
    '--prevent-flex-grow': _vm.preventFlexGrow ? 0 : 1,
    '--comp-input-group-wrap-content': _vm.inlineWrapContent
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }