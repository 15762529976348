<script>
import { SuawParagraph } from "@/components";
import * as db from "../data.js";

export default {
  name: "AuthorBio",
  components: {
    SuawParagraph
  },
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      authors: db.authors()
    };
  },
  computed: {
    author() {
      return this.authors.filter(a => a.id === this.authorId)[0];
    },
    bio() {
      return this.author.bio;
    }
  }
};
</script>

<template>
  <SuawParagraph :text="bio" />
</template>
