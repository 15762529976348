<script>
export default {
  name: "Pill",
  props: {
    status: {
      type: String,
      validator: function (value) {
        return [
          "default",
          "active",
          "dark",
          "darken",
          "lightest",
          "darkest",
          "success",
          "critical",
          "disabled"
        ].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
    },
    labelClassName: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  computed: {
    pillClasses() {
      return this.text == '' ? 'suaw-pill--dot' : null
    }
  }
};
</script>

<template>
  <div 
    class="suaw-pill"
    :class="['suaw-pill--' + status, className, pillClasses]"
  >
    <span 
      class="suaw-pill__label"
      :class="labelClassName"
    >
      {{ text }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.suaw-pill {
  align-items: center;
  border-radius: var(--sem-radius-xs);
  display: inline-flex;
  padding: var(--sem-space-quarter) var(--sem-space-half);
  position: relative;

  &--dot {
    height: 8px;
  }

  &--default {
    background-color: var(--sem-color-neutral-light);
  }

  &--dark {
    background-color: var(--sem-color-text-regular);
  }

  &--darkest {
    background-color: var(--sem-color-background-darken);
  }

  &--lightest {
    background-color: var(--sem-color-background-lightest);
    box-shadow: var(--date-square-shadow);
  }

  &--darken {
    background-color: var(--sem-color-neutral-regular);
  }

  &--active {
    background-color: var(--sem-color-info-medium);
  }

  &--success {
    background-color: var(--sem-color-success-medium);
  }

  &--critical {
    background-color: var(--sem-color-critical-medium);
  }

  &--disabled {
    background-color: var(--sem-color-inactive-medium);
  }

  &__label {
    font-family: var(--font-family);
    font-size: var(--badge-font-size);
    font-style: var(--badge-font-style);
    font-weight: var(--badge-font-weight);
    letter-spacing: var(--badge-letter-spacing);
    line-height: var(--badge-line-height);
    position: relative;
    white-space: nowrap;

    .suaw-pill--default & {
      color: var(--sem-color-text-regular);
    }

    .suaw-pill--dark &,
    .suaw-pill--darken &,
    .suaw-pill--darkest &,
    .suaw-pill--success &,
    .suaw-pill--critical &,
    .suaw-pill--disabled &,
    .suaw-pill--active & {
      color: var(--sem-color-text-lightest);
    }
  }

  &__label:not(.suaw-pill__label--success):not(.suaw-pill__label--critical):not(.suaw-pill__label--info) {
    .suaw-pill--lightest & {
      color: var(--sem-color-text-regular);
    }
  }

  &__label--success {
    color: var(--sem-color-success-medium);
  }

  &__label--critical {
    color: var(--sem-color-critical-medium);
  }

  &__label--info {
    color: var(--sem-color-info-medium);
  }
}
</style>
