<script>
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';

export default {
  name: "SubHeader",
  components: {
    Heading,
    Paragraph,
  },
  props: {
    headlineTitle: String,
    headlineDesc: String,
    descAlignment: String,
    size: {
      type: String,
      default: "large",
      validator: function (value) {
        return ["large", "medium", "small"].indexOf(value) !== -1;
      },
    },
  }
}
</script>

<template>
  <article class="suaw-subheader">
    <div 
      class="suaw-subheader__holder"
      :class="'suaw-subheader__holder--' + size"
    >
      <Heading
        level="2"
        :content="headlineTitle"
      />
      <Paragraph
        v-if="headlineDesc"
        :text="headlineDesc"
        :alignment="descAlignment"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
.suaw-subheader {
  background-color: var(--sem-color-background-lightest);
  border-bottom: 1px solid var(--sem-color-border-regular);
  padding: var(--sem-space-triple);
  position: relative;
  width: 100%;

  @media screen and (min-width: 1025px) {
    --comp-headline-fields-width: calc(100% / 2 - var(--sem-space-base));
  }

  @media screen and (max-width: 1024px) {
    --comp-headline-fields-width: 100%;
  }

  &__holder {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--sem-space-base);
    justify-content: space-between;
    margin: auto;
    max-width: var(--comp-holder-width);
  }

  &__holder--large {
    --comp-holder-width: 1120px;
  }

  &__holder--medium {
    --comp-holder-width: 720px;
  }

  &__holder--small {
    --comp-holder-width: 400px;
  }

  &__holder--large > * {
    width: var(--comp-headline-fields-width);
  }
}
</style>
