<script>
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";
import ArticleCard from "../Card/ArticleCard.vue";
import Link from "../Link/Link.vue";

export default {
  name: "Carousel",
  components: { Heading, Button, ArticleCard, Link },
  props: {
    title: {
      type: String,
    },
    maxCardsPerPage: {
      type: Number,
      default: 3, // Maximum cards for large screens
    },
    cards: {
      type: Array,
      required: true,
    },
    link: {
      type: String,
    },
    linkText: {
      type: String,
      default: "View All",
    },
  },
  data() {
    return {
      currentIndex: 0, // Tracks the current starting card index
      cardWidth: 0, // Width of a single card, dynamically calculated
      cardsPerPage: this.maxCardsPerPage, // Dynamically updated based on screen size
      gap: 24, // Space between cards
    };
  },
  computed: {
    totalCards() {
      return this.cards.length;
    },
    isPrevDisabled() {
      return this.currentIndex === 0;
    },
    isNextDisabled() {
      return this.currentIndex >= this.totalCards - this.cardsPerPage;
    },
    transformStyle() {
      return `translateX(-${this.currentIndex * (this.cardWidth + this.gap)}px)`;
    },
  },
  methods: {
    calculateCardsPerPage() {
      const container = this.$el.querySelector(".suaw-carousel__cards-container");
      if (container) {
        const containerWidth = container.offsetWidth;

        // Minimum card width including gap
        const minCardWidth = 250;

        // Calculate cards per page based on container width and minimum card width
        let calculatedCards = Math.floor(
          (containerWidth + this.gap) / (minCardWidth + this.gap)
        );

        // Ensure cardsPerPage respects maxCardsPerPage
        this.cardsPerPage = Math.min(calculatedCards, this.maxCardsPerPage);

        // Ensure at least one card is displayed
        this.cardsPerPage = Math.max(this.cardsPerPage, 1);

        // Calculate the card width while respecting gaps
        this.cardWidth =
          (containerWidth - this.gap * (this.cardsPerPage - 1)) /
          this.cardsPerPage;

        // Update track width dynamically
        this.updateTrackWidth();
      }
    },
    updateTrackWidth() {
      const track = this.$el.querySelector(".suaw-carousel__cards-track");
      if (track) {
        track.style.width = `${this.totalCards * (this.cardWidth + this.gap)}px`;
      }
    },
    prev() {
      if (!this.isPrevDisabled) {
        this.currentIndex = Math.max(0, this.currentIndex - this.cardsPerPage);
      }
    },
    next() {
      if (!this.isNextDisabled) {
        this.currentIndex = Math.min(
          this.totalCards - this.cardsPerPage,
          this.currentIndex + this.cardsPerPage
        );
      }
    },
  },
  mounted() {
    this.calculateCardsPerPage();
    window.addEventListener("resize", this.calculateCardsPerPage);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateCardsPerPage);
  },
};
</script>

<template>
  <div class="suaw-carousel">
    <div class="suaw-carousel__header">
      <Heading class="suaw-carousel__title" level="3" :content="title" />
      <Link
        v-if="link"
        class="suaw-carousel__link view-all-link"
        weight="bold"
        size="medium"
        :to="link"
        >{{ linkText }}</Link
      >
      <nav class="suaw-carousel__navigation">
        <Button
          class="suaw-carousel__button suaw-button--no-x-pd suaw-button--no-y-pd"
          size="large"
          type="ghost"
          iconLeft="IconCircleChevronLeft"
          buttonText="Prev"
          @click="prev"
          :disabled="isPrevDisabled"
          :aria-disabled="isPrevDisabled"
        />
        <Button
          class="suaw-carousel__button suaw-button--no-x-pd suaw-button--no-y-pd"
          size="large"
          type="ghost"
          iconRight="IconCircleChevronRight"
          buttonText="Next"
          @click="next"
          :disabled="isNextDisabled"
          :aria-disabled="isNextDisabled"
        />
      </nav>
    </div>
    <!-- Cards Container -->
    <div class="suaw-carousel__cards-container">
      <div
        class="suaw-carousel__cards-track"
        :style="{ transform: transformStyle }"
      >
        <!-- Render all cards inside the track -->
        <ArticleCard
          v-for="card in cards"
          :key="card.id"
          v-bind="card"
          :style="{ width: cardWidth + 'px' }"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-carousel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 650px) {
    gap: 8px;
  }

  &__cards-container {
    overflow: hidden;
    width: 100%;
  }

  &__cards-track {
    display: flex;
    gap: 24px;
    transition: transform 0.5s ease; /* Smooth sliding animation */
    will-change: transform; /* Hint browser to optimize for transform changes */
    width: auto; /* Adjusted dynamically in JavaScript */
  }

  &__header {
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 16px;
  }

  &__title {
    width: auto;
    align-self: center;

    @media (max-width: 700px) {
      font-size: 20px;
      font-weight: 500;
      grid-row: 1/2;
      grid-column: 1/3;
    }
    @media (max-width: 400px) {
      font-size: 18px;
    }
  }

  .view-all-link {
    margin-top: 4px;
    white-space: nowrap;
    @media (max-width: 700px) {
      margin-top: 0px;
      grid-row: 2/3;
    }
  }

  &__navigation {
    justify-self: end;
    align-self: center;
    display: flex;
    gap: 16px;
    justify-content: center;
    grid-row: 1/2;
    grid-column: 3/4;
    @media (max-width: 650px) {
      align-self: flex-start;
    }
  }

  &__button {
    height: 22px;
  }

  &__link {
    color: var(--sem-color-info-medium);
    font-size: 14px;
    font-weight: 500;
    align-self: center;
  }
}
</style>
