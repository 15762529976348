<script>
export default {
  name: "IconHeadingH3",
}
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 4C3.55228 4 4 4.44772 4 5V11H10V5C10 4.44772 10.4477 4 11 4C11.5523 4 12 4.44772 12 5V19C12 19.5523 11.5523 20 11 20C10.4477 20 10 19.5523 10 19V13H4V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V5C2 4.44772 2.44772 4 3 4ZM14 9C14 8.44772 14.4477 8 15 8H21C21.4045 8 21.7691 8.24364 21.9239 8.61732C22.0787 8.99099 21.9931 9.42111 21.7071 9.70711L19.2236 12.1906C20.8342 12.7076 22 14.2176 22 16C22 18.2091 20.2091 20 18 20C17.1727 20 16.3653 19.7437 15.6895 19.2656C15.0138 18.7875 14.5033 18.1116 14.2279 17.3314C14.0441 16.8106 14.3173 16.2394 14.8381 16.0555C15.3589 15.8717 15.9301 16.1449 16.1139 16.6657C16.2516 17.056 16.507 17.394 16.8447 17.6329C17.1822 17.8717 17.5859 18 18 18C19.1046 18 20 17.1046 20 16C20 14.8954 19.1046 14 18 14H17C16.5955 14 16.2309 13.7564 16.0761 13.3827C15.9213 13.009 16.0069 12.5789 16.2929 12.2929L18.5858 10H15C14.4477 10 14 9.55228 14 9Z" />
</svg>
</template>
