var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suaw-tabs",class:['suaw-tabs--' + _vm.tabsStyle, _vm.className]},[_c('nav',{staticClass:"suaw-tabs__nav",class:'suaw-tabs__nav--' + _vm.tabsType},_vm._l((_vm.tabs),function(tab,index){return _c('button',{key:index,staticClass:"suaw-tabs__item",class:[
        'suaw-tabs__item--' + _vm.tabsType,
        {
          'suaw-tabs__item--active': _vm.value === index,
          'suaw-tabs__item--inactive': _vm.value !== index
        }
      ],attrs:{"aria-controls":'tabpanel-' + index,"disabled":_vm.disabled},on:{"click":function($event){return _vm.changeTab(index)}}},[_c('span',{staticClass:"suaw-tabs__label"},[_vm._v(_vm._s(tab.label))])])}),0),_c('div',{staticClass:"suaw-tabs__content",class:'suaw-tabs__content--' + _vm.tabsStyle},_vm._l((_vm.tabs),function(tab,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value === index),expression:"value === index"}],key:index,staticClass:"suaw-tabs__block"},[_vm._t('tab-' + index)],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }