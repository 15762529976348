<script>
import ResourceFrame from "../../components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";
export default {
  name: "TroubleshootingAtEvents",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Troubleshooting at <b>Events</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Our events generally run smoothly; most people are really great. However, Shut Up & Write! events are open to the public, so there may be times when you need to take action to ensure the comfort of your members."
      />

      <h2 class="suaw-organizer-resource-subtitle">
        Dealing with Disruptive Members at In-Person Events
      </h2>
      <p class="suaw-paragraph">
        If you have a disruptive member at your event, staying calm can help de-escalate the situation.
      </p>
      <p class="suaw-paragraph">
        If you're experiencing a minor issue, like an overly talkative member, gently remind them of the purpose of Shut Up & Write!, which is to quietly write and then connect afterward as a community.
      </p>
      <p class="suaw-paragraph">
        <b>If someone is behaving inappropriately, take steps to ensure the safety of your group, including:</b>
      </p>
      <ol class="suaw-organizer-resource-steps">
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Asking the staff at your venue for additional support.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Calling law enforcement to report the issue, if necessary.</p>
        </li>
      </ol>
      <p class="suaw-paragraph">
        You can also ask the staff at your venue for ongoing support, if a member of concern might return. Depending on your local regulations, you can also ask law enforcement for ongoing support.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        Dealing with Disruptive Members at Online Events
      </h2>
      <p class="suaw-paragraph">
        Prevention is the best way to reduce the risk of disruptive members at your online Shut Up & Write! events.
      </p>
      <p class="suaw-paragraph">
        <b>Before your first online event, please review & implement the following settings in your video conferencing admin account:</b>
      </p>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Enable the waiting room</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Disable screen sharing</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">During the &quot;Shut Up & Write!&quot; phase of your event, default to a <b>Mute</b> setting for all of your attendees in order to maintain a quiet writing environment.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Also, during the &quot;Shut Up & Write!&quot; phase, enable &quot;Mute Upon Entry.&quot;</p>
        </li>
      </ul>
      <p class="suaw-paragraph">
        If you're using Zoom, please also review <a href='https://blog.zoom.us/keep-uninvited-guests-out-of-your-zoom-meeting/' target='_blank'>Zoom's guide to keeping unwanted guests out of meetings</a>.
      </p>
      <p class="suaw-paragraph">
        If you're using different video conferencing software, please review the documentation they make available to their users. Some examples are:
      </p>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><a href='https://support.google.com/meet/topic/14074046?hl=en&ref_topic=14075141&sjid=2453267516085352268-NA' target='_blank'>Google Meet's Host Controls Page</a></p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><a href='https://community.jitsi.org/t/how-to-what-is-a-meeting-moderator/73139/3' target='_blank'>Jitsi's Moderator Controls Features</a></p>
        </li>
      </ul>

      <h2 class="suaw-organizer-resource-subtitle">
        What If No One Comes?
      </h2>
      <p class="suaw-paragraph">
        This can happen to anyone — you advertise your event, and even so, you're the only writer there. Don't despair! You still set aside time for your own writing, so you've already done something great. That said, we do have some suggestions for organizers who are seeing consistently low turnouts:
      </p>
      <ul class="suaw-organizer-resource-list">
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph"><router-link :to="{ name: 'PromotingYourEvent' }">Promote Your Event</router-link>: Here's where you can flex your creativity. Even though Shut Up & Write! provides listings for your event on Meetup and on our site, organizers need to put energy into promoting their events to make sure their communities are aware.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Examine Your Event: Did you pick a time that might be challenging for people who work full-time? In the case of in-person events, is there a lack of parking, or unreliable public transit? Consider tweaking your event's time or location. To make changes to in-person events, edit your Meetup event listing. To change an online event, you'll need to edit both your Meetup listing and your <a href='http://shutupwrite.com' target='_blank'>shutupwrite.com</a> listing.</p>
        </li>
        <li class="suaw-organizer-resource-list__item">
          <p class="suaw-paragraph">Keep Trying: All of our most successful events started out with only a few people. Give it time. (If you build it, they will come…)</p>
        </li>
      </ul>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
//steps
.suaw-organizer-resource-steps {
  list-style: decimal;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}
//list
.suaw-organizer-resource-list {
  list-style: disc;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}
</style>
