<template>
  <div class="editor-wrapper">
    <div v-if="editor" class="editor-toolbar">
      <input ref="fileInput" type="file" accept="image/*" style="display: none" @change="handleFileInput" />
      <button @click="$refs.fileInput.click()">Add Image</button>
      <button @click="emitJSON">Get JSON</button>
      <button @click="loadJSON">Load Example Database JSON</button>
    </div>
    <editor-content :editor="editor" class="editor-content" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import { uploadImage } from "../discussions/api.js";
import { GetImageUrlById } from "../discussions/operations.gql";
import { hasuraClient } from "../../utils/graphql/apollo.js";

class FileHandler {
  constructor({ editor, acceptedImageFileTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"] } = {}) {
    this.editor = editor;
    this.acceptedImageFileTypes = acceptedImageFileTypes;
  }

  async upload(file) {
    const reader = new FileReader();
    const base64Promise = new Promise((resolve, reject) => {
      reader.onload = () => {
        // Get the base64 string by removing the data URL prefix
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
    });

    reader.readAsDataURL(file);
    const base64Data = await base64Promise;

    const response = await uploadImage(base64Data);
    if (!response.success) {
      throw new Error(response.error);
    }

    const imageResult = await hasuraClient.query({
      query: GetImageUrlById,
      variables: {
        id: response.result.id
      }
    });

    const url = imageResult.data.resultGetImageUrlById.url;
    return url;
  }

  accepts(file) {
    return this.acceptedImageFileTypes.includes(file.type);
  }
}

export default {
  name: "TipTapTest",
  components: {
    EditorContent
  },
  data() {
    return {
      editor: null,
      fileHandler: null
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Image.configure({
          inline: true,
          allowBase64: true
        })
      ],
      content: "<p>Welcome to the Tiptap editor! Click the button above to add an image, or drag and drop an image here.</p>",
      editorProps: {
        handleDrop: (view, event, slice, moved) => {
          if (!moved && event.dataTransfer?.files?.length) {
            const files = Array.from(event.dataTransfer.files);
            const images = files.filter(file => this.fileHandler.accepts(file));

            if (images.length > 0) {
              event.preventDefault();
              this.uploadFiles(images);
              return true;
            }
          }
          return false;
        },
        handlePaste: (view, event) => {
          if (event.clipboardData?.files?.length) {
            const files = Array.from(event.clipboardData.files);
            const images = files.filter(file => this.fileHandler.accepts(file));

            if (images.length > 0) {
              event.preventDefault();
              this.uploadFiles(images);
              return true;
            }
          }
          return false;
        }
      }
    });

    this.fileHandler = new FileHandler({ editor: this.editor });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    async handleFileInput(event) {
      const files = Array.from(event.target.files);
      await this.uploadFiles(files);
      event.target.value = ""; // Reset input
    },
    async uploadFiles(files) {
      for (const file of files) {
        try {
          if (this.fileHandler.accepts(file)) {
            const url = await this.fileHandler.upload(file);
            this.editor
              .chain()
              .focus()
              .setImage({ src: url })
              .run();
          }
        } catch (error) {
          //!!change to global error
        }
      }
    },
    emitJSON() {
      //console.log(JSON.stringify(this.editor.getJSON()));
      this.$emit("json-content", this.editor.getJSON());
    },
    loadJSON() {
      //example JSON that is stored in the database
      this.editor.commands.setContent({
        type: "doc",
        content: [
          {
            type: "paragraph",
            content: [
              {
                text: "Have",
                type: "text"
              }
            ]
          },
          {
            type: "paragraph"
          },
          {
            type: "paragraph",
            content: [
              {
                text: "Hug",
                type: "text"
              },
              {
                type: "image",
                attrs: {
                  src: "https://suaw-photo.s3.us-west-1.amazonaws.com/l/id2ny0ldnd00.webp"
                }
              }
            ]
          }
        ]
      });
    }
  }
};
</script>

<style scoped>
.editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px;
}

.editor-toolbar {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
}

.editor-toolbar button {
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.editor-toolbar button:hover {
  background-color: #f0f0f0;
}

.editor-content {
  padding: 20px;
}

.editor-content :deep(img) {
  max-width: 100%;
  height: auto;
}

.editor-content :deep(p) {
  margin: 0;
  line-height: 1.5;
}
</style>
