<script>
import Label from "../Label/Label.vue";

export default {
  name: "TextInput",
  components: {
    Label
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    borderStyle: {
      type: String,
      default: "default",
      validator: function (value) {
        return [
          "default",
          "ghost",
        ].indexOf(value) !== -1;
      },
    },
    id: {
      type: String,
      default: "textInput",
    },
    type: {
      type: String,
      default: "text",
      validator: function (value) {
        return [
          "text",
          "address",
          "email",
          "number",
          "password",
          "time",
          "date",
          "search"
        ].indexOf(value) !== -1;
      },
    },
    state: {
      type: String,
      validator: function (value) {
        return [
          "valid",
          "invalid",
          "disabled",
          "default"
        ].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["medium", "large"].indexOf(value) !== -1;
      }
    },
    label: {
      type: String,
      default: "",
    },
    labelWeight: {
      type: String,
      default: "bold",
      validator: function (value) {
        return ["normal", "bold"].indexOf(value) !== -1;
      },
    },
    value: {
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    message: {
      type: String,
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    successMessage: {
      type: String,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setPlaceholders() {
      return this.placeholder;
    },
    setLabels() {
      if (this.label === "" && this.noLabel == false) {
        return ['time'].includes(this.type) ? 'Start Time:' :
          ['address'].includes(this.type) ? 'Address:' :
          ['password'].includes(this.type) ? 'Password:' :
          ['date'].includes(this.type) ? 'Start Date:' :
          ['email'].includes(this.type) ? 'Email:' : '';
      } else if (this.noLabel == true) {
        return null;
      } else {
        return this.label;
      }
    },
    inputClasses() {
      return {
        'suaw-input-text--valid': this.state === 'valid',
        'suaw-input-text--invalid': this.state === 'invalid',
        'suaw-input-text--disabled': this.state === 'disabled',
        'suaw-input-text--required': this.isRequired,
        [`suaw-input-text--${this.size}`]: true,
      };
    },
    setMessages() {
      if (this.state === "invalid") {
        return this.errorMessage;
      }
      if (this.state === "valid" && this.successMessage) {
        return this.successMessage;
      }
      return this.message || "";
    },
    messageClasses() {
      if (this.state === "invalid") {
        return "suaw-input-text__message--invalid";
      }
      if (this.state === "valid" && this.successMessage) {
        return "suaw-input-text__message--valid";
      }
      return "suaw-input-text__message--default";
    }
  },
};
</script>

<template>
  <div 
    class="suaw-input-text"
    :class="[inputClasses, className]"
  >
    <Label
      v-if="setLabels"
      :labelText="setLabels"
      :labelFor="id"
      size="small"
      :weight="labelWeight"
    />
    <div class="suaw-input-text__holder">
      <input
        class="suaw-input-text__field"
        :type="type"
        :placeholder="setPlaceholders"
        v-bind="$attrs"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        :id="id"
        ref="inputType"
        :disabled="state === 'disabled'"
        :required="isRequired"
        :class="[
          'suaw-input-text__field--' + borderStyle,
          className
        ]"
      />
      <small
        v-if="setMessages"
        class="suaw-input-text__message"
        :class="messageClasses"
      >
        {{ setMessages }}
      </small>
    </div>
  </div>
</template>

<style scoped lang="scss">
.suaw-input-text {
  border: 0;
  flex-direction: column;
  gap: var(--sem-space-half);
  padding: 0;

  &,
  &__holder {
    display: flex;
    position: relative;
  }

  &__field {
    background-color: var(--sem-color-background-lightest);
    color: var(--sem-color-text-medium);
    appearance: none;
    font-family: var(--font-family);
    font-size: var(--p-font-size);
    height: 40px;
    padding-left: var(--sem-space-base);
    padding-right: var(--sem-space-base);
    padding-top: var(--sem-space-base);
    padding-bottom: var(--sem-space-base);
    width: 100%;

    .suaw-input-text--large & {
      font-size: 18px;
      height: 56px;
    }
  }

  &__field--default {
    border-radius: var(--sem-radius-sm);
    border: 1px solid var(--sem-color-border-medium);
  }

  &__field--default:focus {
    border-color: var(--sem-color-info-medium);
  }

  &__field--ghost {
    border: 0;
    padding: 0 var(--sem-space-base);
  }

  &__field:focus-visible {
    outline: none;
  }

  &__field:invalid:not(:focus) {
    box-shadow: none;

    .suaw-input-text--invalid & {
      border-color: var(--sem-color-critical-medium);
    }
  }

  &__field::placeholder {
    color: var(--sem-color-inactive-medium);
    font-weight: 400;
  }

  &__message {
    font-weight: 400;
    position: absolute;
    bottom: -25px;
  }

  &__message--default {
    color: var(--sem-color-neutral-medium);
  }

  &__message--invalid {
    color: var(--sem-color-critical-medium);
  }

  &__message--valid {
    color: var(--sem-color-success-medium);
  }
}
</style>
