<script>
import Avatar from "../Avatar/Avatar.vue";
import Button from "../Button/Button.vue";
import Link from "../Link/Link.vue";

export default {
  name: 'ListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemClickable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Avatar,
    Button,
    Link,
  },
  methods: {
    itemClicked() {
      if (this.itemClickable) {
        this.$emit("item-click", this.item);
      }
    },
    handleButtonClick() {
      if (this.item.buttonClick) {
        this.item.buttonClick();
      }
      this.$emit("button-click", this.item);
    },
  },
};
</script>

<template>
  <li
    class="suaw-list-item__item"
    @click="itemClicked"
  >
    <div
      v-if="item.picture || item.avatar"
      class="suaw-list-item__preview"
    >
      <Avatar
        v-if="item.avatar"
        :type="item.avatarType"
        size="large"
        :content="item.avatar"
      />
      <img
        v-else
        class="suaw-list-item__picture"
        :src="item.picture"
      />
    </div>
    <div class="suaw-list-item__content">
      <span
        v-if="item.text"
        class="suaw-list-item__text"
      >{{ item.text }}</span>
      <Button
        v-if="item.buttonText"
        :buttonText="item.buttonText"
        size="medium"
        type="ghost"
        :iconLeft="item.buttonIcon"
        className="suaw-list-item__button"
        @click="handleButtonClick"
      />
      <Link
        v-if="item.linkUrl"
        :to="item.linkUrl"
        weight="bold"
      >{{ item.linkText }}</Link>
    </div>
  </li>
</template>


<style lang="scss" scoped>
.suaw-list-item {
  &__item {
    display: flex;
    gap: var(--sem-space-base);
    padding: 0;
  }

  &__item:not(:last-child) {
    margin-bottom: var(--sem-space-base);
    border-bottom: 1px solid var(--sem-color-border-regular);
    padding-bottom: var(--sem-space-base);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-quarter);
    justify-content: center;
  }

  &__preview {
    display: flex;
  }

  &__picture {
    border-radius: var(--sem-radius-md);
    width: 58px;
    height: 58px;
    object-fit: cover;
  }

  &__text {
    font-size: var(--p-font-size);
    font-weight: 500;
    line-height: var(--base-line-height);
  }

  &__button {
    height: auto;
    padding: 0;
  }
}
</style>
