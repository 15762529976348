<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Chip from "../Chip/Chip.vue";
import ChipTwo from "../Chip/ChipTwo.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import EditorBox from "../EditorBox/EditorBox.vue";

export default {
  name: "ReplyItem",
  components: {
    Paragraph,
    Chip,
    ChipTwo,
    Dropdown,
    EditorBox,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    parentPostId: {
      required: true,
      type: String,
    },
    cardText: {
      type: Object,
    },
    chipText: {
      type: String,
    },
    chipContent: {
      type: String,
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String,
    },
    avatarInitials: {
      type: String,
    },
    isAvatarHidden: {
      type: Boolean,
      default: false,
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
    },
    posted: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    showDiscussionLikes: {
      type: Boolean,
      default: true,
    },
    reply: Object,
    index: Number,
    // editMode: Boolean,
    replyEditModes: Array,
    textBoxPrimaryButton: String,
    textBoxSecondaryButton: String,
    textBoxSize: String,
    showModal: Boolean,
    modalTitles: String,
    likeCount: {
      type: Number,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      editableText: this.cardText,
    };
  },
  computed: {
    computedDiscussionLikes() {
      if (!this.likeCount) {
        return "";
      }
      return `${this.likeCount}`;
    },
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    handleEditorBoxPrimaryButtonClick() {
      const payload = {
        text: this.editableText,
        id: this.id,
        parentPostId: this.parentPostId,
      };
      this.$emit("edit-reply", payload);
      this.toggleEditMode();
    },
    // toggleReplyEditMode() {
    //   this.$emit("editReplyModeToggled", this.index);
    // },
    // toggleReportModal() {
    //   this.$emit("reportModalToggled");
    // },
    // toggleModalVisibility() {
    //   this.$emit("toggle-modal-visibility");
    // },
  },
};
</script>

<template>
  <li class="suaw-reply__list-item">
    <div class="suaw-reply__content">
      <template v-if="editMode">
        <EditorBox
          v-model="editableText"
          :size="textBoxSize"
          :buttonText="textBoxPrimaryButton"
          :secondary-button-text="textBoxSecondaryButton"
          :use-tip-tap="true"
          @submit="handleEditorBoxPrimaryButtonClick"
          @secondary-click="toggleEditMode"
        />
      </template>

      <!-- <template v-else>
        <template v-if="replyEditModes[index]">
          <EditorBox
            :buttonText="textBoxPrimaryButton"
            :size="textBoxSize"
            :secondaryButtonText="textBoxSecondaryButton"
          />
        </template>
      </template> -->

      <template v-else>
        <Paragraph
          size="small"
          weight="bold"
          :text="cardText"
        />
      </template>
    </div>

    <footer class="suaw-reply__footer--reply">
      <div class="suaw-reply__block--reply">
        <ChipTwo
          type="avatar"
          size="small"
          :avatar-url="avatarUrl"
          :avatar-color="avatarColor"
          :avatar-initials="avatarInitials"
          :is-avatar-hidden="isAvatarHidden"
          :is-deactivated="isDeactivated"
          :display-name="displayName"
          :text="displayName"
        />
        <small class="suaw-reply__posted--small">{{ posted }}</small>
      </div>

      <div class="suaw-reply__block">
        <Chip
          v-if="showDiscussionLikes"
          :key="isLiked"
          size="small"
          chipType="icon"
          status="critical"
          :icon="isLiked ? 'IconHeartFill' : 'IconHeartOutline'"
          shape="square"
          class="suaw-chip--clickable"
          :label="computedDiscussionLikes"
          @click="$emit('like-click', { id, isLiked })"
        />
        <Dropdown
          iconDropdown="IconMoreHorizontal"
          dropdownButtonSize="small"
          buttonType="secondary-outline"
          buttonClassName="suaw-dropdown__button--chip"
          :items="dropdownItems"
          @editModeToggled="toggleEditMode"
          @item-click="$emit('dropdown-item-click', $event)"
        />
      </div>

      <!-- <transition name="suaw-modal__fade">
        <slot name="modal" />
        <Modal
          v-if="showModal"
          modalType="confirmation"
          :class="toggleModalClasses"
          :toggleButtonConfirm="toggleButtonConfirm"
          :toggleButtonCancel="toggleButtonCancel"
          :modalTitle="modalTitles"
          :click-method="toggleModalVisibility"
          :isReportModal="true"
        />
      </transition> -->
    </footer>
  </li>
</template>

<style lang="scss" scoped>
.suaw-reply__list-item {
  padding: var(--sem-space-triple);
  background-color: var(--sem-color-background-light);
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-base);
  border-radius: var(--sem-radius-sm);
}

.suaw-reply__footer--reply {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.suaw-reply__block--reply {
  display: flex;
  align-items: center;
}

.suaw-reply__posted--small {
  font-size: 12px;
}
</style>
