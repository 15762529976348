<script>
import CalendarDate from '../CalendarDate/CalendarDate.vue';
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import Heading from '../Heading/Heading.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardOnlineEvent",
  components: {
    CalendarDate,
    Thumbnail,
    Heading,
    Chip,
  },
  props: {
    id: {
      type: String,
    },
    heading: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    calendarDate: {
      type: Object,
      default: () => {},
    },
    chipItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div 
    class="suaw-card__content"
    @click.stop="$emit('click', id)"
  >
    <header class="suaw-card__header">
      <CalendarDate
        size="medium"
        :status="calendarDate.status"
        :month="calendarDate.month"
        :day="calendarDate.day"
        :time="calendarDate.time"
        :timezone="calendarDate.timezone"
        class="suaw-card__calendar"
      />
      <Thumbnail
        size="medium"
        :content="thumbnail"
        class="suaw-card__thumbnail"
      />
    </header>
    <Heading
      level="5"
      :content="heading"
    />
    <footer class="suaw-card__footer">
      <Chip
        v-for="(chip, index) in chipItems"
        :key="index"
        size="medium"
        :status="chip.status"
        :icon="chip.icon"
        :label="chip.label"
        :chipType="chip.type"
        :avatarType="chip.avatarType"
        :avatarContent="chip.avatarContent"
        :pill="chip.pill"
      />
      <slot name="chips"></slot>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__header,
  &__content,
  &__footer {
    display: flex;
  }

  &__content,
  &__footer {
    align-items: flex-start;
    flex-direction: column;
  }

  &__content {
    gap: var(--sem-space-double);
    text-align: left;
    transition: transform .25s linear;
  }

  &__content:hover {
    cursor: pointer;
    transform: scale(0.99);
  }

  &__header,
  &__footer {
    gap: var(--sem-space-base);
  }

  &__thumbnail {
    height: auto;
  }

  &__calendar,
  &__thumbnail {
    width: calc(100% / 2);
  }
}
</style>
