<script>
export default {
  name: "Avatar",
  props: {
    type: {
      type: String,
      validator: function (value) {
        return [
          "blue",
          "light-blue",
          "orange",
          "light-orange",
          "green",
          "light-green",
          "red",
          "light-red",
          "purple",
          "light-purple",
          "cyan",
          "light-cyan",
          "light-gray",
          "picture"
        ].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      validator: function (value) {
        return [
          "huge",
          "large",
          "medium",
          "small",
        ].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
    AvatarTextClassName: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <span 
    class="suaw-avatar"
    :class="['suaw-avatar--' + type, 'suaw-avatar--' + size, className]"
  >
    <img
      v-if="content.length > 2"
      class="suaw-avatar__image"
      :src="content"
    />
    <p
      v-else
      class="suaw-avatar__text"
      :class="AvatarTextClassName"
    >
      {{ content }}
    </p>
  </span>
</template>

<style lang="scss" scoped>
.suaw-avatar {
  border-radius: var(--sem-radius-max);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &--huge {
    height: 80px;
    min-width: 80px;
    width: 80px;
  }

  &--large {
    height: 56px;
    min-width: 56px;
    width: 56px;
  }

  &--medium {
    height: 40px;
    min-width: 40px;
    width: 40px;
  }

  &--small {
    height: 24px;
    min-width: 24px;
    width: 24px;
  }

  &--green {
    background-color: var(--sem-color-success-medium);
  }

  &--light-green {
    background-color: var(--sem-color-success-light);
  }

  &--blue {
    background-color: var(--sem-color-info-medium);
  }

  &--light-blue {
    background-color: var(--sem-color-info-light);
  }

  &--cyan {
    background-color: var(--sem-color-accent-cyan);
  }

  &--light-cyan {
    background-color: var(--sem-color-decorative-cyan);
  }

  &--purple {
    background-color: var(--sem-color-accent-purple);
  }

  &--light-purple {
    background-color: var(--sem-color-decorative-purple);
  }

  &--orange {
    background-color: var(--sem-color-accent-orange);
  }

  &--light-orange {
    background-color: var(--sem-color-decorative-orange);
  }

  &--red {
    background-color: var(--sem-color-critical-medium);
  }

  &--light-red {
    background-color: var(--sem-color-critical-light);
  }

  &--light-gray {
    background-color: var(--sem-color-neutral-light);
  }

  &__image {
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  &__text {
    font-family: var(--h-4-font-family);
    font-style: var(--h-4-font-style);
    font-weight: var(--h-4-font-weight);
    letter-spacing: var(--h-4-letter-spacing);
    line-height: var(--h-4-line-height);
    text-align: center;
    white-space: nowrap;

    .suaw-avatar--huge & {
      font-size: 28px;
    }
    .suaw-avatar--large & {
      font-size: 20px;
    }

    .suaw-avatar--medium & {
      font-size: 16px;
    }

    .suaw-avatar--small & {
      font-size: 10px;
    }

    .suaw-avatar--green & {
      color: var(--sem-color-success-light);
    }

    .suaw-avatar--light-green & {
      color: var(--sem-color-success-medium);
    }

    .suaw-avatar--blue & {
      color: var(--sem-color-info-light);
    }

    .suaw-avatar--light-blue & {
      color: var(--sem-color-info-medium);
    }

    .suaw-avatar--cyan & {
      color: var(--sem-color-decorative-cyan);
    }

    .suaw-avatar--light-cyan & {
      color: var(--sem-color-accent-cyan);
    }

    .suaw-avatar--purple & {
      color: var(--sem-color-decorative-purple);
    }

    .suaw-avatar--light-purple & {
      color: var(--sem-color-accent-purple);
    }

    .suaw-avatar--orange & {
      color: var(--sem-color-decorative-orange);

    }
    .suaw-avatar--light-orange & {
      color: var(--sem-color-accent-orange);
    }

    .suaw-avatar--red & {
      color: var(--sem-color-critical-light);
    }

    .suaw-avatar--light-red & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-avatar--light-gray & {
      color: var(--sem-color-text-darken);
    }
  }
}
</style>
