<script>
import { SuawInputGroup, SuawButton } from "@/components";
export default {
  name: "SeriesEditFormButtons",
  components: { SuawInputGroup, SuawButton },
  props: {
    confirmButtonText: {
      type: String,
      default: "Save and Continue"
    }
  },
  methods: {
    onCancelClicked() {
      this.$emit("cancel");
    },
    onBackClicked() {
      this.$emit("back");
    },
    onConfirmClicked() {
      this.$emit("confirm");
    }
  }
};
</script>

<template>
  <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
    <SuawButton class="suaw-button--pull-left" size="large" type="critical-light" icon-right="" button-text="Cancel Series" @click="onCancelClicked" />
    <SuawButton size="large" type="ghost-outline" icon-right="" button-text="Back to Series Page" @click="onBackClicked" />
    <SuawButton size="large" type="primary" icon-right="IconCircleChevronRight" :button-text="confirmButtonText" @click="onConfirmClicked" />
  </SuawInputGroup>
</template>
