<script>
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardArticle",
  components: {
    Thumbnail,
    Heading,
    Paragraph,
    Chip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    author: {
      type: String,
    },
    avatarType: {
      type: String,
    },
    avatarContent: {
      type: String,
    },
    posted: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    heading: {
      type: String,
    },
    route: {
      type: String,
    },
  },
};
</script>

<template>
  <div 
    class="suaw-card__content"
    @click.stop="$emit('click', $props)"
  >
    <Thumbnail
      size="large"
      :content="thumbnail"
      class="suaw-thumbnail--fit"
    />
    <Heading
      level="5"
      :content="heading"
    />
    <footer class="suaw-card__footer">
      <span>by</span>
      <Chip
        size="medium"
        status="default"
        :label="author"
        chipType="avatar"
        :avatarType="avatarType"
        :avatarContent="avatarContent"
      />
    </footer>
    <Paragraph
      v-if="posted"
      weight="normal"
      size="small"
      :text="posted"
    />
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    align-items: flex-start;
    flex-direction: column;
    gap: var(--sem-space-base);
    transition: transform .25s linear;
  }

  &__content:hover {
    cursor: pointer;
    transform: scale(0.99);
  }

  &__footer {
    align-items: center;
  }
}
</style>
