<script>
import { SuawDivider, SuawButton, SuawListSection, SuawIcon } from "@/components";

export default {
  name: "SideNav",
  components: { SuawDivider, SuawButton, SuawListSection, SuawIcon },
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  methods: {
    async copyLink() {
      try {
        const url = window.location.origin + this.$route.fullPath;
        await navigator.clipboard.writeText(url);
        this.$root.$emit("universal-success-message", "Link copied to clipboard successfully!");
      } catch (err) {
        this.$root.$emit("universal-error-message", "Failed to copy link to clipboard.");
      }
    },
    isActive(to) {
      if (typeof to === 'object' && to.name) {
        return this.$route.name === to.name && (!to.hash || this.$route.hash === to.hash);
      }
      return false;
    }
  }
};
</script>

<template>
  <div class="suaw-side-nav">
    <div class="suaw-side-nav__content">
      <div class="suaw-side-nav__list-container">
        <SuawListSection class="suaw-side-nav__list-container">
          <ul class="suaw-list-section__list suaw-list-section__list--vertical">
            <router-link
              v-for="(link, index) in links"
              :key="index"
              :to="link.to"
              custom
              v-slot="{ navigate, href }"
            >
              <a
                :href="href"
                @click="navigate"
                class="suaw-link suaw-link--default suaw-link--left"
                :class="{ 'suaw-link--active': isActive(link.to) }"
              >
                <SuawIcon
                  :icon="isActive(link.to) ? 'IconChevronRight' : 'IconDot02S'"
                  class="suaw-icon suaw-link__icon suaw-icon--carbon suaw-icon--medium"
                  :class="{ 'suaw-link__icon--active': isActive(link.to) }"
                />
                <span class="suaw-link__text suaw-link__text--bold suaw-link__text--medium">
                  {{ link.name }}
                </span>
              </a>
            </router-link>
          </ul>
        </SuawListSection>
      </div>
      <SuawDivider class="suaw-divider--medium suaw-divider--horizontal suaw-divider--bottom-default suaw-divider--top-default suaw-divider--outer" />
      <div class="suaw-side-nav__footer">
        <div class="suaw-sid-nav__button-container">
          <SuawButton
            class="suaw-sid-nav__button"
            button-text="Share"
            icon-right="IconShareOutline"
            size="small"
            type="secondary-outline"
            @click="copyLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-side-nav {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--sem-color-border-regular);
  padding: var(--sem-space-triple);
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--date-square-shadow);
  position: sticky;
  top: var(--sem-space-septuple);
  align-self: flex-start;

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
  }
}

.suaw-list-section {
  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.suaw-link {
  color: var(--sem-color-info-medium);
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
  transition: color .25s linear;
  width: 100%;
  align-items: center;
  gap: var(--sem-space-base);
  padding: var(--sem-space-half) 0;

  &:hover {
    text-decoration: none;
    color: var(--sem-color-info-dark);
  }

  &--active {
    color: var(--sem-color-text-darkest);
    pointer-events: none;
  }

  &__icon {
    fill: currentColor;
    width: 24px;
    height: 24px;

    &--active {
      fill: var(--sem-color-text-darkest);
    }
  }

  &__text {
    &--bold {
      font-weight: 500;
    }

    &--medium {
      font-size: var(--p-font-size);
    }
  }
}

.suaw-sid-nav__button-container {
  width: fit-content;
}

.suaw-sid-nav__button {
  font-size: var(--body-copy-font-size);
}
</style>
