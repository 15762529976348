<script>
import { SuawMainContent, SuawSection } from "@/components";
import VerificationForm from "../components/VerificationForm.vue";
import CompleteAccountForm from "../components/CompleteAccountForm.vue";
import SuccessForm from "../components/SuccessForm.vue";
import * as UserApi from "../../api.js";

export default {
  name: "CompleteAccount",
  components: {
    SuawMainContent,
    SuawSection,
    VerificationForm,
    CompleteAccountForm,
    SuccessForm
  },
  data() {
    return {
      activeForm: "verification"
    };
  },
  computed: {
    userEmail() {
      return this.$route.query.email || "your email";
    }
  },
  mounted() {
    const { token: refreshToken } = this.$route.params;
    if (refreshToken) {
      this.loginByToken(refreshToken);
    }
    if (this.$auth.user && this.$auth.user.email_verified) {
      this.activeForm = "complete-account";
    }
  },
  methods: {
    async loginByToken(token) {
      const result = await UserApi.loginByToken(token);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      // begin rare case of doing more stuff inside the helper method before returning its result
      const { accessToken, user } = result.result.value || {};
      this.$auth.refreshAuthUser({ accessToken, user });
      this.activeForm = "complete-account";
      this.$router.push({ name: "CompleteAccountToken", params: { token } });
      // end rare case of doing more stuff inside the helper method before returning its result
      return result;
    },
    async onResendCode() {
      const result = await UserApi.sendEmailVerification(this.$route.query.id, false);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      } else {
        this.$root.$emit("universal-success-message", `A new code has successfully been sent to ${this.userEmail}.`);
      }
    },
    async onAccountCompleted() {
      try {
        await this.$auth.logout({ redirectLocation: { name: "none" } });
        this.activeForm = "success";
      } catch (error) {
        this.$root.$emit("universal-error-message", "Unable to redirect you to the login screen. Please navigate there to log in with your new password.");
      }
    }
  }
};
</script>

<template>
  <SuawMainContent size="small">
    <SuawSection section-style="border">
      <VerificationForm v-if="activeForm === 'verification'" :user-email="userEmail" @submit="loginByToken" @resend="onResendCode" />
      <CompleteAccountForm v-if="activeForm === 'complete-account'" @submit="onAccountCompleted" />
      <SuccessForm v-if="activeForm === 'success'" heading="Your account is complete!" description="You have been logged out and must log back in to use your new account." />
    </SuawSection>
  </SuawMainContent>
</template>
