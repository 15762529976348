<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import impactHeroImage from "../../../../public/img/resources/impact-hero.png";
import { SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail } from "@/components";
export default {
  name: "ResourcesImpact",
  components: { ResourceFrame, SuawInputGroup, SuawHeading, SuawParagraph, SuawThumbnail },
  data() {
    return {
      impactHeroImage
    };
  }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-impact-heading">Our <b>Impact</b></h1>
      <SuawHeading
        class="suaw-impact-intro"
        level="3"
        content="Since 2007, Shut Up & Write! has empowered writers, built communities, and transformed ideas into action. We believe that every writer deserves support and structure to achieve their goals, and we're proud of the positive impact we've made on the writing community."
      />
      <SuawThumbnail class="suaw-impact-thumbnail" :content="impactHeroImage" />
      <h2 class="suaw-impact-subtitle">
        By the Numbers
      </h2>
      <div class="suaw-impact-guide">
        <ul class="suaw-impact-guide__list">
          <li class="suaw-impact-guide__item">
            <SuawParagraph text="<b>Global Reach:</b> Shut Up & Write! has hosted events in over 60 countries, connecting writers from all corners of the world." />
          </li>
          <li class="suaw-impact-guide__item">
            <SuawParagraph
              text="<b>Writers Supported:</b> We've supported over 141,000 writers, helping them overcome writer's block, stay productive, and complete their projects."
            />
          </li>
          <li class="suaw-impact-guide__item">
            <SuawParagraph
              text="<b>Events Hosted:</b> Our community has organized more than 10,000 writing sessions, providing dedicated time and space for writers to focus and write."
            />
          </li>
          <li class="suaw-impact-guide__item">
            <SuawParagraph
              text="<b>Community Organizers:</b> We have a dedicated network of over 250 organizers who create and lead events, fostering local and online writing communities."
            />
          </li>
          <li class="suaw-impact-guide__item">
            <SuawParagraph
              text="<b>Writing Hours Logged:</b> Together, our participants have logged over 1 million hours of focused writing, turning countless ideas into written works."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-impact-subtitle">
        Impact in Academia
      </h2>
      <SuawParagraph
        text="Shut Up & Write! has become a cornerstone in academic environments worldwide, offering a structured approach to writing that addresses the unique challenges faced by scholars. Over 200 universities globally have integrated our methodology into their programs, providing invaluable support to graduate students, researchers, and faculty alike."
      />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-impact-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-impact-intro {
  font-family: Roboto;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--sem-color-neutral-dark);
}
.suaw-impact-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-impact-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
//list
.suaw-impact-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
