<script>
import Icon from "../Icon/Icon.vue";
import Link from "../Link/Link.vue";
import Logo from "../Logo/Logo.vue";

export default {
  name: 'CardOnlineMeeting',
  components: {
    Icon,
    Link,
    Logo,
  },
  props: {
    meetingUrl: {
      type: String,
      default: 'https://us02web.zoom.us/j/87667132997',
    },
    hasRSVPed: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    linkContent() {
      return this.hasRSVPed ? this.meetingUrl : 'RSVP for event to reveal online meeting URL.';
    },
    cardRSVPClasses() {
      return this.hasRSVPed ? '' : 'is-pending';
    },
    allowedServices() {
      return ['zoom', 'whereby', 'teams', 'meet'];
    },
    capitalizedServiceName() {
      const serviceNameRegex = new RegExp(`(?:https?://)?(?:www\\.)?(${this.allowedServices.join('|')})(?:\\.|$)`, 'i');
      const match = this.meetingUrl.match(serviceNameRegex);
      const serviceName = match ? match[1] : '';

      // Check if the serviceName is one of the allowed values, otherwise use a default value
      return this.allowedServices.includes(serviceName)
        ? serviceName.charAt(0).toUpperCase() + serviceName.slice(1)
        : 'Default';
    },
  }
};
</script>

<template>
  <div class="suaw-card__content">
    <div class="suaw-card__block">
      <Icon
        v-if="hasRSVPed"
        icon="IconLink02"
        class="suaw-card__icon"
        color="gray"
        key="icon-rsvped"
      />
      <Icon
        v-else
        icon="IconWarning"
        class="suaw-card__icon"
        color="red"
        key="icon-not-rsvped"
      />
      <component
        :is="hasRSVPed ? 'Link' : 'span'"
        :to="linkContent"
        :class="[
          'suaw-card__link',
          cardRSVPClasses
        ]"
      >
        {{ linkContent }}
      </component>
    </div>
    <Logo
      :type="`Logo${capitalizedServiceName}`"
      className="suaw-card__logo"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__block,
  &__content {
    align-items: center;
    display: flex;
  }

  &__content {
    justify-content: space-between;
  }

  &__block {
    gap: var(--sem-space-half);
  }

  &__logo {
    max-height: 25px;
    max-width: 55px;
  }

  &__link.is-pending {
    color: var(--sem-color-critical-medium);
  }
}
</style>
