<script>
import { SuawInputGroup, SuawEmptyState, SuawSectionHeader, SuawSeriesMemberCard, SuawListSection } from "@/components";
import { GetCurrentEventRsvpedAndCheckedInList, GetPreviousEventAttendeeList } from "../../operations.gql";
import * as SeriesApi from "../../api";
export default {
  name: "EventRsvpedAndLastAttendeeList",
  components: {
    SuawInputGroup,
    SuawSeriesMemberCard,
    SuawEmptyState,
    SuawSectionHeader,
    SuawListSection
  },
  props: {
    eventId: {
      type: String,
      required: true
    },
    isAttendanceTime: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetCurrentEventRsvpedAndCheckedInList: {
        id: "",
        starts_at: "",
        is_virtual: null,
        rsvpedMembers: [],
        checkedInMembers: [],
        event_series: {
          id: "",
          isOrganizer: []
        }
      },
      resultGetPreviousEventAttendeeList: []
    };
  },
  apollo: {
    resultGetCurrentEventRsvpedAndCheckedInList: {
      query: GetCurrentEventRsvpedAndCheckedInList,
      variables() {
        return {
          id: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetPreviousEventAttendeeList: {
      query: GetPreviousEventAttendeeList,
      variables() {
        return {
          starts_at: this.startsAt,
          event_series_id: this.eventSeriesId
        };
      },
      skip() {
        return !this.startsAt || !this.eventSeriesId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEvent() {
      return this.resultGetCurrentEventRsvpedAndCheckedInList.id !== "";
    },
    startsAt() {
      return this.hasEvent ? this.resultGetCurrentEventRsvpedAndCheckedInList.starts_at : null;
    },
    eventSeriesId() {
      return this.hasEvent ? this.resultGetCurrentEventRsvpedAndCheckedInList.event_series.id : null;
    },
    isVirtual() {
      return this.hasEvent ? this.resultGetCurrentEventRsvpedAndCheckedInList.is_virtual : null;
    },
    isAuthenticatedOrganizer() {
      const organizers = this.resultGetCurrentEventRsvpedAndCheckedInList.event_series.isOrganizer.map(org => org.user_id);
      return this.$auth.isAuthenticated && organizers.includes(this.$auth.user.id);
    },
    attendedLastEventRsvpedOrCheckedInList() {
      if (this.hasEvent) {
        const rsvpedMembers = this.resultGetCurrentEventRsvpedAndCheckedInList.rsvpedMembers || [];
        const checkedInMembers = this.resultGetCurrentEventRsvpedAndCheckedInList.checkedInMembers || [];
        const previousEvent = this.resultGetPreviousEventAttendeeList[0] || {};
        const previousAttendees = previousEvent.attendees || [];

        // Collect all member IDs for filtering duplicates
        const rsvpedMemberIds = rsvpedMembers.map(member => member.user_view.id);
        const previousAttendeeIds = previousAttendees.map(member => member.user_view.id);

        // Filter previous attendees to exclude those who are already RSVP'd
        const filteredPreviousAttendees = previousAttendees.filter(member => !rsvpedMemberIds.includes(member.user_view.id));

        // Filter checked-in members that are not already in the RSVP list or previous attendees
        const uniqueCheckedInMembers = checkedInMembers.filter(checkedIn => !rsvpedMemberIds.includes(checkedIn.user_view.id) && !previousAttendeeIds.includes(checkedIn.user_view.id));

        // Combine RSVP'd, filtered previous attendees, and unique checked-in members
        const combinedMembers = [
          ...rsvpedMembers.map(member => ({
            ...member,
            is_rsvped: true,
            is_recent_attendee: previousAttendeeIds.includes(member.user_view.id), // Check if RSVP'd member is a recent attendee
            is_in_attendance: checkedInMembers.some(checkedIn => checkedIn.user_view.id === member.user_view.id) // Check if RSVP'd member is in attendance
          })),
          ...filteredPreviousAttendees.map(member => ({
            ...member,
            is_rsvped: false, // Previous attendees who didn't RSVP
            is_recent_attendee: true, // Previous attendees are recent attendees
            is_in_attendance: checkedInMembers.some(checkedIn => checkedIn.user_view.id === member.user_view.id) // If they checked into the current event
          })),
          ...uniqueCheckedInMembers.map(member => ({
            ...member,
            is_rsvped: false, // These members have not RSVP'd
            is_recent_attendee: false, // These members didn't attend the last event
            is_in_attendance: true // But they have checked in
          }))
        ];

        // Flatten the structure by merging `user` properties with top-level properties
        const writers = combinedMembers.map(member => ({
          ...member,
          ...member.user_view, // Merge user properties to the top level
          is_organizer: this.isAuthenticatedOrganizer
        }));

        return writers.map(({ ...rest }) => rest);
      }
      return [];
    }
  },
  methods: {
    onProfileClick(userId) {
      this.$router.push({
        name: "UserProfile",
        params: {
          userId: userId
        }
      });
    },
    async onAttendanceChange({ userId, attended }) {
      if (attended) {
        const result = await SeriesApi.checkIn(this.eventId, userId);
        if (result.success) {
          await this.$apollo.queries.resultGetCurrentEventRsvpedAndCheckedInList.refetch();
        } else {
          await this.$apollo.queries.resultGetCurrentEventRsvpedAndCheckedInList.refetch();
          this.$root.$emit("universal-error-message", result.error);
        }
      } else {
        //!! do we allow organizers to un-checkin members
        return;
      }
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="double">
    <SuawSectionHeader header-label="Attended Last Event or RSVP'd" button-label="View All Members" @view-all-click="$emit('view-all-click')" />
    <SuawEmptyState v-if="isLoading" message="Loading RSVPs and previous attendees..." size="medium" />
    <SuawEmptyState v-else-if="!isLoading && attendedLastEventRsvpedOrCheckedInList.length === 0" message="No Recent Attendees or RSVP'd Members" />
    <SuawListSection v-else>
      <SuawSeriesMemberCard
        v-for="writer in attendedLastEventRsvpedOrCheckedInList"
        :key="writer.id"
        :user-id="writer.id"
        :avatar-url="writer.avatar_url"
        :avatar-color="writer.avatar_color"
        :avatar-initials="writer.initials"
        :is-avatar-hidden="writer.is_avatar_hidden"
        :is-deactivated="writer.deleted_at !== null"
        :display-name="writer.display_name"
        :location="writer.country"
        :is-rsvped="writer.is_rsvped"
        :is-recent-attendee="writer.is_recent_attendee"
        :is-in-attendance="writer.is_in_attendance"
        :is-organizer="writer.is_organizer"
        :is-event-time="isVirtual ? false : isAttendanceTime"
        :event-count="writer.event_count.aggregate.count"
        :post-count="writer.post_count.aggregate.count"
        @profile-click="onProfileClick"
        @attendance-checkbox-click="onAttendanceChange"
      />
    </SuawListSection>
  </SuawInputGroup>
</template>

<style lang="scss">
.suaw-event-rsvped-label {
  margin-bottom: 16px;
}
</style>
