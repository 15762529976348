<script>
import Modal from "../Modal/Modal.vue";
import Button from "../Button/Button.vue";

export default {
  name: "Gallery",
  components: { Modal, Button },
  props: {
    coverPhoto: {
      type: String,
      required: true,
    },
    galleryPhotos: {
      type: Array,
      default: () => [] // Provide a default empty array
    }
  },
  data() {
    return {
      showModal: false,
      currentIndex: 0,
    };
  },
  computed: {
    isPrevDisabled() {
      return this.currentIndex === 0;
    },
    isNextDisabled() {
      return this.currentIndex >= this.galleryPhotos.length - 1;
    },
    currentImage() {
      return this.galleryPhotos[this.currentIndex] || '';
    }
  },
  methods: {
    handleGalleryClick() {
      this.currentIndex = 0;
      this.showModal = true;
    },
    next() {
      if (this.currentIndex < this.galleryPhotos.length - 1) {
        this.currentIndex++;
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    closeModal() {
      this.showModal = false;
    },
  }
};
</script>

<template>
  <div class="suaw-gallery">
    <!-- Always show the cover photo -->
    <img
      class="suaw-gallery__image"
      :src="coverPhoto"
      @click="handleGalleryClick"
    />
    <!-- Modal to display gallery photos -->
    <Modal
      class="suaw-modal__gallery"
      v-if="showModal"
      :clickMethod="closeModal"
      modalSize="medium"
      modalType="custom"
    >
      <div class="suaw-gallery__modal-content">
        <Button
          v-if="!isPrevDisabled"
          class="suaw-button--no-x-pd prev-button"
          size="large"
          type="ghost"
          iconLeft="IconCircleChevronLeft"
          icon-size="extra-large"
          buttonText=""
          @click="prev"
        />
        <img
          v-if="currentImage"
          class="suaw-gallery__modal-image"
          :src="currentImage"
        />
        <Button
          v-if="!isNextDisabled"
          class="suaw-button--no-x-pd next-button"
          size="large"
          type="ghost"
          iconRight="IconCircleChevronRight"
          icon-size="extra-large"
          buttonText=""
          @click="next"
        />
      </div>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.suaw-gallery {
  max-width: 1264px;
  width: 100%;
  &__image {
    cursor: pointer;
    width: 100%;
    height: 220px;
    border-radius: 16px;
    box-shadow: var(--soft-shadow-draft);
    object-fit: cover;
    transition: 0.3s;
  }
  &__image:hover {
    opacity: 0.8;
  }
  &__modal-content {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
  }
  .prev-button {
    grid-column: 1/2;
  }
  .next-button {
    grid-column: 3/4;
  }
  &__modal-image {
    grid-column: 2/3;
    width: 100%;
    height: auto;
    border-radius: 16px;
    box-shadow: var(--soft-shadow-draft);
    object-fit: cover;
    transition: 0.3s;
  }
}
</style>
