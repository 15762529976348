<script>
import LogoDefault from "./LogoDefault.vue";
import LogoSign from "./LogoSign.vue";
import LogoInitials from "./LogoInitials.vue";
import LogoBW from "./LogoBW.vue";
import LogoWhereby from "./LogoWhereby.vue";
import LogoZoom from "./LogoZoom.vue";
import LogoTeams from "./LogoTeams.vue";
import LogoMeet from "./LogoGoogleMeet.vue";

export default {
  name: "Logo",
  components: {
    LogoDefault,
    LogoSign,
    LogoInitials,
    LogoBW,
    LogoWhereby,
    LogoZoom,
    LogoTeams,
    LogoMeet,
  },
  props: {
    type: {
      type: String,
      validator: function (value) {
        return [
          "LogoInitials",
          "LogoSign",
          "LogoBW",
          "LogoDefault",
          "LogoWhereby",
          "LogoZoom",
          "LogoTeams",
          "LogoMeet",
        ].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "suaw-logo",
    },
  },
}
</script>

<template>
  <component
    :is="type"
    :class="className"
  />
</template>

<style lang="scss" scoped>
.suaw-logo {
  display: inline-block;
}
</style>
