<script>
import { SuawHeading, SuawParagraph } from "@/components";
export default {
  name: "StatsSection",
  components: { SuawHeading, SuawParagraph }
};
</script>

<template>
  <div class="suaw-stats-section">
    <div class="suaw-stats-section__main-container">
      <div class="suaw-stats-section__stat-container-1">
        <SuawHeading class="suaw-stats-section__stat-number" content="100,000+" level="3" />
        <SuawParagraph class="suaw-stats-section__stat-content-desktop" alignment="center" text="Writers Worldwide" />
        <SuawParagraph class="suaw-stats-section__stat-content-mobile" alignment="center" text="Writers" />
      </div>
      <div class="suaw-stats-section__stat-container-2">
        <SuawHeading class="suaw-stats-section__stat-number" content="1500+" level="3" />
        <SuawParagraph class="suaw-stats-section__stat-content-desktop" alignment="center" text="Weekly Events" />
        <SuawParagraph class="suaw-stats-section__stat-content-mobile" alignment="center" text="Weekly Events" />
      </div>
      <div class="suaw-stats-section__stat-container-3">
        <SuawHeading class="suaw-stats-section__stat-number" content="400+" level="3" />
        <SuawParagraph class="suaw-stats-section__stat-content-desktop" alignment="center" text="Chapter Cities" />
        <SuawParagraph class="suaw-stats-section__stat-content-mobile" alignment="center" text="Cities" />
      </div>
      <div class="suaw-stats-section__stat-container-4">
        <SuawHeading class="suaw-stats-section__stat-number" content="60+" level="3" />
        <SuawParagraph class="suaw-stats-section__stat-content-desktop" alignment="center" text="Countries With Events" />
        <SuawParagraph class="suaw-stats-section__stat-content-mobile" alignment="center" text="Countries" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-stats-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--sem-color-decorative-blue);
  &__main-container {
    margin: 16px 0px;
    max-width: 1264px;
    width: calc(100% - 48px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 24px;
    }
    @media (max-width: 649px) {
      width: calc(100% - 32px);
    }
  }
  &__stat-container-1 {
    justify-self: start;
    display: flex;
    align-items: center;
    gap: 4px;
    @media (max-width: 1000px) {
      justify-self: center;
    }
    @media (max-width: 784px) {
      display: flex;
      flex-direction: column;
      margin-right: 48px;
    }
    @media (max-width: 400px) {
      margin-right: 0px;
    }
  }
  &__stat-container-2 {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-self: center;
    @media (max-width: 784px) {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 650px) {
      margin-left: 48px;
    }
    @media (max-width: 400px) {
      margin-left: 0px;
    }
  }
  &__stat-container-3 {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-self: center;
    @media (max-width: 784px) {
      justify-self: center;
    }
    @media (max-width: 784px) {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 650px) {
      margin-right: 48px;
    }
    @media (max-width: 400px) {
      margin-right: 0px;
    }
  }
  &__stat-container-4 {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-self: end;
    @media (max-width: 1000px) {
      justify-self: center;
    }
    @media (max-width: 784px) {
      display: flex;
      flex-direction: column;
      margin-left: 48px;
    }
    @media (max-width: 400px) {
      margin-left: 0px;
    }
  }

  &__stat-number {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    @media (max-width: 784px) {
      font-size: 32px;
    }
  }
  &__stat-content-desktop {
    font-size: 18px;
    display: block;
    font-weight: 700;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__stat-content-mobile {
    font-size: 20px;
    font-weight: 500;
    display: none;
    @media (max-width: 1000px) {
      display: block;
    }
  }
}
</style>
