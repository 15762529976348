<script>
import Pill from "../Pill/Pill.vue";

export default {
  name: "CalendarDate",
  components: {
    Pill,
  },
  props: {
    type: {
      type: String,
      default: "default",
      validator: function (value) {
        return [
          "default",
          "shadow",
        ].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      validator: function (value) {
        return [
          "large",
          "micro",
          "medium",
          "small",
        ].indexOf(value) !== -1;
      },
    },
    status: {
      type: String,
      validator: function (value) {
        return [
          "cancelled",
          "in-person",
          "past",
          "online",
        ].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
    },
    timezone: {
      type: String,
    },
    time: {
      type: String,
      required: false,
      validator: function (value) {
        const timeRegex = /^([1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
        return timeRegex.test(value);
      },
    },
    month: {
      type: Number,
      validator: function (value) {
        return value >= 1 && value <= 12;
      },
    },
    day: {
      type: Number,
      validator: function (value) {
        return value >= 1 && value <= 31;
      },
    }
  },
  computed: {
    isValidTime() {
      return this.$options.props.time.validator(this.time);
    },
    monthFormat() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (typeof this.month !== "number" || this.month < 1 || this.month > 12) {
        return "";
      }

      if (this.size.includes("large")) {
        return monthNames[this.month - 1];
      } else {
        const month = monthNames[this.month - 1];
        if (["May", "June", "July"].includes(month)) {
          return month;
        } else {
          const abbreviatedMonth = month.substring(0, 3) + ".";
          return abbreviatedMonth;
        }
      }
    },
    dateFormat() {
      return this.size.includes('micro') ? this.month + '/' + this.day : this.day;
    },
    monthClasses() {
      return {
        'suaw-calendar-date__month--small': this.size == 'small',
      }
    },
    headerClasses() {
      return {
        'suaw-calendar-date__header--small': this.size == 'small',
        'suaw-calendar-date__header--micro': this.size == 'micro',
      }
    },
    timeClasses() {
      return {
        'suaw-calendar-date__time--large': this.size == 'large',
        'suaw-calendar-date__time--micro': this.size == 'micro',
      }
    },
    footerClasses() {
      return {
        'suaw-calendar-date__footer--small': this.size == 'small',
        'suaw-calendar-date__footer--micro': this.size == 'micro',
      }
    },
  }
};
</script>

<template>
  <div 
    class="suaw-calendar-date"
    :class="[
      'suaw-calendar-date--' + size,
      'suaw-calendar-date--' + type,
      'suaw-calendar-date--' + status,
      className
    ]"
  >
    <div class="suaw-calendar-date__holder">
      <header 
        class="suaw-calendar-date__header"
        :class="headerClasses"
      >
        <p
          v-if="['large', 'medium', 'small'].includes(size)"
          class="suaw-calendar-date__month"
          v-html="monthFormat"
        ></p>
        <p
          v-if="size === 'micro'"
          v-html="dateFormat"
        ></p>
      </header>
      <div
        v-if="['large', 'medium', 'small'].includes(size)"
        class="suaw-calendar-date__content"
      >
        <p
          class="suaw-calendar-date__day"
          v-html="dateFormat"
        ></p>
      </div>
      <footer
        v-if="['large', 'medium', 'micro'].includes(size)"
        class="suaw-calendar-date__footer"
        :class="footerClasses"
      >
        <time
          v-if="isValidTime"
          class="suaw-calendar-date__time"
          :class="timeClasses"
        >
          {{ time }}
        </time>
        <Pill
          v-if="size === 'large'"
          class="suaw-calendar-date__pill"
          labelClassName="suaw-calendar-date__timezone"
          status="default"
          :text="timezone"
        />
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-calendar-date {
  overflow: hidden;
  position: relative;

  &--shadow {
    box-shadow: var(--date-square-shadow);
    --comp-calendar-date-footer-border: var(--sem-color-border-medium);
  }

  &--default {
    border-width: 1px;
    border-style: solid;
    border-color: var(--sem-color-border-darkest);
    --comp-calendar-date-footer-border: var(--sem-color-border-darkest);
  }

  &--large {
    align-items: center;
    background-color: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    display: inline-flex;
    flex-direction: column;
    width: 216px;
    max-width: 100%;
  }

  &--medium {
    align-items: flex-start;
    background-color: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    display: flex;
    flex-direction: column;
    width: 112px;
  }

  &--medium .suaw-calendar-date__content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--small {
    background-color: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    height: 80px;
    min-width: 80px;
    width: 80px;
  }

  &--small .suaw-calendar-date__content {
    border-bottom-left-radius: var(--sem-radius-md);
    border-bottom-right-radius: var(--sem-radius-md);
    border-bottom: 1px solid var(--sem-color-border-medium);
  }

  &--micro {
    border-radius: var(--sem-radius-md);
    overflow: hidden;
    width: 58px;
  }

  &--micro.suaw-calendar-date--cancelled {
    background-color: var(--sem-color-critical-medium);
  }

  &--micro.suaw-calendar-date--past {
    background-color: var(--sem-color-background-medium);
  }

  &--micro.suaw-calendar-date--online {
    background-color: var(--sem-color-complementary-medium);
  }

  &--micro.suaw-calendar-date--in-person {
    background-color: var(--sem-color-primary-medium);
  }

  &__holder {
    width: 100%;
  }

  &__header {
    border-radius: var(--sem-radius-md) var(--sem-radius-md) 0 0;
    position: relative;

    .suaw-calendar-date--large & {
      max-height: 32px;
      padding: 4px 8px;
    }

    .suaw-calendar-date--online & {
      background-color: var(--sem-color-complementary-medium);
    }

    .suaw-calendar-date--in-person & {
      background-color: var(--sem-color-primary-medium);
    }

    .suaw-calendar-date--past & {
      background-color: var(--sem-color-neutral-medium);
    }

    .suaw-calendar-date--medium & {
      height: 28px;
      max-height: 28px;
      width: 100%;
    }

    .suaw-calendar-date--cancelled & {
      background-color: var(--sem-color-critical-medium);
    }

    .suaw-calendar-date--large & {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &__header--small {
    border-radius: var(--radius-top-default);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    max-height: 24px;
    width: 100%;

    .suaw-calendar-date--in-person & {
      background-color: var(--sem-color-primary-medium);
    }

    .suaw-calendar-date--past & {
      background-color: var(--sem-color-neutral-medium);
      color: var(--sem-color-text-lightest);
    }

    .suaw-calendar-date--cancelled & {
      background-color: var(--sem-color-critical-medium);
      color: var(--sem-color-text-lightest);
    }

    .suaw-calendar-date--online & {
      background-color: var(--sem-color-complementary-medium);
      color: var(--sem-color-text-lightest);
    }
  }

  &__header--micro {
    border-radius: 0;
    font-family: var(--font-family);
    font-size: var(--h-5-font-size);
    font-style: var(--h-5-font-style);
    font-weight: var(--h-5-font-weight);
    letter-spacing: var(--h-5-letter-spacing);
    text-align: center;
    display: block;
    padding: 2px 4px;

    .suaw-calendar-date--in-person & {
      color: var(--sem-color-text-darkest);
    }

    .suaw-calendar-date--past & {
      color: var(--sem-color-text-lightest);
    }

    .suaw-calendar-date--cancelled & {
      color: var(--sem-color-text-lightest);
    }

    .suaw-calendar-date--online & {
      color: var(--sem-color-text-lightest);
    }
  }

  &__footer {
    background-color: var(--sem-color-background-lightest);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-left: 1px solid var(--sem-color-border-medium);
    border-right: 1px solid var(--sem-color-border-medium);
    border-bottom-left-radius: var(--sem-radius-md);
    border-bottom-right-radius: var(--sem-radius-md);
    border-bottom: 1px solid var(--sem-color-border-medium);

    &:not(.suaw-calendar-date__footer--micro) {
      border-top: 1px solid;
      border-color: var(--comp-calendar-date-footer-border);
      border-radius: 0px 0px 8px 8px;
    }

    .suaw-calendar-date--large & {
      align-items: center;
      display: flex;
      height: 33px;
      justify-content: center;
      padding: 8px;
    }

    .suaw-calendar-date--medium & {
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .suaw-calendar-date--micro & {
      height: 24px;
    }
  }

  &__footer--micro {
    padding: 2px 4px;
  }

  &__month {
    text-align: center;
    white-space: nowrap;

    .suaw-calendar-date--large & {
      font-family: var(--font-family);
      font-size: var(--h-5-font-size);
      font-style: var(--h-5-font-style);
      font-weight: var(--h-5-font-weight);
      letter-spacing: var(--h-5-letter-spacing);
      line-height: var(--base-line-height);
      position: relative;
    }

    .suaw-calendar-date--online & {
      color: var(--sem-color-text-lightest);
    }

    .suaw-calendar-date--small & {
      font-family: var(--font-family);
      font-size: var(--button-bold-font-size);
      font-style: var(--h-5-font-style);
      font-weight: var(--h-5-font-weight);
      letter-spacing: var(--h-5-letter-spacing);
      line-height: var(--base-line-height);
      position: relative;
    }

    .suaw-calendar-date--in-person & {
      color: var(--sem-color-text-darkest);
    }

    .suaw-calendar-date--past & {
      color: var(--sem-color-text-lightest);
    }

    .suaw-calendar-date--medium & {
      font-family: var(--font-family);
      font-size: var(--button-bold-font-size);
      font-style: var(--button-bold-font-style);
      font-weight: var(--button-bold-font-weight);
      letter-spacing: var(--button-bold-letter-spacing);
      line-height: var(--button-bold-line-height);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .suaw-calendar-date--micro & {
      font-family: var(--font-family);
      font-size: var(--h-5-font-size);
      font-style: var(--h-5-font-style);
      font-weight: var(--h-5-font-weight);
      letter-spacing: var(--h-5-letter-spacing);
      line-height: var(--base-line-height);
      position: relative;
    }

    .suaw-calendar-date--cancelled & {
      color: var(--sem-color-text-lightest);
    }
  }

  &__content {
    position: relative;
    width: 100%;
    border-left: 1px solid var(--sem-color-border-medium);
    border-right: 1px solid var(--sem-color-border-medium);

    .suaw-calendar-date--large & {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 8px;
    }

    .suaw-calendar-date--medium & {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 58px;
      text-align: center;
    }
  }

  &__timezone {
    color: var(--sem-color-text-regular) !important;
  }

  &__day {
    font-family: var(--font-family);
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;

    .suaw-calendar-date--large & {
      font-size: 64px;
      line-height: 80px;
      position: relative;
      width: fit-content;
    }

    .suaw-calendar-date--online & {
      color: var(--sem-color-text-darkest);
    }

    .suaw-calendar-date--small & {
      font-size: 64px;
      line-height: 80px;
      position: relative;
    }

    .suaw-calendar-date--in-person & {
      color: var(--sem-color-text-darkest);
    }

    .suaw-calendar-date--past & {
      color: var(--sem-color-text-regular);
    }

    .suaw-calendar-date--medium & {
      font-size: 48px;
      height: 52px;
      line-height: 52px;
    }

    .suaw-calendar-date--small & {
      font-size: 48px;
      height: 55px;
      letter-spacing: 0;
      line-height: normal;
    }

    .suaw-calendar-date--cancelled & {
      color: var(--sem-color-critical-medium);
    }
  }

  &__time {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    white-space: nowrap;

    .suaw-calendar-date--in-person & {
      color: var(--sem-color-text-darkest);
    }

    .suaw-calendar-date--past & {
      color: var(--sem-color-text-regular);
    }

    .suaw-calendar-date--cancelled & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-calendar-date--online & {
      color: var(--sem-color-text-darkest);
    }
  }

  &__time--large {
    margin-right: 4px;
    position: relative;
  }

  &__time--micro {
    font-size: var(--body-small-bold-font-size);
    line-height: var(--base-line-height);

    .suaw-calendar-date--in-person &,
    .suaw-calendar-date--online & {
      color: var(--sem-color-text-darkest);
    }

    .suaw-calendar-date--past &,
    .suaw-calendar-date--cancelled & {
      color: var(--sem-color-text-regular);
    }
  }
}
</style>
