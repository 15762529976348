<script>
import ModalConfirmation from "./ModalConfirmation.vue";

export default {
  name: 'Modal',
  components: {
    ModalConfirmation,
  },
  props: {
    clickMethod: {
      type: Function,
    },
    modalSize: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["large", "medium", "small"].indexOf(value) !== -1;
      },
    },
    modalType: {
      type: String,
      default: "custom",
      validator: function (value) {
        return ["custom", "confirmation"].indexOf(value) !== -1;
      },
    },
    toggleButtonConfirm: {
      type: Function,
    },
    toggleButtonCancel: {
      type: Function,
    },
    modalTitle: {
      type: String,
    },
  },
  methods: {
    handleClick() {
      if (this.clickMethod) {
        this.clickMethod();
      }
      this.$emit('button-clicked');
    },
  },
};
</script>

<template>
  <div class="suaw-modal">
    <div 
      class="suaw-modal__overlay"
      @click="handleClick"
    ></div>
    <div
      v-if="modalType == 'custom'"
      class="suaw-modal__content"
      :class="'suaw-modal__content--' + modalSize"
    >
      <slot></slot>
    </div>
    <ModalConfirmation
      v-else
      :toggleButtonConfirm="toggleButtonConfirm"
      :toggleButtonCancel="toggleButtonCancel"
      :modalTitle="modalTitle"
      :clickMethod="clickMethod"
    >
      <slot></slot>
    </ModalConfirmation>
  </div>
</template>

<style lang="scss" scoped>
.suaw-modal {
  z-index: 99998;

  &,
  &__overlay {
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &__overlay {
    background-color: rgba(10,10,10,.86);
  }

  &,
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    box-shadow: var(--soft-shadow-draft);
    gap: var(--sem-space-triple);
    left: 0;
    margin: auto;
    right: 0;
    padding: var(--sem-space-quintuple);
    position: relative;
    min-width: 320px;
    max-width: 100%;
  }

  &__content--medium {
    align-items: center;
    text-align: center;
    width: 50%;
  }

  &__content--small {
    width: 420px;
  }

  &__logo {
    width: 175px;
  }

  &__qr-code {
    display: block;
    margin: var(--sem-space-double) auto;
    max-width: 224px;

    @media screen and (min-width: 768px) {
      max-width: 500px;
    }
  }

  &__close {
    position: absolute;
    right: var(--sem-space-double);
    top: var(--sem-space-double);
  }

  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity .25s linear;
  }

  &__fade-enter,
  &__fade-leave-to {
    opacity: 0;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
