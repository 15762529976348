<script>
import { SuawInputGroup, SuawHeading, SuawEmptyState } from "@/components";
import { GetUserViewForAdminTokens } from "../../operations.gql";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "UserViewTokens",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserView: {
        user_id: "",
        user_tokens: [
          // {
          //   id: "",
          //   comment: ""
          //   created_at
          //   expires_at
          // }
        ]
      }
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminTokens,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.user_id !== "";
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    tokenId(item) {
      return new Date(item.expires_at) > new Date() ? item.user_id : "(expired)";
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawHeading content="Tokens" level="5" />
    <table>
      <tr>
        <th>ID</th>
        <th>Purpose</th>
        <th>Creation</th>
        <th>Expiration</th>
      </tr>
      <tr v-for="item in resultUserView.user_tokens" :key="item.user_id">
        <td>{{ tokenId(item) }}</td>
        <td>{{ item.comment }}</td>
        <td>{{ formatDateAt(item.created_at) }}</td>
        <td>{{ formatDateAt(item.expires_at) }}</td>
      </tr>
    </table>
  </SuawInputGroup>
</template>

<style lang="css" scoped>
table {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid black;
  padding: 0.5rem;
}
/* tr:nth-child(even) {
  background-color: #f2f2f2; // Background color for even rows
} */
</style>
