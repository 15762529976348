<script>
import InboxItem from "./InboxItem.vue";
import Link from "../Link/Link.vue";

export default {
  name: "InboxList",
  components: {
    InboxItem,
    Link,
  },
  props: {
    inboxListItems: {
      type: Array,
      default: () => [],
    },
    expandableInboxListItems: {
      type: Array,
      default: () => [],
    },
    totalNotifications: {
      type: Number,
      default: 0
    },
    newResponse: {
      type: String,
      default: "New Response"
    },
    cardText: {
      type: String,
    },
    chipText: {
      type: String,
    },
    discussionResponses: {
      type: String,
    },
    discussionLikes: {
      type: String,
    },
    posted: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    messagesLabel: {
      type: String,
      default: "See All Messages",
    },
    messagesUrl: {
      type: String,
      default: "https://#",
    },
    showViewAll: {
      type: Boolean,
      default: true,
    },
    sidepanelTitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <ul class="suaw-updates-list">
    <li
      v-for="(item, index) in inboxListItems"
      :key="index"
      class="suaw-updates-list__item"
    >
      <InboxItem
        :id="item.id"
        :headingText="item.text"
        :cardText="item.cardText"
        :chipContent="item.chipContent"
        :chipText="item.chipText"
        :chipColor="item.chipColor"
        :posted="item.posted"
        :discussionLikes="item.discussionLikes"
        :discussionResponses="item.discussionResponses"
        :totalNotifications="item.totalNotifications"
        :newResponse="item.newResponse"
        :dropdownItems="item.dropdownItems"
        :sidepanelTitle="item.sidepanelTitle"
        :expandableInboxListItems="item.expandableInboxListItems"
        :new-response-count="item.newResponseCount"
        @message-card-click="$emit('message-card-click', $event)"
        @dropdown-item-click="$emit('dropdown-item-click', $event)"
      />
    </li>
    <slot />
    <li class="suaw-updates-list__item suaw-updates-list__item--center">
      <Link
        v-if="showViewAll"
        :to="messagesUrl"
        size="medium"
        weight="bold"
        textClassName="suaw-link__text--fill"
      >{{ messagesLabel }}</Link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.suaw-updates-list {
  border-radius: var(--sem-radius-sm);
  list-style-type: none;
  padding: var(--sem-space-base) 0 0;

  &__item:not(:first-child) {
    margin-top: var(--sem-space-triple);
  }

  &__item--center {
    margin: auto;
    text-align: center;
  }
}
</style>
