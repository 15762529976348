<script>
import Card from '../Card/Card.vue';
import Heading from '../Heading/Heading.vue';
import Button from '../Button/Button.vue';

export default {
  name: 'CarouselRows',
  components: {
    Card,
    Heading,
    Button,
  },
  props: {
    carouselHeading: {
      type: String,
    },
    cards: {
      type: Array,
      default: () => []
    },
    cardsPerPage: {
      type: Number,
      default: 8,
    },
    cardType: {
      type: String,
      validator: function (value) {
        return [
          "CardChapterExpanded",
          "CardEventExpanded",
          "CardOnlineEvent",
          "CardChapter",
          "CardArticle",
          "CardArticleExpanded",
        ].indexOf(value) !== -1;
      },
    }
  },
  data() {
    return {
      currentIndex: 0,
      translateValue: 0,
      cardWidth: 0,
      cardMargin: 10,
    };
  },
  watch: {
    cards: {
      handler() {
        this.$nextTick(() => {
          this.updateTranslateValue();
        });
      },
      deep: true
    },
    currentIndex() {
      this.updateTranslateValue();
    },
  },
  computed: {
    visibleCards() {
      const endIndex = Math.min(this.currentIndex + this.cardsPerPage, this.cards.length);
      return this.cards.slice(this.currentIndex, endIndex);
    },
    isPrevDisabled() {
      return this.currentIndex === 0;
    },
    isNextDisabled() {
      const lastIndex = this.cards.length - 1;
      return this.currentIndex >= lastIndex - this.cardsPerPage + 1;
    },
  },
  mounted() {
    this.updateCardWidth();
    this.updateTranslateValue();
  },
  methods: {
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex -= this.cardsPerPage;
        this.updateTranslateValue();
      }
    },
    next() {
      const lastIndex = this.cards.length - 1;
      if (this.currentIndex < lastIndex) {
        this.currentIndex += this.cardsPerPage;
        this.updateTranslateValue();
      }
    },
    updateTranslateValue() {
      this.translateValue = -1 * (this.cardWidth + this.cardMargin) * this.currentIndex;
    },
    updateCardWidth() {
      // Assuming all cards have the same width
      const firstCard = this.$el.querySelector('.suaw-card');
      this.cardWidth = firstCard ? firstCard.offsetWidth : 0;
    },
  },
};
</script>

<template>
  <div class="suaw-carousel-cards">
    <div class="suaw-carousel-cards__row">
      <div class="suaw-carousel-cards__headline">
        <Heading
          level="3"
          :content="carouselHeading"
        />
        <slot name="filters" />
      </div>
      <nav class="suaw-carousel-cards__nav">
        <Button
          class="suaw-button--no-x-pd"
          size="large"
          type="ghost"
          iconLeft="IconCircleChevronLeft"
          buttonText="Prev"
          :disabled="isPrevDisabled"
          :aria-disabled="isPrevDisabled"
          @click="prev"
        />
        <Button
          class="suaw-button--no-x-pd"
          size="large"
          type="ghost"
          iconRight="IconCircleChevronRight"
          buttonText="Next"
          :disabled="isNextDisabled"
          :aria-disabled="isNextDisabled"
          @click="next"
        />
      </nav>
    </div>
    <div class="suaw-carousel-cards__container">
      <div class="suaw-carousel-cards__holder">
        <slot name="cards" />
        <Card
          v-for="(card, index) in visibleCards"
          :key="card.id || index"
          :cardType="cardType"
          :id="card.id"
          :thumbnail="card.thumbnail"
          :avatarType="card.avatarType"
          :avatarContent="card.avatarContent"
          :posted="card.posted"
          :route="card.route"
          :author="card.author"
          :heading="card.heading"
          :description="card.description"
          :location="card.location"
          :members="card.members"
          :distance="card.distance"
          :calendarDate="card.calendarDate"
          :chipItems="card.chipItems"
          :upcomingEvents="card.upcomingEvents"
          :activeSince="card.activeSince"
          @click="$emit('click', $event)"
        />
      </div>
      <footer class="suaw-carousel-cards__footer">
        <Button
          class="suaw-button--no-x-pd"
          size="large"
          type="ghost"
          iconLeft="IconCircleChevronLeft"
          buttonText="Prev"
          :disabled="isPrevDisabled"
          :aria-disabled="isPrevDisabled"
          @click="prev"
        />
        <Button
          class="suaw-button--no-x-pd"
          size="large"
          type="ghost"
          iconRight="IconCircleChevronRight"
          buttonText="Next"
          :disabled="isNextDisabled"
          :aria-disabled="isNextDisabled"
          @click="next"
        />
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-carousel-cards {
  @media screen and (min-width: 1160px) {
    --comp-carousel-rows-cards-width: calc(100% / 4 - var(--sem-space-quadruple));
  }

  @media screen and (max-width: 1159px) {
    --comp-carousel-rows-cards-width: calc(100% / 3 - var(--sem-space-quadruple));
  }

  @media screen and (max-width: 880px) {
    --comp-carousel-rows-cards-width: calc(100% / 2 - var(--sem-space-quadruple));
  }

  @media screen and (max-width: 600px) {
    --comp-carousel-rows-cards-width: 100%;
  }

  text-align: center;
  width: 100%;

  &__nav,
  &__footer,
  &__row,
  &__holder {
    display: flex;
  }

  &__nav,
  &__row {
    justify-content: space-between;
    align-items: center;
  }

  &__footer,
  &__nav {
    gap: var(--sem-space-double);
  }

  &__container {
    max-width: 100%;
    overflow: visible;
  }

  &__holder {
    flex-wrap: wrap;
    // gap: var(--sem-space-quadruple);
    gap: 42px;
    padding: var(--sem-space-triple) 0;
    transition: transform 0.3s ease-in-out;

    > * {
      width: var(--comp-carousel-rows-cards-width);
      max-width: none;
    }
  }

  &__footer {
    justify-content: center;
  }
}
</style>
