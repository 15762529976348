<script>
import CalendarDate from '../CalendarDate/CalendarDate.vue';
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import ChipTwo from '../Chip/ChipTwo.vue';
import Icon from '../Icon/Icon.vue';
export default {
  name: "ChapterEventCard",
  components: { CalendarDate, Heading, Paragraph, ChipTwo, Icon },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    status: {
      type: String,
    },
    isVirtual: {
      type: Boolean,
    },
    month: {
      type: Number,
    },
    day: {
      type: Number,
    },
    startTime: {
      type: String,
    },
    duration: {
      type: String,
    },
    location: {
      type: String,
    },
    organizers: {
      type: Array,
    },
    isRsvped: {
      type: Boolean,
      default: false,
    },
    isManifested: {
      type: Boolean,
    },
    seriesSlug: {
      type: String,
    },
    chapterSlug: {
      type: String,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 650,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    truncatedTitle() {
      const maxLength = this.isMobile ? 65 : 90; // Set maxLength to 65 for mobile, 80 for desktop
      if (this.title && this.title.length > maxLength) {
        return this.title.substring(0, maxLength) + "...";
      }
      return this.title;
    }
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 650;
    },
  },
};
</script>

<template>
  <div class="suaw-chapter-event-card" @click="$emit('click', { ...$props })">
    <!-- Desktop content -->
    <div v-if="!isMobile" class="suaw-chapter-event-card__desktop-content">
      <div class="suaw-chapter-event-card__cal-container">
        <CalendarDate
          type="shadow"
          size="medium"
          :status="status"
          :month="month"
          :day="day"
          :time="startTime"
        />
      </div>
      <div class="suaw-chapter-event-card__title-container">
        <Heading
          level="4"
          style="color: var(--sem-color-background-darken)"
          :content="truncatedTitle"
        />
      </div>
      <div class="suaw-chapter-event-card__status-container">
        <div v-if="isRsvped" class="suaw-chapter-event-card__status-chip">
          <Icon
            class="suaw-chapter-event-card__rsvp-icon"
            icon="IconDownloadDone"
            color="green"
          />
          <Paragraph
            class="suaw-chapter-event-card__status rsvp-status"
            text="RSVP'd"
          />
        </div>
      </div>
      <div class="suaw-chapter-event-card__info-chip-container">
        <ChipTwo
          size="medium"
          type="icon"
          icon="IconClock"
          icon-color="blue"
          :text="duration"
        />
        <ChipTwo
          :key="isVirtual"
          size="medium"
          type="icon"
          :icon="isVirtual ? 'IconMonitor' : 'IconLocation'"
          :icon-color="isVirtual ? 'purple' : 'blue'"
          :text="location"
          :color="isVirtual ? 'online' : 'default'"
        />
      </div>
      <div class="suaw-chapter-event-card__organizer-chip-container">
        <Paragraph
          class="suaw-chapter-event-card__paragraph suaw-chapter-event-card__paragraph-normal"
          text="Hosted By: "
        />
        <ChipTwo
          v-for="(organizer, index) in organizers"
          :key="organizer.id || index"
          size="medium"
          type="avatar"
          :avatar-url="organizer.avatar_url"
          :avatar-color="organizer.avatar_color"
          :avatar-initials="organizer.initials"
          :display-name="organizer.display_name"
          :text="organizer.display_name"
          :is-avatar-hidden="organizer.is_avatar_hidden"
          :is-deactivated="!!organizer.deleted_at"
        />
      </div>
    </div>
    <!-- Mobile content -->
    <div v-else class="suaw-chapter-event-card__mobile-content">
      <div class="suaw-chapter-event-card__cal-container">
        <CalendarDate
          type="shadow"
          size="micro"
          :status="status"
          :month="month"
          :day="day"
          :time="startTime"
        />
      </div>
      <div class="suaw-chapter-event-card__title-container">
        <Heading
          class="suaw-chapter-event-card__mobile-title"
          level="5"
          style="color: var(--sem-color-background-darken)"
          :content="truncatedTitle"
        />
      </div>
      <div class="suaw-chapter-event-card__status-container">
        <div v-if="isRsvped" class="suaw-chapter-event-card__status-chip">
          <Icon
            class="suaw-chapter-event-card__rsvp-icon"
            icon="IconDownloadDone"
            color="green"
          />
          <Paragraph
            class="suaw-chapter-event-card__status rsvp-status"
            text="RSVP'd"
          />
        </div>
      </div>
      <div class="suaw-chapter-event-card__info-chip-container">
        <ChipTwo
          :key="isVirtual"
          size="small"
          type="icon"
          :icon="isVirtual ? 'IconMonitor' : 'IconLocation'"
          :icon-color="isVirtual ? 'purple' : 'blue'"
          :text="location"
          :color="isVirtual ? 'online' : 'default'"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-event-card {
  // General card styles
  max-width: 1264px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  box-shadow: var(--soft-shadow-draft);
  background-color: var(--sem-color-background-lightest);
  transition: box-shadow 0.25s linear, transform 0.25s ease;
  &:hover {
    cursor: pointer;
    box-shadow: var(--shadow-2); // Ensure this is the right shadow variable
    transform: scale(0.99);
  }
  @media (max-width: 650px) {
    box-shadow: var(--date-square-shadow);
    min-height: 74px;
    padding: 8px;
  }

  // Container for desktop-specific content
  &__desktop-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 16px;
  }
  // &__desktop-content:hover {
  //   cursor: pointer;
  //   transform: scale(0.99);
  // }

  // Container for mobile-specific content
  &__mobile-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 12px;
  }

  // Calendar container styles for both desktop and mobile
  &__cal-container {
    grid-column: 1/2;
    grid-row: 1/4;
    @media (max-width: 650px) {
      grid-row: 1/3;
    }
  }

  // Title container shared between desktop and mobile
  &__title-container {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
  }

  &__mobile-title {
    font-size: 14px;
  }

  // RSVP and status section shared between both desktop and mobile
  &__status-container {
    grid-column: 3/4;
    grid-row: 1/2;
    display: flex;
    align-items: flex-start;
  }

  &__status-chip {
    display: flex;
    padding: 4px 8px 4px 4px;
    border-radius: 4px;
    background: #eafeef;
    @media (max-width: 525px) {
      padding: 2px;
    }
  }

  &__rsvp-icon {
    padding-bottom: 1px;
  }

  &__status {
    font-family: var(--body-bold-is-size-7-font-family);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: var(--body-bold-is-size-7-line-height);
    padding-top: 1px;
  }

  // RSVP Status color
  .rsvp-status {
    color: var(--sem-color-success-medium); // Green color for success state

    @media (max-width: 525px) {
      display: none;
    }
  }

  // Info chip container shared between both desktop and mobile
  &__info-chip-container {
    grid-column: 2/3;
    grid-row: 2/3;
    align-self: center;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    @media (max-width: 650px) {
      grid-column: 2/4;
      grid-row: 2/3;
    }
  }

  // Organizer chip container for desktop and mobile, moved to a shared block
  &__organizer-chip-container {
    grid-column: 2/3;
    grid-row: 3/4;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    @media (max-width: 650px) {
      grid-column: 1/4;
      grid-row: 5/6;
    }
  }

  // Paragraph styles
  &__paragraph {
    color: #575860;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 125%; /* 15px */

    @media (max-width: 429px) {
      display: none;
    }
  }
}
</style>
