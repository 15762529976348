<script>
export default {
  name: 'BlockQuote',
  components: {
  },
  props: {
    text: {
      type: [String, Object],
      default: "",
    },
  }
}
</script>

<template>
  <blockquote class="suaw-blockquote">
    {{ text }}
  </blockquote>
</template>

<style lang="scss" scoped>
.suaw-blockquote {
  background: var(--sem-color-background-lightest);
  border-color: var(--sem-color-primary-medium);
  border-style: solid;
  border-width: 1px 1px 1px 16px;
  color: var(--sem-color-text-medium);
  font-size: var(--p-font-size);
  margin: 0.5em auto;
  padding: 1rem;
  width: 90%;
}
</style>
