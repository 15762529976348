<script>
import { SuawDescriptionSection } from "@/components";
import { GetChapterDescriptionById } from "../../operations.gql";

export default {
  name: "ChapterDescriptionPipe",
  components: { SuawDescriptionSection },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetChapterDescriptionById: {
        id: "",
        description_json: ""
      }
    };
  },
  apollo: {
    resultGetChapterDescriptionById: {
      query: GetChapterDescriptionById,
      variables() {
        return {
          chapterId: this.chapterId
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasChapter() {
      return !!this.resultGetChapterDescriptionById.id;
    },
    chapter() {
      return this.hasChapter ? this.resultGetChapterDescriptionById : null;
    },
    description() {
      return this.chapter?.description_json || null;
    }
  }
}
</script>

<template>
  <SuawDescriptionSection label-text="About Our Chapter" :description-object="description" />
</template>
