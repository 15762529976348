<script>
import Button from "../Button/Button.vue";
import Paragraph from "../Paragraph/Paragraph.vue";

export default {
  name: 'EditableBlock',
  components: {
    Button,
    Paragraph,
  },
  props: {
    label: String,
    description: String,
    isEditable: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: 'Edit',
    },
    click: Function,
    blockType: {
      type: String,
      default: "default",
      validator: function (value) {
        return [
          "default",
          "ghost",
        ].indexOf(value) !== -1;
      },
    }
  },
}
</script>

<template>
  <div
    class="suaw-editable-block"
    :class="'suaw-editable-block--' + blockType"
  >
    <div class="suaw-editable-block__text">
      <Paragraph
        v-if="label"
        weight="bold"
        size="medium"
        :text="label"
      />
      <Paragraph
        v-if="label"
        weight="normal"
        size="small"
        :text="description"
      />
    </div>
    <Button
      v-if="isEditable"
      type="ghost"
      size="medium"
      :buttonText="buttonText"
      class="suaw-editable-block__button"
      @click="click"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-editable-block {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  &:not(.suaw-editable-block--ghost) {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-regular);
    border-radius: var(--sem-radius-sm);
    min-height: 56px;
    padding: var(--sem-space-base);
  }

  &__button {
    padding-right: 0;
  }
}
</style>
