<script>
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
export default {
  name: "CtaCard",
  components: { Heading, Button, Paragraph },
  props: {
    heading: {
      type: String,
    },
    text: {
      type: String,
    },
    primaryButtonText: {
      type: String,
      default: "View",
    },
    showSecondaryButton: {
      type: Boolean,
      default: false,
    },
    secondaryButtonText: {
      type: String,
      default: "View",
    },
  },
}
</script>

<template>
  <div class="suaw-cta-card">
    <div class="suaw-cta-card__content">
      <div v-if="heading" class="suaw-cta-card__heading-container">
        <Heading
          class="suaw-cta-card__heading"
          level="3"
          alignment="center"
          :content="heading"
          weight="bold"
        />
      </div>
      <div v-if="text" class="suaw-cta-card__text-container">
        <Paragraph
          class="suaw-cta-card__text"
          alignment="center"
          :text="text"
          siz="medium"
          weight="bold"
        />
      </div>
      <div class="suaw-cta-card__buttons-container">
        <div class="suaw-cta-card__button-container">
          <Button
            class="suaw-cta-card__button"
            :buttonText="primaryButtonText"
            size="large"
            type="primary"
            @click="$emit('click')"
          />
        </div>
        <div v-if="showSecondaryButton" class="suaw-cta-card__button-container">
          <Button
            class="suaw-cta-card__button online-button"
            :buttonText="secondaryButtonText"
            size="large"
            @click="$emit('secondary-click')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-cta-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-triple);
  width: 100%;
  max-width: 1264px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-triple);
    justify-content: center;
    align-items: center;
  }
  &__buttons-container {
    display: inline-flex;
    justify-content: center;
    gap: var(--sem-space-double);
    @media (max-width: 650px) {
      width: 100%;
    }
    @media (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
  &__button-container {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .online-button {
    background: #6737ff;
  }
  &__heading {
    font-weight: 500;
  }
  &__text {
    color: var(--sem-color-text-darken);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
}
</style>
