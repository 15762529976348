<script>
import { SuawMainContent, SuawParagraph, SuawHeading, SuawBlockQuote } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToSelfEditInSixPainlessSteps",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    SuawBlockQuote,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToSelfEditInSixPainlessSteps">
      <SuawParagraph
        text='Welcome aboard! This is your editor speaking. On this trip through your recently completed draft, we’re going to run through a few easy steps to take while you re-read. These steps will teach you to recognize common mistakes and eliminate a lot of red ink along the way. My fellow editors will thank you, and manufacturers of red pens everywhere will curse you. Remember, in the event of a grammar emergency, a Google search will point you toward your nearest <a target="_blank" href="https://www.merriam-webster.com/">online dictionary</a> or <a target="_blank" href="https://thewritelife.com/writing-style-guide/">style guide</a>. Now, let’s take a quick look at the procedures that will help you navigate any turbulence, and take that prose from rough to smooth.'
      />
      <SuawHeading level="3" content="1. Read Your Work Out Loud" />
      <SuawParagraph
        text="This is probably not the first time you’ve heard this tip—it’s a venerable old chestnut of writing advice. Nonetheless, a huge percentage of writers see this advice and think, “Okay, good idea. Here we go, I’m reading my draft out loud… in my head.”"
      />
      <SuawParagraph
        text="It may come as a surprise that reading your work out loud in your head and reading it out loud with your voice are not the same thing. One is a purely mental exercise; the other is a physical action. Reading out loud forces you to listen to your own words in the same way that your audience would hear them for the first time. It also helps you understand that words are meant to be spoken, and that writing does not only exist on a page. You can’t get those results from reading in your head, no matter how much your internal narrator sounds like Morgan Freeman."
      />
      <SuawParagraph
        text="Get a glass of water, clear your throat, and start talking. It might feel embarrassing to read aloud to an empty room, but your empty room will forgive you. Incidentally, your pets or house plants may be the perfect audience."
      />
      <SuawParagraph
        text="If your sentences are too convoluted, you’ll catch that. If you stumble over awkward phrasing, you’ll catch that too. If you have to take a deep breath halfway through every sentence, you’ll learn to break up those long clauses. Similarly, if you find yourself stopping constantly, you may realize your sentences are short and choppy. Reading aloud will give you a sense of the rhythm and flow of your writing."
      />
      <SuawParagraph
        text="Most importantly, you will find obvious mistakes. Your grammar app may not alert you if you used the word “corpse” instead of “course,” and your spell check may not realize that you used the word “realize” six times on one page. But you’ll notice those things if you read out loud, and when you do, you’ll vow never to edit in silence again."
      />
      <SuawParagraph text="Also, your editor can tell if you didn’t read your piece out loud. Just saying." />
      <SuawHeading level="3" content="2. Take Action… Verbs!" />
      <SuawParagraph text="Now that you’ve caught your most glaring errors, let’s dive into a style tip that can wake up your writing like a strong cup of coffee." />
      <SuawParagraph text="There are some sentences that are boring. They are just dull. They are perfectly functional, but they do not bring any life to your writing." />
      <SuawParagraph
        text="Check out those two paragraphs above. In that first paragraph, I used action verbs: “caught,” “dive,” “wake up.” In the second, I chose stative verbs and statements: “there are,” “they are.” I nearly fell asleep writing that second paragraph."
      />
      <SuawParagraph
        text='Take a glance at your <a target="_blank" href="https://www.englishgrammar.org/state-verbs-action-verbs/">verb choices</a>. Are you starting every paragraph with “there is” or “they are?” It’s fine to do that once in a while, but if you’re hoping to captivate your audience, consider switching up your verbs every so often. Almost any sentence can be rewritten to feel more active and dynamic—this is especially important to remember when writing opening lines. Even if you’re writing about abstract concepts or a stationary setting, you can recruit a few action verbs for a stylistic shot of espresso.'
      />
      <SuawHeading level="3" content="3. Watch Your Purple Prose" />
      <SuawParagraph
        text="Word choice is one of those key elements that determines a writer’s voice, so as an editor, I tread lightly when giving feedback in this area. However, when editing your own work, it’s a good idea to examine your word choices carefully. Words have great power, and as a radioactive teenager once said, with great power comes great responsibility."
      />
      <SuawParagraph
        text='If you re-read a sentence and a particular word stands out to you, remember that a reader could have the same experience. Ask yourself if you want to deliberately draw attention to that word. If so, great! You’ve made a powerful word choice. If not, find a <a target="_blank" href="https://www.thesaurus.com/">thesaurus</a> and try again. Remember that an eye-catching word can pull a reader out of a narrative. If you throw a word like “obsequious” or “crepuscular” into a fast-paced action sequence, you may send readers scrambling for a dictionary instead of turning the page. But that same word could be the perfect choice in a different context.'
      />
      <SuawParagraph
        text="Also, remember that you don’t need to choose “writerly” words to be a writer. You don’t need to adorn every sentence with an opulent metaphor, or describe every sunrise like a beacon of hope that unfurls its majestic brilliance across the verdant blanket of a lush meadow. Editors call this kind of language “purple prose.” Just like the accidental application of too much perfume, a draft dripping with purple prose can overwhelm and distract a reader."
      />
      <SuawParagraph
        text="While examining your word choices, confirm that the words and phrases you’ve chosen have the meaning you intend. Make sure your editor has no reason to quote Inigo Montoya: “You keep using that word. I do not think it means what you think it means.”"
      />
      <SuawBlockQuote text="Your editor can tell if you didn’t read your piece out loud. Just saying." />
      <SuawHeading level="3" content="4. Stop Repeating Yourself" />
      <SuawParagraph
        text="Words are a huge part of a writer’s life, so it makes sense that we’re particularly attached to some of them. Sometimes we may not even realize we’re repeating our favorite turns of phrase every time we’re at the keyboard. We’re particularly attached to words. Wait, hang on, that sounds familiar…"
      />
      <SuawParagraph
        text="When reviewing your own work, keep an eye out for repetition. It could be a word, a phrase, or even a punctuation mark. If you find a phrase in your draft that gives you déjà vu, use the “Find” function and search for it. With “Find,” you can discover if you’ve already written the phrase “beam me up” four times, or if you’re a little too fond of the em dash."
      />
      <SuawParagraph
        text="Years ago, one of my beta readers pointed out that I had a semicolon addiction so severe that I was using one in every other sentence. He pulled out a highlighter and began marking them in my document. When he got to 15 in a single page, he asked nicely if I wanted him to continue. I’ve since recovered from my semicolon addiction, but that day I learned the wonders of the Find tool."
      />
      <SuawParagraph
        text="A word of caution about the Find tool, however: Be careful about using the “Replace” feature — especially the “Replace All” feature. If you use “Replace All” to change every instance of “ten” to “10”, you’ll end up changing words like “tense” into “10se.” But for hunting down your repetition habits, “Find” can’t be beat."
      />
      <SuawHeading level="3" content="5. Curb Your Time Traveling" />
      <SuawParagraph
        text='You may be a time traveler without even knowing it, but this type of time-hopping doesn’t involve a TARDIS or Doc Brown’s DeLorean. Take a look at your verb tenses: are you switching back and forth between past and present tense? There’s a simple remedy. Pick a <a target="_blank" href="https://owl.purdue.edu/owl/general_writing/grammar/verb_tenses/index.html">single tense</a> and stay with it unless you have a deliberate, time-based reason to change.'
      />
      <SuawParagraph
        text="For example, if you are writing a story in the present tense, you should use the past tense when referring to past events: “Wayne shuffles the cards like a Vegas dealer. His dad taught him to deal cards when he was only eight.” But as long as you’re with Wayne in the present, make sure your verbs stay with him."
      />
      <SuawParagraph
        text="It’s not always easy to notice all the places you’ve slipped back and forth. For example, try this sentence: It seems grammar was tricky to learn. Sounds sort of okay, but take a closer look. “Seems” is in present tense, while “was” is past tense. Unless you’re set in the present and referring to the past, this could be a mistake. Once this type of error is on your radar, it becomes easier to spot."
      />
      <SuawParagraph
        text="If you read your draft with verb tenses in mind, you’ll realize your choice of verb tense is an essential decision. Past tense is a simple and clean default when writing fiction; present tense is often used for nonfiction, but can create an entirely different mood in fiction. Make a note of your choice, and then make sure that you switch only when the narrative calls for it. It’s the accidental switches that cause problems, especially if you’re Marty McFly."
      />
      <SuawHeading level="3" content="6. Eschew Prolixity" />
      <SuawParagraph
        text='Take one last scan of your draft with an eye toward overabundance. Make a game of it: how many unnecessary words can you trim? Can you say something in five words that you’re currently saying in 10? Cut out <a target="_blank" href="https://writing.wisc.edu/handbook/style/css_wordyphrases/">wordy phrases</a> until they’re no longer strangling your sentences. Phrases like “The fact that” and “for the purpose of” are red flags. Hopefully, you caught any stray instances of the passive voice in your verb tense scan, but if you didn’t, the wordiness of passive phrasing should set off alarm bells. “An excellent first draft was written by a Shut Up and Write! member” becomes “A Shut Up and Write! member wrote an excellent first draft.” Because you just did! Congratulations.'
      />
      <SuawParagraph
        text="This concludes our demonstration of the self-editing procedures that should prepare your draft for another set of eyes. Remember, it’s always important to edit your own work before sending it out into the world. Thanks for helping out your editor, and come back soon — your next draft awaits."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
