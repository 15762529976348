<script>
import Label from "../Label/Label.vue";
import Button from "../Button/Button.vue";

export default {
  name: "SectionHeader",
  components: { Label, Button },
  props: {
    headerLabel: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "View All",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <div class="suaw-section-header">
    <Label size="large" weight="bold" :label-text="headerLabel" />
    <Button
      v-if="showButton"
      class="suaw-section-header__header-button suaw-button--no-y-pd suaw-button--no-x-pd"
      size="hero"
      type="ghost"
      :button-text="buttonLabel"
      @click="$emit('view-all-click')"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-section-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__header-button {
    height: auto;
  }
}
</style>
