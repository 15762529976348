<script>
import Paragraph from "../Paragraph/Paragraph.vue";

export default {
  name: "SummaryModalJoinChapter",
  components: {
    Paragraph,
  },
  props: {
    chapterJoined: {
      type: Boolean,
    },
  },
  methods: {},
};
</script>

<template>
  <div class="suaw-modal__block">
    <template v-if="!chapterJoined">
      <Paragraph
        text="You will be notified weekly about chapter events and receive updates from organizers."
        size="small"
      />
      <Paragraph
        text="You can adjust notifications on your settings page."
        size="small"
      />
    </template>
    <Paragraph
      v-else
      text="You will no longer be notified of events and updates in this chapter. You can rejoin the chapter at any time."
      size="small"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-modal__block {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
}
</style>
