<script>
import { SuawList, SuawInputGroup, SuawLabel, SuawEmptyState } from "@/components";
import { GetOrganizersByIds } from "../../operations.gql";
import { ListItemItemFactory } from "@/utils/factories/listItemProps.js";

export default {
  name: "OrganizerSidebar",
  components: {
    SuawLabel,
    SuawList,
    SuawInputGroup,
    SuawEmptyState
  },
  props: {
    headerText: {
      type: String,
      required: true
    },
    organizerIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      resultGetOrganizersByIds: [
        // {
        //   id: "",
        //   display_name: "",
        //   avatar_url: "",
        //   avatar_color: ""
        //   is_avatar_hidden: false
        // }
      ]
    };
  },
  apollo: {
    resultGetOrganizersByIds: {
      query: GetOrganizersByIds,
      variables() {
        return {
          ids: this.organizerIds
        };
      },
      skip() {
        return !this.organizerIds.length;
      },
      fetchPolicy: "no-cache"
    }
  },
  computed: {
    listItems() {
      let factory = Object.create(ListItemItemFactory);
      return this.resultGetOrganizersByIds.map(org => {
        return factory
          .clean()
          .withUserObject(org)
          .withButton("Send Message", "IconComment")
          .build();
      });
    }
  },
  methods: {
    handleMessageClick(item) {
      const otherUserId = item.id;
      this.$root.$emit("open-direct-message", { otherUserId: otherUserId });
    }
  }
};
</script>

<template>
  <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
    <SuawLabel size="large" :label-text="headerText" weight="bold" />
    <SuawList v-if="listItems.length > 0" :items="listItems" @button-click="handleMessageClick" />
    <SuawEmptyState v-else size="medium" message="No Chapter Admins" />
  </SuawInputGroup>
</template>
