<script>
import Divider from '../Divider/Divider.vue';
import Button from '../Button/Button.vue';
import ListSection from '../List/ListSection.vue';
import Link from '../Link/Link.vue';
export default {
  name: "SideNav",
  components: { Divider, Button, ListSection, Link },
  props: {
    links: {
      type: Array,
    },
  },
};
</script>

<template>
  <div class="suaw-side-nav">
    <div class="suaw-side-nav__content">
      <div class="suaw-side-nav__list-container">
        <ListSection class="suaw-side-nav__list-container">
          <Link
            v-for="(link, index) in links"
            :key="index"
            :to="link.to"
            size="medium"
            weight="bold"
            icon="IconDot02S"
            icon-active="IconChevronRight"
          >
            {{ link.name }}
          </Link>
        </ListSection>
      </div>
      <Divider />
      <div class="suaw-side-nav__footer">
        <div class="suaw-sid-nav__button-container">
          <Button
            class="suaw-sid-nav__button"
            buttonText="Share"
            icon-right="IconShareOutline"
            size="small"
            type="secondary-outline"
            @click="$emit('click')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.suaw-side-nav {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--sem-color-border-regular);
  padding: var(--sem-space-triple);
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--date-square-shadow);

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
  }
}
</style>
