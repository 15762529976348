<script>
import CalendarDate from '../CalendarDate/CalendarDate.vue';
import Heading from '../Heading/Heading.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardEvent",
  components: {
    CalendarDate,
    Heading,
    Chip,
  },
  props: {
    eventHeading: {
      type: String,
    },
    eventMembers: {
      type: String,
    },
    eventLocation: {
      type: String,
    },
    eventStatus: {
      type: String,
    },
    eventMonth: {
      type: Number,
    },
    eventDay: {
      type: Number,
    },
    eventTime: {
      type: String,
    },
    eventTimezone: {
      type: String,
    },
    chipItems: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<template>
  <div class="suaw-card__content">
    <CalendarDate
      size="large"
      :status="eventStatus"
      :month="eventMonth"
      :day="eventDay"
      :time="eventTime"
      :timezone="eventTimezone"
    />
    <Heading
      level="5"
      :content="eventHeading"
    />
    <footer class="suaw-card__footer">
      <Chip
        v-for="chip in chipItems"
        :key="chip.icon"
        size="medium"
        :status="chip.status"
        :icon="chip.icon"
        :label="chip.label"
        :chipType="chip.type"
        :pill="chip.pill"
      />
      <slot name="chips"></slot>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content,
  &__footer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__content {
    gap: var(--sem-space-double);
    text-align: left;
  }

  &__footer {
    gap: var(--sem-space-base);
  }
}
</style>
