<script>
import { SuawMainContent, SuawForm, SuawSummary, SuawTextInput, SuawLocationInput, SuawFileUpload, SuawTextArea, SuawSearchInput } from "@/components";
import { GetChapterSlugById } from "../../operations.gql";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as ChapterApi from "../../api.js";
import { convertFileToBase64String } from "@/utils/formatting/files.js";

export default {
  name: "ChapterCreate",
  components: { SuawMainContent, SuawForm, SuawSummary, SuawTextInput, SuawLocationInput, SuawFileUpload, SuawTextArea, SuawSearchInput },
  data() {
    return {
      slug: undefined,
      chapterForm: {
        chapterId: undefined,
        address: undefined,
        title: undefined,
        description: undefined,
        descriptionJson: undefined,
        lat: undefined,
        lng: undefined,
        photoUrl: undefined,
        domains: ""
      }
    };
  },
  validations: {
    chapterForm: {
      title: { required },
      address: { required },
      descriptionJson: { required }
    }
  },
  apollo: {
    // GetChapterSlugById is called when this.chapterForm.chapterId is truthy
    slug: {
      query: GetChapterSlugById,
      variables() {
        return {
          id: this.chapterForm.chapterId
        };
      },
      skip() {
        return !this.chapterForm.chapterId;
      },
      update(data) {
        if (data && data.result) {
          return data.result.slug;
        }
        return "";
      }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    onPlaceSelected(place) {
      this.chapterForm.address = place.formatted_address;
      this.chapterForm.lat = place.geometry.location.lat();
      this.chapterForm.lng = place.geometry.location.lng();
    },
    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.chapterForm.photoUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    async createChapter() {
      const { descriptionJson, lat, lng, title } = this.chapterForm;
      const result = await ChapterApi.createChapter(descriptionJson, lat, lng, title);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async uploadChapterPhoto() {
      const { chapterId, photoUrl } = this.chapterForm;
      const result = await ChapterApi.uploadChapterPhoto(chapterId, photoUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async addChapterDomain(domain) {
      const { chapterId } = this.chapterForm;
      const result = await ChapterApi.addChapterDomain(chapterId, domain);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onCreateClicked() {
      this.$v.chapterForm.$touch();
      if (this.$v.chapterForm.$invalid) {
        this.$root.$emit("universal-error-message", "The chapter form is invalid.");
        return;
      }
      const createResult = await this.createChapter();
      if (!createResult.success) {
        return;
      }
      // GetChapterSlugById will be called when this.chapterForm.chapterId is assigned
      this.chapterForm.chapterId = createResult.result.id;
      this.$emit("created", this.chapterForm.chapterId);

      if (this.chapterForm.domains.length > 0) {
        this.chapterForm.domains.split(",").forEach(async domain => {
          let addResult = await this.addChapterDomain(domain.trim());
          if (!addResult.success) {
            this.$root.$emit("universal-error-message", addResult.error);
          }
        });
      }
      const uploadResult = await this.uploadChapterPhoto();
      if (!uploadResult.success) {
        return;
      }
      this.$router.push({
        name: "Chapter",
        params: {
          chapterId: this.chapterForm.chapterId,
          chapterSlug: this.slug
        }
      });
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawForm>
      <template #form>
        <SuawSummary summary-type="form" heading="Create New Chapter" button-label="Create" @click="onCreateClicked" />
        <SuawTextInput
          id="chapterName"
          v-model="chapterForm.title"
          type="text"
          label="Chapter Name"
          is-required
          placeholder="How the chapter will be displayed to the world."
          :state="formFieldState($v, 'chapterForm', 'title')"
          :error-message="validateErrors($v.chapterForm.title, 'Chapter Name')"
          @blur="$v.chapterForm.title.$touch()"
        />
        <SuawSearchInput
          v-model="chapterForm.address"
          :cities-only="true"
          placeholder="Enter a city"
          :is-required="true"
          :state="formFieldState($v, 'chapterForm', 'address')"
          :error-message="validateErrors($v.chapterForm.address, 'Chapter city')"
          :use-google="true"
          :show-icon="true"
          @blur="$v.chapterForm.address.$touch()"
          @item-select="onPlaceSelected"
        />
        <SuawLocationInput
          label="Region"
          :location-name="chapterForm.title"
          :location-address="chapterForm.address"
          :lat="chapterForm.lat"
          :lng="chapterForm.lng"
          :zoom="11"
          :editable="true"
        />
        <SuawFileUpload upload-title="Upload Chapter Image" @file-selected="convertFile" />
        <SuawTextArea id="chapterDesc" v-model="chapterForm.descriptionJson" use-tip-tap label="Description" placeholder="Describe the chapter" />
        <SuawTextInput
          v-model="chapterForm.domains"
          label="Email Domain(s)"
          placeholder="People in these domains (separated by commas) are automatically added to this chapter."
        />
      </template>
    </SuawForm>
  </SuawMainContent>
</template>
