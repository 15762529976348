<script>
import { SuawMainContent, SuawParagraph, SuawHeading, SuawBlockQuote } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "WhenShouldICallMyselfAWriter",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    SuawBlockQuote,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="WhenShouldICallMyselfAWriter">
      <SuawBlockQuote text="I am not a writer. I’ve been fooling myself and other people." />
      <SuawParagraph
        text="No, this isn’t me magically reading your mind. It’s actually something&nbsp;<a target='_blank' title='John Steinbeck had insecurities as a writer' href='https://www.entrepreneur.com/leadership/these-artists-authors-and-leaders-battled-self-doubt/304340'><u>John Steinbeck wrote in his journal in 1938</u></a> while he was struggling to draft&nbsp;<em>The Grapes of Wrath</em>."
      />
      <SuawParagraph
        text="John Steinbeck wasn’t the only writer who had a penchant for doubting his process. Famous writers from Edith Wharton to David Foster Wallace have struggled to see themselves as authentic writers."
      />
      <SuawParagraph
        text="In an article about her writing process,&nbsp;<a target='_blank' title='Edith Wharton also had insecurities as a writer' href='https://www.theatlantic.com/magazine/archive/1933/04/confessions-of-a-novelist/385504/'><u>published in The Atlantic in 1933</u></a>, Edith Wharton wrote, “Any attempt to analyze work of one’s own doing seems to imply that one regards it as likely to be of lasting interest, and I wish at the outset to repudiate any such assumption.” In other words, she didn’t see herself as someone capable of producing timeless work. This is ironic, considering many of her novels are still being taught in high school English classes today."
      />
      <SuawParagraph
        text="In 1995, decades after Wharton’s humble confession, as the publication date for his novel&nbsp;<em>Infinite Jest</em> drew near, David Foster Wallace wrote to his mentor Don DeLillo, “I have a lot of dread and terror and inadequacy-shit, now, when I’m trying to write.”"
      />
      <SuawParagraph
        text="So, rest assured that self-doubt is at least one thing you have in common with these giants of literature. But, happily, there’s one other thing you have in common with them: you, reader, are&nbsp;<em>also</em> a writer."
      />
      <SuawParagraph text="Because when do you go from “aspiring writer” to writer? The short answer is: Right now! If you write, you’re a writer. It’s as simple as that." />
      <SuawParagraph
        text="But keep in mind that being a writer is a lot like being anything else—a friend, a parent, a pianist. To earn these titles and then keep them, you have to practice being a friend, raising your kids, and playing the piano. Too much time away and the title begins to slip away like water held in your hands. As writers, we must continually practice our craft, and as regularly as possible."
      />
      <SuawParagraph
        text="If you’re struggling with actually writing, which is the one thing you need to do to call yourself a writer, don’t worry. Since our founder, Rennie Saunders, started Shut Up &amp; Write in 2008, we have helped tens of thousands of writers put pen to paper and hands to keyboard. Try the following tips to brush off those literary cobwebs and get going with your writing."
      />
      <SuawHeading level="3" content="Set Achievable Goals" />
      <SuawParagraph
        text="“Being a writer” can be an abstract concept. One way to make it more concrete is to set something called a SMART goal, a framework&nbsp;<a target='_blank' title='SMART framework for goal-setting' href='https://www.projectsmart.co.uk/smart-goals/brief-history-of-smart-goals.php'><u>created by consultant George T. Doran in 1981</u></a>. SMART is an acronym that stands for Specific, Measurable, Achievable, Realistic, and Timely. “I want to be a writer,” is the opposite of a SMART goal, because it is vague, hard to measure, and lacks a motivating deadline. Here are some examples of good SMART goals that you can set for your writing:"
      />
      <SuawParagraph
        text="<ul><li><div>I will finish the first draft of Chapter 1 of my novel within the next two weeks.</div></li><li><div>I will outline my memoir, chapter by chapter, by the end of April.</div></li><li><div>I will write raw material for my blog for half an hour every day before my workday starts.</div></li></ul>"
      />
      <SuawParagraph
        text="Note the time component in each of the above goals, as well as how realistic they are for a writer who is busy with a day job and a personal life. If you’re going to get anything done, it’s crucial to balance ambition with clear-eyed time management. No use getting worked up over not having a best-selling debut novel by the end of the week!"
      />
      <SuawParagraph
        text="We writers have to proceed one step at a time, just like anyone else. But once you finish that first chapter, then the next, and so on, you will someday be able to behold your entire manuscript and think to yourself, “Wow,&nbsp;<em>I</em> wrote all this.” I’ve had the pleasure of celebrating this exact moment three times in my journey as a poet and a novelist, and though my manuscripts now need editing, the incredible rush of being done with the first draft always spurs me to keep on keeping on."
      />
      <SuawHeading level="3" content="Make Writing a Habit, Not a Choice" />
      <SuawParagraph
        text="Whether you are new to writing or a seasoned veteran, many things can dissuade you from getting your word count in by the time you set out to do so. Meetings can run late, or the rain might keep you from going on your morning run, throwing off your schedule. Life happens! But if you really want to be a writer, you have to find a way to&nbsp;<a title='Make writing a habit, not a choice' href='/articles/make-writing-a-habit-not-a-choice'><u>make writing a habit, not a choice</u></a>."
      />
      <SuawParagraph
        text="Remove the possibility of not writing altogether by channeling your innate creativity into inventive solutions, like Vladimir Nabokov did. As Sarah Stodola reports in her book,&nbsp;<em>Process: The Writing Lives of Great Authors</em>:"
      />
      <SuawBlockQuote
        text="Nabokov became flexible in his writing routine because he had to in order to accommodate his irregular working life, but also because it suited his temperament. During a spell of time unencumbered by outside work, he might write all night, then not rise until noon. Or he might rise early to get started, such as during an extended holiday in the South of France, when he wrote his Russian novel&nbsp;<em>The Gift</em> from 7 a.m. to 10 a.m. every morning, then took a break for the beach and lunch, then resumed writing from 3 p.m. to 11:30 p.m. At other times in Berlin, he worked during the day around tutoring or caring for his young son, then wrote into the night."
      />
      <SuawParagraph
        text="Mimicking the greats can bring you closer to your own potential as a writer. So, make writing a habit, not a choice, and make a commitment to your own work."
      />
      <SuawHeading level="3" content="Deal With Your Inner Critic" />
      <SuawParagraph
        text="Some of us fall on the more introverted, self-reflective spectrum of human nature, and though self-reflection has its benefits, it can also lead to harsh criticism of our own work."
      />
      <SuawParagraph
        text="All writers have an inner critic, some more scathing than others. To keep making progress despite that nagging inner voice, try&nbsp;<a href='/articles/five-ways-to-silence-your-inner-critic-while-drafting'><u>some concrete ways to deal with your inner critic</u></a>. Give them a goofy name or schedule a weekly meeting to hear them out. Whatever you do, don’t let your inner critic hold you back from being who you are: a writer who actually writes."
      />
      <SuawHeading level="3" content="Try New Ideas" />
      <SuawParagraph
        text="For writers, the only thing as common as carpal tunnel is writer’s block. It’s normal to feel stuck in your process sometimes, and there’s nothing wrong with that. If you’ve run out of steam,&nbsp;<a href='/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas'><u>try some new writing techniques</u></a> to get your groove back. Write in a new place, like a coffee shop or an art museum, or mine your dreams for inspiration. With just a little bit of curiosity and sense of adventure, you can shake off that stale, empty feeling and charge forward into new frontiers."
      />
      <SuawHeading level="3" content="Lean On Your Community" />
      <SuawParagraph
        text="Last, but definitely not least, know that you are not alone. Get to know this fact as deeply as possible by surrounding yourself with your own kind—other writers who are working on their own literary goals. You can do this by attending a Shut Up &amp; Write event&nbsp;<a href='/in-person-events'><u>in person</u></a> if you’re a fan of working at your local cafe, or&nbsp;<a href='/online-events'><u>online</u></a> from the comfort of your own home."
      />
      <SuawParagraph text="So, what are you waiting for? RSVP to an event, get some writing done, and start calling yourself a writer, because now, you are one!" />
    </ArticleFrame>
  </SuawMainContent>
</template>
