<script>
import { SuawForm, SuawTextInput, SuawButton, SuawLink } from "@/components";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, email } from "vuelidate/lib/validators";
import * as UserApi from "../../api.js";
export default {
  name: "LogInForm",
  components: { SuawForm, SuawTextInput, SuawButton, SuawLink },
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    loginForm: {
      email: { required, email },
      password: { required }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async onLogin() {
      this.$v.loginForm.$touch();
      if (this.$v.loginForm.$invalid) {
        this.$root.$emit("universal-error-message", "Invalid login form");
      } else {
        const loginByPasswordResult = await this.loginByPassword();
        if (loginByPasswordResult.success) {
          const { accessToken, refreshToken, user } = loginByPasswordResult.result.value || {};
          if (this.$route.query.redirectUrl) {
            this.$auth.refreshAccess({ accessToken, refreshToken, user, redirectLocation: this.$route.query.redirectUrl });
          } else {
            this.$auth.refreshAccess({ accessToken, refreshToken, user, redirectLocation: { name: "DashboardEvents" } });
          }
        }
      }
    },
    async loginByPassword() {
      const result = await UserApi.loginByPassword(this.loginForm.email, this.loginForm.password);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    }
  }
};
</script>

<template>
  <SuawForm @submit="onLogin">
    <template #form>
      <SuawTextInput
        id="email-login"
        v-model="loginForm.email"
        type="email"
        is-required
        label-weight="bold"
        label="Email"
        :state="formFieldState($v, 'loginForm', 'email')"
        :error-message="validateErrors($v.loginForm.email, 'Email')"
        @blur="$v.loginForm.email.$touch()"
      />
      <SuawTextInput
        id="password-login"
        v-model="loginForm.password"
        type="password"
        is-required
        label="Password"
        label-weight="bold"
        :state="formFieldState($v, 'loginForm', 'password')"
        :error-message="validateErrors($v.loginForm.password, 'Password')"
        @blur="$v.loginForm.password.$touch()"
      />
      <SuawButton size="large" type="primary" button-text="Log In" />
      <SuawLink to="/reset-password" alignment="center">Don’t remember your password?</SuawLink>
    </template>
  </SuawForm>
</template>
