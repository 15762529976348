<script>
import Chip from "../Chip/Chip.vue";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";

export default {
  name: 'CardUpdate',
  components: {
    Chip,
    Heading,
    Paragraph,
  },
  props: {
    headingText: {
      type: String,
    },
    text: {
      type: String,
    },
    posted: {
      type: String,
    },
    chipContent: {
      type: String,
    },
    chipText: {
      type: String,
    },
  },
};
</script>

<template>
  <div class="suaw-card__content">
    <Heading
      v-if="headingText"
      level="5"
      :content="headingText"
    />
    <Paragraph
      v-if="text"
      class="suaw-card__text"
      :text="text"
    />
    <footer
      v-if="chipContent || posted"
      class="suaw-card__footer"
    >
      <Chip
        v-if="chipText"
        size="medium"
        status="default"
        chipType="avatar"
        :avatarContent="chipContent"
        :label="chipText"
        avatarType="orange"
      />
      <span class="suaw-card__posted">{{ posted }}</span>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__footer {
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__posted {
    color: var(--sem-color-text-regular);
  }
}
</style>
