<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "AfterTheFirstDraftProTipsForEditingYourWork",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="AfterTheFirstDraftProTipsForEditingYourWork">
      <SuawParagraph
        text="So you’ve finished your book. Let’s just take a moment to contemplate and revel in what a huge thing you’ve accomplished. Lots of people talk about writing books but a lot fewer actually get it done. So, well done, you! Now it’s time to take it to the next level: editing your work."
      />
      <SuawParagraph
        text="If this is your first full draft, take that massive pile of words and set it aside for a while—maybe a month. Think of it as making bread: You’ve made the dough but now you have to let it sit for a time so it can rise. It gives you a little respite from the story and allows you to re-establish a bit of perspective."
      />
      <SuawParagraph
        text="If you’re thinking this is the time to hand it over to an editor (or even beta readers), think again. You owe it to yourself to make another pass through the manuscript before anyone else sees it. So swallow hard and read it through, beginning to end, and fix the problems that are obvious to you. Why? Because you’ll feel dumb at having paid an editor to point out things you would have known anyway if only you’d taken the time, and you’ll find your beta readers will be mysteriously unavailable to read your next project if they feel that you’re handing them a sloppy manuscript."
      />
      <SuawParagraph text="Evaluating your own book, however, isn’t an easy job. Here are a few tips to make your editing easier—or at least more effective." />
      <SuawHeading level="3" content="Editing your fiction, memoirs, and narrative nonfiction:" />
      <SuawHeading level="4" content="Begin at the beginning." />
      <SuawParagraph
        text="One of the most common problems in first novel drafts is starting the manuscript well before the story really gets going. Ask yourself if the first 25-50 pages are really necessary to the reader or were they just necessary to you, the writer. If the reader doesn’t really need that information, take the pulse on where things really get interesting and figure out how to start the story there. The parts you cut may fit better somewhere else in the story, but more likely, they should get filed in your “Backstory” file."
      />
      <SuawHeading level="4" content="Check the timing and pacing." />
      <SuawParagraph
        text="If you had a story timeline that you were working from, check the manuscript against it. If you were “pantsing” your way through the first draft, draw up a timeline from what you’ve written and make sure it makes sense. Use the timeline not only to check the logical flow of the story but also that your chapter breaks occur at the best places."
      />
      <SuawHeading level="4" content="Assemble your cast of characters." />
      <SuawParagraph
        text="List all your characters, either in order of appearance or importance, and jot down the essential details. Have you kept names and physical characteristics consistent or does your leading character have blue eyes in one chapter and green eyes in another? Are your primary characters actually showing growth or change over the course of the story?"
      />
      <SuawHeading level="4" content="Spell-check!" />
      <SuawParagraph text="Do I really have to tell you this?" />
      <SuawHeading level="3" content="Editing your general nonfiction:" />
      <SuawHeading level="4" content="Check your outline/table of contents." />
      <SuawParagraph
        text="Create a multi-level table of contents (TOC) of your manuscript (you can do this automatically in MS Word) and check that you have covered your topics and sub-topics fully and delivered on the overall premise or intention of the book."
      />
      <SuawHeading level="4" content="Cite references." />
      <SuawParagraph
        text="You give your book credibility by accurately citing the works that have inspired or added to your text. How you cite them depends on who your target audience is. Academic, scientific, or professional audiences expect full citations, footnotes and bibliographies that follow the appropriate style. General audiences typically want accurate but more casually worded references woven into the text, possibly with a bibliography at the end for further reading."
      />
      <SuawHeading level="4" content="Test the readability." />
      <SuawParagraph
        text="It helps to know that you’re writing at a level that your target audience will easily understand. You can run a test to determine the grade level and/or reading ease of your text by doing a grammar check in MS Word with the check box labeled “Show readability statistics” checked."
      />
      <SuawHeading level="4" content="Run a plagiarism check." />
      <SuawParagraph
        text="If you’ve drawn on the works of others while writing your book, do yourself a favor and run your text through an online plagiarism checker. If you’ve been less than scrupulous in digesting and referring to other writers’ work in your manuscript, it’s best to fix it now before they find out about it. (Grammarly.com has a plagiarism checker but you have to have a premium account with them to access it; a Google search will direct you to other checkers, including those for scientific and scholarly works.)"
      />
      <SuawHeading level="4" content="Spell-check!" />
      <SuawParagraph text="You knew I was going to say this." />
      <SuawParagraph
        text="By taking these steps to review, evaluate, and improve your manuscript, you’ll be in a much better position to take advantage of the critiques of your beta readers or editor—and the extra care will show in your final book."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
