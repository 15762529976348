<script>
import AvatarTwo from "../Avatar/AvatarTwo.vue";
import Chip from "../Chip/Chip.vue";
import ChipTwo from "../Chip/ChipTwo.vue";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Button from "../Button/Button.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Select from "../Select/Select.vue";
import Icon from "../Icon/Icon.vue";
import Checkbox from "../Checkbox/Checkbox.vue";
export default {
  name: "SeriesMemberCard",
  components: {
    AvatarTwo,
    Chip,
    Heading,
    Paragraph,
    Button,
    Dropdown,
    Icon,
    Select,
    ChipTwo,
    Checkbox,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    isAvatarHidden: {
      type: Boolean,
      default: false
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String,
    },
    avatarInitials: {
      type: String,
    },
    displayName: {
      type: String,
    },
    location: {
      type: String,
      default: "",
    },
    isRsvped: {
      type: Boolean,
      default: true,
    },
    isRecentAttendee: {
      type: Boolean,
      default: false,
    },
    isInAttendance: {
      type: Boolean,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    isEventTime: {
      type: Boolean,
      default: false,
    },
    eventCount: {
      type: Number,
      default: 0,
    },
    postCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 650,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    userEvents() {
      return `${this.eventCount} Event${this.eventCount !== 1 ? "s" : ""}`;
    },
    userPosts() {
      return `${this.postCount} Post${this.postCount !== 1 ? "s" : ""}`;
    },
    showAttendanceCheckbox() {
      return this.isEventTime && this.isOrganizer;
    },
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 650;
    },
    handleCheckboxInput(event) {
      this.$emit("attendance-checkbox-click", { userId: this.userId, attended: event });
    },
  },
}
</script>

<template>
  <li class="suaw-series-member-card">
    <!-- Desktop content -->
    <div v-if="!isMobile" class="suaw-series-member-card__desktop-content">
      <div class="suaw-series-member-card__avatar-container">
        <AvatarTwo
          size="huge"
          :url="avatarUrl"
          :color="avatarColor"
          :initials="avatarInitials"
          :display-name="displayName"
          :is-avatar-hidden="isAvatarHidden"
          :is-deactivated="isDeactivated"
        />
      </div>
      <div class="suaw-series-member-card__name-container">
        <Button
          class="suaw-series-member-card__name-button suaw-button--no-y-pd suaw-button--no-x-pd"
          size="hero"
          type="ghost"
          :button-text="displayName"
          @click="$emit('profile-click', userId)"
        />
        <Paragraph class="suaw-series-member-card__location" :text="location" />
      </div>
      <div class="suaw-series-member-card__status-container">
        <div
          v-if="isRsvped"
          class="suaw-series-member-card__status-chip rsvp-chip"
        >
          <Icon
            class="suaw-series-member-card__rsvp-icon"
            icon="IconDownloadDone"
            color="green"
          />
          <Paragraph
            class="suaw-series-member-card__status rsvp-status"
            text="RSVP'd"
          />
        </div>
        <div
          v-if="!isRsvped && isRecentAttendee"
          class="suaw-series-member-card__status-chip attended-chip"
        >
          <Paragraph
            class="suaw-series-member-card__status attended-status"
            text="Recently Attended"
          />
        </div>
      </div>
      <div class="suaw-series-member-card__chips-container">
        <ChipTwo
          size="medium"
          type="icon"
          icon="IconCalendarCheck"
          icon-color="blue"
          :text="userEvents"
        />
        <ChipTwo
          size="medium"
          type="icon"
          icon="IconComment"
          icon-color="blue"
          :text="userPosts"
        />
      </div>
      <div
        v-if="showAttendanceCheckbox"
        class="suaw-series-member-card__attendance-container"
      >
        <Checkbox
          :value="isInAttendance"
          :disabled="isInAttendance"
          label="Attended"
          @input="handleCheckboxInput"
        />
      </div>
    </div>
    <!-- Mobile Content -->
    <div v-else class="suaw-series-member-card__mobile-content">
      <div class="suaw-series-member-card__avatar-container">
        <AvatarTwo
          size="large"
          :url="avatarUrl"
          :color="avatarColor"
          :initials="avatarInitials"
          :display-name="displayName"
          :is-avatar-hidden="isAvatarHidden"
          :is-deactivated="isDeactivated"
        />
      </div>
      <div class="suaw-series-member-card__name-container">
        <Button
          class="suaw-series-member-card__name-button suaw-button--no-y-pd suaw-button--no-x-pd"
          size="medium"
          type="ghost"
          :button-text="displayName"
          @click="$emit('profile-click', userId)"
        />
        <Paragraph class="suaw-series-member-card__location" :text="location" />
      </div>
      <div class="suaw-series-member-card__status-container">
        <div
          v-if="isRsvped"
          class="suaw-series-member-card__status-chip rsvp-chip"
        >
          <Icon
            class="suaw-series-member-card__rsvp-icon"
            icon="IconDownloadDone"
            color="green"
          />
          <Paragraph
            class="suaw-series-member-card__status rsvp-status"
            text="RSVP'd"
          />
        </div>
        <div
          v-if="!isRsvped && isRecentAttendee"
          class="suaw-series-member-card__status-chip attended-chip"
        >
          <Paragraph
            class="suaw-series-member-card__status attended-status"
            text="Recently Attended"
          />
        </div>
      </div>
      <div class="suaw-series-member-card__chips-container">
        <ChipTwo
          size="small"
          type="icon"
          icon="IconCalendarCheck"
          icon-color="blue"
          :text="userEvents"
        />
        <ChipTwo
          size="small"
          type="icon"
          icon="IconComment"
          icon-color="blue"
          :text="userPosts"
        />
      </div>
      <div
        v-if="showAttendanceCheckbox"
        class="suaw-series-member-card__attendance-container"
      >
        <Checkbox
          size="small"
          :value="isInAttendance"
          :disabled="isInAttendance"
          label="Attended"
          @input="handleCheckboxInput"
        />
      </div>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.suaw-series-member-card {
  list-style-type: none;
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-double);
  width: 100%;
  max-width: 1264px;
  @media (max-width: 650px) {
    box-shadow: var(--date-square-shadow);
    min-height: 74px;
    padding: 8px;
  }
  &__desktop-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    gap: 16px;
    @media (max-width: 650px) {
      grid-template-columns: auto 1fr;
    }
  }
  &__mobile-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    column-gap: 12px;
  }
  &__avatar-container {
    grid-column: 1/2;
    grid-row: 1/3;
    display: flex;
  }
  &__name-container {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: end;
    .suaw-series-member-card__name-button {
      height: auto;
    }
    .suaw-series-member-card__location {
      color: #7e7f88;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      @media (max-width: 650px) {
        font-size: 12px;
      }
      @media (max-width: 425px) {
        display: none;
      }
    }
    @media (max-width: 650px) {
      grid-column: 2/4;
      align-self: center;
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__status-container {
    grid-column: 3/4;
    grid-row: 1/2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  &__chips-container {
    grid-column: 2/3;
    grid-row: 2/3;
    display: inline-flex;
    gap: 8px;
    @media (max-width: 650px) {
      align-self: center;
      flex-wrap: wrap;
    }
  }
  &__attendance-container {
    grid-column: 3/4;
    grid-row: 2/3;
    margin-top: 4px;
    justify-self: end;
    @media (max-width: 650px) {
      margin-top: 2px;
      align-self: center;
    }
  }
  &__status-chip {
    display: flex;
    padding: 4px 6px;
    border-radius: 4px;
    @media (max-width: 525px) {
      padding: 2px;
    }
  }
  .rsvp-chip {
    background: #eafeef;
  }
  .attended-chip {
    background: var(--sem-color-info-light);
    @media (max-width: 525px) {
      display: none;
    }
  }
  &__status {
    font-family: var(--body-bold-is-size-7-font-family);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.12px;
    line-height: var(--body-bold-is-size-7-line-height);
  }
  .rsvp-status {
    color: var(--sem-color-success-medium);

    @media (max-width: 525px) {
      display: none;
    }
  }
  .attended-status {
    color: var(--sem-color-info-medium);
    @media (max-width: 525px) {
      display: none;
    }
  }
}
</style>
