<script>
import CalendarDate from '../CalendarDate/CalendarDate.vue';
import Chip from "../Chip/Chip.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Heading from "../Heading/Heading.vue";
import Link from "../Link/Link.vue";

export default {
  name: 'EventListItem',
  components: {
    CalendarDate,
    Chip,
    Heading,
    Paragraph,
    Link,
  },
  props: {
    size: {
      type: String,
      default: "micro",
      validator: function (value) {
        return [
          "micro",
          "medium",
        ].indexOf(value) !== -1;
      },
    },
    item: {
      type: Object,
      default: () => ({})
    },
    itemClickable: {
      type: Boolean,
      default: true,
    },
    className: {
      Type: String,
    },
  },
  data() {
    return {
      index: 0 // Initialize index with a default value
    };
  },
  computed: {
    eventItemClasses() {
      return {
        'suaw-event-list-item__holder--micro': this.size == 'micro',
        'suaw-event-list-item__holder--medium': this.size == 'medium'
      }
    },
    eventContentClasses() {
      return this.size == 'micro' ? '' : 'suaw-event-list-item__content--medium'
    },
    calendarSize() {
      return this.size == 'micro' ? 'micro' : 'medium'
    },
    textSize() {
      return this.size == 'micro' ? 'Paragraph' : 'Heading'
    },
    shouldDisplayPill() {
      return this.size === 'micro' ? null : this.item.pillContent;
    },
    chipRSVPLabels() {
      return () => {
        if (this.item.eventCancelled) {
          return "Cancelled";
        } else {
          return this.item.RSVPed ? "RSVPed" : "RSVP";
        }
      };
    },
    chipRSVPIcons() {
      return () => {
        if (this.item.eventCancelled) {
          return "";
        } else {
          return this.item.RSVPed ? "IconDownloadDone" : "";
        }
      };
    },
    chipRSVPStatus() {
      return () => this.item.eventCancelled ? 'critical' : 'success';
    },
    chipRSVPClasses() {
      return () => this.item.eventCancelled ? 'suaw-chip--no-click' : 'suaw-chip--clickable';
    },
  },
  methods: {
    toggleRSVP() {
      this.$emit("toggle-rsvp", this.item);
    },
    itemClicked() {
      if (this.itemClickable) {
        this.$emit("item-click", this.item);
      }
    },
  },
};
</script>

<template>
  <li 
    class="suaw-event-list-item__holder"
    :class="[eventItemClasses, className]"
    @click="itemClicked"
  >
    <div
      v-if="item.eventDay && item.eventMonth"
      class="suaw-event-list-item__preview"
    >
      <CalendarDate
        :size="item.calendarSize || calendarSize"
        :status="item.eventStatus"
        :month="item.eventMonth"
        :day="item.eventDay"
        :time="item.eventTime"
      />
    </div>
    <div
      class="suaw-event-list-item__content"
      :class="eventContentClasses"
    >
      <component
        v-if="item.text"
        :is="textSize"
        size="small"
        weight="bold"
        level="4"
        :text="item.text"
        :content="item.text"
      />
      <div
        v-if="size !== 'micro'"
        class="suaw-event-list-item__row"
      >
        <Chip
          v-if="item.eventLocation"
          size="small"
          status="default"
          icon="IconLocation"
          :label="item.eventLocation"
          chipType="icon"
        />
        <Chip
          v-if="item.eventDuration"
          size="small"
          status="default"
          icon="IconClock"
          :label="item.eventDuration"
          chipType="icon"
          :pill="shouldDisplayPill"
        />
      </div>
      <template v-if="size === 'micro'">
        <Chip
          size="small"
          :status="chipRSVPStatus(item)"
          shape="square"
          :icon="chipRSVPIcons(item)"
          :label="chipRSVPLabels(item)"
          chipType="icon"
          :key="item.id ? item.id : index"
          class="suaw-chip--no-click"
        />
      </template>
      <div
        v-if="size === 'medium'"
        class="suaw-event-list-item__row"
      >
        <Chip
          v-if="item.userName"
          size="small"
          status="default"
          :avatarContent="item.avatarContent"
          :avatarType="item.avatarType"
          :label="item.userName"
          chipType="avatar"
        />
        <Chip
          v-if="item.chapterName"
          size="small"
          status="info"
          icon="IconUser"
          :label="item.chapterName"
          chipType="icon"
        />
      </div>
    </div>
    <div
      v-if="size === 'medium'"
      class="suaw-event-list-item__chip"
    >
      <Chip
        v-if="item.RSVPed || item.eventCancelled"
        size="small"
        :status="chipRSVPStatus(item)"
        shape="square"
        :icon="chipRSVPIcons(item)"
        :label="chipRSVPLabels(item)"
        chipType="icon"
        :key="item.id ? item.id : index"
        class="suaw-chip--no-click"
      />
    </div>
  </li>
</template>

<style lang="scss" scoped>
.suaw-event-list-item {
  &__holder {
    align-items: baseline;
    display: flex;
    gap: var(--sem-space-base);
    transition: all .25s linear;
    will-change: background-color, box-shadow, transform;

    .suaw-event-list-item__holder--medium & {
      gap: var(--sem-space-double);
    }
  }

  &__holder:not(.is-active):hover {
    cursor: pointer;
  }

  &__holder--micro {
    padding: var(--sem-space-base);
  }

  &__holder--micro:not(.is-active):hover {
    transform: translateX(1%);
  }

  &__holder--medium {
    background-color: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-sm);
    box-shadow: var(--date-square-shadow);
    margin-top: var(--sem-space-double);
    margin-bottom: var(--sem-space-triple);
    padding: var(--sem-space-double);
  }

  &__holder--medium:last-of-type {
    margin-bottom: var(--sem-space-base);
  }

  &__holder--medium:hover {
    box-shadow: var(--shadow-2);
    transform: scale(0.99);
  }

  &__holder:not(:last-child) {
    border-bottom: 1px solid var(--sem-color-border-regular);
  }

  &__holder.is-active {
    background-color: var(--sem-color-neutral-light);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__content:not(.suaw-event-list-item__content--medium) {
    gap: var(--sem-space-quarter);
  }

  &__content--medium {
    align-items: flex-start;
    gap: var(--sem-space-base);
    margin: auto 0;
  }

  &__row {
    gap: var(--sem-space-base);
    display: flex;
    flex-wrap: wrap;
  }

  &__chip {
    margin-left: auto;
  }
}
</style>
