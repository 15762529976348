<script>
import Card from '../Card/Card.vue';
import Heading from '../Heading/Heading.vue';
import Button from '../Button/Button.vue';
import Link from '../Link/Link.vue';
import Divider from '../Divider/Divider.vue';

export default {
  name: 'CarouselColumns',
  components: {
    Heading,
    Button,
    Link,
    Divider,
    Card,
  },
  props: {
    carouselHeading: {
      type: String,
    },
    cards: {
      type: Array,
      default: () => []
    },
    cardsPerPage: {
      type: Number,
      default: 4,
    },
    cardType: {
      type: String,
      validator: function (value) {
        return [
          "CardChapterExpanded",
          "CardEventExpanded",
          "CardOnlineEvent",
          "CardChapter",
          "CardArticle",
          "CardArticleExpanded",
        ].indexOf(value) !== -1;
      },
    },
    linkContent: {
      type: String,
    },
    linkText: {
      type: String,
    },
  },
  data() {
    return {
      currentIndex: 0,
      translateValue: 0,
    };
  },
  watch: {
    cards: {
      handler() {
        this.$nextTick(() => {
          this.updateTranslateValue();
        });
      },
      deep: true,
    },
    currentIndex() {
      this.updateTranslateValue();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateTranslateValue();
    });
  },
  computed: {
    isPrevDisabled() {
      return this.currentIndex === 0;
    },
    isNextDisabled() {
      const lastIndex = this.cards.length - this.cardsPerPage;
      return this.currentIndex >= lastIndex;
    },
    cardWidth() {
      const firstCard = this.$el.querySelector(".suaw-card");
      return firstCard ? firstCard.offsetWidth : 0;
    },
  },
  methods: {
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateTranslateValue();
      }
    },
    next() {
      const lastIndex = this.cards.length - this.cardsPerPage;
      if (this.currentIndex < lastIndex) {
        this.currentIndex++;
        this.updateTranslateValue();
      }
    },
    updateTranslateValue() {
      this.$nextTick(() => {
        this.translateValue = -1 * (this.cardWidth + 32) * this.currentIndex;
      });
    },
  },
};
</script>

<template>
  <div class="suaw-carousel-cards">
    <div class="suaw-carousel-cards__row">
      <Heading
        level="3"
        :content="carouselHeading"
      />
      <nav class="suaw-carousel-cards__nav">
        <Button
          class="suaw-button--no-x-pd"
          size="large"
          type="ghost"
          iconLeft="IconCircleChevronLeft"
          buttonText="Prev"
          :disabled="isPrevDisabled"
          :aria-disabled="isPrevDisabled"
          @click="prev"
        />
        <Button
          class="suaw-button--no-x-pd"
          size="large"
          type="ghost"
          iconRight="IconCircleChevronRight"
          buttonText="Next"
          :disabled="isNextDisabled"
          :aria-disabled="isNextDisabled"
          @click="next"
        />
        <template v-if="linkContent && linkText">
          <Divider
            color="medium"
            type="inner"
            orientation="vertical"
          />
          <Link
            :to="linkContent"
            size="medium"
            weight="bold"
            class="suaw-carousel-cards__link"
          >{{ linkText }}</Link>
        </template>
      </nav>
    </div>
    <div class="suaw-carousel-cards__container">
      <div
        class="suaw-carousel-cards__holder"
        :style="{ transform: `translateX(${translateValue}px)` }"
      >
        <slot name="cards" />
        <Card
          v-for="(card, index) in cards"
          :key="card.id || index"
          :cardType="cardType"
          :id="card.id"
          :thumbnail="card.thumbnail"
          :avatarType="card.avatarType"
          :avatarContent="card.avatarContent"
          :posted="card.posted"
          :route="card.route"
          :author="card.author"
          :heading="card.heading"
          :description="card.description"
          :location="card.location"
          :members="card.members"
          :distance="card.distance"
          :chipItems="card.chipItems"
          :calendarDate="card.calendarDate"
          :upcomingEvents="card.upcomingEvents"
          :activeSince="card.activeSince"
          @click="$emit('click', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-carousel-cards {
  text-align: center;
  width: 100%;

  &__nav,
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    gap: var(--sem-space-double);
  }

  &__container {
    max-width: 100%;
    overflow: hidden;
  }

  &__holder {
    display: flex;
    gap: var(--sem-space-quadruple);
    padding: var(--sem-space-triple) 0;
    transition: transform 0.3s ease-in-out;
  }
}
</style>
