<script>
import { SuawInputGroup, SuawFilterBar, SuawListSection, SuawChapterEventCard, SuawEmptyState, SuawCalendarDropdown } from "@/components";
import { GetUserOnlineEventsRsvpStatus, UpcomingEvents, UpcomingEventDates } from "../../operations.gql";
import { add, startOfDay } from "date-fns";
export default {
  name: "OnlineEventsListPipe",
  components: { SuawInputGroup, SuawFilterBar, SuawListSection, SuawChapterEventCard, SuawEmptyState, SuawCalendarDropdown },
  props: {
    chapterId: {
      type: String,
      required: true
    },
    initialSelectedDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      initialLoadUpcomingEvents: true,
      loadingUpcomingEventsQueriesCount: 0,
      eventDates: [],
      selectedEventDate: this.initialSelectedDate || this.setToUserLocalMidnightUtc(),
      selectedTimeOfDay: "All",
      resultUpcomingEvents: {
        value: [
          // {
          //   id: "",
          //   event_series_id: "",
          //   is_virtual: null,
          //   is_manifested: null,
          //   starts_at: "",
          //   is_global: null,
          //   is_cancelled: null,
          //   ends_at: "",
          //   venue_json_physical_title: null,
          //   event_series_slug: "",
          //   event_series_title: "",
          //   event_series_organizers: [
          //     {
          //       id: "",
          //       display_name: "",
          //       initials: "",
          //       avatar_url: "",
          //       avatar_color: "",
          //       is_avatar_hidden: null
          //     }
          //   ],
          //   chapter_slug: "",
          //   chapter_id: ""
          // }
        ],
        errors: [
          // {
          //   hierarchy: "",
          //   exceptionType: "",
          //   reason: "",
          //   message: ""
          // }
        ]
      },
      resultGetUserOnlineEventsRsvpStatus: [
        // {
        //   id: "",
        //   event_occurrence_id: ""
        // }
      ],
      filterList: [
        {
          label: "Time of Day:",
          dropdownItems: [
            { text: "All", filter: "Time of Day" },
            { text: "Morning", filter: "Time of Day" },
            { text: "Afternoon", filter: "Time of Day" },
            { text: "Evening", filter: "Time of Day" }
          ]
        }
      ]
    };
  },
  apollo: {
    resultUpcomingEventDates: {
      query: UpcomingEventDates,
      variables() {
        return {
          chapterId: this.chapterId,
          timeZoneId: this.userTimeZone,
          asOfDate: this.setToUserLocalMidnightUtc()
        };
      },
      update(data) {
        // Set the initial selected date to the first event date
        if (data.resultUpcomingEventDates.value.length > 0) {
          this.eventDates = data.resultUpcomingEventDates.value;
        }
      },
      skip() {
        return !this.chapterId;
      }
    },
    resultUpcomingEvents: {
      query: UpcomingEvents,
      variables() {
        const asOfDate = this.selectedEventDate;
        const untilDate = this.calculateUntilDate(this.selectedEventDate);
        return {
          chapterId: this.chapterId,
          asOfDate,
          untilDate
        };
      },
      skip() {
        return !this.chapterId || !this.selectedEventDate;
      },
      result() {
        this.initialLoadUpcomingEvents = false;
      },
      loadingKey: "loadingUpcomingEventsQueriesCount"
    },
    resultGetUserOnlineEventsRsvpStatus: {
      query: GetUserOnlineEventsRsvpStatus,
      variables() {
        return {
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoadingUpcomingEvents() {
      return this.loadingUpcomingEventsQueriesCount > 0;
    },
    hasEvents() {
      return this.resultUpcomingEvents?.value?.length > 0;
    },
    hasEventDates() {
      return this.eventDates.length > 0;
    },
    events() {
      return this.hasEvents ? this.resultUpcomingEvents?.value : [];
    },
    userTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/Universal";
    },
    hasRsvpStatus() {
      return this.resultGetUserOnlineEventsRsvpStatus.length > 0;
    },
    rsvpedEventIds() {
      return this.hasRsvpStatus ? this.resultGetUserOnlineEventsRsvpStatus.map(rsvp => rsvp.event_occurrence_id) : null;
    },
    formattedSelectedDate() {
      if (this.selectedEventDate) {
        const [year, month, day] = this.selectedEventDate.split("T")[0].split("-");
        return `Date: ${month}-${day}-${year}`;
      }
      return "Date";
    },
    filteredEvents() {
      return this.filterEvents();
    }
  },
  methods: {
    setToUserLocalMidnightUtc(date = new Date()) {
      return startOfDay(date).toISOString();
    },
    calculateUntilDate(isoString) {
      const date = new Date(isoString);
      const updatedDate = add(date, { hours: 24 });
      return updatedDate.toISOString();
    },
    filterEvents() {
      if (this.events?.length <= 0) return [];
      const filtered = this.events
        .filter(event => !event.is_cancelled) // Exclude cancelled events
        .filter(event => {
          if (this.selectedTimeOfDay === "All") return true; // Show all events by default
          const eventHour = new Date(event.starts_at).getHours();
          if (this.selectedTimeOfDay === "Morning") {
            return eventHour >= 0 && eventHour < 12;
          } else if (this.selectedTimeOfDay === "Afternoon") {
            return eventHour >= 12 && eventHour < 18;
          } else if (this.selectedTimeOfDay === "Evening") {
            return eventHour >= 18 && eventHour < 24;
          }
          return true;
        });

      return this.transformEvents(filtered); // Transform the filtered events before displaying
    },
    transformEvents(events) {
      return events.map(event => {
        const startsAtDate = new Date(event.starts_at);
        const endsAtDate = new Date(event.ends_at);

        const timeOptionsWithSpace = { hour: "numeric", minute: "numeric", hour12: true };
        const startTime = startsAtDate.toLocaleTimeString([], timeOptionsWithSpace).replace(/([AP]M)/, match => match.toLowerCase());

        const startTimeNoSpace = startTime.replace(/ /g, "").toLowerCase();
        const endTimeNoSpace = endsAtDate
          .toLocaleTimeString([], timeOptionsWithSpace)
          .replace(/ /g, "")
          .toLowerCase();
        const duration = `${startTimeNoSpace} - ${endTimeNoSpace}`;
        const status = event.is_cancelled ? "cancelled" : new Date(event.ends_at) < new Date() ? "past" : event.is_virtual ? "online" : "in-person";
        const organizers = event.event_series_organizers.map(org => ({
          id: org.id,
          avatar_url: org.avatar_url,
          avatar_color: org.avatar_color,
          initials: org.initials,
          display_name: org.display_name,
          is_avatar_hidden: org.is_avatar_hidden
        }));
        return {
          id: event.id,
          title: event.event_series_title,
          status,
          month: startsAtDate.getMonth() + 1,
          day: startsAtDate.getDate(),
          isVirtual: event.is_virtual,
          isManifested: event.is_manifested,
          seriesId: event.event_series_id,
          startTime,
          startsAt: event.starts_at,
          endsAt: endsAtDate,
          duration,
          location: "Online Event",
          organizers,
          rsvped: this.rsvpedEventIds?.includes(event.id) ?? false,
          seriesSlug: event.event_series_slug,
          chapterSlug: event.chapter_slug
        };
      });
    },
    onDateSelected(selectedDate) {
      this.selectedEventDate = selectedDate; // Setting this triggers reactivity in `filteredEvents`
      this.$router.replace({
        query: {
          ...this.$route.query,
          selectedDate: selectedDate
        }
      });
    },
    onFilterChange({ text, filter }) {
      if (filter === "Time of Day") {
        this.selectedTimeOfDay = text; // Setting this triggers reactivity in `filteredEvents`
      }
    },
    onCardClick(card) {
      const query = !card.isManifested ? { m: "false", d: card.startsAt, s: card.seriesId } : {};
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: card.chapterSlug,
          eventId: card.id,
          seriesSlug: card.seriesSlug
        },
        query
      });
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="double">
    <SuawFilterBar :filter-list="filterList" @item-click="onFilterChange">
      <template #filters>
        <SuawCalendarDropdown
          v-if="hasEventDates"
          :label="formattedSelectedDate"
          :event-dates="eventDates"
          :selected-date="selectedEventDate"
          :show-footer="false"
          @date-selected="onDateSelected"
        />
      </template>
    </SuawFilterBar>
    <SuawEmptyState v-if="initialLoadUpcomingEvents" message="Loading Online Events..." />
    <SuawEmptyState v-else-if="isLoadingUpcomingEvents" message="Loading Online Events..." />
    <SuawEmptyState v-else-if="!initialLoadUpcomingEvents && (!hasEvents || filteredEvents.length === 0)" message="There are no events for the selected day and time." />
    <SuawListSection v-else>
      <SuawChapterEventCard
        v-for="event in filteredEvents"
        :id="event.id"
        :key="event.id"
        :title="event.title"
        :status="event.status"
        :month="event.month"
        :day="event.day"
        :start-time="event.startTime"
        :is-virtual="event.isVirtual"
        :duration="event.duration"
        :location="event.location"
        :organizers="event.organizers"
        :is-rsvped="event.rsvped"
        :series-slug="event.seriesSlug"
        :chapter-slug="event.chapterSlug"
        @click="onCardClick(event)"
      />
    </SuawListSection>
  </SuawInputGroup>
</template>
