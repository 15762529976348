<script>
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import Thumbnail from '../Thumbnail/Thumbnail.vue';

export default {
  name: "Callout",
  components: {
    Heading,
    Paragraph,
    Thumbnail,
  },
  props: {
    layout: {
      type: String,
      default: 'center',
      validator: function (value) {
        return ["center", "split"].indexOf(value) !== -1;
      },
    },
    calloutImage: {
      type: String,
    },
    imageLeft: {
      type: Boolean,
      default: true
    },
    hasActions: {
      type: Boolean,
      default: true,
    },
    calloutHeading: {
      type: String,
    },
    calloutDesc: {
      type: String,
    },
  },
  computed: {
    reverseClass() {
      return {
        'suaw-callout--reverse': this.imageLeft === false
      }
    }
  }
}
</script>

<template>
  <div 
    class="suaw-callout"
    :class="[
      'suaw-callout--' + layout,
      reverseClass
    ]"
  >
    <div
      v-if="calloutImage && layout === 'center'"
      class="suaw-callout__image"
    >
      <Thumbnail
        size="fill"
        :content="calloutImage"
        class="suaw-callout__thumbnail"
      />
    </div>
    <div 
      class="suaw-callout__holder"
      :class="'suaw-callout__holder--' + layout"
    >
      <header
        class="suaw-callout__header"
        :class="'suaw-callout__header--' + layout"
      >
        <Heading
          v-if="calloutHeading"
          level="3"
          :content="calloutHeading"
        />
        <Paragraph
          v-if="calloutDesc"
          weight="normal"
          size="medium"
          :text="calloutDesc"
        />
      </header>
      <footer
        v-if="$slots.actions && hasActions"
        class="suaw-callout__actions"
        :class="'suaw-callout__actions--' + layout"
      >
        <slot name="actions"></slot>
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-callout {
  background-color: var(--sem-color-background-lightest);
  border-radius: var(--sem-radius-md);
  box-shadow: var(--date-square-shadow);
  margin: auto;
  max-width: 1264px;
  overflow: hidden;
  width: 100%;

  &--reverse {
    flex-direction: row-reverse;
  }

  &,
  &__holder--split {
    @media screen and (max-width: 680px) {
      flex-direction: column;
    }
  }

  &--center {
    padding: var(--sem-space-octuple);
  }

  &--split {
    padding: var(--sem-space-quintuple) var(--sem-space-quadruple);
  }

  &,
  &__header,
  &__actions,
  &__holder {
    display: flex;
  }

  &__header,
  &__holder {
    gap: var(--sem-space-double);
  }

  &__header,
  &__holder--center {
    flex-direction: column;
    justify-content: center;
  }

  &__holder--split {
    align-items: center;
    justify-content: space-between;
  }

  &__header--center,
  &__thumbnail,
  &__holder {
    width: 100%;
  }

  &__header--center {
    align-items: center;
    text-align: center;
  }

  &__image {
    --comp-callout-image-margin-left: calc(var(--sem-space-octuple) * -1);
    margin-bottom: var(--comp-callout-image-margin-bottom);
    margin-left: var(--comp-callout-image-margin-left);
    margin-right: var(--comp-callout-image-margin-right);
    margin-top: calc(var(--sem-space-octuple) * -1);
    position: relative;

    @media screen and (min-width: 681px) {
      --comp-callout-image-margin-bottom: calc(var(--sem-space-octuple) * -1);
      --comp-callout-image-margin-right: var(--sem-space-octuple);
      min-width: calc(40% + var(--sem-space-quadruple));
    }

    @media screen and (max-width: 680px) {
      --comp-callout-image-margin-bottom: var(--sem-space-quadruple);
      --comp-callout-image-margin-right: calc(var(--sem-space-octuple) * -1);
    }

    .suaw-callout--reverse & {
      @media screen and (min-width: 681px) {
        --comp-callout-image-margin-left: var(--sem-space-octuple);
        --comp-callout-image-margin-right: calc(var(--sem-space-octuple) * -1);
      }
    }
  }

  &__thumbnail {
    border-radius: 0 !important;
    display: block;
    height: 100%;

    @media screen and (min-width: 681px) {
      position: absolute;
    }
  }

  &__actions {
    gap: var(--sem-space-double);
    justify-content: center;
  }

  &__actions--center {
    flex-wrap: wrap;
  }

  &__actions--split {
    @media screen and (max-width: 680px) {
      flex-wrap: wrap;
    }
  }
}
</style>
