<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Chip from "../Chip/Chip.vue";
import ChipTwo from "../Chip/ChipTwo.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import EditorBox from "../EditorBox/EditorBox.vue";
import Sidepanel from "../Sidepanel/Sidepanel.vue";
import ResponseList from "../Response/ResponseList.vue";
import DiscussionDetails from "../DiscussionList/DiscussionDetails.vue";

export default {
  name: "DiscussionItem",
  components: {
    Paragraph,
    Chip,
    ChipTwo,
    Dropdown,
    EditorBox,
    Sidepanel,
    ResponseList,
    DiscussionDetails,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    cardText: {
      type: [Object, String]
    },
    chipText: {
      type: String,
    },
    chipContent: {
      type: String,
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String,
    },
    avatarInitials: {
      type: String,
    },
    isAvatarHidden: {
      type: Boolean,
      default: false,
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
    },
    likeCount: {
      type: Number,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
    posted: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    discussionReplies: {
      type: Array,
      default: () => [],
    },
    showDiscussionLikes: {
      type: Boolean,
      default: true,
    },
    isSidepanelDiscussion: {
      type: Boolean,
    },
    editorBoxPrimaryButtonText: {
      type: String,
    },
    editorBoxSecondaryButtonText: {
      type: String,
    },
    textBoxSize: {
      type: String,
    },
    expandableDiscussionListItems: {
      type: Array,
      default: () => [],
    },
    replyPlaceholder: {
      type: String,
    },
    replyButtonText: {
      type: String,
    },
    sidepanelTitle: {
      type: String,
      default: "Back to Discussions",
    },
    responseNumber: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    cardText(newVal) {
      this.editableText = newVal;
    },
  },
  computed: {
    formattedResponseLabel() {
      return this.responseNumber === 0
        ? "No Responses"
        : `${this.responseNumber} Response${
            this.responseNumber > 1 ? "s" : ""
          }`;
    },
    computedChipSize() {
      return this.isSidepanelDiscussion ? "small" : "medium";
    },
    computedDiscussionLikes() {
      if (!this.likeCount) {
        return "";
      }
      return `${this.likeCount} Like${this.likeCount !== 1 ? "s" : ""}`;
    },
  },
  data() {
    return {
      editMode: false,
      editableText: this.cardText,
      isSidepanelOpen: false,
      textAreaReply: false,
      hasSidepanelDiscussionList: true,
    };
  },
  methods: {
    toggleSidepanel() {
      this.isSidepanelOpen = !this.isSidepanelOpen;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    handleEditorBoxPrimaryButtonClick() {
      const payload = {
        text: this.editableText,
        id: this.id,
      };
      this.$emit("edit-discussion", payload);
      this.toggleEditMode();
    },
    handleImageUpload(base64Data) {
      //do not like the ref drilling here and hate emitting the editor
      const editor = this.$refs.editorBox.$refs.textArea.editor;
      this.$emit("image-upload", base64Data, editor);
    },
  },
};
</script>

<template>
  <div class="suaw-card">
    <div class="suaw-card__content">
      <footer class="suaw-card__footer">
        <div class="suaw-card__block">
          <div class="suaw-card__status-block">
            <ChipTwo
              type="avatar"
              :size="computedChipSize"
              :avatar-url="avatarUrl"
              :avatar-color="avatarColor"
              :avatar-initials="avatarInitials"
              :is-avatar-hidden="isAvatarHidden"
              :is-deactivated="isDeactivated"
              :display-name="displayName"
              :text="displayName"
            />
            <span
              :class="{
                'suaw-card__posted--small': isSidepanelDiscussion,
                'suaw-card__posted': !isSidepanelDiscussion
              }"
            >
              {{ posted }}
            </span>
          </div>
          <div class="suaw-card__status-block">
            <slot name="discussion-content"></slot>
            <Chip
              :size="computedChipSize"
              chipType="icon"
              status="info"
              icon="IconComment"
              shape="square"
              :label="formattedResponseLabel"
              class="suaw-chip--clickable"
              @click="toggleSidepanel"
            />
            <Chip
              v-if="showDiscussionLikes"
              :key="isLiked"
              :size="computedChipSize"
              chipType="icon"
              status="critical"
              :icon="isLiked ? 'IconHeartFill' : 'IconHeartOutline'"
              shape="square"
              class="suaw-chip--clickable"
              :label="computedDiscussionLikes"
              @click="$emit('like-click', { id, isLiked })"
            />
            <Dropdown
              iconDropdown="IconMoreHorizontal"
              dropdownButtonSize="small"
              buttonType="secondary-outline"
              buttonClassName="suaw-dropdown__button--chip"
              :items="dropdownItems"
              @editModeToggled="toggleEditMode"
              @item-click="$emit('dropdown-item-click', $event)"
            />
          </div>
        </div>
        <transition name="suaw-sidepanel__fade">
          <Sidepanel
            v-if="isSidepanelOpen"
            @button-clicked="toggleSidepanel"
            :sidepanelTitle="sidepanelTitle"
          >
            <DiscussionDetails
              :id="id"
              :headingText="cardText"
              :chipText="chipText"
              :chipContent="chipContent"
              :avatar-url="avatarUrl"
              :avatar-color="avatarColor"
              :avatar-initials="avatarInitials"
              :is-avatar-hidden="isAvatarHidden"
              :is-deactivated="isDeactivated"
              :display-name="displayName"
              :posted="posted"
              :dropdownItems="dropdownItems"
              :show-discussion-likes="showDiscussionLikes"
              :like-count="likeCount"
              :is-liked="isLiked"
              :reply-placeholder="replyPlaceholder"
              :reply-button-text="replyButtonText"
              :text-box-primary-button="editorBoxPrimaryButtonText"
              :text-box-secondary-button="editorBoxSecondaryButtonText"
              @edit-discussion="$emit('edit-discussion', $event)"
              @submit-response="$emit('submit-response', $event)"
              @like-click="$emit('like-click', $event)"
            />
            <slot></slot>
            <ResponseList
              v-if="expandableDiscussionListItems && expandableDiscussionListItems.length > 0"
              :expandableDiscussionListItems="expandableDiscussionListItems"
              :total-responses="formattedResponseLabel"
            />
          </Sidepanel>
        </transition>
      </footer>
      <template v-if="editMode">
        <EditorBox
          ref="editorBox"
          v-model="editableText"
          :size="textBoxSize"
          :button-text="editorBoxPrimaryButtonText"
          :secondary-button-text="editorBoxSecondaryButtonText"
          :use-formatting="true"
          @submit="handleEditorBoxPrimaryButtonClick"
          @secondary-click="toggleEditMode"
          @image-upload="handleImageUpload"
        />
      </template>
      <template v-else-if="isSidepanelDiscussion">
        <Paragraph
          size="small"
          weight="bold"
          :text="cardText"
          @click="toggleSidepanel"
        />
      </template>
      <template v-else>
        <div 
          class="suaw-card__text"
          @click="toggleSidepanel"
        >
          <Paragraph
            size="medium"
            weight="normal"
            :text="cardText"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--sem-space-double);

  &__text {
    cursor: pointer;
  }

  &__content,
  &__block,
  &__footer {
    display: flex;
    flex-wrap: wrap;
  }

  &__content {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__footer {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__block {
    justify-content: space-between;
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__block-reply {
    flex-direction: column;
    position: relative;
  }

  &__posted,
  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__discussion-block {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    width: 100%;
  }
}
</style>
