<script>
import { DatePicker } from "v-calendar";
import Button from "../Button/Button.vue";
import Divider from "../Divider/Divider.vue";
export default {
  name: "CalendarCard",
  components: { DatePicker, Button, Divider },
  props: {
    selectedDate: {
      type: String, // The selected date passed from the parent
      default: null,
    },
    eventDates: {
      type: Array, // Array of date strings
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allEventDates() {
      // Ensure each Date object is at local midnight by constructing with individual date components
      return this.eventDates.map(date => {
        const [year, month, day] = date.split("T")[0].split("-");
        return new Date(year, month - 1, day); // month is 0-indexed in JS Date constructor
      });
    },
    pastEventDates() {
      // Filter for dates before today
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to midnight for comparison
      return this.allEventDates.filter(date => date < today);
    },
    upcomingEventDates() {
      // Filter for dates on or after today
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to midnight for comparison
      return this.allEventDates.filter(date => date >= today);
    },
    initialViewDate() {
      if (this.selectedDate) {
        const [year, month, day] = this.selectedDate.split("T")[0].split("-");
        return new Date(year, month - 1, day);
      }
      return new Date();
    },
    calendarAttrs() {
      return [
        {
          key: "selected",
          highlight: {
            class: "vc-selected",
            contentClass: "vc-selected-content",
          },
          dates: [this.initialViewDate]
            .filter(Boolean)
            .map(date => new Date(date)),
        },
        {
          key: "events",
          highlight: {
            class: "vc-events",
            contentClass: "vc-events-content",
          },
          dates: this.upcomingEventDates,
        },
        {
          key: "past-events",
          highlight: {
            class: "vc-past-events",
            contentClass: "vc-past-events-content",
          },
          dates: this.pastEventDates,
        },
      ];
    },
  },
  methods: {
    handleDayClick(event) {
      const emittedDateObject = event.date;
      const utcDateString = emittedDateObject.toISOString();
      this.$emit("date-selected", utcDateString);
    },
    handleClearSelection() {
      this.$emit("clear-selected");
    },
  },
};
</script>

<template>
  <div class="suaw-calendar-card">
    <DatePicker
      class="suaw-calendar-card__cal"
      :value="initialViewDate"
      :attributes="calendarAttrs"
      :available-dates="allEventDates"
      :from-date="initialViewDate"
      is-expanded
      :theme="{
        dayContentDisabled: 'vc-day-content-disabled',
        dayDisabled: 'vc-day-disabled',
        primary: '#376BFF',
      }"
      @dayclick="handleDayClick"
    />
    <div v-if="showFooter" class="suaw-calendar-card__footer">
      <Divider />
      <div class="suaw-calendar-card__button-container">
        <Button
          class="suaw-calendar-card__button"
          button-text="Clear Selection"
          size="medium"
          type="ghost"
          @click="handleClearSelection"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-calendar-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  width: 100%;
  height: auto;
  &__footer {
    padding: 0px 16px;
  }
  &__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__cal {
    border: none;
    :deep(.vc-pane) {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 12px;
      justify-content: space-between;
    }
    :deep(.vc-header) {
      margin-top: 19px;
      padding: 0;
    }
    :deep(.vc-arrows-container) {
      margin-top: 10px;
    }
    :deep(.vc-weeks) {
      gap: 5px;
      // padding-bottom: 15px;
    }
    :deep(.vc-day-content) {
      border-radius: 4px;

      &:hover {
        background-color: #376bff;
        transition: background-color 0.2s;
        color: white;
        border-radius: 4px;
      }
      &:focus {
        background-color: #376bff;
        color: white;
        font-weight: 400;
        // transition: background-color 0.2s;
        // color: white;
        border-radius: 4px;
      }
    }
    :deep(.vc-events) {
      background-color: #ebf0ff;
    }
    :deep(.vc-events-content) {
      border-radius: 4px;
      color: #376bff;
      background-color: #ebf0ff;
    }
    :deep(.vc-past-events) {
      background-color: var(--sem-color-inactive-light);
    }

    :deep(.vc-past-events-content) {
      border-radius: 4px;
      color: white;
    }
    :deep(.vc-selected) {
      border-radius: 4px;
      z-index: 1;
      background-color: #376bff !important;
    }

    :deep(.vc-past-events) {
      background-color: var(--sem-color-inactive-light);
    }
    :deep(.vc-highlight) {
      border-radius: 4px !important;
      color: white;
    }

    :deep(.is-disabled) {
      pointer-events: none;
    }
  }
}
</style>
