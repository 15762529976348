<script>
import Card from "../Card/Card.vue";

export default {
  name: "UpdatesList",
  props: {
    updatesListItems: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Card,
  },
};
</script>

<template>
  <ul class="suaw-updates-list">
    <li
      v-for="(item, index) in updatesListItems"
      :key="index"
      class="suaw-updates-list__item"
    >
      <Card
        cardType="CardUpdate"
        :text="item.text"
        :chipContent="item.chipContent"
        :chipText="item.chipText"
        :posted="item.posted"
      />
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.suaw-updates-list {
  border-radius: var(--sem-radius-sm);
  list-style-type: none;
  overflow: hidden;
  padding: var(--sem-space-base);

  &__item:not(:first-child) {
    margin-top: var(--sem-space-double);
  }
}
</style>
