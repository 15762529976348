<script>
import Paragraph from "../Paragraph/Paragraph.vue";

export default {
  name: "SummaryModalRSVP",
  components: {
    Paragraph,
  },
  props: {
    eventRSVPed: {
      type: Boolean,
    },
  },
  methods: {},
};
</script>

<template>
  <div class="suaw-modal__block">
    <template v-if="!eventRSVPed">
      <Paragraph
        text="We'll email you a reminder 24 hours before the event begins."
        size="small"
      />
      <Paragraph
        text="This event is hosted by community chapters, which you can join to receive new event postings and recent online discussions."
        size="small"
      />
      <Paragraph
        text="You can adjust notifications on your settings page."
        size="small"
      />
    </template>
    <Paragraph
      v-else
      text="You will no longer receive updates about this event. You can re-RSVP at a future time, however space may be limited."
      size="small"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-modal__block {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
}
</style>
