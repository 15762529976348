<script>
export default {
  name: "ReplyList"
};
</script>

<template>
  <ul class="suaw-reply__list">
    <slot />
  </ul>
</template>

<style lang="scss" scoped>
.suaw-reply__list {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  list-style-type: none;
  padding: 0;
  width: 100%;
}
</style>
