import { render, staticRenderFns } from "./IconData.vue?vue&type=template&id=b2c20a28"
import script from "./IconData.vue?vue&type=script&lang=js"
export * from "./IconData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports