var render = function render(){var _vm=this,_c=_vm._self._c;return _c('hr',{staticClass:"suaw-divider",class:[
    'suaw-divider--' + _vm.color,
    'suaw-divider--' + _vm.orientation,
    'suaw-divider--bottom-' + _vm.bottomDistance,
    'suaw-divider--top-' + _vm.topDistance,
    'suaw-divider--' + _vm.type,
    _vm.className
  ]})
}
var staticRenderFns = []

export { render, staticRenderFns }