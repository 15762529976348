<script>
import Label from "../Label/Label.vue";
import Button from "../Button/Button.vue";
export default {
  name: "GridSection",
  components: { Label, Button },
  props: {
    showHeader: {
      type: Boolean,
      default: false,
    },
    headerLabel: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "View All",
    },
  },
};
</script>

<template>
  <div class="suaw-grid-section">
    <div v-if="showHeader" class="suaw-grid-section__header">
      <Label size="large" weight="bold" :label-text="headerLabel" />
      <Button
        class="suaw-grid-section__header-button suaw-button--no-y-pd suaw-button--no-x-pd"
        size="hero"
        type="ghost"
        :button-text="buttonLabel"
        @click="$emit('profile-click', userId)"
      />
    </div>
    <div class="suaw-grid-section__grid">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-grid-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header-button {
    height: auto;
  }
  &__grid {
    width: 100%;
    display: grid;
    column-gap: var(--sem-space-septuple);
    row-gap: var(--sem-space-triple);
    grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
  }
}
</style>
