<script>
import { SuawMainContent, SuawSection, SuawInputGroup, SuawTextInput, SuawTable, SuawTableItem, SuawEmptyState, SuawButton } from "@/components";
import { GetUserListForAdmin } from "../../operations.gql";

export default {
  name: "UserSearch",
  components: {
    SuawMainContent,
    SuawSection,
    SuawInputGroup,
    SuawTextInput,
    SuawTable,
    SuawTableItem,
    SuawEmptyState,
    SuawButton
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserList: [
        // {
        //   avatar_color: "",
        //   avatar_url: "",
        //   first_name: "",
        //   last_name: "",
        //   initials: "",
        //   id: "",
        //   user_auth {
        //     email_pending
        //     email_pending_at
        //     email
        //     email_verified_at
        //   }
        // }
      ],
      searchQuery: "",
      offset: 0,
      limit: 8,
      filterQuery: ""
    };
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    usersList() {
      return this.resultUserList;
    },
    filteredUsers() {
      if (!this.filterQuery) {
        return this.usersList;
      }
      const lowerFilterQuery = this.filterQuery.toLowerCase();
      return this.usersList.filter(u => {
        return (
          u.user_auth.email.toLowerCase().includes(lowerFilterQuery) || u.first_name.toLowerCase().includes(lowerFilterQuery) || u.last_name.toLowerCase().includes(lowerFilterQuery)
        );
      });
    },
    hasNoData() {
      return !this.isLoading && this.filteredUsers.length === 0;
    },
    showPrev() {
      return this.offset !== 0;
    },
    showNext() {
      return this.usersList.length === this.limit;
    }
  },
  watch: {
    searchQuery() {
      this.offset = 0;
    }
  },
  apollo: {
    resultUserList: {
      query: GetUserListForAdmin,
      debounce: 1000,
      variables() {
        return {
          limit: this.limit,
          offset: this.offset,
          like: `%${this.searchQuery}%`
        };
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  methods: {
    handleSearch(searchTerm) {
      if (!searchTerm) {
        this.searchQuery = "";
        return;
      }
      this.searchQuery = searchTerm;
    },
    handleFilter(filterTerm) {
      if (!filterTerm) {
        this.filterQuery = "";
        return;
      }
      this.filterQuery = filterTerm;
    },
    buildUserItem(u) {
      return {
        id: u.id,
        avatar: u.avatar_url ?? u.initials,
        color: u.avatar_color,
        type: u.avatar_url ? "picture" : u.avatar_color,
        contents: [
          {
            text: `${u.first_name} ${u.last_name}`,
            subtext: u.user_auth.email_verified_at !== null ? u.user_auth.email : `${u.user_auth.email_pending} (unverified)`
          }
        ],
        button: "View"
      };
    },
    handleViewClick(user) {
      this.$router.push({ name: "UserView", params: { userId: user.id } });
    },
    onPrev() {
      this.offset -= this.limit;
    },
    onNext() {
      this.offset += this.limit;
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
      <SuawSection section-size="medium">
        <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
          <SuawTextInput v-model="searchQuery" placeholder="Search user database" @input="handleSearch" />
        </SuawInputGroup>
        <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
          <SuawTextInput v-model="filterQuery" placeholder="Filter visible results" @input="handleFilter" />
        </SuawInputGroup>
      </SuawSection>
      <SuawSection section-size="medium">
        <SuawButton v-if="showPrev" icon-left="IconCircleChevronLeft" button-text="Prev" type="ghost" class="suaw-button--pull-left" size="small" @click="onPrev" />
        <SuawButton v-if="showNext" icon-left="IconCircleChevronRight" button-text="Next" type="ghost" class="suaw-button--pull-right" size="small" @click="onNext" />
      </SuawSection>
      <SuawSection section-size="medium">
        <SuawEmptyState v-if="isLoading" message="Loading data..." />
        <SuawEmptyState v-else-if="hasNoData" message="No data" />
        <SuawTable v-else>
          <SuawTableItem v-for="u in filteredUsers" :key="u.id" :item="buildUserItem(u)" @message-click="handleViewClick" />
        </SuawTable>
      </SuawSection>
    </SuawInputGroup>
  </SuawMainContent>
</template>
