<script>
export default {
  name: 'LogoWhereby',
}
</script>

<template>
<svg width="56" height="15" viewBox="0 0 56 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.989 1.00154C10.0087 1.00154 8.95365 1.73979 8.11337 3.4715L7.03034 0.93774H6.96499C6.78759 1.33877 6.22741 1.55751 5.70457 1.55751H0V1.66688C0.457486 1.85828 0.858953 2.50539 1.23241 3.38036L4.78026 11.7381H4.8923L7.1984 6.29692L9.5045 11.7381H9.61654L11.5865 6.96226C12.3521 5.08473 12.8469 4.05482 12.8469 3.11605C12.8283 1.87651 12.1187 1.00154 10.989 1.00154ZM5.66722 8.8398L3.37046 3.38948C2.997 2.49628 3.34245 1.86739 4.28543 1.86739C5.11637 1.86739 5.52717 2.40514 5.94731 3.38036L6.96499 5.77741L5.66722 8.8398ZM11.1757 6.79821L10.3728 8.78512L8.24408 3.79962C8.76692 3.06136 9.43914 2.78793 10.2234 2.78793C11.1477 2.78793 11.7079 3.4077 11.7079 4.52876C11.7079 5.13941 11.5398 5.91413 11.1757 6.79821Z" fill="#242529"/>
<path d="M40.9681 6.4063C40.9681 5.30347 39.801 4.4923 38.3259 4.4923C36.0851 4.4923 34.5633 6.37895 34.5633 8.36587C34.5633 10.5259 36.2532 11.8202 37.9244 11.8202C39.2688 11.8202 40.5666 11.0272 40.9681 9.63275L40.8747 9.55072C40.4172 10.2069 39.633 10.5715 38.8394 10.5715C37.803 10.5715 36.9534 9.9882 36.5519 8.94918C38.6993 8.62106 40.9681 8.05598 40.9681 6.4063ZM36.4586 8.63018C36.3745 8.30206 36.3372 7.95572 36.3372 7.59115C36.3372 5.84121 37.1028 4.85687 38.0364 4.85687C38.774 4.85687 38.9888 5.46753 38.9888 6.11464C38.9888 7.46355 37.7377 8.36586 36.4586 8.63018Z" fill="#242529"/>
<path d="M54.627 4.29204C53.6747 4.29204 52.7598 5.16701 52.1996 6.35186L51.2566 4.41053H51.1446C50.9672 5.06675 50.1175 5.44955 49.1932 5.43132V5.54069C49.7721 5.67741 50.0242 6.21515 50.379 6.92606L52.8251 11.8478L52.6571 12.1485C52.1342 13.1602 51.7701 13.5248 51.3966 13.5248C50.7151 13.5248 50.6591 12.5678 49.8468 12.5678C49.3613 12.5678 48.9598 12.8959 48.9598 13.461C48.9598 14.099 49.6787 14.5 50.4257 14.5C51.518 14.5 52.1996 13.6615 52.9278 12.3126L54.8231 8.85828C55.5887 7.47291 55.9995 6.86226 55.9995 5.82323C56.0182 4.99384 55.514 4.29204 54.627 4.29204ZM54.5057 8.68511L53.8988 9.8335L52.3396 6.62529C52.6384 6.10578 53.1612 5.68652 53.9455 5.68652C54.6831 5.68652 55.0659 6.13312 55.0659 6.86226C55.0565 7.36354 54.8791 7.98331 54.5057 8.68511Z" fill="#242529"/>
<path d="M20.5498 10.2067V6.04147C20.5498 5.11181 19.9522 4.49204 19.0653 4.49204C18.0009 4.49204 16.6565 5.43993 15.9376 6.1235V0.5H15.8442C15.4147 1.15623 14.3037 1.56637 13.2393 1.53903V1.6484C13.8462 1.81245 14.0236 2.23171 14.0236 3.03376V10.2067C14.0236 10.8173 13.7435 11.3004 13.2487 11.5009V11.6103H16.7405V11.5009C16.227 11.2184 15.9376 10.8173 15.9376 10.234V6.46984C16.7498 5.88653 17.3754 5.60398 17.8329 5.60398C18.3464 5.60398 18.6358 5.96855 18.6358 6.70681V10.234C18.6358 10.8173 18.3744 11.2001 17.8329 11.5009V11.6103H21.3247V11.5009C20.8299 11.3004 20.5498 10.8173 20.5498 10.2067Z" fill="#242529"/>
<path d="M46.4586 4.49204C45.4597 4.49204 44.5073 5.20296 43.9845 5.75893V0.5H43.9005C43.4056 1.15623 42.2573 1.56637 41.2303 1.53903V1.6484C41.7998 1.81245 42.0612 2.24082 42.0612 3.03376V11.747H42.1826L43.0975 10.7535C43.499 11.3733 44.2646 11.747 45.1702 11.747C47.467 11.747 49.0448 9.833 49.0448 7.74584C49.0542 5.83184 47.8218 4.49204 46.4586 4.49204ZM45.1235 11.3824C44.442 11.3824 43.9845 10.8447 43.9845 10.0973V6.13261C44.442 5.7407 44.9555 5.37613 45.525 5.39436C46.496 5.40347 47.0468 6.46984 47.0655 8.10129C47.0749 9.95149 46.2999 11.3824 45.1235 11.3824Z" fill="#242529"/>
<path d="M33.4803 4.49154C32.7147 4.49154 31.8931 5.28448 31.5009 6.04096V4.40951H31.3889C30.9221 5.06573 29.811 5.45765 28.7747 5.44853V5.5579C29.3442 5.72196 29.615 6.16856 29.615 6.94327V10.1515C29.615 10.7713 29.3349 11.2543 28.784 11.5004V11.6098H32.3506V11.5004C31.781 11.2543 31.5383 10.7713 31.5383 10.1515V6.37819C31.7437 6.12299 31.9771 5.84956 32.2572 5.84956C32.7707 5.84956 32.9761 6.46022 33.611 6.46022C34.0498 6.46022 34.5073 6.11387 34.5073 5.51233C34.5166 4.92902 34.0311 4.49154 33.4803 4.49154Z" fill="#242529"/>
<path d="M28.1305 6.4063C28.1305 5.30347 26.9634 4.4923 25.4882 4.4923C23.2475 4.4923 21.7256 6.37895 21.7256 8.36587C21.7256 10.5259 23.4155 11.8202 25.0868 11.8202C26.4312 11.8202 27.729 11.0272 28.1305 9.63275L28.0371 9.55072C27.5796 10.2069 26.7954 10.5715 26.0018 10.5715C24.9654 10.5715 24.1158 9.9882 23.7143 8.94918C25.8617 8.62106 28.1305 8.05598 28.1305 6.4063ZM23.6209 8.63018C23.5369 8.30206 23.4996 7.95572 23.4996 7.59115C23.4996 5.84121 24.2652 4.85687 25.1988 4.85687C25.9364 4.85687 26.1698 5.46753 26.1698 6.11464C26.1698 7.46355 24.9094 8.36586 23.6209 8.63018Z" fill="#242529"/>
</svg>
</template>

<style scoped>
</style>
