var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suaw-textarea"},[(_vm.label)?_c('Label',{attrs:{"labelText":_vm.label,"labelFor":_vm.id,"weight":_vm.labelWeight,"size":"small"}}):_vm._e(),(_vm.useFormatting)?_c('div',{staticClass:"toolbar suaw-textarea__toolbar"},[_c('Button',{attrs:{"type":"ghost","iconLeft":"IconUndo","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}}),_c('Button',{attrs:{"type":"ghost","iconLeft":"IconRedo","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}}),_c('Button',{attrs:{"type":"ghost","iconLeft":"IconBold","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}}),_c('Button',{attrs:{"type":"ghost","iconLeft":"IconItalic","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}}),_c('Button',{attrs:{"type":"ghost","iconLeft":"IconUnderline","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().toggleUnderline().run()}}}),_c('Button',{attrs:{"type":"ghost","iconLeft":"IconListUl","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}}),_c('Button',{attrs:{"type":"ghost","iconLeft":"IconListOl","size":"small","button-text":""},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}}),(_vm.allowImages)?_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.handleFileInput}}):_vm._e(),(_vm.allowImages)?_c('Button',{attrs:{"type":"ghost","iconLeft":"IconImageAlt","size":"small","button-text":""},on:{"click":function($event){return _vm.$refs.fileInput.click()}}}):_vm._e()],1):_vm._e(),(_vm.useTipTap)?_c('editor-content',{ref:"editorContent",class:[
      'suaw-textarea__field',
      'suaw-textarea--' + _vm.borderStyle,
      _vm.className
    ],attrs:{"editor":_vm.editor}}):_c('textarea',{ref:"textarea",staticClass:"suaw-textarea__field",class:[
      'suaw-textarea--' + _vm.borderStyle,
      _vm.className
    ],attrs:{"id":_vm.id,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.handleInput}}),_vm._v(" "),(_vm.isRequired)?_c('small',{staticClass:"suaw-textarea__message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }