<script>
import { SuawInputGroup, SuawHeading, SuawBlockQuote } from "@/components";
import ResourceFrame from "@/features/resources/components/ResourceFrame.vue";

export default {
  name: "OrganizerHandbook",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawBlockQuote,
    ResourceFrame
  }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
      <h1 class="suaw-organizer-resource-heading">Organizer <b>Handbook</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Since 2007, our organizers have run thousands of events using our tried-and-true formats. Now it's your turn!"
      />
      <p class="suaw-paragraph">
        If you'd like to become an organizer, follow our simple application process <router-link :to="{ name: 'BecomeAnOrganizer' }">here</router-link> to set up a video call with our team.
      </p>
      <p class="suaw-paragraph">
        Before your call with our team, it's helpful to familiarize yourself with our events. <b>We ask that you attend at least one Shut Up & Write! event before applying to host your own.</b> Once your application has been approved, follow these steps to launch your own Shut Up & Write! event.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Getting Started</h2>
      <h2 class="suaw-organizer-resource-subtitle">1. Pick a format</h2>
      <p class="suaw-paragraph">Decide whether you would like to host your events in person or online.</p>
      <p class="suaw-paragraph">
        <b>In-person</b>: You'll need to pick a venue for your in-person event. Great places to host include your local coffee shop, library, or bookstore. Choosing a venue requires preparation, but for some writers, nothing beats the connection and productivity gained from meeting face-to-face on a regular basis.
      </p>
      <p class="suaw-paragraph">
        <b>Online:</b> Hosting an event online has a few advantages: it's incredibly convenient, can be done from anywhere with a good internet connection, and allows writers to participate who may otherwise not be able to attend in-person events. Hosting an online event requires the use of a paid account on a video conferencing platform that allows for a meeting to run 60+ minutes.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">2. Pick a time</h2>
      <p class="suaw-paragraph">
        Next, you'll need to pick a time for your event. Most organizers run Shut Up & Write! sessions once a week, but some like to run events even more frequently. Choose a recurring date and time that works with your schedule and is also accessible for other writers, such as mid-morning on Saturdays, or weekdays after work.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">3. Pick an event duration</h2>
      <p class="suaw-paragraph">
        How long are you going to write? A classic Shut Up & Write! is an hour of focused writing time, which is the duration we recommend for most organizers, especially those just starting out. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. If you'd like to write for a long period of time, remember to schedule breaks.
      </p>
      <p class="suaw-paragraph">What defines a Shut Up & Write! event? No read-alouds, no critiques, no feedback. Just writing.</p>
      <SuawBlockQuote
        text='The "Classic" Shut Up & Write: Start with 15 minutes of introductions. Then, set a timer for the duration of your event, start the timer, and tell everyone to "Shut Up & Write!" Use about 15 minutes at the end of the event to check in and share how your writing went. Then, offer an opportunity for everyone to stay and socialize.'
      />
      <h2 class="suaw-organizer-resource-subtitle">4. Create your event</h2>
      <p class="suaw-paragraph">
        Ready to get the word out about your event? To help our organizers reach as many writers as possible, we list all events on both <a href='https://www.meetup.com/pro/shut-up-write/' target='_blank'>Meetup</a> and our own Shut Up & Write! website. Contact <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a> and we'll help you set everything up.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">5. Spread the word</h2>
      <p class="suaw-paragraph">
        Now that you've created your event, other writers in our network will be able to find it! Additionally, we encourage you to promote your events on social media or leave flyers at your chosen venue.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Additional Tips</h2>
      <h2 class="suaw-organizer-resource-subtitle">Ask a Friend to Help</h2>
      <p class="suaw-paragraph">
        Having a co-host is a great way to have ongoing support, and we encourage this whenever possible. Try asking on social media or at the end of your event to find potential co-hosts. A co-host can serve as an emergency backup, be as involved as the main organizer, or strike a balance in between. If you identify a co-host, find an arrangement that works best for everyone. (Yes, you can have multiple co-hosts!)
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Know the Code</h2>
      <p class="suaw-paragraph">
        Shut Up & Write! events are a space for accountable writing time. Be sure that you and your writers are following our <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link> at all events.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Keep Us Posted</h2>
      <p class="suaw-paragraph">
        Regularly check in with the Shut Up & Write! Community Team about your events, ideas you have about how to support your group, and any <a href='https://shutupwrite.typeform.com/to/EI3Ohubg' target='_blank'>success stories</a> you'd like to share. Email us at <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a>.
      </p>
      <p class="suaw-paragraph">(P.S. Be sure to read the <router-link :to="{ name: 'OrganizerFaq' }">Organizer FAQ</router-link> as it covers a lot of helpful information you'll need to know as a host!)</p>
      <h2 class="suaw-organizer-resource-subtitle">In-Person Events</h2>
      <h2 class="suaw-organizer-resource-subtitle">Format</h2>
      <p class="suaw-paragraph">
        Standard in-person events last <b>90 minutes</b>. If you'd like to host for a longer period of time, this format can be expanded. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. All of our events share our reliable format of checking in before and after your writing time. Here's an example of a classic Shut Up & Write! event schedule:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">15 mins: Introductions</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">60 mins: Shut Up & Write!</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">15 mins: Conclusion</p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">Setup</h2>
      <p class="suaw-paragraph">
        Print a copy of our <router-link :to="{ name: 'Downloads', hash: '#table-tent' }">table tent</router-link> and leave it in an easily visible place so your writers can find you at your chosen venue. You can include other tips ("I always reserve the table in back," etc.) on your event listing page.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Event Discussions</h2>
      <p class="suaw-paragraph">
        Your event listing page includes a discussion area for you and your event attendees. As the host of the event, you are also the host of this discussion space. We hope you'll use this space to get to know each other!
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Tips</h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Speak to the manager at the venue to learn whether or not you'll need to make a reservation ahead of time.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Encourage your attendees to patronize the venue in some way, whether that's buying a beverage or a snack.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">If you need to send a last-minute message to your attendees, go to your event page and post a new message in the discussion board. Your members will receive a notification.</p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">Tricky Situations</h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Latecomers: Give them a friendly wave and let them know you'll check in with them after the timer goes off that concludes the writing portion of your event.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Overly talkative writers: If you're in the writing portion of the event, remind them that it's time for quiet writing, but there will be time for socializing at the end.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Bossy writers: Hold your ground! If someone insists on changing your event's venue or timing to suit their needs, let them know they can <router-link :to="{ name: 'BecomeAnOrganizer' }">start their own event</router-link>.</p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">Inappropriate Behaviors</h2>
      <p class="suaw-paragraph">
        This is thankfully a very rare occurrence within our community, but in case this does happen, please know that our team will be available to support you. Email <a href='mailto:hostsupport@shutupwrite.com'>hostsupport@shutupwrite.com</a> and report as many details as possible, including dates, times, and what was said between the involved parties. All community members must abide by our <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link>. In the event that you witness illegal behavior at one of your events, please contact your local authorities.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Online Events</h2>
      <h2 class="suaw-organizer-resource-subtitle">Format</h2>
      <p class="suaw-paragraph">
        Standard online events last 90 minutes. If you'd like to host for a longer period of time, this format can be expanded. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. All of our events share our reliable format of checking in before and after your writing time. Here's an example of a classic online event schedule:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">5 mins: Introductions</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">60 mins: Shut Up & Write!</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">25 mins (or less): Conclusion</p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">Setup</h2>
      <p class="suaw-paragraph">
        In order to make sure your online event runs smoothly, we recommend the following steps. These instructions are Zoom-specific, since a large percentage of our hosts use Zoom. For tips on Zoom alternatives, please check the website for your video hosting software of choice.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Zoom Installation & Settings</h2>
      <p class="suaw-paragraph">
        Make sure you are able to run Zoom by checking the <a href='https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0060748' target='_blank'>system requirements</a>. You will also need a stable internet connection.
      </p>
      <p class="suaw-paragraph">Before your first meeting, make sure you have enabled the following Zoom settings:</p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Enable the waiting room</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Disable screen sharing</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">During the "Shut Up & Write!" phase of your event, default to a Mute setting for all of your attendees in order to maintain a quiet writing environment.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Also, during the "Shut Up & Write!" phase, enable "Mute Upon Entry."</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">For more on Zoom security, please review <a href='https://www.zoom.com/en/blog/keep-uninvited-guests-out-of-your-zoom-meeting/' target='_blank'>Zoom's guide to keeping unwanted guests out of meetings</a>.</p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">Backgrounds</h2>
      <p class="suaw-paragraph">
        As an online organizer, try to make sure your background is free from distractions. No need to be formal, but remember that others are trying to be productive!
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Tricky Situations</h2>
      <h2 class="suaw-organizer-resource-subtitle">Zoombombing</h2>
      <p class="suaw-paragraph">
        If you find yourself getting "Zoombombed," i.e. you have unwanted participants who are disrupting your session, remove them by mousing over their name and clicking "Remove." Let the other writers know you are handling the situation and keep in mind that you're not alone—this is a situation that others who have hosted Zoom meetings have also faced. Quick action will allow you to preserve the time that remains for writing.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Inappropriate Behavior</h2>
      <p class="suaw-paragraph">
        This is thankfully a very rare occurrence within our community, but in case this does happen, please know that our team will be available to support you. Email <a href='mailto:hostsupport@shutupwrite.com'>hostsupport@shutupwrite.com</a> and report as many details as possible, including dates, times, and what was said between the involved parties. All community members must abide by our <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link>. In the event that you witness illegal behavior at one of your events, please contact your local authorities.
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}

.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
