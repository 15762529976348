<script>
import Button from "../Button/Button.vue";
import Paragraph from "../Paragraph/Paragraph.vue";

export default {
  name: "Snackbar",
  components: {
    Paragraph,
    Button,
  },
  props: {
    type: {
      type: String,
      default: "neutral",
      validator: function (value) {
        return [
          "neutral",
          "critical",
          "success",
          "info",
        ].indexOf(value) !== -1;
      },
    },
    position: {
      type: String,
      default: "center",
      validator: function (value) {
        return [
          "left",
          "right",
          "center",
        ].indexOf(value) !== -1;
      },
    },
    textMessage: {
      type: String,
      default: "Message Goes Here",
    },
    labelPrimary: {
      type: String,
      default: "Confirm",
    },
    labelSecondary: {
      type: String,
      default: "Cancel",
    },
    hasAction: {
      type: Boolean,
      default: true,
    },
    hasDismiss: {
      type: Boolean,
      default: true,
    },
    hasTimer: {
      type: Boolean,
      default: false,
    },
    timerAmount: {
      type: Number,
      default: 5, // default to 5 seconds
    }
  },
  data() {
    return {
      isOpen: true,
    };
  },
  mounted() {
    if (this.hasTimer) {
      setTimeout(() => {
        this.handleClose();
      }, this.timerAmount * 1000);
    }
  },
  computed: {
    buttonTypes() {
      if (this.type === 'info') {
        return {
          primary: 'primary',
          secondary: 'ghost',
        };
      } else if (this.type === 'success') {
        return {
          primary: 'success',
          secondary: 'success-ghost',
        };
      } else if (this.type === 'critical') {
        return {
          primary: 'critical',
          secondary: 'critical-ghost',
        };
      } else {
        return {
          primary: 'ghost-outline',
          secondary: 'ghost',
        };
      }
    }
  },
  methods: {
    handleClose() {
      this.isOpen = false;
      this.$emit("cancel-click");
    },
  },
};
</script>

<template>
  <div
    v-if="isOpen"
    class="suaw-snackbar"
    :class="['suaw-snackbar--' + position]"
  >
    <Paragraph
      :text="textMessage"
      size="small"
    />
    <div
      v-if="hasAction || hasDismiss"
      class="suaw-snackbar__actions"
    >
      <Button
        v-if="hasDismiss"
        :buttonText="labelSecondary"
        :type="buttonTypes.secondary"
        size="medium"
        @click="handleClose"
      />
      <Button
        v-if="hasAction"
        :buttonText="labelPrimary"
        :type="buttonTypes.primary"
        size="medium"
        @click="$emit('action-click')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-snackbar {
  position: fixed;
  top: 6rem;
  align-items: center;
  background-color: var(--sem-color-background-lightest);
  border: 1px solid var(--sem-color-border-medium);
  border-radius: var(--sem-radius-sm);
  box-shadow: var(--soft-shadow-draft);
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: calc(100% - 2rem);
  min-width: 300px;
  padding: 10px;
  width: 600px;
  z-index: 99999;

  &--center {
    left: 0;
    right: 0;
  }

  &--right {
    right: 1rem;
  }

  &--left {
    left: 1rem;
  }

  &__actions {
    display: flex;
    gap: var(--sem-space-double);
  }

  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 0.25s linear;
  }

  &__fade-enter,
  &__fade-leave-to {
    opacity: 0;
  }
}
</style>
