<script>
import Label from '../Label/Label.vue';
export default {
  name: "Select",
  components: { Label },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select Option",
    },
    label: {
      type: String,
      default: "",
    },
    labelWeight: {
      type: String,
      default: "bold",
      validator: function (value) {
        return ["normal", "bold"].indexOf(value) !== -1;
      },
    },
    id: {
      type: String,
      default: "selectField",
    },
    className: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    showSuccessMessage: {
      type: Boolean,
      default: true,
    },
    successMessage: {
      type: String,
      default: "Success!",
    },
    state: {
      type: String,
      validator: function (value) {
        return ["valid", "invalid", "disabled", "default"].includes(value);
      },
    },
  },
  computed: {
    setLabels() {
      return this.label !== "" ? this.label : null;
    },
    selectClasses() {
      return {
        "suaw-select--valid": this.state === "valid",
        "suaw-select--invalid": this.state === "invalid",
        "suaw-select--disabled": this.state === "disabled",
        "suaw-select--required": this.isRequired,
      };
    },
    setMessages() {
      if (this.state === "invalid") {
        return this.errorMessage;
      } else if (this.state === "valid" && this.showSuccessMessage) {
        return this.successMessage;
      }
      return "";
    },
    messageClasses() {
      return {
        "suaw-select__message--invalid": this.state === "invalid",
        "suaw-select__message--valid": this.state === "valid",
      };
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<template>
  <div 
    :class="[
      'suaw-select',
      selectClasses,
      className
    ]"
  >
    <Label
      v-if="setLabels"
      :labelFor="id"
      :label-text="setLabels"
      :weight="labelWeight"
      size="small"
    />
    <select
      class="suaw-select__field"
      :value="value"
      :required="isRequired"
      :id="id"
      :disabled="state === 'disabled'"
      @change="handleChange"
    >
      <option 
        v-if="placeholder"
        value=""
        disabled
      >
        {{ placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :value="option.value ? option.value : 'value-' + index"
        :key="option.key ? option.key : index"
      >
        {{ option.text || 'Unnamed Option' }}
      </option>
    </select>
    <small
      v-if="isRequired"
      :class="[
        'suaw-select__message',
        messageClasses
      ]"
    >
      {{ setMessages }}
    </small>
  </div>
</template>

<style lang="scss" scoped>
.suaw-select {
  display: flex;
  gap: var(--sem-space-half);
  flex-direction: column;

  &__field {
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%229%22%20viewBox%3D%220%200%2014%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.989746%202.20132L7.00015%208.21173L13.0106%202.20132L11.5963%200.78711L7.00015%205.3833L2.40396%200.787109L0.989746%202.20132Z%22%20fill%3D%22%23575860%22%2F%3E%3C%2Fsvg%3E");
    background-color: var(--sem-color-background-lightest);
    background-position: center right .75rem;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 1px solid var(--sem-color-border-medium);
    color: var(--sem-color-text-medium);
    cursor: pointer;
    height: 40px;
    padding: var(--sem-space-base);
    border-radius: var(--sem-radius-sm);
    font-family: var(--font-family);
    font-size: var(--body-copy-font-size);
    font-weight: var(--button-bold-font-weight);
    width: 100%;
    position: relative;

    .suaw-select--invalid & {
      border-color: var(--sem-color-critical-medium);
    }

    .suaw-select--disabled & {
      cursor: default;
    }
  }

  &__message {
    position: absolute;
    bottom: -25px;

    &--invalid {
      color: var(--sem-color-critical-medium);
    }

    &--valid {
      color: var(--sem-color-success-medium);
    }
  }
}
</style>
