<script>
import ResourceFrame from "../../components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";

export default {
  name: "BeforeYourVideoCall",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Before Your <b>Video Call</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Thank you for your interest in hosting Shut Up & Write! events! During the video call you have scheduled, you'll speak with one of our staff members so we can explore whether hosting Shut Up & Write! events will be a good fit for you. Before the call, we recommend you familiarize yourself with our organization, our method, and our events themselves."
      />

      <h2 class="suaw-organizer-resource-subtitle">
        Attend an Event
      </h2>
      <p class="suaw-paragraph">
        We think it's important for our organizers to have a sense of what our events are like, so we ask that you attend one of our existing events before you host one yourself. If there isn't a <router-link :to="{ name: 'ChapterDiscovery' }">local event</router-link> near you, you can attend an <router-link :to="{ name: 'OnlineEventDiscovery' }">online event</router-link> to get a feel for what we do.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        Register for Meetup
      </h2>
      <p class="suaw-paragraph">
        Before your call, please make sure you have a <a href='https://www.meetup.com/' target="_blank" rel="noopener">Meetup.com</a> account set up, if you don't have one already. This will help your call go more smoothly.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        In-Person or Online?
      </h2>
      <p class="suaw-paragraph">
        Once you're familiar with our event format, decide whether you would like to host your event in person or online. There are a few factors you might want to consider when you make this decision.
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>In-Person:</b> Choosing a venue requires preparation, but for some writers, nothing beats the connection and productivity gained from meeting face-to-face on a regular basis. You'll need to pick a venue for your in-person event. Great places to host include your local coffee shop, public library, or bookstore.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Online:</b> Hosting an event online has a few advantages: it's incredibly convenient, can be done from anywhere with a good internet connection, and allows writers to participate who may not be able to attend in-person events. Hosting an online event requires the use of a paid account on a video conferencing platform that allows for a meeting to run for 60+ minutes.
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Timing Is Everything
      </h2>
      <p class="suaw-paragraph">
        Next, you'll need to pick a time for your event. Most organizers run Shut Up & Write! sessions once a week, but some like to run events even more frequently. Choose a recurring date and time that works with your schedule and is also accessible for other writers, such as mid-morning on Saturdays, or weekdays after work. 11 AM on Saturdays and after 6 PM on weekdays tend to be popular times for writers to attend sessions.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        Decide on Your Structure
      </h2>
      <p class="suaw-paragraph">
        How long are you going to write? A classic Shut Up & Write! is an hour of focused writing time, which is the duration we recommend for most organizers, especially those just starting out. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. If you'd like to write for a long period of time, remember to schedule breaks. You can iterate on this over time, too—check in with your group to ensure the amount of time allotted for writing also meets their needs.
      </p>
      <p class="suaw-paragraph">
        What defines a Shut Up & Write! event? No read-alouds, no critiques, no feedback. Just writing.
      </p>

      <div class="suaw-organizer-resource-guide">
        <div class="suaw-organizer-resource-callout">
          <p class="suaw-paragraph">
            <b>The 'Classic' Shut Up & Write!</b>
            Go around and ask everyone to share 1-2 sentences about their intention today for the session. This should take no longer than 5-10 minutes. Then, set a timer for the duration of your event, start the timer, and tell everyone to 'Shut Up & Write!' Use about 15 minutes at the end of the event to check in on how everyone's session went, which is a proven, effective way to create accountability. At the end, offer an opportunity for everyone to stay and socialize.
          </p>
        </div>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Chapters and Co-Hosts
      </h2>
      <p class="suaw-paragraph">
        If you are hoping to start a brand new Shut Up & Write! chapter in an area that does not yet have one, please note that we require two organizers in order to start a new chapter. If this applies to you, please find a potential co-host for your event, and have them also fill out our <a href="https://shutupwrite.typeform.com/to/DS7ugm?typeform-source=shutupwrite.com" target="_blank" rel="noopener">new organizer application</a>. Each co-host must be approved by our team.
      </p>
      <p class="suaw-paragraph">
        Having a co-host is a great way to have ongoing support, and we encourage this whenever possible. A co-host can serve as an emergency backup, be as involved as the main organizer, or strike a balance in between. And yes, you can have more than one co-host!
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        Know the Code
      </h2>
      <p class="suaw-paragraph">
        Shut Up & Write! events are a space for accountable writing time in community. Be sure that you're familiar with our
        <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link>.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        What Happens Next?
      </h2>
      <p class="suaw-paragraph">
        After your video call, we'll reach out to you with an update to your application status. If you've been accepted as an organizer, we'll mve forward with setting up your new event. If your application isn't approved, we encourage you to stay with us as a member so you can continue to learn about hosting our events.
      </p>
      <p class="suaw-paragraph">
        If you have any questions about your upcoming video call, reach out to us at <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a>. We look forward to meeting you!
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-organizer-resource-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
//list
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
