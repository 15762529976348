<script>
import AvatarTwo from "../Avatar/AvatarTwo.vue";
import Button from "../Button/Button.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import ChipTwo from "../Chip/ChipTwo.vue";
export default {
  name: "ChapterMemberCard",
  components: { AvatarTwo, Button, Paragraph, ChipTwo },
  props: {
    userId: {
      type: String,
      required: true,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String,
    },
    avatarInitials: {
      type: String,
    },
    isAvatarHidden: {
      type: Boolean,
      default: false,
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
    },
    location: {
      type: String,
      default: "",
    },
    canMessage: {
      type: Boolean,
      default: false,
    },
    eventCount: {
      type: Number,
      default: 0,
    },
    postCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    userEvents() {
      return `${this.eventCount} Event${this.eventCount !== 1 ? "s" : ""}`;
    },
    userPosts() {
      return `${this.postCount} Post${this.postCount !== 1 ? "s" : ""}`;
    },
    computedDisplayName() {
      return this.isDeactivated ? "Community Member" : this.displayName;
    },
    isButtonDisabled() {
      return this.isDeactivated;
    },
  },
}
</script>

<template>
  <div class="suaw-chapter-member-card">
    <div class="suaw-chapter-member-card__content">
      <div class="suaw-chapter-member-card__top-container">
        <div class="suaw-chapter-member-card__avatar-container">
          <AvatarTwo
            size="huge"
            :url="avatarUrl"
            :color="avatarColor"
            :initials="avatarInitials"
            :display-name="computedDisplayName"
            :is-avatar-hidden="isAvatarHidden"
            :is-deactivated="isDeactivated"
          />
        </div>
        <div class="suaw-chapter-member-card__name-container">
          <Button
            class="suaw-chapter-member-card__name-button suaw-button--no-y-pd suaw-button--no-x-pd"
            size="hero"
            type="ghost"
            :button-text="computedDisplayName"
            :disabled="isButtonDisabled"
            @click="$emit('profile-click', userId)"
          />
          <Paragraph
            class="suaw-chapter-member-card__location"
            :text="location"
          />
        </div>
      </div>
      <div class="suaw-chapter-member-card__message-container">
        <Button
          v-if="canMessage"
          class="suaw-chapter-member-card__name-button suaw-button--no-y-pd suaw-button--no-x-pd"
          size="large"
          icon-left="IconComment"
          type="ghost"
          button-text="Send Message"
          @click="$emit('message-click', userId)"
        />
      </div>
      <div class="suaw-chapter-member-card__chips-container">
        <ChipTwo
          size="medium"
          type="icon"
          icon="IconCalendarCheck"
          icon-color="blue"
          :text="userEvents"
        />
        <ChipTwo
          size="medium"
          type="icon"
          icon="IconComment"
          icon-color="blue"
          :text="userPosts"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-member-card {
  min-width: 238px;
  height: 296px;
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-triple);
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    row-gap: 16px;
  }
  &__top-container {
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    row-gap: 8px;
  }
  &__avatar-container {
    display: flex;
  }
  &__name-container {
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    row-gap: 4px;
  }
  &__location {
    color: #7e7f88;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
  &__name-button {
    height: auto;
  }
  &__message-container {
    display: inline-flex;
    height: 19px;
  }
  &__chips-container {
    display: inline-flex;
    gap: 8px;
  }
}
</style>
