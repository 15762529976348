<script>
import TextInput from "../TextInput/TextInput.vue";
import Button from "../Button/Button.vue";

export default {
  name: "InputBox",
  props: {
    placeholder: {
      type: String,
      default: "Enter Your Email",
    },
    buttonText: {
      type: String,
      default: "Subscribe",
    },
    clickMethod: {
      type: Function,
      default: null,
    },
  },
  components: {
    TextInput,
    Button,
  },
  data() {
    return {
      userInput: "",
    };
  },
  methods: {
    handleSubmit() {
      if (!this.userInput.trim()) {
        console.error("Input cannot be empty.");
        return;
      }
      this.$emit("submit", this.userInput);
      this.userInput = "";
    },
  },
};
</script>

<template>
  <div class="suaw-input-box">
    <TextInput
      v-model="userInput"
      type="email"
      noLabel
      :placeholder="placeholder"
      borderStyle="ghost"
    />
    <Button
      size="small"
      :buttonText="buttonText"
      :click-method="clickMethod"
      type="primary"
      @click="handleSubmit"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-input-box {
  align-items: center;
  border: 1px solid var(--sem-color-border-medium);
  border-radius: var(--sem-radius-sm);
  display: flex;
  gap: var(--sem-space-base);
  justify-content: space-between;
  max-width: 400px;
  overflow: hidden;
  padding-right: var(--sem-space-base);
  box-shadow: var(--date-square-shadow)
}
</style>
