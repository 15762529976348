<script>
export default {
  name: "IconHeadingH2",
}
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 4C3.55228 4 4 4.44772 4 5V11H10V5C10 4.44772 10.4477 4 11 4C11.5523 4 12 4.44772 12 5V19C12 19.5523 11.5523 20 11 20C10.4477 20 10 19.5523 10 19V13H4V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V5C2 4.44772 2.44772 4 3 4ZM18 10C16.8954 10 16 10.8954 16 12V12.5C16 13.0523 15.5523 13.5 15 13.5C14.4477 13.5 14 13.0523 14 12.5V12C14 9.79086 15.7909 8 18 8H18.1716C20.2862 8 21.9996 9.71449 21.9996 11.8286C21.9996 12.8436 21.5969 13.8175 20.8787 14.5357L17.4143 18.0001L21 18C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20L15 20.0002C14.5956 20.0002 14.2309 19.7566 14.0761 19.3829C13.9213 19.0092 14.0069 18.5791 14.2929 18.2931L19.4645 13.1215C19.8071 12.7789 19.9996 12.3139 19.9996 11.8286C19.9996 10.8185 19.1811 10 18.1716 10H18Z" />
</svg>
</template>
