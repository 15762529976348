<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToFindALiteraryAgent",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToFindALiteraryAgent">
      <SuawParagraph
        text="Finding a literary agent is a crucial step in the publication process for those looking to pursue traditional publishing rather than self-publishing."
      />
      <SuawParagraph text="First, let’s talk about what a literary agent is and how they can help you.&nbsp;" />
      <SuawParagraph
        text="<strong>A literary agent is an expert in the publishing industry who takes on authors as clients and presents manuscripts or nonfiction book proposals to publishing houses.</strong> They only get paid when they sell your work to a publisher, typically receiving a standard 15% commission on your advance and royalties. Publishing houses rarely accept direct submissions from authors, so finding a good literary agent is all but required if you want to be traditionally published."
      />
      <SuawParagraph
        text="The process of finding a literary agent can be a project in and of itself. At minimum, you will need to draft a query letter that summarizes your project in an expected, digestible format. We’ll release more resources on how to write query letters soon."
      />
      <SuawParagraph
        text='Before you start querying literary agents, make sure that your manuscript or book proposal is 100% ready to go. Check out our recent article, “<a href="https://www.shutupwrite.com/articles/between-editing-and-submission" target="_blank">Between Editing &amp; Submission</a>,” for tips on how to get your work in tip-top shape before external parties have the chance to judge it.'
      />
      <SuawHeading level="3" content="Where To Find Literary Agents" />
      <SuawParagraph text="There are three main places you can go to find names, contact information, and areas of expertise for literary agents:" />
      <SuawParagraph text="<strong>Agent Databases</strong>" />
      <SuawParagraph
        text='<a href="https://www.publishersmarketplace.com/" target="_blank">Publishers Marketplace</a>, <a href="https://querytracker.net/" target="_blank">QueryTracker</a>, and <a href="https://agentquery.com/publishing_mp.aspx" target="_blank">AgentQuery</a> are three of the biggest and best-known databases to find literary agents.'
      />
      <SuawParagraph text="<strong>Manuscript Wish List</strong>" />
      <SuawParagraph
        text='<a href="https://mswishlist.com/" target="_blank">Manuscript Wish List</a> is a great resource consisting of agents who specifically describe the kinds of projects they’d be excited to take on. What you’ve written might be the perfect fit for a particular agent, and you can access their contact information on the website itself. You may even get an idea for a future project while browsing the Manuscript Wish List!'
      />
      <SuawParagraph text="<strong>Acknowledgment pages of books</strong>" />
      <SuawParagraph
        text="Find books similar to yours in genre, style, or theme and flip to the acknowledgment page, where the author thanks everyone who’s helped them on their path to publication. You’re likely to find the name of the literary agent who supported the author in finding the right publisher and getting their book out there in the world. Then, Google away to find contact information for this agent."
      />
      <SuawHeading level="3" content="How Many Agents to Approach" />
      <SuawParagraph text="You’re going to want to query multiple agents at the same time, and 10-12 literary agents is a good number to aim for in each query cycle." />
      <SuawParagraph
        text="Once you narrow down your list to the 10-12 agents who could be a good fit, take time to review their submission guidelines before you draft your query letter and any other material they’ve asked for, like a novel synopsis or nonfiction book proposal. Remember to get feedback from trusted peers on the material you’ve prepared before you start submitting queries to those literary agents."
      />
      <SuawParagraph
        text='A word of caution here—finding support online always comes with risks, so keep an eye out in order to avoid tricksters and scammers! When it comes to agents, red flags include charging a non-standard commission (higher than 15%) or a horror story about that agent on the <a href="https://accrispin.blogspot.com/" target="_blank">Writer Beware</a> website.'
      />
      <SuawHeading level="3" content="Timelines and Responses" />
      <SuawParagraph
        text='Once you start querying, it <a href="https://www.writersdigest.com/publishing-insights/11-authors-discuss-the-road-to-getting-a-literary-agent" target="_blank">can take a few months</a> before you hear back from agents, and it’s not uncommon to receive many rejections. But being a writer is all about persistence! You’ve already made it this far, and it’s important to pick yourself back up and keep trying.'
      />
      <SuawParagraph
        text="Although agents don’t typically read full manuscripts at first, you may get requests for your full manuscript, partial manuscript, or book proposal from an agent—a great sign! This means you’ve piqued their interest and are now one step closer to potentially signing on with someone.&nbsp;"
      />
      <SuawParagraph
        text="If, after querying dozens or more agents, you still haven’t received a positive response, you may want to revisit your query letter. There may also be issues with your actual manuscript or book proposal, which would be better to fix before you start another query cycle."
      />
      <SuawParagraph
        text="If you’re able to establish a back-and-forth chain of communication with an agent, use this as an opportunity to learn more about their sales record, communication style, knowledge of publishing contracts, and overall level of excitement about your project. During this process, you may decide that one agent feels like a particularly good fit amongst the batch."
      />
      <SuawParagraph
        text="Finding a literary agent takes time and patience. Although the process can take a while and is riddled with rejections, remember not to tie your self-worth to the outcome of your querying process. Sometimes, it’s just not the right time. But you wrote your manuscript or proposal because <em>you</em> believe in it. Most projects have room for improvement, but don’t let your inner critic trample your self-esteem! You’ve accomplished something amazing in developing your manuscript or proposal, and that’s something that no one can take away from you."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
