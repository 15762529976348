<script>
import { SuawSection, SuawInputGroup, SuawHeading, SuawThumbnail, SuawAlert, SuawParagraph, SuawEmptyState, SuawButton } from "@/components";
import { GetChapterViewForAdminBasic } from "../../operations.gql";
import * as ChapterApi from "../../api.js";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "ChapterViewBasic",
  components: {
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    SuawThumbnail,
    SuawAlert,
    SuawParagraph,
    SuawEmptyState,
    SuawButton
  },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //Basic
      loadingQueriesCount: 0,
      resultGetChapterViewForAdminBasic: {
        id: "",
        title: "",
        slug: "",
        photo_url: "",
        description_json: {},
        founded_at: "",
        deleted_at: "",
        place: {
          id: "",
          title: "",
          street_address: "",
          city: "",
          region: "",
          postal_code: "",
          country_abbrev: "",
          timezone: "",
          lat: "",
          lng: ""
        },
        meetup_chapter_map: {
          id: "",
          meetup_group_id: "",
          meetup_group_urlname: ""
        }
      }
    };
  },
  apollo: {
    resultGetChapterViewForAdminBasic: {
      query: GetChapterViewForAdminBasic,
      variables() {
        return {
          id: this.chapterId
        };
      },
      skip() {
        return !this.chapterId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    //Basic Info
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultGetChapterViewForAdminBasic.id !== "";
    },
    hasMeetup() {
      return !!this.resultGetChapterViewForAdminBasic.meetup_chapter_map;
    },
    meetupLink() {
      return `https://www.meetup.com/${this.resultGetChapterViewForAdminBasic.meetup_chapter_map?.meetup_group_urlname}/`;
    },
    foundedAt() {
      return this.formatDateAt(this.resultGetChapterViewForAdminBasic.founded_at);
    },
    dectivatedAt() {
      return this.formatDateAt(this.resultGetChapterViewForAdminBasic.deleted_at);
    },
    isDeactivated() {
      return !!this.resultGetChapterViewForAdminBasic.deleted_at;
    },
    deactivatedAlertMessage() {
      return `This chapter was deactivated on ${new Date(this.resultGetChapterViewForAdminBasic.deleted_at)}`;
    },
    chapterLink() {
      const route = this.$router.resolve({
        name: "Chapter",
        params: {
          chapterId: this.resultGetChapterViewForAdminBasic.id,
          chapterSlug: this.resultGetChapterViewForAdminBasic.slug
        }
      });
      return `${window.location.origin}${route.href}`;
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    async deactivateChapter() {
      const result = await ChapterApi.deactivateChapter(this.resultGetChapterViewForAdminBasic.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onDeactivateChapterClick() {
      const result = await this.deactivateChapter();
      if (!result.success) {
        return;
      }
      this.$apollo.queries.resultGetChapterViewForAdminBasic.refetch();
      this.$root.$emit("universal-success-message", "Chapter deactivated.");
    },
    async reactivateChapter() {
      const result = await ChapterApi.reactivateChapter(this.resultGetChapterViewForAdminBasic.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onReactivateChapterClick() {
      const result = await this.reactivateChapter();
      if (!result.success) {
        return;
      }
      this.$apollo.queries.resultGetChapterViewForAdminBasic.refetch();
      this.$root.$emit("universal-success-message", "Chapter reactivated.");
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawAlert v-if="isDeactivated" :message="deactivatedAlertMessage" type="critical" />
    <SuawSection>
      <SuawHeading content="Thumbnail Photo" level="5" />
    </SuawSection>
    <SuawSection>
      <SuawThumbnail :content="resultGetChapterViewForAdminBasic.photo_url" image-alt="chapter-photo" />
    </SuawSection>
    <SuawSection>
      <SuawHeading content="Details" level="5" />
      <SuawButton
        v-if="!isDeactivated"
        icon-left="IconMinus"
        button-text="Deactivate"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onDeactivateChapterClick"
      ></SuawButton>
      <SuawButton
        v-else
        icon-left="IconPlus"
        button-text="Reactivate"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onReactivateChapterClick"
      ></SuawButton>
    </SuawSection>
    <table>
      <tr>
        <td><strong>Chapter Detail Link</strong></td>
        <td>
          <a :href="chapterLink">{{ chapterLink }}</a>
        </td>
      </tr>
      <tr>
        <td><strong>Founded At</strong></td>
        <td>{{ foundedAt }}</td>
      </tr>
      <tr>
        <td><strong>Title</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.title }}</td>
      </tr>
      <tr>
        <td><strong>Slug</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.slug }}</td>
      </tr>
      <tr>
        <td><strong>Street Address</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.street_address }}</td>
      </tr>
      <tr>
        <td><strong>City</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.city }}</td>
      </tr>
      <tr>
        <td><strong>Region</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.region }}</td>
      </tr>
      <tr>
        <td><strong>Postal Code</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.postal_code }}</td>
      </tr>
      <tr>
        <td><strong>Country</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.country_abbrev }}</td>
      </tr>
      <tr>
        <td><strong>Timezone</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.timezone }}</td>
      </tr>
      <tr>
        <td><strong>Latitude</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.lat }}</td>
      </tr>
      <tr>
        <td><strong>Longitude</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.place.lng }}</td>
      </tr>
    </table>
    <SuawHeading v-if="hasMeetup" content="Meetup" level="5" />
    <table v-if="hasMeetup">
      <tr>
        <td><strong>Meetup Group ID</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.meetup_chapter_map?.meetup_group_id }}</td>
      </tr>
      <tr>
        <td><strong>Meetup Group URL Name</strong></td>
        <td>{{ resultGetChapterViewForAdminBasic.meetup_chapter_map?.meetup_group_urlname }}</td>
      </tr>
      <tr>
        <td><strong>Meetup Group Link</strong></td>
        <td>
          <a :href="meetupLink" target="_blank">{{ meetupLink }}</a>
        </td>
      </tr>
    </table>
    <SuawHeading content="About" level="5" />
    <SuawSection section-style="border">
      <SuawParagraph :text="resultGetChapterViewForAdminBasic.description_json" />
    </SuawSection>
  </SuawInputGroup>
</template>

<style lang="css" scoped>
</style>
