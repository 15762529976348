<script>
import Label from "../Label/Label.vue";

export default {
  name: 'Radio',
  components: {
    Label,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    name: {
      type: String,
      default: "formName",
    },
    value: {
      type: [String, Number, Boolean],
    },
    nativeValue: {
      type: [String, Number, Boolean],
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.value === this.nativeValue;
    },
    radioClasses() {
      return {
        'suaw-radio--disabled': this.disabled
      }
    },
  },
};
</script>

<template>
  <div 
    class="suaw-radio"
    :class="radioClasses"
  >
    <input
      type="radio"
      class="suaw-radio__control"
      :name="name"
      :checked="isChecked"
      :disabled="disabled"
      @change="$emit('change', nativeValue)"
      :value="nativeValue"
      :id="id"
    />
    <Label
      v-if="label"
      :labelFor="id"
      :labelText="label"
      size="medium"
      class="suaw-radio__label"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-radio {
  display: flex;
  align-items: center;

  &--disabled * {
    cursor: not-allowed;
  }

  &__control:disabled {
    ~ .suaw-radio__label {
      color: var(--sem-color-inactive-medium);
    }
  }

  &__label {
    margin-left: var(--sem-space-base);
  }
}
</style>
