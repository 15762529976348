<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawSearchInput, SuawLocationInput, SuawParagraph, SuawTabs, SuawEmptyState } from "@/components";
import EventEditFormButtons from "../components/EventEditFormButtons.vue";
import { GetEventLocationById } from "../../operations.gql";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as EventApi from "../../api.js";

export default {
  name: "EventEditLocation",
  components: { EventEditFormButtons, SuawForm, SuawHeading, SuawTextInput, SuawSearchInput, SuawLocationInput, SuawParagraph, SuawTabs, SuawEmptyState },
  props: {
    eventId: {
      type: String,
      required: true
    },
    isCancelled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      activeTabIndex: null,
      editEventPhysicalLocationForm: {
        lat: 0,
        lng: 0,
        physicalAddress: null,
        physicalTitle: null
      },
      editEventVirtualLocationForm: {
        virtualUrl: null,
        virtualMeetingId: null,
        virtualMeetingPassword: null
      },
      resultGetEventLocationById: {
        id: "",
        is_virtual: null,
        venue_json: {
          physicalTitle: "",
          physicalAddress: "",
          virtualUrl: "",
          virtualMeetingId: "",
          virtualMeetingPassword: ""
        },
        place: {
          id: "",
          lat: null,
          lng: null
        },
        event_series: {
          id: "",
          chapter: {
            id: ""
          }
        }
      }
    };
  },
  apollo: {
    resultGetEventLocationById: {
      query: GetEventLocationById,
      variables() {
        return {
          id: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingQueriesCount",
      result({ data }) {
        this.resultGetEventLocationById = data.resultGetEventLocationById;
        this.activeTabIndex = this.isVirtual ? 1 : 0;
        this.editEventPhysicalLocationForm.lat = this.lat;
        this.editEventPhysicalLocationForm.lng = this.lng;
        this.editEventPhysicalLocationForm.physicalAddress = this.physicalAddress;
        this.editEventPhysicalLocationForm.physicalTitle = this.physicalTitle;
        this.editEventVirtualLocationForm.virtualUrl = this.virtualUrl;
        this.editEventVirtualLocationForm.virtualMeetingId = this.virtualMeetingId;
        this.editEventVirtualLocationForm.virtualMeetingPassword = this.virtualMeetingPassword;
      }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEventLocation() {
      return this.resultGetEventLocationById.id !== "";
    },
    event() {
      return this.hasEventLocation ? this.resultGetEventLocationById : null;
    },
    isVirtual() {
      return this.hasEventLocation ? this.event.is_virtual : null;
    },
    isGlobalOnlineChapter() {
      return this.hasEventLocation ? this.event.event_series.chapter.slug === "shutupandwriteonlineevents" : null;
    },
    lat() {
      return this.hasEventLocation ? this.event.place.lat : null;
    },
    lng() {
      return this.hasEventLocation ? this.event.place.lng : null;
    },
    physicalAddress() {
      return this.hasEventLocation ? this.event.venue_json.physicalAddress : null;
    },
    physicalTitle() {
      return this.hasEventLocation ? this.event.venue_json.physicalTitle : null;
    },
    virtualUrl() {
      return this.hasEventLocation ? this.event.venue_json.virtualUrl : null;
    },
    virtualMeetingId() {
      return this.hasEventLocation ? this.event.venue_json.virtualMeetingId : null;
    },
    virtualMeetingPassword() {
      return this.hasEventLocation ? this.event.venue_json.virtualMeetingPassword : null;
    }
  },
  validations: {
    editEventPhysicalLocationForm: {
      physicalTitle: { required },
      physicalAddress: { required }
    },
    editEventVirtualLocationForm: {
      virtualUrl: { required }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    onCancelClicked() {
      this.$emit("cancel");
    },
    onBackClicked() {
      this.$emit("back");
    },
    onUncancelClicked() {
      this.$emit("uncancel");
    },
    onPlaceSelected(place) {
      this.editEventPhysicalLocationForm.physicalTitle = place.name;
      this.editEventPhysicalLocationForm.physicalAddress = place.formatted_address;
      this.editEventPhysicalLocationForm.lat = place.geometry.location.lat();
      this.editEventPhysicalLocationForm.lng = place.geometry.location.lng();
    },
    async updateEventOccurrencePhysicalLocation() {
      const { lat, lng, physicalAddress, physicalTitle } = this.editEventPhysicalLocationForm;
      const result = await EventApi.updateEventOccurrencePhysicalLocation(this.eventId, lat, lng, physicalAddress, physicalTitle);
      return result;
    },
    async onConfirmPhysicalClicked() {
      this.$v.editEventPhysicalLocationForm.$touch();
      if (this.$v.editEventPhysicalLocationForm.$invalid) {
        this.$root.$emit("universal-error-message", "The form is invalid.");
      } else {
        const updateResult = await this.updateEventOccurrencePhysicalLocation();
        if (!updateResult.success) {
          this.$root.$emit("universal-error-message", updateResult.error);
          return;
        }
        this.$root.$emit("universal-success-message", "You have successfully updated your event location.");
        this.$emit("updated");
      }
    },
    async updateEventOccurrenceVirtualLocation() {
      const { virtualUrl, virtualMeetingId, virtualMeetingPassword } = this.editEventVirtualLocationForm;
      const { lat, lng } = this.editEventPhysicalLocationForm;
      const result = await EventApi.updateEventOccurrenceVirtualLocation(this.eventId, lat, lng, virtualUrl, virtualMeetingId, virtualMeetingPassword);
      return result;
    },
    async onConfirmVirtualClicked() {
      this.$v.editEventVirtualLocationForm.$touch();
      if (this.$v.editEventVirtualLocationForm.$invalid) {
        this.$root.$emit("universal-error-message", "The form is invalid.");
      } else {
        const updateResult = await this.updateEventOccurrenceVirtualLocation();
        if (!updateResult.success) {
          this.$root.$emit("universal-error-message", updateResult.error);
          return;
        }
        this.$root.$emit("universal-success-message", "You have successfully updated your event location.");
        this.$emit("updated");
      }
    }
  }
};
</script>

<template>
  <SuawForm v-if="hasEventLocation && !isLoading">
    <template #form>
      <SuawHeading level="4" content="Event Type" />
      <SuawParagraph v-if="isGlobalOnlineChapter" text="Events in the Global Online Chapter must be online." />
      <SuawTabs v-model="activeTabIndex" :tabs="[{ label: 'In-Person' }, { label: 'Online' }]" tabs-type="buttons" :disabled="isGlobalOnlineChapter">
        <template #tab-0>
          <SuawHeading level="4" content="Location" />
          <SuawSearchInput
            v-model="editEventPhysicalLocationForm.physicalAddress"
            placeholder="Venue"
            :use-google="true"
            :show-icon="true"
            :is-required="true"
            :state="formFieldState($v, 'editEventPhysicalLocationForm', 'physicalAddress')"
            :error-message="validateErrors($v.editEventPhysicalLocationForm.physicalAddress, 'Venue address')"
            @blur="$v.editEventPhysicalLocationForm.physicalAddress.$touch()"
            @item-select="onPlaceSelected"
          />
          <SuawTextInput
            id="eventHostCity"
            v-model="editEventPhysicalLocationForm.physicalTitle"
            type="text"
            is-required
            label="Venue Display Name"
            placeholder="How the venue name will be displayed in your series."
            :state="formFieldState($v, 'editEventPhysicalLocationForm', 'physicalTitle')"
            :error-message="validateErrors($v.editEventPhysicalLocationForm.physicalTitle, 'Venue name')"
            @blur="$v.editEventPhysicalLocationForm.physicalTitle.$touch()"
          />
          <SuawLocationInput
            :location-name="editEventPhysicalLocationForm.physicalTitle"
            :location-address="editEventPhysicalLocationForm.physicalAddress"
            :lat="editEventPhysicalLocationForm.lat"
            :lng="editEventPhysicalLocationForm.lng"
            :zoom="15"
            :editable="true"
          />
          <EventEditFormButtons
            :is-cancelled="isCancelled"
            @cancel="onCancelClicked"
            @back="onBackClicked"
            @confirm="onConfirmPhysicalClicked"
            @uncancel="onUncancelClicked"
          />
        </template>
        <template #tab-1>
          <SuawHeading level="4" content="Video Conferencing" />
          <SuawTextInput
            id="eventMeetingUrl"
            v-model="editEventVirtualLocationForm.virtualUrl"
            name="eventMeetingUrl"
            type="text"
            no-label
            is-required
            placeholder="Meeting URL"
            :state="formFieldState($v, 'editEventVirtualLocationForm', 'virtualUrl')"
            :error-message="validateErrors($v.editEventVirtualLocationForm.virtualUrl, 'Meeting URL')"
            @blur="$v.editEventVirtualLocationForm.virtualUrl.$touch()"
          />
          <SuawTextInput id="virtual-meeting-id" v-model="editEventVirtualLocationForm.virtualMeetingId" type="text" no-label placeholder="Meeting ID (Optional)" />
          <SuawTextInput id="virtual-meeting-password" v-model="editEventVirtualLocationForm.virtualMeetingPassword" type="text" no-label placeholder="Passcode (Optional)" />
          <EventEditFormButtons :is-cancelled="isCancelled" @cancel="onCancelClicked" @back="onBackClicked" @confirm="onConfirmVirtualClicked" @uncancel="onUncancelClicked" />
        </template>
      </SuawTabs>
    </template>
  </SuawForm>
  <SuawEmptyState v-else-if="isLoading" message="Loading Your Event Location..." />
  <SuawEmptyState v-else message="No Event Found" />
</template>
