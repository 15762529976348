<script>
import { SuawMainContent, SuawSubheaderTwo, SuawSection, SuawDivider } from "@/components";
import OnlineEventsListPipe from "../components/OnlineEventsListPipe.vue";
import BecomeOrganizer from "../../../common/components/BecomeOrganizer.vue";
import { GetChapterBySlug } from "../../operations.gql";
import onlineEventDiscoveryImage from "../../../../../public/img/home/online-event-discovery-image.svg";
export default {
  name: "OnlineEventDiscovery",
  components: {
    BecomeOrganizer,
    SuawMainContent,
    OnlineEventsListPipe,
    SuawSubheaderTwo,
    SuawSection,
    SuawDivider
  },
  props: {
    selectedDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      onlineEventDiscoveryImage,
      resultGetChapterBySlug: [
        // {
        //   "id": "",
        //   "chapter_organizers": [
        //     {
        //       "id": ""
        //     }
        //   ]
        // }
      ]
    };
  },
  apollo: {
    resultGetChapterBySlug: {
      query: GetChapterBySlug,
      variables() {
        return {
          slug: "shutupandwriteonlineevents"
        };
      }
    }
  },
  computed: {
    chapterId() {
      return this.resultGetChapterBySlug[0]?.id || "";
    },
    isOrganizer() {
      if (!this.$auth.isAuthenticated) return false;
      const organizers = this.resultGetChapterBySlug[0]?.chapter_organizers || [];
      return organizers.some(member => member.user_id === this.$auth.user.id);
    },
    showButton() {
      return this.isOrganizer;
    }
  },
  methods: {
    async onClickCreateEventSeries() {
      const chapterId = this.resultGetChapterBySlug[0].id;
      this.$router.push({
        name: "SeriesCreate",
        params: {
          chapterId: chapterId
        },
        query: {
          step: "schedule"
        }
      });
    }
  }
};
</script>

<template>
  <SuawMainContent class="suaw-main-content" :show-gradient="true" :small-top-margin="true">
    <SuawSubheaderTwo
      title="Online Events"
      description="Explore our list of online writing events and find the perfect space to write, connect, and grow. Join an event that fits your schedule and start your writing journey today."
      :show-button="showButton"
      button-text="Create Event Series"
      :image-source="onlineEventDiscoveryImage"
      image-alt="Writer working on her laptop sitting on top of a calendar with a clock in the background."
      @click="onClickCreateEventSeries"
    />
    <SuawSection>
      <OnlineEventsListPipe :chapter-id="chapterId" :initial-selected-date="selectedDate" />
    </SuawSection>
    <SuawDivider />
    <SuawSection>
      <BecomeOrganizer />
    </SuawSection>
  </SuawMainContent>
</template>
