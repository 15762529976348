<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "TheElementsOfStorytellingForNovelists",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="TheElementsOfStorytellingForNovelists">
      <SuawParagraph
        text="Writing a novel involves juggling multiple elements of storytelling, like plot, characters, point of view, setting, pacing, and perhaps the most elusive one of all, tone. Many successful combinations of these elements are possible, but how do you find a winning recipe for your own novel? Here’s where you can start:"
      />
      <SuawHeading level="3" content="First, Find Your Story" />
      <SuawParagraph
        text="All novelists start with an initial spark that ignites their desire to write a novel, perhaps triggered by a colorful dream or an event in real life. Maybe you watched a movie and decided that you could tell the same story in a fresh new way. Or maybe you remembered a story a parent told you about one of your ancestors, and you’re inspired to imagine their life journey in a fictional form."
      />
      <SuawParagraph
        text="Whatever it is, I recommend you first take a few pages to do some freewriting about this “spark,” whether it is a dream, event, movie, or family story. You can freewrite in prose or outline using a bulleted list. The intention is to pour out on the page as much as possible about your newly-sparked inspiration."
      />
      <SuawParagraph
        text="You may want to keep at this for a few days to uncover all that your novel-to-be could potentially contain. As you progress, you will see some themes emerge, and quite possibly, the beating heart of your book. Who is your protagonist? What is their main arc? What is their goal and what are the obstacles in their way? If you were to summarize what happens across the entire novel in one or two sentences, what would that summary say? Can you picture the longer blurb which will be printed on the back of the book someday?"
      />
      <SuawHeading level="3" content="Plot and Characters" />
      <SuawParagraph
        text="The above exercise likely revealed your protagonist and some of your plot. Plot and characters are deeply intertwined—you can’t have one without the other. Who will be doing what during the twists and turns of your novel? In what sequence will these events be most likely to engage your reader?"
      />
      <SuawParagraph
        text='<a target="_blank" href="https://www.jessicabrody.com/books/non-fiction/save-cat-writes-novel/about/">Jessica Brody’s “Save The Cat! Writes a Novel”</a> is an excellent resource to review while pondering these questions. This book provides a step-by-step overview of the plot conventions of ten genres that Brody defines afresh. Even if you’re not yet sure which category your novel fits into, reviewing the structural elements of a few different genres is a great way to get inspired and hone in on what feels right for your story. Which story “shape” matches most closely to the rough contours of your own story?'
      />
      <SuawParagraph
        text="You may choose your genre inadvertently when you decide between the set of plot points represented by “Dude With a Problem,” “Monster in the House,” or one of the other types of sequences presented in Brody’s book. This applies especially to those of us who are new to novel-writing and unsure of the conventions used in different genres of fiction."
      />
      <SuawParagraph
        text="From this bare bone string of “beats”, i.e. plot inflections, you can start to develop a list of scenes which will create the narrative progression of your story."
      />
      <SuawParagraph
        text="You may be the type of writer who wants to have each scene outlined at a high level before starting the actual writing—a “planner”—or you may want to discover your plan while writing, with the specific futures of your characters unknown even to you—a “pantser” (the term referring to the idea of writing by the seat of your pants). Either way, it can help to know the beats you’ll be writing, since this is at the core of your novel’s structure."
      />
      <SuawHeading level="3" content="Pacing" />
      <SuawParagraph
        text="Pacing is a storytelling element that goes hand-in-hand with plot and character development. Typically, pacing ends up happening along the way without the author having to think much about it. However, you can deliberately control your pacing if, for example, you’re excited about the idea of a particularly fast (or slow) pace."
      />
      <SuawParagraph
        text="To manipulate the pace of your events, play around with the length of each sentence, as well as each scene. Shorter sentences and scenes can create a sense of quick movement for your reader, whereas longer, more baroque sentences and lengthy scenes will slow down the action and allow your reader to linger in the moments you’ve created."
      />
      <SuawParagraph
        text="Whether you generally prefer to use shorter or longer sentences, do make sure that you vary the length enough within each paragraph for some variety that can help hold your reader’s interest."
      />
      <SuawHeading level="3" content="Point of View" />
      <SuawParagraph
        text="To avoid painful rewrites down the road, it’s best to decide early on the point of view you’ll be using. Through whose eyes will the reader be witnessing the events of your story? Will you be sticking with one character’s experience, or alternating between two or more main characters? Is the intimacy of the first-person point of view necessary to create a feeling of closeness with your reader, or can you achieve this using a third-person point of view? A truly omniscient narrator is quite rare in contemporary fiction, but perhaps that’s exactly what your story needs. You can take some time to decide, and even write your way into it by diving right into a scene and seeing who shows up on the page to tell the story."
      />
      <SuawHeading level="3" content="Setting and Tone" />
      <SuawParagraph
        text="Some of us start off with an immediate, visceral sense of where our stories will take place. Your novel may be set in a contemporary, realistic world, or a fantastical new universe of your own imagining. You may know the exact places where many scenes occur, and even how your characters use their environment to move through the plot."
      />
      <SuawParagraph
        text="On the other hand, you might not have a particularly strong sense of where you would like your novel to take place, which is okay. One way to get closer to the setting of your novel is, again, to do some freewriting. Begin with a fresh piece of paper or new document and start brainstorming. Are you telling a suspenseful story with lots of action, or a slow-burning love story? A humorous set of vignettes, or a philosophical narrative? The type of tale you wish to weave will involve your setting to different degrees. In fact, setting ideally helps to express the trickiest element of storytelling—tone. There’s a reason that the movie “The Matrix” begins in a greenish, abandoned hotel, with Trinity fleeing the police—this setting immerses the viewer in the grim, technological tone of the overall narrative. Similarly, your task as a writer is to pick settings which convey a particular feeling."
      />
      <SuawParagraph
        text="You can choose different tones and settings for different scenes, but make sure that you approach the changes in a considered way. Sometimes, you need a humorous change after a dark, funereal scene to keep the story fresh—but at other times, you need to double down on the darkness and suspense. Doing this well is more art than science, and a skill you can definitely improve upon with practice."
      />
      <SuawParagraph
        text="At first glance, balancing these many elements of storytelling for the duration of a novel can seem overwhelming, but remember, your first draft doesn’t have to be perfect—it just has to exist! You’ll have many chances to edit and polish your work once you’re done with your first draft. And if you’re looking for accountability and support as you move along in your process, you always have the option of attending a Shut Up & Write! session, many of which our Organizers are now hosting online!"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
