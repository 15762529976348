<script>
import { SuawMainContent, SuawSection, SuawInputGroup, SuawHeading } from "@/components";
import UserViewBasic from "../components/UserViewBasic.vue";
import UserViewChapters from "../components/UserViewChapters.vue";
import UserViewComplaintsProfile from "../components/UserViewComplaintsProfile.vue";
import UserViewComplaintsAvatar from "../components/UserViewComplaintsAvatar.vue";
import UserViewComplaintsPost from "../components/UserViewComplaintsPost.vue";
import UserViewTokens from "../components/UserViewTokens.vue";
import UserViewLogins from "../components/UserViewLogins.vue";

export default {
  name: "UserView",
  components: {
    SuawMainContent,
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    UserViewBasic,
    UserViewChapters,
    UserViewComplaintsProfile,
    UserViewComplaintsAvatar,
    UserViewComplaintsPost,
    UserViewTokens,
    UserViewLogins
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <SuawSection section-size="medium">
      <SuawInputGroup direction="column">
        <SuawHeading content="User Admin" level="3" />
        <UserViewBasic :user-id="userId" />
        <UserViewChapters :user-id="userId" />
        <UserViewComplaintsProfile :user-id="userId" />
        <UserViewComplaintsAvatar :user-id="userId" />
        <UserViewComplaintsPost :user-id="userId" />
        <UserViewTokens :user-id="userId" />
        <UserViewLogins :user-id="userId" />
      </SuawInputGroup>
    </SuawSection>
  </SuawMainContent>
</template>

<style lang="css" scoped></style>
