<script>
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardChapter",
  components: {
    Thumbnail,
    Heading,
    Paragraph,
    Chip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
    },
    heading: {
      type: String,
    },
    description: {
      type: Object,
    },
    members: {
      type: String,
    },
    location: {
      type: String,
    },
    chipItems: {
      type: Array,
      default: () => []
    },
  },
};
</script>

<template>
  <div 
    class="suaw-card__content"
    @click.stop="$emit('click', id)"
  >
    <Thumbnail
      size="large"
      :content="thumbnail"
      class="suaw-thumbnail--fit"
    />
    <Heading
      level="5"
      :content="heading"
    />
    <Paragraph
      v-if="description"
      weight="normal"
      size="small"
      :text="description"
    />
    <Chip
      v-for="(chip, index) in chipItems"
      :key="index"
      size="medium"
      :status="chip.status"
      :icon="chip.icon"
      :label="chip.label"
      chipType="icon"
      :pill="chip.pill"
    />
    <slot name="chips"></slot>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    transition: transform .25s linear;
  }

  &__content:hover {
    cursor: pointer;
    transform: scale(0.99);
  }
}
</style>
