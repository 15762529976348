<script>
import { SuawInputGroup, SuawHeading, SuawEmptyState, SuawTable, SuawTableItem, SuawButton, SuawTextInput } from "@/components";
import { GetUserViewForAdminComplaintsAvatar } from "../../operations.gql";
import * as UserApi from "@/features/users/api.js";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "UserViewComplaintsAvatar",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState,
    SuawTable,
    SuawTableItem,
    SuawButton,
    SuawTextInput
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserView: {
        id: "",
        complaints_avatars_reporting: [
          // {
          //   id: "",
          //   comment: "",
          //   created_at: "",
          //   reported_user: {
          //     id: "",
          //     email: "",
          //     first_name: "",
          //     last_name: "",
          //     avatar_color: "",
          //     avatar_url: "",
          //     initials: ""
          //   }
          //   complaints_avatars_resolution {
          //     id
          //   }
          // }
        ],
        complaints_avatars: [
          // {
          //   id: "",
          //   comment: "",
          //   created_at: "",
          //   reporting_user: {
          //     id: "",
          //     email: "",
          //     first_name: "",
          //     last_name: "",
          //     avatar_color: "",
          //     avatar_url: "",
          //     initials: ""
          //   }
          //   complaints_avatars_resolution {
          //     id
          //   }
          // }
        ]
      },
      resolvingComplaints: [],
      resolutionComment: null
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminComplaintsAvatar,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.id !== "";
    },
    complaintAvatarMadeTableItems() {
      return this.resultUserView.complaints_avatars_reporting && this.resultUserView.complaints_avatars_reporting.length > 0
        ? this.complaintAvatarMadeTableProps(this.resultUserView.complaints_avatars_reporting)
        : [];
    },
    complaintAvatarReceivedTableItems() {
      return this.resultUserView.complaints_avatars && this.resultUserView.complaints_avatars.length > 0
        ? this.complaintAvatarReceivedTableProps(this.resultUserView.complaints_avatars.filter(c => !this.resolvingComplaints.find(r => r.id === c.id)))
        : [];
    },
    resolvingTableItems() {
      return this.resolvingComplaints.length > 0
        ? this.resolvingTableProps(this.resultUserView.complaints_avatars.filter(c => this.resolvingComplaints.find(r => r.id === c.id)))
        : [];
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    complaintAvatarMadeTableProps(complaintAvatarMadeItems) {
      return complaintAvatarMadeItems.map(x => ({
        id: x.id,
        userId: x.user.id,
        avatar: x.user.avatar_url ?? x.user.initials,
        color: x.user.avatar_url ? "picture" : x.user.avatar_color,
        contents: [
          {
            text: `${x.user.first_name} ${x.user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          },
          ...(x.complaints_avatars_resolution
            ? [
                {
                  text: "Resolution",
                  subtext: x.complaints_avatars_resolution.comment
                }
              ]
            : [])
        ],
        button: x.complaints_avatars_resolution ? "Resolved" : "View Avatar"
      }));
    },
    onComplaintAvatarMadeViewClick(item) {
      this.$router.push({ name: "UserView", params: { userId: item.userId } });
    },
    complaintAvatarReceivedTableProps(complaintAvatarReceivedItems) {
      return complaintAvatarReceivedItems.map(x => ({
        id: x.id,
        userId: x.reporting_user.id,
        avatar: x.reporting_user.avatar_url ?? x.reporting_user.initials,
        color: x.reporting_user.avatar_url ? "picture" : x.reporting_user.avatar_color,
        contents: [
          {
            text: `${x.reporting_user.first_name} ${x.reporting_user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          },
          ...(x.complaints_avatars_resolution
            ? [
                {
                  text: "Resolution",
                  subtext: x.complaints_avatars_resolution.comment
                }
              ]
            : [])
        ],
        button: x.complaints_avatars_resolution ? "Resolved" : "View Complainer",
        buttonCritical: x.complaints_avatars_resolution ? null : "Resolve"
      }));
    },
    onComplaintAvatarReceivedViewClick(item) {
      this.$router.push({ name: "UserView", params: { userId: item.userId } });
    },
    onComplaintAvatarReceivedResolveClick(item) {
      this.resolvingComplaints.push(item);
    },
    resolvingTableProps(complaintReceivedItems) {
      return complaintReceivedItems.map(x => ({
        id: x.id,
        userId: x.reporting_user.id,
        avatar: x.reporting_user.avatar_url ?? x.reporting_user.initials,
        color: x.reporting_user.avatar_url ? "picture" : x.reporting_user.avatar_color,
        contents: [
          {
            text: `${x.reporting_user.first_name} ${x.reporting_user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: "Undo"
      }));
    },
    onUndoClick(item) {
      const i = this.resolvingComplaints.findIndex(c => c.id === item.id);
      if (i !== -1) {
        this.resolvingComplaints.splice(i, 1);
      }
    },
    async resolveComplaint() {
      var ids = this.resolvingComplaints.map(c => c.id);
      const result = await UserApi.resolveComplaintAvatar(ids, this.resolutionComment);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSaveResolution() {
      if (!this.resolutionComment) {
        this.$root.$emit("universal-error-message", "A resolution comment is required.");
        return;
      } else {
        const result = await this.resolveComplaint();
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        } else {
          this.$root.$emit("universal-success-message", "Complaint(s) resolved.");
          this.$apollo.queries.resultUserView.refetch();
          this.resolvingComplaints = [];
          this.resolutionComment = null;
        }
      }
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawInputGroup v-if="complaintAvatarReceivedTableItems.length > 0" direction="column">
      <SuawHeading content="Avatar Complaints Received" level="5" />
      <SuawTable>
        <SuawTableItem
          v-for="item in complaintAvatarReceivedTableItems"
          :key="item.id"
          :item="item"
          @message-click="onComplaintAvatarReceivedViewClick"
          @report-click="onComplaintAvatarReceivedResolveClick"
        />
      </SuawTable>
    </SuawInputGroup>
    <SuawInputGroup v-if="resolvingComplaints.length > 0" direction="column">
      <SuawTextInput
        id="resolutionComment"
        v-model="resolutionComment"
        type="text"
        label="Avatar Complaint Resolution Comment"
        is-required
        placeholder="Explain how you resolved the Avatar complaint(s) listed below."
      />
      <SuawTable>
        <SuawTableItem v-for="item in resolvingTableItems" :key="item.id" :item="item" @message-click="onUndoClick" />
      </SuawTable>
      <SuawButton
        v-if="resolvingComplaints.length > 0"
        icon-left="IconDone"
        button-text="Save Resolution"
        type="primary"
        class="suaw-button--pull-left"
        size="medium"
        @click="onSaveResolution"
      ></SuawButton>
    </SuawInputGroup>
    <SuawHeading v-else content="No Avatar Complaints Received" level="6" />
    <SuawInputGroup v-if="complaintAvatarMadeTableItems.length > 0" direction="column">
      <SuawHeading content="Avatar Complaints Made" level="5" />
      <SuawTable>
        <SuawTableItem v-for="item in complaintAvatarMadeTableItems" :key="item.id" :item="item" @message-click="onComplaintAvatarMadeViewClick" />
      </SuawTable>
    </SuawInputGroup>
    <SuawHeading v-else content="No Avatar Complaints Made" level="6" />
  </SuawInputGroup>
</template>

<style lang="css" scoped></style>
