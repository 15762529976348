<script>
import ResourceFrame from "../../components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";
export default {
  name: "NewOrganizerStartHere",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">New Organizer? <b>Start Here</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="As a Shut Up & Write! organizer, you are as much a community builder as you are a writer. With every event you host, you cultivate a positive and supportive environment for the writing community in your neighborhood, whether in person or online. Here's how to get started."
      />
      <h2 class="suaw-organizer-resource-subtitle">
        In-Person or Online?
      </h2>
      <p class="suaw-paragraph">
        First, decide whether you would like to host your events in person or online. There are a few factors you might want to consider when you make this decision.
      </p>
      <p class="suaw-paragraph">
        <b>In-Person:</b> You'll need to pick a venue for your in-person event. Great places to host include your local coffee shop, library, or bookstore. Choosing a venue requires preparation, but for some writers, nothing beats the connection and productivity gained from meeting face-to-face on a regular basis. (For more information, check out <router-link :to="{ name: 'HostingYourInPersonEvent' }">Hosting Your In-Person Event</router-link>.)
      </p>
      <p class="suaw-paragraph">
        <b>Online:</b> Hosting an event online has a few advantages: it's incredibly convenient, can be done from anywhere with a good internet connection, and allows writers to participate who may otherwise not be able to attend in-person events. Hosting an online event requires the use of a paid account on a video conferencing platform that allows for a meeting to run 60+ minutes. (For more information, check out <router-link :to="{ name: 'HostingYourOnlineEvent' }">Hosting Your Online Event</router-link>.)
      </p>
      <h2 class="suaw-organizer-resource-subtitle">
        Timing Is Everthing
      </h2>
      <p class="suaw-paragraph">
        Next, you'll need to pick a time for your event. Most organizers run Shut Up & Write! sessions once a week, but some like to run events even more frequently. Choose a recurring date and time that works with your schedule and is also accessible for other writers, such as mid-morning on Saturdays, or weekdays after work.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">
        Choose Your Format
      </h2>
      <p class="suaw-paragraph">
        How long are you going to write? A classic Shut Up & Write! is an hour of focused writing time, which is the duration we recommend for most organizers, especially those just starting out. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. If you'd like to write for a long period of time, remember to schedule breaks.
      </p>
      <p class="suaw-paragraph">
        What defines a Shut Up & Write! event? No read-alouds, no critiques, no feedback. Just writing.
      </p>
      <SuawHeading level="4" content='The "Classic" Shut Up & Write!' />
      <p class="suaw-paragraph">
        Start with 15 minutes of introductions. Then, set a timer for the duration of your event, start the timer, and tell everyone to "Shut Up & Write!" Use about 15 minutes at the end of the event to check in and share how your writing went. Then, offer an opportunity for everyone to stay and socialize.
      </p>
      <SuawHeading level="4" content="Sample Shut Up & Write! Event Schedule" />
      <p class="suaw-paragraph">
        <b>7:00 - Brief Introductions:</b>
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              The organizer welcomes everyone, then invites attendees to go around in a circle, give their names, and share what they'll be working on.
            </p>
          </li>
        </ul>
      </div>
      <p class="suaw-paragraph">
        <b>7:15 - Write for an Hour:</b>
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              The organizer sets a timer for an hour, then tells everyone to "Shut Up & Write!"
            </p>
          </li>
        </ul>
      </div>
      <p class="suaw-paragraph">
        <b>8:15 - Wrap Up with Optional Socializing:</b>
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              Timer goes off to signify the conclusion of the event.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              Everyone goes around again and shares their feelings about their writing session and what they accomplished.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              The official event is now over, but people can stay and socialize for as long as they want.
            </p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">
        List Your Event
      </h2>
      <p class="suaw-paragraph">
        Ready to get the word out about your event? To help our organizers reach as many writers as possible, we list all events on both
        <a href='https://www.meetup.com/pro/shut-up-write/' target="_blank" rel="noopener noreferrer">Meetup</a> and our own Shut Up & Write! website. Contact <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a> and we'll help you
        set everything up.
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              For a step-by-step reference, check out <router-link :to="{ name: 'ListingYourInPersonEvent' }">Listing Your In-Person Event</router-link>.
              <!-- and <router-link :to="{ name: 'ListingYourOnlineEvent' }">Listing Your Online Event</router-link>. -->
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Spread the Word
      </h2>
      <p class="suaw-paragraph">
        Now that you've listed your event, other writers in our network will be able to find it! Additionally, we encourage you to promote your events on social media or leave flyers at your chosen venue.
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              Need more tips? Check out <router-link :to="{ name: 'PromotingYourEvent' }">Promoting Your Event</router-link>.
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Last But Not Least…
      </h2>
      <p class="suaw-paragraph">
        <b>Ask a Friend to Help:</b> Having a co-host is a great way to have ongoing support, and we encourage this whenever possible. Try asking on social media or at the end of your event to find potential co-hosts. A co-host can serve as an emergency backup, be as involved as the main organizer, or strike a balance in between. If you identify a co-host, find an arrangement that works best for everyone. (Yes, you can have multiple co-hosts!)
      </p>
      <p class="suaw-paragraph">
        <b>Know the Code:</b> Shut Up & Write! events are a space for accountable writing time. Be sure that you and your writers are following our <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link> at all events.
      </p>
      <p class="suaw-paragraph">
        <b>Keep Us Posted:</b> Regularly check in with the Shut Up & Write! Community Team about your events, ideas you have about how to support your group, and any <a href='https://shutupwrite.typeform.com/to/EI3Ohubg' target="_blank" rel="noopener noreferrer">success stories</a> you'd like to share. Email us at <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a>.
      </p>
      <p class="suaw-paragraph">
        We genuinely hope you find hosting Shut Up & Write! events to be a rewarding and productive experience. We're grateful you've decided to join our community. Enjoy your writing group!
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-organizer-resource-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
//list
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
