<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToEditTheFirstDraftOfYourNovel",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToEditTheFirstDraftOfYourNovel">
      <SuawParagraph
        text="In July this year, I completed the first draft of my manuscript! It felt glorious to reach this milestone after the months of emotional ups and downs which typically comprise the development of a first draft. Luckily, I had the support of the Shut Up &amp; Write! community throughout the process."
      />
      <SuawParagraph
        text="But a completed first draft is still a long way from being publishable. Each time I wavered during the drafting process, I reminded myself that I could always fix it later. This idea was the wind behind my wings as I speed-drafted the raw, messy version of my manuscript over the course of seven months."
      />
      <SuawParagraph
        text="Well, now it’s “later.” How on earth am I going to embark upon the Herculean task of “fixing” my draft? Minor corrections like typos are one thing, but what I have in front of me is a massive document in need of a full round of developmental self-editing. This is a stage of revision that involves complex changes like reordering plot, deepening relationships between characters, and even getting rid of some characters altogether. Where should I even start?"
      />
      <SuawParagraph
        text="If you’re feeling similarly overwhelmed about your first draft, you’re not alone. Here are some of the ways I’ve chosen to structure my editing process."
      />
      <SuawHeading level="3" content="1. Let It Sit" />
      <SuawParagraph
        text="Gaining perspective on your novel requires that you wait a little while before jumping into revisions. But how long should you wait? Some authors can start revising within a few days after finishing a draft, while others prefer to wait for a couple of weeks or even as long as a few months. You’ll know you’ve let it sit for the right amount of time once you can pick up your draft and feel a little surprised at what’s on the page. This means you’re reading it with fresh eyes, and it’s a good time to start editing."
      />
      <SuawHeading level="3" content="2. Find Your Novel’s Purpose" />
      <SuawParagraph
        text="Whether you’ve crafted a new fantasy universe of your own or set your novel in our ordinary reality, you are still the creator of your novel’s world. Wielding this power means you get to decide what is most important. What are the biggest things you want your audience to take away from reading your novel? Do you want them biting their nails while traversing the twists and turns in your plot, or would you rather they laugh and cry alongside your protagonist? Typically, you will have multiple goals for the impact you want to have on your readers, but getting specific with your primary objectives is critical to guiding the revision process."
      />
      <SuawParagraph
        text="Keep in mind that goals can change during the drafting process. You may, for example, have started out thinking your book was going to be an epic fantasy novel, but then found yourself gravitating instead towards an intimate portrait of your main character’s struggles. That’s okay! But take the time to reflect on the overall imprint you want to leave on your reader before you start revising."
      />
      <SuawHeading level="3" content="3. Read It Through" />
      <SuawParagraph
        text="Once you have a sense of what kind of novel you’re aiming for, do a full read-through <em>without making any changes.</em> Set aside a few days to do this, with time allotted for taking copious notes in a separate document."
      />
      <SuawParagraph
        text="As you read your draft, take notes on what you like and don’t like about each chapter. Do this sequentially so you can read chapter-by-chapter notes on what’s working well and what needs work. Remember, the question of “what needs work” has to do with your desired impact. If you’re aiming for a fast-paced thriller but your plot is sleepy in places, then the tension in your plot needs work. If you’re aspiring to a poignant, heartbreaking tale about people living in a small town, but your characters feel one-dimensional, then your character development needs work. Even though this will be your second draft, you can’t fix everything at once, so prioritize core changes and leave a more extensive wishlist in a separate section for each chapter."
      />
      <SuawHeading level="3" content="4. List Your Problems" />
      <SuawParagraph text="After you have your chapter notes, do a read-through of <em>those</em> and sort the to-do items into the following categories:" />
      <SuawParagraph text="<strong>Plot</strong>" />
      <SuawParagraph
        text="<ul><li>Example: “In chapters 7 and 8, my characters are just sitting around doing nothing.”</li><li>Example: “Chapters 11 and 18 don’t end in a compelling way.”</li><li>Example: “The ending of my book feels too drawn out.”</li></ul>"
      />
      <SuawParagraph text="<strong>Setting</strong>" />
      <SuawParagraph
        text="<ul><li>Example: “The forest and the lake can’t logically be so close together.”</li><li>Example: “It’s hard to visualize my protagonist’s workplace, where a lot of the action happens.”</li></ul>"
      />
      <SuawParagraph text="<strong>Characters</strong>" />
      <SuawParagraph
        text="<ul><li>Example: “My protagonist’s motivation doesn’t seem realistic.”</li><li>Example: “I never explained why my protagonist is bonded to her best friend.”</li><li>Example: “There are too many characters at the beginning, and it’s hard to tell them apart.”</li></ul>"
      />
      <SuawParagraph text="<strong>Tone</strong>" />
      <SuawParagraph
        text="<ul><li>Example: “My dialogue has a lot of jokes, but I’d rather my book be suspenseful and inspire feelings of thrill and fear.”</li><li>Example: “In Chapters 1-3, I overuse the word ‘creepy’ in lieu of actually showing what makes the situation creepy.”</li></ul>"
      />
      <SuawParagraph
        text="Organizing your insights in this way gives you what I call “The Problems List,” which clearly lays out everything you want to fix in your second draft."
      />
      <SuawHeading level="3" content="5. Find Solutions" />
      <SuawParagraph text="Now it’s time to brainstorm solutions to each problem in your list. First, make a copy of your Problems List and name it “The Solutions List.”" />
      <SuawParagraph
        text="Then, let yourself imagine multiple ways to fix each of the problems in your manuscript, jotting them down in as organized a way as you can manage. Work through this list one item at a time, and don’t be afraid to take as long as you need to brainstorm solutions for some of the hardest problems."
      />
      <SuawParagraph
        text="Finally, for each problem you’ve identified, go through and select the solution you like best and which will be the most compatible with the other solutions. For example, if you decide your protagonist needs a stronger motivation, and you choose to go with “revenge against the childhood best friend who bullied her,” make sure that you’re not using the childhood best friend in a conflicting role, such as a shoulder to cry on in Chapter 5, for example."
      />
      <SuawParagraph text="After you’ve picked the winning solution to each problem, you have your Solutions List! You can think of it as an outline for your second draft." />
      <SuawParagraph
        text="This stage can take a long time, from weeks to even months. But the work you put in during this phase will pay off in hours saved later down the line."
      />
      <SuawHeading level="3" content="6. Start Your Second Draft" />
      <SuawParagraph text="Finally, you’re ready to start revising! Be sure to make a copy of your first draft so you can use it as a reference if necessary." />
      <SuawParagraph
        text="Personally, I prefer to work in non-chronological order so I can jump around and implement my solutions in order of priority. If I need my villain to come in at the midpoint in order to justify all of my heroine’s prior actions, I want to be able to really see and feel the impact of what my villain is doing before tackling the nitty-gritty of my heroine’s decisions. In this case, I would relocate and revise the scene where my villain comes in <em>before</em> I revise the earlier chapters which reference the oncoming threat."
      />
      <SuawParagraph
        text="As you revise, you may discover additional problems you’d like to address, but I say, save those for the next draft. Jot them down in separate document so you don’t forget them. Perfect should never become the enemy of the good, so keep marching one step at a time until you’ve finished your second draft."
      />
      <SuawHeading level="3" content="7. Seek Feedback" />
      <SuawParagraph
        text="Once you’re done with your second draft, you can celebrate how far you’ve come in your journey! Each draft is a labor of love, and the fact that you’ve now done <em>two</em> drafts is nothing to sneeze at."
      />
      <SuawParagraph
        text="Before moving onto another round of edits, you will likely want some feedback from other readers. This ensures that you don’t end up changing things that are working well, and overlooking issues that you didn’t have enough perspective to see."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
