<script>
import {
  SuawMainContent,
  SuawForm,
  SuawHeading,
  SuawTextInput,
  SuawButton,
  SuawSelect,
  SuawInputGroup,
  SuawSearchInput,
  SuawParagraph,
  SuawChipList
} from "@/components";
import { Timezones } from "../../operations.gql";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { format } from "date-fns";
import _ from "lodash";
import SeriesEditFormButtons from "./SeriesEditFormButtons.vue";
import { UserChip } from "../../common.js";
import * as SeriesApi from "../../api.js";

export default {
  name: "SeriesSchedule",
  components: {
    SuawMainContent,
    SuawForm,
    SuawHeading,
    SuawTextInput,
    SuawButton,
    SuawSelect,
    SuawInputGroup,
    SuawSearchInput,
    SuawParagraph,
    SeriesEditFormButtons,
    SuawChipList
  },
  props: {
    chapter: {
      type: Object,
      default: () => {}
    },
    series: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      eventTypeTabs: [{ label: "In-Person" }, { label: "Online" }],
      activeTabIndex: 0,
      chapterOrganizers: this.chapter.organizers,
      initialOrganizers: [],
      timezoneOptions: [],
      durationOptions: [
        { text: "1.5 Hours", value: 90 },
        { text: "2 Hours", value: 120 },
        { text: "2.5 Hours", value: 150 },
        { text: "3 Hours", value: 180 },
        { text: "3.5 Hours", value: 210 }
      ],
      frequencyOptions: [
        { text: "Weekly", value: 1 },
        { text: "Every Other Week", value: 2 }
      ],
      seriesScheduleForm: {
        chapterId: this.chapter.id,
        durationMinutes: null,
        beginDate: "",
        timeZoneId: this.chapter.timezone,
        weeklyFrequency: null,
        organizers: [],
        startTime: ""
      }
    };
  },
  computed: {
    isGlobalOnlineChapter() {
      return this.chapter?.slug === "shutupandwriteonlineevents";
    },
    publishedEdit() {
      return this.series && !this.series.is_draft;
    },
    summaryHeading() {
      if (this.isGlobalOnlineChapter) {
        return this.publishedEdit ? "Edit Global Online Series" : "Create New Global Online Series";
      }
      return this.publishedEdit ? "Edit Series" : "Create New Series";
    },
    todayDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    userTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
  created() {
    if (this.series) {
      this.seriesScheduleForm.beginDate = this.formattedDate(this.series.schedule.beginDate);
      this.seriesScheduleForm.startTime = this.formattedTime(this.series.schedule.startTime);
      this.seriesScheduleForm.timeZoneId = this.series.schedule.timeZoneId;
      this.seriesScheduleForm.durationMinutes = this.series.schedule.durationMinutes;
      this.seriesScheduleForm.weeklyFrequency = this.series.schedule.weeklyFrequency;
      this.seriesScheduleForm.organizers = this.series.organizers;
      this.initialOrganizers = _.cloneDeep(this.series.organizers);
    } else {
      this.seriesScheduleForm.timeZoneId = this.userTimeZone || this.chapter.timezone;
      if (this.$auth.isAuthenticated) {
        const chip = UserChip(this.$auth.user);
        this.seriesScheduleForm.organizers.push(chip);
      }
    }
  },
  apollo: {
    timezoneOptions: {
      query: Timezones,
      update(data) {
        if (data) {
          return data.timezones.value;
        }
        return null;
      }
    }
  },
  validations: {
    seriesScheduleForm: {
      beginDate: { required },
      startTime: { required },
      durationMinutes: { required },
      weeklyFrequency: { required }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    formattedDate(dateStr) {
      // Expected input format: "yyyyMMdd", e.g., "20240430"
      // Output format: "yyyy-MM-dd", e.g., "2024-04-30"
      if (dateStr && dateStr.length === 8) {
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6)}`;
      }
      return "";
    },
    formattedTime(timeStr) {
      // Expected input format: "HHmmss", e.g., "121500"
      // Output format: "HH:mm", e.g., "12:15"
      if (timeStr && timeStr.length === 6) {
        return `${timeStr.substring(0, 2)}:${timeStr.substring(2, 4)}`;
      }
      return "";
    },
    onAddOrganizer(organizer) {
      if (!this.seriesScheduleForm.organizers.find(o => o.id === organizer.id)) {
        this.seriesScheduleForm.organizers.push(organizer);
      }
    },
    onRemoveOrganizer(organizer) {
      const currentUser = this.$auth.user;
      if (organizer.id === currentUser.id && currentUser.role !== "app_admin") {
        return;
      }
      const index = this.seriesScheduleForm.organizers.findIndex(o => o.id === organizer.id);
      if (index !== -1) {
        this.seriesScheduleForm.organizers.splice(index, 1);
      }
    },
    async draftEventSeries() {
      const { chapterId, beginDate, startTime, timeZoneId, organizers, durationMinutes, weeklyFrequency } = this.seriesScheduleForm;
      const organizerUserIds = organizers.map(organizer => organizer.id);
      const result = await SeriesApi.draftEventSeries(chapterId, beginDate, startTime, parseInt(durationMinutes), parseInt(weeklyFrequency), timeZoneId, organizerUserIds);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateEventSeriesSchedule() {
      const { durationMinutes, beginDate, startTime, timeZoneId, weeklyFrequency } = this.seriesScheduleForm;
      const result = await SeriesApi.updateEventSeriesSchedule(this.series.id, beginDate, startTime, parseInt(durationMinutes), parseInt(weeklyFrequency), timeZoneId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async addEventSubscription(organizer) {
      const result = await SeriesApi.addEventSubscription(this.series.id, organizer.id, organizer.role === "app_admin" || organizer.role === "organizer");
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async removeEventSubscription(organizer) {
      const result = await SeriesApi.removeEventSubscription(this.series.id, organizer.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async detectAndHandleOrganizerChanges() {
      let success = true;
      let errors = [];
      const currentOrganizers = this.seriesScheduleForm.organizers;
      const initialOrganizers = this.initialOrganizers;

      const organizersToAdd = currentOrganizers.filter(org => !initialOrganizers.some(initOrg => initOrg.id === org.id));
      const organizersToRemove = initialOrganizers.filter(initOrg => !currentOrganizers.some(org => org.id === initOrg.id));

      for (const organizer of organizersToAdd) {
        const addResult = await this.addEventSubscription(organizer);
        if (!addResult.success) {
          success = false;
          errors.push(addResult.error);
        }
      }
      for (const organizer of organizersToRemove) {
        const removeResult = await this.removeEventSubscription(organizer);
        if (!removeResult.success) {
          success = false;
          errors.push(removeResult.error);
        }
      }
      return { success, errors };
    },
    async onSeriesScheduleSubmit() {
      if (this.series) {
        await this.onSeriesScheduleUpdated();
      } else {
        await this.onSeriesScheduleDrafted();
      }
    },
    async onSeriesScheduleDrafted() {
      this.$v.seriesScheduleForm.$touch();
      if (this.$v.seriesScheduleForm.$invalid) {
        this.$root.$emit("universal-error-message", "The series schedule form is invalid.");
        return;
      } else {
        const draftEventSeriesResult = await this.draftEventSeries();
        if (draftEventSeriesResult.success) {
          const seriesId = draftEventSeriesResult.result.id;
          this.$emit("next-step", { step: "location", seriesId });
        }
      }
    },
    async onSeriesScheduleUpdated() {
      this.$v.seriesScheduleForm.$touch();
      if (this.$v.seriesScheduleForm.$invalid) {
        this.$root.$emit("universal-error-message", "The updated series schedule form is invalid.");
        return;
      } else {
        const updateEventSeriesScheduleResult = await this.updateEventSeriesSchedule();
        if (!updateEventSeriesScheduleResult.success) {
          return;
        }
        const organizerChangeResult = await this.detectAndHandleOrganizerChanges();
        if (!organizerChangeResult.success) {
          this.$log.error("Error updating organizers: " + organizerChangeResult.errors.join(", "));
          return;
        }
        this.$emit("next-step", { step: "location" });
      }
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawHeading level="2" :content="summaryHeading" />
    <SuawForm>
      <template #form>
        <SuawHeading level="4" content="Schedule" />
        <SuawTextInput
          id="eventDate"
          v-model="seriesScheduleForm.beginDate"
          type="date"
          :min="todayDate"
          label="Start Date"
          is-required
          placeholder="Enter Date"
          :state="formFieldState($v, 'seriesScheduleForm', 'beginDate')"
          :error-message="validateErrors($v.seriesScheduleForm.beginDate, 'Start date')"
          @blur="$v.seriesScheduleForm.beginDate.$touch()"
        />
        <SuawInputGroup>
          <SuawTextInput
            id="eventTime"
            v-model="seriesScheduleForm.startTime"
            type="time"
            label="Start Time"
            is-required
            placeholder="Start Time"
            :state="formFieldState($v, 'seriesScheduleForm', 'startTime')"
            :error-message="validateErrors($v.seriesScheduleForm.startTime, 'Start time')"
            @blur="$v.seriesScheduleForm.startTime.$touch()"
          />
          <SuawSelect v-if="timezoneOptions.length > 0" id="timezone" v-model="seriesScheduleForm.timeZoneId" placeholder="Timezone" :options="timezoneOptions" is-required />
        </SuawInputGroup>
        <SuawSelect
          id="duration"
          v-model="seriesScheduleForm.durationMinutes"
          label="Duration"
          is-required
          placeholder="Duration"
          :options="durationOptions"
          :state="formFieldState($v, 'seriesScheduleForm', 'durationMinutes')"
          :error-message="validateErrors($v.seriesScheduleForm.durationMinutes, 'Duration')"
          success-message=""
          @blur="$v.seriesScheduleForm.durationMinutes.$touch()"
        />
        <SuawSelect
          id="frequency"
          v-model="seriesScheduleForm.weeklyFrequency"
          label="Frequency"
          is-required
          placeholder="Frequency"
          :options="frequencyOptions"
          :state="formFieldState($v, 'seriesScheduleForm', 'weeklyFrequency')"
          :error-message="validateErrors($v.seriesScheduleForm.weeklyFrequency, 'Frequency')"
          success-message=""
          @blur="$v.seriesScheduleForm.weeklyFrequency.$touch()"
        />
        <SuawHeading level="4" content="Organizers" />
        <SuawParagraph
          size="small"
          text="Users added as organizers will be able to edit event details, delete the event, and send updates. The default title of the event will include the first organizer listed."
          alignment="left"
        />
        <SuawChipList :chip-items="seriesScheduleForm.organizers" @chip-click="onRemoveOrganizer" />
        <SuawSearchInput
          id="organizers"
          placeholder="Search organizers in this chapter..."
          :search-results="chapterOrganizers"
          :clear-on-select="true"
          @item-select="onAddOrganizer"
        />
        <SeriesEditFormButtons v-if="publishedEdit" @cancel="$emit('cancel')" @back="$emit('back')" @confirm="onSeriesScheduleSubmit" />
        <SuawButton v-else class="suaw-button--no-x-pd" size="large" type="ghost" icon-right="IconCircleChevronRight" button-text="Next" @click="onSeriesScheduleSubmit" />
      </template>
    </SuawForm>
  </SuawMainContent>
</template>
