<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Pill from "../Pill/Pill.vue";
import Icon from "../Icon/Icon.vue";

export default {
  name: "NotificationItem",
  components: {
    Paragraph,
    Pill,
    Icon,
  },
  props: {
    id: {
      required: true,
      type: [String, Number, Object],
    },
    noticeStatus: {
      type: String,
      validator(value) {
        return ["Unseen", "Seen", "Read"].includes(value);
      },
    },
    headingText: {
      type: String,
    },
    posted: {
      type: String,
    },
    isSidepanelNotification: {
      type: Boolean,
      default: true,
    },
    hasNotifications: {
      type: Boolean,
    },
    hasLink: {
      type: Boolean,
    },
    linkContent: {
      type: String,
    },
    eyebrow: {
      type: String,
      default: "New",
    },
  },
  computed: {
    // notificationClasses() {
    //   return {
    //     'suaw-notification-item__content--read' : this.hasNotifications == false,
    //   }
    // },
    notificationClasses() {
      return {
        "suaw-notification-item__content--read": this.read,
        "suaw-notification-item__content--hoverable": this.hasLink,
      };
    },
    unseen() {
      return this.noticeStatus === "Unseen";
    },
    seen() {
      return this.noticeStatus === "Seen";
    },
    read() {
      return this.noticeStatus === "Read";
    },
  },
  methods: {
    handleClick() {
      // this.hasNotifications = !this.hasNotifications;
      this.$emit("status-change", {
        id: this.id,
        noticeStatus: this.noticeStatus,
      });
      if (this.hasLink) {
        this.$emit("link-click", {
          id: this.id,
          linkContent: this.linkContent,
        });
      }
    },
  },
};
</script>

<template>
  <li
    class="suaw-notification-item__content"
    :class="notificationClasses"
    :id="id"
    @click="handleClick"
  >
    <div class="suaw-notification-item__holder">
      <header
        v-if="unseen"
        class="suaw-notification-item__header"
      >
        <Paragraph
          size="small"
          :text="eyebrow"
        />
        <Pill
          text=""
          status="critical"
        />
      </header>

      <div class="suaw-notification-item__text">
        <Paragraph
          size="medium"
          weight="bold"
          :text="headingText"
        />
      </div>

      <footer class="suaw-notification-item__footer">
        <div class="suaw-notification-item__block">
          <div class="suaw-notification-item__status-block">
            <span
              :class="{
                'suaw-notification-item__posted--small': isSidepanelNotification,
                'suaw-notification-item__posted': !isSidepanelNotification
              }"
            >{{ posted }}</span>
          </div>
        </div>
      </footer>
      <slot />
    </div>
    <Icon
      v-if="hasLink"
      icon="IconChevronBigRight"
      color="gray"
      key="icon-chevron-big-right"
    />
  </li>
</template>

<style lang="scss" scoped>
.suaw-notification-item {
  &__header,
  &__holder,
  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    border-radius: var(--sem-radius-sm);
    border: 1px solid;
    gap: var(--sem-space-base);
    justify-content: space-between;
    padding: var(--sem-space-triple);
    transition: all .25s linear;
    will-change: box-shadow, border-color;
  }

  &__holder {
    flex-direction: column;
    gap: var(--sem-space-double);
  }

  &__content:not(.suaw-notification-item__content--read) {
    background-color: var(--sem-color-background-lightest);
    box-shadow: var(--soft-shadow-draft);
    border-color: var(--sem-color-border-lightest);
  }

  &__content--read {
    background-color: var(--sem-color-background-light);
    border-color: var(--sem-color-border-light);
  }

  &__content--hoverable {
    &:not(.suaw-notification-item__content--read):hover {
      box-shadow: var(--shadow-2);
    }

    &:hover {
      cursor: pointer;
    }

    &--read:hover {
      border-color: var(--sem-color-border-regular);
    }
  }

  &__header {
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__footer {
    flex-direction: column;
  }

  &__footer--reply {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: space-between;
  }

  &__block--reply {
    display: flex;
    align-items: center;
  }

  &__block--textarea-holder {
    margin: var(--sem-space-double) 0;
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    padding: var(--sem-space-base);
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__posted,
  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__text {
    .suaw-notification-item__content--read & {
      opacity: .75;

      > * {
        font-weight: normal;
      }
    }
  }
}
</style>

<!-- <style lang="scss" scoped>
.suaw-notification-item {
  &__header,
  &__holder,
  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    border-radius: var(--sem-radius-sm);
    border: 1px solid;
    gap: var(--sem-space-base);
    justify-content: space-between;
    padding: var(--sem-space-triple);
    transition: all .25s linear;
    will-change: box-shadow, border-color;
  }

  &__holder {
    flex-direction: column;
    gap: var(--sem-space-double);
  }

  &__content:not(.suaw-notification-item__content--read) {
    background-color: var(--sem-color-background-lightest);
    box-shadow: var(--soft-shadow-draft);
    border-color: var(--sem-color-border-lightest);
  }

  &__content:not(.suaw-notification-item__content--read):hover {
    box-shadow: var(--shadow-2);
  }

  &__content--read {
    background-color: var(--sem-color-background-light);
    border-color: var(--sem-color-border-light);
  }

  &__content--read:hover {
    border-color: var(--sem-color-border-regular);
  }

  &__content:hover {
    cursor: pointer;
  }

  &__header {
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__footer {
    flex-direction: column;
  }

  &__footer--reply {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: space-between;
  }

  &__block--reply {
    display: flex;
    align-items: center;
  }

  &__block--textarea-holder {
    margin: var(--sem-space-double) 0;
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    padding: var(--sem-space-base);
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__posted,
  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__text {
    .suaw-notification-item__content--read & {
      opacity: .75;

      > * {
        font-weight: normal;
      }
    }
  }
}
</style> -->
