<script>
export default {
  name: "IconBold",
}
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 5C7 4.44772 7.44772 4 8 4H12.5C14.9853 4 17 6.01472 17 8.5C17 9.70431 16.5269 10.7981 15.7564 11.6058C17.0979 12.3847 18 13.837 18 15.5C18 17.9853 15.9853 20 13.5 20H8C7.44772 20 7 19.5523 7 19V5ZM12.5 11C13.8807 11 15 9.88071 15 8.5C15 7.11929 13.8807 6 12.5 6H9V11H12.5ZM9 13H13.5C14.8807 13 16 14.1193 16 15.5C16 16.8807 14.8807 18 13.5 18H9V13Z"/>
</svg>
</template>
