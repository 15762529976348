<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import { SuawInputGroup, SuawParagraph } from "@/components";
export default {
  name: "Contact",
  components: { ResourceFrame, SuawInputGroup, SuawParagraph }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-resource-heading">Contact <b>Us</b></h1>

      <h2 class="suaw-resource-subtitle">
        Email Us
      </h2>
      <SuawParagraph
        text="Please reach out to our Community Team at <a href='mailto:questions@shutupwrite.com'><b>questions@shutupwrite.com</b></a>. Even if you have corresponded with one of our team members at a different email address, emailing questions@ is the fastest and easiest way to reach us, and the best way for us to ensure that the right person receives your message."
      />

      <h2 class="suaw-resource-subtitle">
        Success Stories
      </h2>
      <SuawParagraph
        text="Did you finally finish that novel, dissertation, or poem? Did you meet your best friend at a Shut Up & Write? If you have a success story about your Shut Up & Write! experience you'd love to share, we'd love to hear it. Please fill out <a href='https://shutupwrite.typeform.com/to/EI3Ohubg' target='_blank'><b>this form</b></a> to share with us."
      />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
</style>
