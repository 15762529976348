<script>
import { SuawHeading, SuawParagraph, SuawButton } from "@/components";
import welcomeImageDesktop from "../../../../public/img/home/welcome-image-2.png";
import welcomeHighlight from "../../../../public/img/home/welcome-highlight.svg";

export default {
  name: "WelcomeSection",
  components: { SuawHeading, SuawParagraph, SuawButton },
  data() {
    return {
      welcomeImageDesktop,
      welcomeHighlight
    };
  }
};
</script>

<template>
  <div class="suaw-welcome-section">
    <div class="suaw-welcome-section__main-container">
      <div class="suaw-welcome-section__grid-item-1">
        <SuawHeading class="suaw-welcome-section__heading" content="Uniting Writers Across the Globe" level="2" />
      </div>
      <div class="suaw-welcome-section__grid-item-2">
        <img class="suaw-welcome-section__image" :src="welcomeImageDesktop" alt="A writing group at a long table writing on laptops." />
      </div>
      <div class="suaw-welcome-section__grid-item-3">
        <SuawParagraph
          class="suaw-welcome-section__subheading"
          text="<b>Shut Up & Write!</b> fosters productivity and connection through regular, supportive writing sessions that help writers build habits, overcome procrastination, and achieve their creative dreams."
        />
      </div>
      <div class="suaw-welcome-section__grid-item-4">
        <SuawParagraph
          class="suaw-welcome-section__subheading"
          text="<b>Our Mission</b> is simple: empower every writer to turn their goals into reality. With nearly two decades of experience leading writing groups, we've perfected the art of community-driven writing success."
        />
      </div>
      <div class="suaw-welcome-section__grid-item-5">
        <SuawParagraph
          class="suaw-welcome-section__subheading"
          text="<b>Our Network</b> of community driven events is constantly expanding! With in-person gatherings in over 60 countries and over 150 online events each week, there's always a space for you to write."
        />
      </div>
      <div class="suaw-welcome-section__grid-item-6">
        <SuawButton class="suaw-welcome-section__button" type="primary" size="hero" button-text="Learn More About Us" @click="$emit('click')" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-welcome-section {
  background: var(--sem-color-background-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 88px;
  @media screen and (max-width: 1024px) {
    padding-top: var(--sem-space-octuple);
  }
  @media screen and (max-width: 600px) {
    padding-top: var(--sem-space-sextuple);
  }
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    row-gap: 28px;
    @media (max-width: 767px) {
      margin: 0 16px;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr;
      row-gap: 28px;
    }
  }

  &__grid-item-1 {
    grid-column: 2/3;
    grid-row: 1/2;
    align-content: center;
    @media (max-width: 1024px) {
      grid-column: 1/3;
      margin-bottom: 24px;
    }
    @media (max-width: 650px) {
      margin: 0;
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__heading {
    font-size: 40px;
    line-height: 125%;
    font-family: var(--secondary-font-family);
    font-weight: 600;
    @media (max-width: 1024px) {
      text-align: center;
      font-size: 54px;
    }
    @media (max-width: 854px) {
      font-size: 48px;
    }
    @media (max-width: 500px) {
      font-size: 40px;
    }
  }

  &__grid-item-2 {
    grid-column: 1/2;
    grid-row: 1/6;
    max-height: 630px;
    @media (max-width: 1024px) {
      grid-row: 2/6;
    }
    @media (max-width: 700px) {
      max-height: none;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: var(--soft-shadow-draft);
    object-fit: cover;
  }

  &__highlight {
    position: absolute;
    top: -40px;
    right: -45px;
    width: 67px;
    height: auto;
    @media (max-width: 1220px) {
      display: none;
    }
  }

  &__grid-item-3 {
    grid-column: 2/3;
    grid-row: 2/3;
    @media (max-width: 1024px) {
      align-content: center;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__grid-item-4 {
    grid-column: 2/3;
    grid-row: 3/4;
    @media (max-width: 1024px) {
      align-content: center;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__grid-item-5 {
    grid-column: 2/3;
    grid-row: 4/5;
    @media (max-width: 1024px) {
      align-content: center;
    }
    @media (max-width: 768px) {
      align-content: center;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__subheading {
    font-size: 17px;
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }

  &__grid-item-6 {
    grid-column: 2/3;
    grid-row: 5/6;
    align-content: center;
    @media (max-width: 1024px) {
      align-content: start;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
