<script>
import { SuawMainContent, SuawSection, SuawCarousel, SuawSubheaderTwo } from "@/components";
import * as db from "../data.js";

export default {
  name: "ArticleDiscovery",
  metaInfo: {
    title: "Articles - ",
    meta: [
      { vmid: "description", name: "description", content: "Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing." }
    ]
  },
  components: {
    SuawMainContent,
    SuawSection,
    SuawCarousel,
    SuawSubheaderTwo
  },
  data() {
    return {
      cards: db.articles()
    };
  },
  computed: {
    featuredArticles() {
      return this.cards.filter(card => card.featured).map(card => ({ ...card }));
    },
    recentArticles() {
      return this.cards.map(card => ({ ...card, cardType: "CardArticle" }));
    }
  },
  methods: {
    onClicked(val) {
      this.$router.push(val.route);
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true">
    <SuawSubheaderTwo title="Articles" description="Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing." />
    <SuawSection>
      <SuawCarousel title="Featured Articles" :cards="featuredArticles" :max-cards-per-page="2" />
    </SuawSection>
    <SuawSection>
      <SuawCarousel title="Recent Articles" :cards="recentArticles" :max-cards-per-page="3" />
    </SuawSection>
  </SuawMainContent>
</template>
