<script>
import ResourceFrame from "../../components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";

export default {
  name: "HostingInPersonEvent",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Hosting Your <b>In-Person Event</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="If you'll be hosting in person, please take some time to read through this guide. We've been organizing in-person events since 2007, so we've got some wisdom to pass along."
      />
      <h2 class="suaw-organizer-resource-subtitle">Location, Location, Location…</h2>
      <p class="suaw-paragraph">
        To host a successful in-person event, you'll need to pick a spot to meet. Great places to host include your local coffee shop, library, or bookstore. Here are a few considerations to keep in mind when you select your venue:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Seating capacity: Can your venue accommodate your group with enough seating for all?</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Level of ambient noise</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Availability of food and beverages</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Parking/public transit availability</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">WiFi</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Outlets for plugging in laptops/devices</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Do they take reservations? (It's a nice plus.)</p>
          </li>
        </ul>
      </div>
      <p class="suaw-paragraph">
        We strongly recommend that you visit your venue ahead of time to check on all of these details. If possible, speak to the manager at the venue to learn whether or not you'll need to make a reservation ahead of time.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Timing</h2>
      <p class="suaw-paragraph">
        You'll also need to choose a time for your event. A few recommendations:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Ensure the time you'd like to host isn't the same as an existing event in your chapter.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Ensure you have no other weekly or monthly commitments during that time.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Saturday morning sessions at 10am or 11am are well-attended across most of our chapters, so this is a solid choice unless there's an existing event already at this time. Weekdays at 6pm also work well to allow folks working a 9-5 job to attend your event after work.</p>
          </li>
        </ul>
      </div>
      <h2 class="suaw-organizer-resource-subtitle">Format</h2>
      <p class="suaw-paragraph">
        Standard in-person events last 90 minutes. If you'd like to host for a longer period of time, this format can be expanded. Here's an example of a classic Shut Up & Write! event schedule:
      </p>
      <SuawHeading level="4" content="👋 15 mins: Introductions" />
      <SuawHeading level="4" content="⏰ 60 mins: Shut Up & Write!" />
      <SuawHeading level="4" content="✅ 15 mins: Conclusion" />
      <p class="suaw-paragraph">
        If your writing period is longer than 60 minutes, we recommend scheduling breaks into your event. For example, write for two 60-minute periods, with a 10-minute break in between.
      </p>
      <p class="suaw-paragraph">
        <b>Pomodoro Technique:</b> This method entails working for a set interval (usually 25 minutes), taking a 5-minute break, and repeating. While this is not the same as the <router-link :to="{ name: 'Method' }">Shut Up & Write! Method</router-link>, we recognize that it can be very effective.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Getting Ready</h2>
      <p class="suaw-paragraph">
        We've got a <router-link :to="{ name: 'InPersonEventChecklist' }">handy checklist</router-link> that you can print to get ready for an in-person event, so be sure to check that out.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">The Day Of</h2>
      <p class="suaw-paragraph">
        On the day of your event, arrive 10-15 minutes early, grab a table, and welcome attendees.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">Tricky Situations</h2>
      <p class="suaw-paragraph">
        Refer to our <router-link :to="{ name: 'TroubleshootingAtEvents' }">Troubleshooting</router-link> page for advice.
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
