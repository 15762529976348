<script>
import { SuawMainContent, SuawHeading, SuawSummary, SuawTabs, SuawEmptyState, SuawModal } from "@/components";
import EventEditSchedule from "../components/EventEditSchedule.vue";
import EventEditDescription from "../components/EventEditDescription.vue";
import EventEditLocation from "../components/EventEditLocation.vue";
import { GetEventOccurrenceById } from "../../operations.gql";
import * as EventApi from "../../api.js";

export default {
  name: "EventEditIndex",
  components: { EventEditSchedule, EventEditDescription, EventEditLocation, SuawMainContent, SuawHeading, SuawSummary, SuawTabs, SuawEmptyState, SuawModal },
  data() {
    return {
      loadingQueriesCount: 0,
      activeTabIndex: 0,
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null,
      resultGetEventOccurenceById: {
        id: "",
        is_virtual: null,
        is_cancelled: null,
        title: "",
        event_series: {
          id: "",
          slug: ""
        }
      }
    };
  },
  apollo: {
    resultGetEventOccurenceById: {
      query: GetEventOccurrenceById,
      variables() {
        return {
          id: this.$route.params.eventId
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.$route.params.eventId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEvent() {
      return this.resultGetEventOccurenceById.id !== "";
    },
    event() {
      return this.hasEvent ? this.resultGetEventOccurenceById : null;
    },
    eventId() {
      return this.hasEvent ? this.event.id : null;
    },
    seriesSlug() {
      return this.hasEvent ? this.event.event_series.slug : null;
    },
    isCancelled() {
      return this.hasEvent ? this.event.is_cancelled : null;
    },
    isVirtual() {
      return this.hasEvent ? this.event.is_virtual : null;
    },
    typeName() {
      return this.hasEvent ? (this.isVirtual ? "Online" : "In-Person") : null;
    },
    heading() {
      return this.hasEvent ? (this.typeName ? `Edit ${this.typeName} Event` : null) : null;
    },
    title() {
      return this.hasEvent ? this.event.title : null;
    },
    chapterSlug() {
      return this.hasEvent ? this.event.event_series.chapter.slug : null;
    }
  },
  methods: {
    onCancelClicked() {
      this.showModal = true;
      this.modalTitle = "Are you sure you want to cancel your event?";
      this.modalConfirmAction = this.CancelEventOccurrence;
    },
    onUncancelClicked() {
      this.showModal = true;
      this.modalTitle = "Are you sure you want to reinstate your cancelled event?";
      this.modalConfirmAction = this.UncancelEventOccurrence;
    },
    onBackClicked() {
      this.showModal = true;
      this.modalTitle = "Any unsaved changes to your event will be lost.";
      this.modalConfirmAction = this.backToSeriesPage;
    },
    onExitModal() {
      this.showModal = false;
      this.modalTitle = "";
      this.modalConfirmAction = null;
    },
    async CancelEventOccurrence() {
      const result = await EventApi.cancelEventOccurrence(this.eventId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      } else {
        this.$root.$emit("universal-success-message", "Event successfully cancelled.");
        this.backToSeriesPage();
      }
    },
    async UncancelEventOccurrence() {
      const result = await EventApi.uncancelEventOccurrence(this.eventId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      } else {
        this.$root.$emit("universal-success-message", "Event successfully Reinstated.");
        this.backToSeriesPage();
      }
    },
    backToSeriesPage() {
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: this.chapterSlug,
          eventId: this.eventId,
          seriesSlug: this.seriesSlug
        }
      });
    }
  }
};
</script>

<template>
  <SuawMainContent v-if="hasEvent && !isLoading" size="medium">
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onExitModal" />
    <SuawSummary summary-type="text" :heading="heading" />
    <SuawHeading level="3" :content="title" />
    <SuawTabs v-model="activeTabIndex" :tabs="[{ label: 'Schedule' }, { label: 'Location' }, { label: 'Details' }]">
      <template #tab-0>
        <EventEditSchedule
          :event-id="eventId"
          :is-cancelled="isCancelled"
          @uncancel="onUncancelClicked"
          @cancel="onCancelClicked"
          @back="onBackClicked"
          @updated="backToSeriesPage"
        />
      </template>
      <template #tab-1>
        <EventEditLocation
          :event-id="eventId"
          :is-cancelled="isCancelled"
          @uncancel="onUncancelClicked"
          @cancel="onCancelClicked"
          @back="onBackClicked"
          @updated="backToSeriesPage"
        />
      </template>
      <template #tab-2>
        <EventEditDescription
          :event-id="eventId"
          :is-cancelled="isCancelled"
          @uncancel="onUncancelClicked"
          @cancel="onCancelClicked"
          @back="onBackClicked"
          @updated="backToSeriesPage"
        />
      </template>
    </SuawTabs>
  </SuawMainContent>
  <SuawEmptyState v-else-if="isLoading" message="Loading Your Event..." />
  <SuawEmptyState v-else message="No Event Found" />
</template>
