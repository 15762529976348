<script>
import EventListItem from './EventListItem.vue';
import Link from "../Link/Link.vue";

export default {
  name: 'EventList',
  components: {
    EventListItem,
    Link,
  },
  props: {
    size: {
      type: String,
      default: "micro",
      validator: function (value) {
        return [
          "micro",
          "medium",
        ].indexOf(value) !== -1;
      },
    },
    items: {
      type: Array,
      default: () => []
    },
    eventsLabel: {
      type: String,
      default: 'View All Events',
    },
    eventsUrl: {
      type: String,
      default: 'https://#',
    },
    showViewAll: {
      type: Boolean,
      default: true,
    },
    className: {
      Type: String,
    }
  },
  computed: {
  },
  methods: {
    toggleRSVP(item) {
      this.$emit("toggle-rsvp", item);
    },
  },
};
</script>

<template>
  <nav 
    class="suaw-event-list"
    :class="['suaw-event-list--' + size]"
  >
    <template v-if="items && items.length">
      <EventListItem
        v-for="(item, index) in items"
        :key="item.id ? item.id : index"
        :size="size"
        :item="item"
        :class="item.className"
        @item-click="$emit('item-click', $event)"
        @toggle-rsvp="toggleRSVP"
      />
    </template>
    <slot></slot>
    <Link
      v-if="showViewAll"
      :to="eventsUrl"
      size="medium"
      weight="bold"
      class="suaw-event-list__cta"
      textClassName="suaw-link__text--fill"
    >
      {{ eventsLabel }}
    </Link>
  </nav>
</template>

<style lang="scss" scoped>
.suaw-event-list {
  padding: 0;

  &--micro {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-regular);
    border-radius: var(--sem-radius-sm);
    box-shadow: var(--date-square-shadow);
    overflow: hidden;
  }

  &__cta:hover {
    background-color: var(--sem-color-background-light);
  }

  &__cta {
    padding: var(--sem-space-double);
    text-align: center;
    width: 100%;
  }
}
</style>
