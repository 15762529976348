<script>
import { SuawMainContent, SuawSection, SuawSidebar, SuawDivider } from "@/components";
import SideNav from "../components/SideNav.vue";
import WriteWithUs from "../components/WriteWithUs.vue";

export default {
  name: "ResourceFrame",
  components: {
    SuawMainContent,
    SuawSection,
    SuawSidebar,
    SuawDivider,
    SideNav,
    WriteWithUs
  },
  props: {
    resourceSideNavType: {
      type: String,
      default: "about"
    },
    customLinks: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isSidebarVisible: window.innerWidth >= 1025
    };
  },
  computed: {
    links() {
      if (this.customLinks) {
        return this.customLinks;
      }
      const linkTypes = {
        about: [
          { to: { name: "About" }, name: "About Shut Up & Write!" },
          { to: { name: "Method" }, name: "Our Method" },
          { to: { name: "OurOrganization" }, name: "Our Organization" },
          { to: { name: "Impact" }, name: "Our Impact" },
          { to: { name: "Faq" }, name: "FAQ" },
          { to: { name: "Contact" }, name: "Contact Us" }
        ],
        general: [
          { to: { name: "ResourceDiscovery" }, name: "Back To Resources" },
          { to: { name: "BecomeAnOrganizer" }, name: "Become An Organizer" },
          { to: { name: "GetInvolved" }, name: "Get Involved" },
          { to: { name: "PrivacyPolicy" }, name: "Privacy Policy" },
          { to: { name: "CodeOfConduct" }, name: "Code Of Conduct" },
          { to: { name: "TermsOfService" }, name: "Terms Of Service" },
          { to: { name: "Legal" }, name: "Legal" },
          { to: { name: "Downloads" }, name: "Downloads" },
          { to: { name: "Contact" }, name: "Contact Us" }
        ],
        organizer: [
          { to: { name: "ResourceDiscovery" }, name: "Back To Resources" },
          { to: { name: "NewOrganizerStartHere" }, name: "New Organizer? Start Here" },
          { to: { name: "BeforeYourVideoCall" }, name: "Before Your Video Call" },
          { to: { name: "OrganizerHandbook" }, name: "Organizer Handbook" },
          { to: { name: "ListingYourInPersonEvent" }, name: "Listing Your In-Person Event" },
          { to: { name: "HostingYourInPersonEvent" }, name: "Hosting Your In-Person Event" },
          { to: { name: "InPersonEventChecklist" }, name: "In-Person Event Checklist" },
          // { to: { name: "ListingYourOnlineEvent" }, name: "Listing Your Online Event" },
          { to: { name: "HostingYourOnlineEvent" }, name: "Hosting Your Online Event" },
          { to: { name: "OnlineEventChecklist" }, name: "Online Event Checklist" },
          { to: { name: "OrganizerFaq" }, name: "Organizer FAQ" },
          { to: { name: "PromotingYourEvent" }, name: "Promoting Your Event" },
          { to: { name: "TroubleshootingAtEvents" }, name: "Troubleshooting at Events" },
          { to: { name: "AcademicOrganizers" }, name: "Academic Organizers" },
          { to: { name: "Contact" }, name: "Contact Us" }
        ]
      };
      return linkTypes[this.resourceSideNavType] || linkTypes.about; // Default to "about" links if the type is invalid
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSidebarVisible = window.innerWidth >= 1025;
    }
  }
};
</script>

<template>
  <SuawMainContent size="large" :show-gradient="true">
    <SuawSection>
      <slot />
      <template v-if="isSidebarVisible">
        <SuawSidebar>
          <SideNav :links="links" />
        </SuawSidebar>
      </template>
    </SuawSection>
    <SuawDivider />
    <SuawSection>
      <WriteWithUs />
    </SuawSection>
  </SuawMainContent>
</template>
