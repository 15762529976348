<script>
export default {
  name: "IconRedo",
}
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9998 2C19.5521 2 19.9998 2.44772 19.9998 3V8C19.9998 8.55228 19.5521 9 18.9998 9H13.9998C13.4475 9 12.9998 8.55228 12.9998 8C12.9998 7.44772 13.4475 7 13.9998 7H16.899C16.1355 6.25192 15.2066 5.68461 14.1804 5.3482C12.7579 4.88189 11.2232 4.88411 9.80171 5.35421C8.3803 5.8243 7.14708 6.73748 6.28312 7.95996C5.41919 9.18238 4.97018 10.6497 5.00154 12.1465C5.03289 13.6433 5.54316 15.0906 6.45758 16.2759C7.37201 17.4612 8.64208 18.3218 10.0819 18.732C11.5217 19.1421 13.0551 19.0799 14.4569 18.5544C15.8587 18.0289 17.0551 17.0678 17.8704 15.8123C18.1712 15.3491 18.7905 15.2175 19.2537 15.5183C19.7169 15.8191 19.8485 16.4384 19.5477 16.9016C18.4994 18.5158 16.9612 19.7515 15.1589 20.4271C13.3566 21.1028 11.3851 21.1827 9.53395 20.6554C7.68284 20.1281 6.0498 19.0215 4.87406 17.4976C3.6983 15.9736 3.04228 14.1127 3.00198 12.1884C2.96167 10.2642 3.53887 8.37764 4.64983 6.80567C5.76076 5.23375 7.34638 4.0597 9.17373 3.45536C11.001 2.85103 12.9743 2.84809 14.8034 3.44771C15.9916 3.83723 17.0784 4.46753 17.9998 5.29158V3C17.9998 2.44772 18.4475 2 18.9998 2Z" />
</svg>
</template>
