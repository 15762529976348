<script>
import { SuawMainContent, SuawSection, SuawCarousel, SuawSubheaderTwo } from "@/components";
import * as resourcesDb from "../data.js";
import * as articlesDb from "../../articles/data.js";

export default {
  name: "ResourceDiscovery",
  metaInfo: {
    title: "Resources - ",
    meta: [{ vmid: "description", name: "description", content: "Find helpful resources for writers and organizers, from writing tips to event organization guides." }]
  },
  components: {
    SuawMainContent,
    SuawSection,
    SuawCarousel,
    SuawSubheaderTwo
  },
  data() {
    return {
      articles: articlesDb.articles(),
      organizerResources: resourcesDb.organizerResources(),
      generalResources: resourcesDb.generalResources()
    };
  },
  computed: {
    articleCards() {
      return this.articles.map(card => ({ ...card, cardType: "CardArticle" }));
    },
    organizerResourceCards() {
      return this.organizerResources.map(card => ({ ...card, cardType: "CardArticle" }));
    },
    generalResourceCards() {
      return this.generalResources.map(card => ({ ...card, cardType: "CardArticle" }));
    }
  },
  methods: {
    onClicked(val) {
      this.$router.push(val.route);
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true">
    <SuawSubheaderTwo title="Resources" description="Discover helpful resources for writers and organizers, from writing tips to guides for planning impactful events." />
    <SuawSection>
      <SuawCarousel link="/resources/articles" title="Articles" :cards="articleCards" :max-cards-per-page="3" @card-clicked="onClicked" />
    </SuawSection>
    <SuawSection>
      <SuawCarousel title="General Resources" :cards="generalResourceCards" :max-cards-per-page="3" @card-clicked="onClicked" />
    </SuawSection>
    <SuawSection>
      <SuawCarousel title="Organizer Resources" :cards="organizerResourceCards" :max-cards-per-page="3" @card-clicked="onClicked" />
    </SuawSection>
  </SuawMainContent>
</template>
