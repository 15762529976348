import { render, staticRenderFns } from "./IconPlusCircleOutline.vue?vue&type=template&id=2482a996"
import script from "./IconPlusCircleOutline.vue?vue&type=script&lang=js"
export * from "./IconPlusCircleOutline.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports