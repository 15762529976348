<script>
import { SuawMainContent, SuawSection, SuawSummary, SuawInputGroup, SuawSidebar, SuawEmptyState, SuawParagraph, SuawInboxList } from "@/components";
import { GetDirectMessageListByUserId, GetDirectMessageListByUserIdSubscription, MyOrganizers } from "../../operations.gql";
import { formatDistanceToNow } from "date-fns";
import OrganizerSidebar from "@/features/chapters/details/components/OrganizerSidebar.vue";
export default {
  name: "DashboardMail",
  components: {
    SuawMainContent,
    SuawSection,
    SuawSummary,
    SuawInputGroup,
    SuawSidebar,
    SuawEmptyState,
    SuawParagraph,
    SuawInboxList,
    OrganizerSidebar
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetDirectMessageListByUserId: {
        id: "",
        top_level_posts: [
          // {
          //   notice_status_value: "",
          //   top_level_post: {
          //     id: "",
          //     posted_at: "",
          //     body_json: {
          //       content: [
          //         {
          //           content: [
          //             {
          //               text: ""
          //             }
          //           ]
          //         }
          //       ]
          //     },
          //     author: {
          //       id: "",
          //       avatar_color: "",
          //       avatar_url: "",
          //       deleted_at: null,
          //       first_name: "",
          //       display_name: ""
          //     },
          //     other_user: [
          //       {
          //         id: "",
          //         user: {
          //           id: "",
          //           first_name: "",
          //           display_name: "",
          //           avatar_color: "",
          //           avatar_url: ""
          //         }
          //       }
          //     ],
          //     posts_threads: [
          //       {
          //         id: "",
          //         latest_response: [
          //           {
          //             id: "",
          //             posted_at: "",
          //             body_json: {
          //               content: [
          //                 {
          //                   content: [
          //                     {
          //                       text: ""
          //                     }
          //                   ]
          //                 }
          //               ]
          //             },
          //             post_users: [
          //               {
          //                 id: "",
          //                 user_id: "",
          //                 notice_status_value: ""
          //               }
          //             ],
          //             author: {
          //               id: ""
          //             }
          //           }
          //         ],
          //         threads_posts: [
          //           {
          //             id: ""
          //           }
          //         ],
          //         unread_response_count: {
          //           aggregate: {
          //             count: 0
          //           }
          //         }
          //       }
          //     ]
          //   }
          // }
        ],
        unread_total_post_count: {
          aggregate: {
            count: 0
          }
        }
      },
      resultMyOrganizers: [
        // {
        //   id: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetDirectMessageListByUserId: {
      query: GetDirectMessageListByUserId,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount",
      deep: true,
      subscribeToMore: {
        document: GetDirectMessageListByUserIdSubscription,
        variables() {
          return {
            user_id: this.$auth.user.id
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          this.resultGetDirectMessageListByUserId = subscriptionData.data.resultGetDirectMessageListByUserId;
        }
      }
    },
    resultMyOrganizers: {
      query: MyOrganizers,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasMail() {
      return !!this.resultGetDirectMessageListByUserId.id;
    },
    hasOrganizers() {
      return this.resultMyOrganizers.length > 0;
    },
    organizerIds() {
      return this.hasOrganizers ? this.resultMyOrganizers.map(org => org.id) : [];
    },
    messages() {
      if (!this.hasMail) return null;

      return this.resultGetDirectMessageListByUserId.top_level_posts
        .filter(postUser => {
          // Include only posts where the other user is defined and not deleted
          const otherUser = postUser.top_level_post.other_user[0]?.user;
          return otherUser && !otherUser.deleted_at;
        })
        .map(postUser => {
          const topLevelPost = postUser.top_level_post;
          const latestResponse = topLevelPost.posts_threads.length > 0 ? topLevelPost.posts_threads[0].latest_response[0] : topLevelPost;
          let unreadCount = topLevelPost.posts_threads.length > 0 ? topLevelPost.posts_threads[0].unread_response_count.aggregate.count : 0;
          if (postUser.notice_status_value === "Unseen" || postUser.notice_status_value === "Seen") {
            unreadCount += 1;
          }
          const otherUser = topLevelPost.other_user[0]?.user || {};
          const isFromYou = latestResponse.author.id === this.$auth.user.id;
          const fromText = isFromYou ? "From You" : `From ${otherUser.first_name}`;

          let aColor = otherUser.avatar_color ? otherUser.avatar_color : "blue";
          let aType = !otherUser.is_avatar_hidden && otherUser.avatar_url ? "picture" : aColor;
          let aAvatar = !otherUser.is_avatar_hidden && aType === "picture" ? otherUser.avatar_url : otherUser.initials;

          return {
            id: topLevelPost.id,
            cardText: latestResponse.body_json,
            posted: `${fromText} ${formatDistanceToNow(new Date(latestResponse.posted_at), { addSuffix: true })}`,
            chipContent: aAvatar,
            chipText: otherUser.display_name,
            chipColor: aColor,
            dropdownItems: [
              { icon: "IconUserCircle", text: "View Profile", userId: otherUser.id },
              { icon: "IconUserVoice", text: "Report", postId: topLevelPost.id }
            ],
            newResponseCount: unreadCount
          };
        });
    },
    showEmpty() {
      return this.hasMail ? this.messages.length === 0 : null;
    },
    unreadCount() {
      return this.hasMail ? this.resultGetDirectMessageListByUserId.unread_total_post_count?.aggregate.count : null;
    },
    unreadText() {
      return `Direct Messages (${this.unreadCount} Unread)`;
    }
  },
  methods: {
    handleMessageCardClick(postId) {
      this.$root.$emit("open-direct-message", { postId: postId });
    },
    handleDropdownOptionClick(option) {
      if (option.text === "View Profile") {
        this.$router.push({
          name: "UserProfile",
          params: {
            userId: option.userId
          }
        });
      }
      if (option.text === "Report") {
        this.handleReportClick(option.postId);
      }
    },
    handleReportClick(postId) {
      const complaintContext = {
        context: "Post",
        postId: postId
      };
      this.$root.$emit("universal-complaint", complaintContext);
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <SuawSection>
      <SuawSummary summary-type="text" heading="Inbox" />
    </SuawSection>
    <SuawSection>
      <SuawInputGroup v-if="showEmpty" direction="column">
        <SuawEmptyState message="You don't have any messages yet" icon="IconInfoCircleOutline" size="medium" />
      </SuawInputGroup>
      <SuawInputGroup v-else-if="!showEmpty && !isLoading" direction="column">
        <SuawParagraph :text="unreadText" weight="bold" />
        <SuawInboxList :inbox-list-items="messages" :show-view-all="false" @message-card-click="handleMessageCardClick" @dropdown-item-click="handleDropdownOptionClick" />
      </SuawInputGroup>
      <SuawInputGroup v-else direction="column">
        <SuawEmptyState message="Loading your messages..." icon="IconInfoCircleOutline" size="medium" />
      </SuawInputGroup>
      <SuawSidebar>
        <OrganizerSidebar header-text="Your Organizers" :organizer-ids="organizerIds" />
      </SuawSidebar>
    </SuawSection>
  </SuawMainContent>
</template>
