<script>
import { SuawMainContent } from "@/components";
export default {
  name: "Maintenance",
  components: {
    SuawMainContent
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <h1>Site Maintenance</h1>
    <p>
      Our site is currently down for scheduled maintenance, but will be back up shortly. Click below to find a writing event that works for you!
    </p>
    <p><a href="https://www.meetup.com/pro/shut-up-write">In-Person Events</a></p>
    <p><a href="https://www.meetup.com/shutupandwriteonlineevents/">Online Events</a></p>
  </SuawMainContent>
</template>
