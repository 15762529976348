<script>
import Thumbnail from "../Thumbnail/Thumbnail.vue";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Link from "../Link/Link.vue";

export default {
  name: "ArticleCard",
  components: { Thumbnail, Heading, Paragraph, Link },
  props: {
    id: {
      type: String,
      required: true,
    },
    author: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    category: {
      type: String,
    },
    heading: {
      type: String,
    },
    preview: {
      type: String,
    },
    posted: {
      type: String,
    },
    route: {
      type: String,
      required: true, // Make it required to ensure every card has a route
    },
  },
  data() {
    return {
      headingCharLimit: 42, // Default char limit for heading
      previewCharLimit: 125, // Default char limit for preview
    };
  },
  computed: {
    truncatedHeading() {
      if (this.heading && this.heading.length > this.headingCharLimit) {
        return this.heading.slice(0, this.headingCharLimit) + "...";
      }
      return this.heading;
    },
    truncatedPreview() {
      if (this.preview && this.preview.length > this.previewCharLimit) {
        return this.preview.slice(0, this.previewCharLimit) + "...";
      }
      return this.preview;
    },
  },
  methods: {
    updateCharLimits() {
      // Screen size breakpoints and corresponding character limits
      if (window.matchMedia("(max-width: 420px)").matches) {
        this.headingCharLimit = 35;
        this.previewCharLimit = 96;
      } else if (window.matchMedia("(max-width: 450px)").matches) {
        this.headingCharLimit = 40;
        this.previewCharLimit = 115;
      } else if (window.matchMedia("(max-width: 769px)").matches) {
        this.headingCharLimit = 35;
        this.previewCharLimit = 105;
      } else if (window.matchMedia("(max-width: 1025px)").matches) {
        this.headingCharLimit = 30;
        this.previewCharLimit = 90;
      } else {
        this.headingCharLimit = 42;
        this.previewCharLimit = 125;
      }
    },
    onCardClick() {
      this.$router.push(this.route);
    }
  },
  mounted() {
    this.updateCharLimits(); // Set initial char limits
    window.addEventListener("resize", this.updateCharLimits); // Update char limits on resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateCharLimits); // Clean up listener
  },
};
</script>

<template>
  <div class="suaw-article-card" @click="onCardClick">
    <div class="suaw-article-card__main-container">
      <div class="suaw-article-card__thumbnail-container">
        <Thumbnail
          class="suaw-article-card__thumbnail"
          :content="thumbnail"
          size="fit"
        />
      </div>
      <div class="suaw-article-card__content-container">
        <div class="suaw-article-card__header">
          <Paragraph
            class="suaw-article-card__author-name"
            weight="normal"
            size="small"
            :text="author"
          />
          <Paragraph
            class="suaw-article-card__posted"
            weight="normal"
            size="small"
            :text="posted"
          />
        </div>
        <Heading
          class="suaw-article-card__heading"
          level="5"
          :content="truncatedHeading"
        />
        <Paragraph
          v-if="preview"
          class="suaw-article-card__preview"
          weight="normal"
          size="small"
          :text="truncatedPreview"
        />
        <Link
          class="suaw-article-card__link"
          weight="bold"
          icon-right="IconChevronRight"
          :to="route"
          >Read More</Link
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-article-card {
  height: 384px;
  width: 100%;
  max-width: 1256px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s ease;
  cursor: pointer;
  &:hover {
    transform: scale(0.99);
  }
  &__main-container {
    border-radius: var(--sem-radius-md);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__thumbnail-container {
    min-height: 240px;
    height: 240px;
  }
  &__content-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__author-name {
    color: var(--sem-color-primary-medium);
    font-weight: 500;
    line-height: 1;
  }
  &__posted {
    line-height: 1.25;

    font-size: 12px;
    font-weight: 500;
    color: var(--sem-color-neutral-medium);
  }
  &__heading {
    color: var(--sem-color-text-medium);
    margin-bottom: 4px;
    overflow: hidden;
    height: 22px;
  }
  &__preview {
    color: var(--sem-color-text-medium);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 8px;
    height: 35px;
    overflow: hidden;
  }
  &__link {
    color: var(--sem-color-info-medium);
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
