<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Button from "../Button/Button.vue";
import Thumbnail from "../Thumbnail/Thumbnail.vue";
import Divider from "../Divider/Divider.vue";

export default {
  name: 'CardEventDetails',
  components: {
    Paragraph,
    Button,
    Thumbnail,
    Divider,
  },
  props: {
    locationName: {
      type: String,
    },
    locationAddress: {
      type: String,
    },
    buttonText: {
      type: String,
      default: "View on Map"
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    cardImage: {
      type: String,
    },
    showHeader: {
      type: Boolean,
    },
    showFooter: {
      type: Boolean,
      default: true
    },
  },
  methods: {
  },
};
</script>

<template>
  <div class="suaw-card__content">
    <div
      v-if="cardImage"
      class="suaw-card__image"
    >
      <Thumbnail
        size="fill"
        radius="sharp"
        :content="cardImage"
        class="suaw-card__thumbnail"
      />
    </div>
    <Divider
      v-if="cardImage === false || showHeader === true && showFooter === true"
      color="medium"
      orientation="horizontal"
      type="inner"
    />
    <footer
      v-if="showFooter"
      class="suaw-card__footer"
    >
      <div class="suaw-card__block">
        <Paragraph
          v-if="locationName"
          class="suaw-card__headline"
          weight="bold"
          size="medium"
          :text="locationName"
        />
        <Paragraph
          v-if="locationAddress"
          class="suaw-card__text"
          size="small"
          :text="locationAddress"
        />
      </div>
      <div class="suaw-card__block">
        <Button
          :buttonText="buttonText"
          :disabled="isDisabled"
          class="button"
          size="small"
          type="ghost-outline"
          iconLeft="IconLink02"
          className="suaw-card__cta"
          @click="$emit('click')"
        />
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    flex-direction: column;
    gap: var(--sem-space-triple);
    overflow: hidden;
  }

  &__image {
    margin-left: calc(-1 * var(--sem-space-triple));
    margin-right: calc(-1 * var(--sem-space-triple));
    margin-top: calc(-1 * var(--sem-space-triple));
    overflow: hidden;
  }

  &__thumbnail {
    border-radius: var(--sem-radius-sm) var(--sem-radius-sm) 0 0;
  }

  &__footer {
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: space-between;
  }
}
</style>
