<script>
import { SuawMainContent, SuawHeading, SuawParagraph, SuawThumbnail } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
  components: {
    SuawMainContent,
    SuawHeading,
    SuawParagraph,
    SuawThumbnail,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="IWrote1000WordsFor30DaysAndHereIsWhatHappened">
      <SuawParagraph
        text="I’m a sucker for endurance sports. Specifically, I’m a sucker for watching marathon highlights on YouTube from the comfort of my couch while snacking on liberal amounts of popcorn. I’m fascinated by the grim determination on the faces of a star-studded cast of athletes, each of them straining to beat the other by a margin of mere minutes. The way in which they push through&nbsp;<a target='_blank' title='https://www.runnersworld.com/uk/training/marathon/a774858/how-to-avoid-the-wall-and-cope-if-you-hit-it/' href='https://www.runnersworld.com/uk/training/marathon/a774858/how-to-avoid-the-wall-and-cope-if-you-hit-it/'><u>the infamous “wall”</u></a> between miles 18 and 20 of the race is nothing short of awe-inspiring."
      />
      <SuawParagraph
        text="Alas, though I’ve dabbled in long-distance running before, I am, as of yet, no marathoner. Instead, what I take away from observing this incredible physical feat is the inspiration to pull off something as outrageous intellectually. The idea of giving myself a terrifying challenge that requires superhuman endurance fills me with secret glee. The best things worth having usually require repeated encounters with fear and discomfort. “No pain, no gain” right?"
      />
      <SuawParagraph
        text="Writing is one area in which my desire for larger-than-life goals routinely shows up. I wrote an entire draft of my very first science fiction novel in six months during lockdown in 2020, and as a relative beginner to writing, this felt like an extraordinary accomplishment! But in the years since, I’ve often wondered how much my achievement could be attributed to the strange circumstances of those months, during which it seemed&nbsp;<em>everyone</em> had the time to make multiple loaves of sourdough as well as cardboard costumes for their pets. Would it be possible to set an ambitious writing goal in the hubbub of the now-times, long after lockdown?"
      />
      <SuawParagraph text="It was time to find out." />
      <SuawParagraph
        text="A little over a month ago, I gave myself a goal to write 1,000 words every single day for 30 consecutive days. (Yes, even weekends.) And I did it! I actually sat down at my desk and wrote every single day from February 16 to March 17 until I hit the 1,000-word mark before putting away my laptop. As intended, I hit mid-March with 30,000 more words than I had when I started!"
      />
      <SuawHeading level="3" content="The Good" />
      <SuawHeading level="4" content="I Saw the Sun Rise" />
      <SuawParagraph
        text="My job happens to be beautifully aligned with my passion for creative writing. As a Content Strategy and Analytics Manager at Shut Up &amp; Write!, I get to write about writing as well as analyze how we can best support our wonderful community of writers across all genres and skill levels. Despite this almost-perfect alignment, there were still days during which I wanted to be done with my daily word count before my coworkers came online to collaborate with me on other tasks. Inspired by artist and writer Julia Page’s “<a target='_blank' title='https://juliacameronlive.com/basic-tools/morning-pages/' href='https://juliacameronlive.com/basic-tools/morning-pages/'><u>Morning Pages</u></a>,” I did many of my 1,000-word writing sessions in the early morning during East Coast time before my colleagues on the West Coast were awake and needed to pick my brain. During the process, I got to see some glorious sunrises, the skies all painted in vibrant oranges and pinks, like this one:"
      />
      <div>
        <SuawThumbnail size="fill" content="https://media.graphassets.com/Qq0LViJFT3SuoqMiHmrn" />
      </div>
      <SuawHeading level="4" content="I Had a 3,000+ Word Day!" />
      <SuawParagraph text="For those of you who are as into bar charts as yours truly…here come the numbers! Above, you’ll see the word count I managed each day." />
      <div>
        <SuawThumbnail size="fit" content="https://media.graphassets.com/pEVwSpUiQSWooVWvmkdN" />
      </div>
      <SuawParagraph
        text="If you don’t take into account that random 3,046-word day on March 5, I averaged 1,096 words per day, which was modestly over my daily goal. As for what happened on March 5th, I have a good night’s sleep to thank! Going to bed a little earlier than usual the previous night may have done the trick. Some days, you just wake up well-rested and inspired, and though good lifestyle habits can contribute to having more days like this, it can also come as a stroke of good luck."
      />
      <div>
        <SuawThumbnail size="fit" content="https://media.graphassets.com/VsUbCmlRryrqBD9CrAuC" />
      </div>
      <SuawParagraph
        text="If you’re wondering what I was writing about day after day, you can see here that my efforts were pretty unevenly distributed. Before I started, I’d had a vague idea that I would direct all my efforts towards the first draft of my novel, but that didn’t quite work out, except for on March 5th, which was one of those golden days during which your characters come alive and start doing things in your mind’s eye and all you have to do is observe them and write it all down."
      />
      <SuawParagraph
        text="However, I ended the month with a total of 4,833 words spent on my novel, 6,225 words on my essays, and all the rest on journaling, the last of which provided some much-needed clarity on a range of personal topics."
      />
      <SuawHeading level="3" content="The Not-So-Good" />
      <SuawHeading level="4" content="I Was Achy" />
      <SuawParagraph
        text="Pretty sunrises while hitting your daily word goal sounds great, but add little aches and pains to the equation, and what you have on your hands is a mixed bag."
      />
      <SuawParagraph
        text="A thousand words in a day is a decent amount of writing, but typically not anything to write home about. However, a total of 30,000 plus words in one month is more physically demanding than I knew to expect. My dry, screen-weary eyes demanded more and more doses of moisturizing eye drops, and I had to increase my number of breaks as the weeks went on to stretch my aching neck and back. You would think an ergonomic setup would reduce the physical strain of writing for hours every day (and yes, it did take hours and hours on some days to produce the requisite amount of words), but apparently, ergonomics can only do so much. I learned the importance of taking care of myself when pursuing a demanding goal, even if the primary intent of my goal was to exercise the mind rather than the body."
      />
      <SuawHeading level="4" content="Some of the Writing Was…Pretty Bad" />
      <SuawParagraph
        text="Having written far more than a thousand words in a day in the past, I didn’t expect that sustaining this word count day after day was going to be as intellectually demanding as it was. Sure, I set out to do something ambitious, but I had secretly thought it was going to be easy&nbsp;<em>and</em> impressive. Not only did I want to spit out a bunch of words everyday, I wanted them to be good! Sadly, there were days when all I could manage was repeating what I had written the day before but in a slightly different way. Other than my journal entries, which are not intended for any outside eyes, my prose felt rushed and unoriginal."
      />
      <SuawParagraph
        text="As authors, we’re often&nbsp;<a target='_blank' title='/articles/five-ways-to-silence-your-inner-critic-while-drafting' href='/articles/five-ways-to-silence-your-inner-critic-while-drafting'><u>our own worst critics</u></a>, so I wanted an outside opinion on what I had produced for my essays and my novel. I asked a fellow writer friend to&nbsp;take a look at my drafts, and unsurprisingly, his feedback matched my own critique—I had been sacrificing quality for quantity."
      />
      <SuawParagraph
        text="Ensuring you don’t sacrifice quality for quantity is a balancing act for most authors, who&nbsp;<a target='_blank' title='https://www.reddit.com/r/writing/comments/99yb2x/when_writing_quality_or_quantity/' href='https://www.reddit.com/r/writing/comments/99yb2x/when_writing_quality_or_quantity/'><u>each seem to have their own way</u></a> to approach the problem. Based on my personal experience, I’d say that focusing on a consistent word count over a long period of time with no external deadlines or specific goals can be more trouble than it’s worth. This is not to say that consistency isn’t important, but the approach used to achieve consistency can make a big difference in the quality of your work over the longer term."
      />
      <SuawHeading level="4" content="What I Learned: Process, Not Product" />
      <SuawParagraph
        text="<a target='_blank' title='https://jamesclear.com/goals-systems' href='https://jamesclear.com/goals-systems'><u>James Clear</u></a> talks about focusing on process, not product, when it comes to achieving results over the longer term. To put it another way, the systems we design have a bigger impact on long-term results than putting ourselves through grueling sprints to finish one specific goal over a shorter time frame. Professor of Engineering Barbara Oakley points to the same concept when&nbsp;<a target='_blank' title='https://www.coursera.org/learn/learning-how-to-learn' href='https://www.coursera.org/learn/learning-how-to-learn'><u>she teaches students to address procrastination</u></a> while working on any intellectual endeavor, or any endeavor, period. Our brains get stressed when we think about a specific product we are expected to create, which can lead to procrastination and burnout. Focusing on a regular, sustainable process during which no specific output is expected is a great way to release the pressure to produce something perfect."
      />
      <SuawParagraph
        text="When tackling ambitious writing projects in the future, I will be setting a process goal instead of a word count goal. In other words, I will create space in my routine to write for a specific number of hours, rather than a specific number of words. On some days, my word count will be as modest as 100 words, while on other days, I may end up writing multiple pages, but the word count will just be a side effect of the process rather than a stressful mark I have to perfectly hit each time."
      />
      <SuawParagraph
        text="And thankfully, I will get to lean on&nbsp;<a title='/events' href='/in-person-events'><u>our trusted format at Shut Up &amp; Write!</u></a> to get it done."
      />
      <SuawParagraph
        text="So, if you enjoy tallying numbers and don't mind sore muscles, a word-count-based challenge might be for you. I did get a few thousand good words by doing it this way, and I’m glad I got to try it! But for everyone else, I’d recommend setting a “process-oriented” goal rather than a word count goal. For example, schedule an hour a week devoted to writing to make steady progress over a longer period of time. Your word count will have no choice but to increase, and you may find it much less stressful."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
