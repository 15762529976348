<script>
import { SuawModal, SuawTextInput, SuawCheckbox } from "@/components";
import { required, maxLength, minLength, sameAs } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";

export default {
  name: "ChangePasswordModal",
  components: { SuawModal, SuawTextInput, SuawCheckbox },
  data() {
    return {
      showPassword: false,
      passwordForm: {
        userId: this.$auth.user.id,
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: ""
      }
    };
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.passwordForm.newPasswordConfirm, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.passwordForm.newPasswordConfirm.sameAsPassword) return "Passwords must match.";
      return "";
    }
  },
  validations: {
    passwordForm: {
      oldPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      newPasswordConfirm: {
        required,
        sameAsPassword: sameAs("newPassword")
      }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async changePassword() {
      const { userId, oldPassword, newPassword } = this.passwordForm;
      const result = await UserApi.changePassword(userId, oldPassword, newPassword);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSubmitModal() {
      this.$v.passwordForm.$touch();
      if (this.$v.passwordForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please fill out all fields of form correctly before submitting.");
        return;
      }

      if (this.passwordForm.oldPassword && this.passwordForm.newPassword) {
        const changePasswordResult = await this.changePassword();
        if (changePasswordResult.success) {
          this.$root.$emit("universal-success-message", "Password successfully changed.");
          this.$emit("close");
        } else {
          this.$root.$emit("universal-error-message", changePasswordResult.error);
        }
      }
    },
    onExitModal() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <SuawModal modal-title="Change Password" modal-type="confirmation" :toggle-button-confirm="onSubmitModal" :toggle-button-cancel="onExitModal">
    <SuawTextInput
      id="old-password"
      v-model="passwordForm.oldPassword"
      :type="passwordInputType"
      placeholder="************"
      label="Old Password:"
      is-required
      :state="formFieldState($v, 'passwordForm', 'oldPassword')"
      :error-message="validateErrors($v.passwordForm.oldPassword, 'Old password')"
      @blur="$v.passwordForm.oldPassword.$touch()"
    />
    <SuawTextInput
      id="new-password"
      v-model="passwordForm.newPassword"
      :type="passwordInputType"
      placeholder="************"
      label="New Password:"
      is-required
      :state="formFieldState($v, 'passwordForm', 'newPassword')"
      :error-message="validateErrors($v.passwordForm.newPassword, 'New password')"
      @blur="$v.passwordForm.newPassword.$touch()"
    />
    <SuawTextInput
      id="new-password-repeat"
      v-model="passwordForm.newPasswordConfirm"
      :type="passwordInputType"
      placeholder="************"
      label="Confirm New Password:"
      is-required
      :state="formFieldState($v, 'passwordForm', 'newPasswordConfirm')"
      :error-message="validateConfirmPasswordError"
      @blur="$v.passwordForm.newPasswordConfirm.$touch()"
    />
    <SuawCheckbox id="show" v-model="showPassword" label="Show Password" />
  </SuawModal>
</template>
