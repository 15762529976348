<script>
import Button from "../Button/Button.vue";
import Thumbnail from "../Thumbnail/Thumbnail.vue";
import Heading from "../Heading/Heading.vue";
import Chip from "../Chip/Chip.vue";

export default {
  name: "Cover",
  components: {
    Button,
    Thumbnail,
    Heading,
    Chip,
  },
  props: {
    hasActions: Boolean,
    coverImage: {
      type: String,
    },
    buttonLeftLabel: {
      Type: String,
      default: 'Edit Series'
    },
    buttonRightLabel: {
      Type: String,
    },
    buttonLeftIcon: {
      Type: String,
      default: 'IconEdit'
    },
    buttonRightIcon: {
      Type: String,
      default: 'IconEdit'
    },
    headingSize: {
      type: [String, Number],
      default: 2
    },
    heading: {
      type: String,
      default: "Event Name",
    },
    chipContent: {
      type: String,
      default: "Weekly on Fridays",
    },
  }
}

</script>

<template>
  <div class="suaw-cover">
    <slot />
    <div class="suaw-cover__headline">
      <Heading
        :level="headingSize"
        :content="heading"
        class="suaw-cover__heading"
      />
      <Chip
        v-if="chipContent"
        size="medium"
        status="default"
        icon="IconRepeat"
        :label="chipContent"
        chipType="icon"
      />
    </div>
    <nav
      v-if="hasActions"
      class="suaw-cover__actions"
    >
      <Button
        :buttonText="buttonLeftLabel"
        :iconLeft="buttonLeftIcon"
        class="button"
        size="large"
        type="primary-light"
        @click="$emit('click')"
      />
      <Button
        v-if="buttonRightLabel"
        :buttonText="buttonRightLabel"
        :iconLeft="buttonRightIcon"
        class="button"
        size="large"
        type="primary-light"
        @click="$emit('secondary-click')"
      />
    </nav>
    <Thumbnail
      size="fill"
      :content="coverImage"
      class="suaw-cover__thumbnail"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-cover {
  border-radius: var(--sem-radius-md);
  overflow: hidden;
  position: relative;
  width: 100%;

  &__headline,
  &__actions {
    display: flex;
    padding: var(--sem-space-double);
    position: absolute;
    width: 100%;
  }

  &__headline {
    align-items: baseline;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    flex-direction: column;
    gap: var(--sem-space-base);
    left: 0;
    bottom: 0;
    z-index: 108;
  }

  &__actions {
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    gap: var(--sem-space-double);
    justify-content: flex-end;
    right: 0;
    top: 0;
    z-index: 107;
  }

  &__heading {
    color: var(--sem-color-text-lightest);
  }
}
</style>
