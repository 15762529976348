<script>
import Tabs from '../Tabs/Tabs.vue';
import Sidebar from '../Sidebar/Sidebar.vue';
import Summary from '../Summary/Summary.vue';
import Form from '../Form/Form.vue';

export default {
  name: "Section",
  components: {
    Tabs,
    Sidebar,
    Summary,
    Form,
  },
  props: {
    sectionItems: {
      type: Array,
    },
    sectionStyle: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "border", "shadow", "border-shadow"].indexOf(value) !== -1;
      },
    },
    sectionSize: {
      type: String,
      default: "full",
      validator: function (value) {
        return ["full", "medium", "small"].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<template>
  <section
    class="suaw-section"
    :class="[
      'suaw-section--' + sectionStyle,
      'suaw-section--' + sectionSize
    ]"
  >
    <component
      v-for="(item, index) in sectionItems"
      :key="index"
      :is="item.component"
      v-bind="item.props"
    />
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.suaw-section {
  @media screen and (min-width: 1025px) {
    --comp-section-wrap-content: no-wrap;
  }

  @media screen and (max-width: 1024px) {
    --comp-section-wrap-content: wrap;
    gap: var(--sem-space-quadruple);
  }

  display: flex;
  flex-wrap: var(--comp-section-wrap-content);
  gap: var(--sem-space-decuple);
  width: var(--comp-section-width);
  max-width: 100%;

  &--border-shadow {
    border: 1px solid var(--sem-color-border-medium);
  }

  &--border {
    border: 1px solid var(--sem-color-border-regular);
  }

  &--shadow,
  &--border-shadow,
  &--border {
    background-color: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-sm);
    padding: var(--sem-space-quintuple);
  }

  &--shadow,
  &--border-shadow {
    box-shadow: var(--date-square-shadow);
  }

  &--full {
    --comp-section-width: 100%;
  }

  &--medium {
    --comp-section-width: 720px;
    margin: auto;
  }

  &--small {
    --comp-section-width: 400px;
    margin: auto;
  }
}
</style>
