<script>
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardChapterExpanded",
  components: {
    Thumbnail,
    Heading,
    Paragraph,
    Chip,
  },
  props: {
    chapterThumbnail: {
      type: String,
    },
    chapterHeading: {
      type: String,
    },
    chapterDescription: {
      type: String,
    },
    chapterUpcomingEvents: {
      type: String,
    },
    chapterActiveSince: {
      type: String,
    },
    chapterMembers: {
      type: String,
    },
    chapterLocation: {
      type: String,
    },
    chapterDistance: {
      type: String,
    },
    chipItems: {
      type: Array,
      default: () => []
    },
  },
  computed: {
  }
}
</script>

<template>
  <div class="suaw-card__content">
    <Chip
      size="medium"
      chipType="icon"
      icon="IconMap"
      status="default"
      shape="square"
      :label="chapterDistance"
      class="suaw-card__distance"
    />
    <Thumbnail
      size="large"
      :content="chapterThumbnail"
      class="suaw-card__thumbnail"
    />
    <div class="suaw-card__data">
      <header class="suaw-card__header">
        <Heading
          level="3"
          :content="chapterHeading"
        />
        <Paragraph
          v-if="chapterUpcomingEvents && chapterActiveSince"
          weight="normal"
          size="small"
          :text="chapterUpcomingEvents + ' · ' + chapterActiveSince"
        />
      </header>
      <Paragraph
        v-if="chapterDescription"
        weight="bold"
        size="medium"
        :text="chapterDescription"
      />
      <div class="suaw-card__chips">
        <Chip
          v-for="chip in chipItems"
          :key="chip.icon"
          size="medium"
          :status="chip.status"
          :icon="chip.icon"
          :label="chip.label"
          chipType="icon"
          :pill="chip.pill"
        />
        <slot name="chips"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content {
    gap: var(--sem-space-triple);

    @media screen and (max-width: 680px) {
      flex-wrap: wrap;
    }
  }

  &__content,
  &__header,
  &__chips,
  &__data {
    align-items: flex-start;
    display: flex;
  }

  &__chips {
    gap: var(--sem-space-base);
  }

  &__data {
    gap: var(--sem-space-double);
  }

  &__header {
    gap: var(--sem-space-half);
  }

  &__header,
  &__chips,
  &__data {
    flex-direction: column;
  }

  &__distance {
    position: absolute;
    top: var(--sem-space-base);
    left: var(--sem-space-base);
    z-index: 2;
  }

  &__thumbnail {
    min-width: 216px;
  }
}
</style>
