<script>
import { parseISO } from "date-fns";
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Chip from "../Chip/Chip.vue";
import { gmapsMap, gmapsMarker } from "x5-gmaps";
import CalendarDate from "../CalendarDate/CalendarDate.vue";
export default {
  name: "EventCard",
  components: {
    CalendarDate,
    Paragraph,
    Button,
    Chip,
    gmapsMap,
    gmapsMarker,
    Heading,
  },
  props: {
    status: {
      type: String,
      validator: function (value) {
        return (
          ["cancelled", "in-person", "past", "online"].indexOf(value) !== -1
        );
      },
    },
    isVirtual: {
      type: Boolean,
      default: true,
    },
    isRsvped: {
      type: Boolean,
      default: false,
    },
    isFollowing: {
      type: Boolean,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    startsAt: {
      type: String,
    },
    endsAt: {
      type: String,
    },
    venueTitle: {
      type: String,
    },
    venueAddress: {
      type: String,
    },
    venueLat: {
      type: Number,
    },
    venueLng: {
      type: Number,
    },
    mapLink: {
      type: String,
    },
    zoom: {
      type: Number,
      default: 10,
    },
    conferenceType: {
      type: String,
    },
    conferenceUrl: {
      type: String,
    },
    isJoinTime: {
      type: Boolean,
      default: false,
    },
    spotsLeft: {
      type: Number,
    },
    isLastEvent: {
      type: Boolean,
      default: false,
    },
    virtualMeetingId: {
      type: String,
    },
    virtualMeetingPassword: {
      type: String,
    },
  },
  computed: {
    mapOptions() {
      return {
        zoom: this.zoom,
        zoomControl: true,
        center: { lat: this.venueLat, lng: this.venueLng },
      };
    },
    duration() {
      return `${this.formatDateToHMMPM(
        this.startsAt
      )} to ${this.formatDateToHMMPM(this.endsAt)}`;
    },
    spotsLeftCount() {
      return `${this.spotsLeft} Spot${this.spotsLeft !== 1 ? "s" : ""} Left`;
    },
    computedMonth() {
      if (!this.startsAt) return 1; // Default to January if startsAt is invalid
      const date = parseISO(this.startsAt);
      return isNaN(date.getTime()) ? 1 : date.getMonth() + 1; // Return 1 if date is invalid
    },
    computedDay() {
      if (!this.startsAt) return 1; // Default to the first day if startsAt is invalid
      const date = parseISO(this.startsAt);
      return isNaN(date.getTime()) ? 1 : date.getDate(); // Return 1 if date is invalid
    },
    isEventInactive() {
      return this.status === "past" || this.status === "cancelled";
    },
    isPast() {
      return this.status === "past";
    },
    isRsvpDisabled() {
      // Disable RSVP if the event is in join time, regardless of following status
      return this.isJoinTime || this.isEventInactive;
    },
    isJoinDisabled() {
      // Disable Join button if not following, even during join time
      return !this.isFollowing || !this.isJoinTime || this.isEventInactive;
    },
  },
  methods: {
    async copyUrl() {
      const linkToCopy = this.mapLink;
      try {
        await navigator.clipboard.writeText(linkToCopy);
        this.$emit("link-copied", { success: true });
      } catch (err) {
        this.$emit("link-copied", { success: false });
      }
    },
    recenterMap() {
      const mapInstance = this.$refs.map.map;
      if (mapInstance) {
        const newCenter = { lat: this.venueLat, lng: this.venueLng };
        mapInstance.setCenter(newCenter);
      }
    },
    formatDateToHMMPM(dateString) {
      const date = new Date(dateString);

      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";

      // Convert hours to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      // Format minutes to always be two digits
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${hours}:${formattedMinutes}${ampm}`;
    },
  },
};
</script>

<template>
  <div class="suaw-event-card">
    <div class="suaw-event-card__summary-container">
      <div class="suaw-event-card__summary-calendar">
        <CalendarDate
          type="shadow"
          size="small"
          :status="status"
          :month="computedMonth"
          :day="computedDay"
        />
      </div>
      <div v-if="isVirtual" class="suaw-event-card__summary-event-info">
        <Paragraph
          class="suaw-event-card__summary-event-duration"
          :text="duration"
          size="small"
          weight="bolder"
        />
        <Paragraph
          class="suaw-event-card__summary-venue-title"
          :text="venueTitle"
          size="small"
          weight="bold"
        />
      </div>
      <div v-else class="suaw-event-card__summary-event-info">
        <Paragraph
          class="suaw-event-card__summary-event-duration"
          :text="duration"
          size="small"
          weight="bolder"
        />
        <Paragraph
          class="suaw-event-card__summary-venue-title"
          :text="venueTitle"
          size="small"
          weight="bold"
        />
        <Paragraph :text="venueAddress" size="small" />
      </div>
      <div class="suaw-event-card__summary-buttons-container">
        <div class="suaw-event-card__summary-button-container">
          <Button
            v-if="isOrganizer"
            buttonText="Edit Event"
            class="suaw-event-card__summary-action-button"
            size="large"
            type="primary-light"
            iconLeft="IconRepeat"
            :disabled="isPast"
            @click="$emit('edit-event-click')"
          />
        </div>
        <div class="suaw-event-card__summary-button-container">
          <Button
            :key="isRsvped"
            :buttonText="isRsvped ? 'RSVP\'d' : 'RSVP'"
            class="suaw-event-card__summary-action-button"
            size="large"
            :type="isEventInactive || isRsvpDisabled ? 'disabled' : isRsvped ? 'success-light' : 'success'"
            :iconLeft="isRsvped ? 'IconDownloadDone' : 'IconDownload'"
            :disabled="isEventInactive || isRsvpDisabled"
            @click="$emit('rsvp-click')"
          />
        </div>
      </div>
      <div
        v-if="spotsLeft !== null"
        class="suaw-event-card__summary-chips-container"
      >
        <Chip
          size="medium"
          status="default"
          icon="IconUserCheck"
          :label="spotsLeftCount"
          chipType="icon"
        />
      </div>
    </div>

    <div
      v-if="isEventInactive"
      class="suaw-event-card__location-container past-cancelled-container"
    >
      <Heading
        class="suaw-event-card__location-heading"
        :content="status === 'past' ? 'This event has ended.' : 'This event has been cancelled.'"
        level="3"
        alignment="center"
      />
      <Paragraph
        v-if="isLastEvent"
        text="There are no future events in this series."
      />
      <Button
        v-else
        class="suaw-event-card__next-event-button"
        size="large"
        type="primary"
        button-text="Go to Next Event"
        icon-right="IconCircleChevronRight"
        @click="$emit('next-event-click')"
      />
    </div>

    <div
      v-else-if="isVirtual"
      class="suaw-event-card__location-container conference-container"
    >
      <template v-if="isJoinTime && isFollowing">
        <div
          v-if="virtualMeetingId || virtualMeetingPassword"
          class="suaw-event-card__meeting-info"
        >
          <Paragraph
            v-if="virtualMeetingId"
            class="suaw-event-card__heading"
            weight="bold"
            size="large"
            :text="`Meeting ID: ${virtualMeetingId}`"
            alignment="center"
          />
          <Paragraph
            v-if="virtualMeetingPassword"
            class="suaw-event-card__heading"
            weight="bold"
            size="large"
            :text="`Meeting Password: ${virtualMeetingPassword}`"
            alignment="center"
          />
        </div>
      </template>
      <!-- <div>
        <Heading
          class="suaw-event-card__location-heading"
          :content="`Online event hosted on ${conferenceType}`"
          level="3"
          alignment="center"
        />
        <Paragraph
          class="suaw-event-card__heading"
          :text="`(<a target='_blank' title='${conferenceType}' href='${conferenceUrl}'>${conferenceUrl}</a>).`"
          alignment="center"
        />
      </div> -->
      <div>
        <Button
          class="suaw-event-card__button"
          size="large"
          :type="isJoinDisabled ? 'disabled' : 'primary'"
          icon-left="IconLink02"
          button-text="Join Event"
          :disabled="isJoinDisabled"
          @click="$emit('join-click')"
        />
      </div>
      <Paragraph
        v-if="isJoinTime && isFollowing"
        text="You can now enter the online event"
        size="small"
        weight="bold"
        alignment="center"
      />

      <div v-else-if="isJoinTime && !isFollowing" class="suaw-event-card__messaging">
        <Paragraph
          text="This event is currently in session."
          weight="bold"
          size="small"
          alignment="center"
        />
        <Paragraph
          text="Only members who follow this series can join this event."
          weight="bold"
          size="small"
          alignment="center"
        />
      </div>

      <div v-else-if="!isJoinTime && !isFollowing" class="suaw-event-card__messaging">
        <Paragraph
          text="This event will unlock 5 minutes before the start time."
          weight="bold"
          size="small"
          alignment="center"
        />
        <Paragraph
          text="Only members who follow this series can join this event."
          weight="bold"
          size="small"
          alignment="center"
        />
      </div>
      <div v-else class="suaw-event-card__messaging">
        <Paragraph
          text="This event will unlock 5 minutes before the start time."
          weight="bold"
          size="small"
          alignment="center"
        />
      </div>
    </div>

    <div v-else class="suaw-event-card__location-container map-container">
      <gmapsMap ref="map" :options="mapOptions">
        <gmapsMarker :position="{ lat: venueLat, lng: venueLng }" />
      </gmapsMap>
    </div>

    <div v-if="!isVirtual" class="suaw-event-card__footer">
      <Button
        class="suaw-event-card__footer-button suaw-button--no-x-pd"
        type="ghost"
        size="medium"
        button-text="Center on Map"
        @click="recenterMap"
      />
      <Button
        type="primary"
        size="small"
        icon-left="IconLink02"
        button-text="Directions"
        @click="copyUrl"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-event-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-double);
  width: 100%;
  max-width: 1264px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  &__summary-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    row-gap: 12px;
    column-gap: 16px;
    align-items: center;
  }
  &__summary-calendar {
    grid-column: 1/2;
    grid-row: 1/3;
    @media (max-width: 650px) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }
  &__summary-event-info {
    grid-column: 2/3;
    grid-row: 1/3;
    display: grid;
    row-gap: 5px;
    @media (max-width: 650px) {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    @media (max-width: 525px) {
      grid-column: 2/4;
    }
  }
  &__summary-event-duration {
    font-size: 18px;
  }
  &__summary-venue-title {
    font-size: 16px;
  }
  &__summary-buttons-container {
    grid-column: 3/4;
    grid-row: 1/2;
    display: inline-flex;
    justify-content: flex-end;
    gap: 12px;
    @media (max-width: 650px) {
      grid-column: 1/4;
      grid-row: 2/3;
      flex-direction: column-reverse;
      width: 100%;
    }
    @media (max-width: 525px) {
      grid-row: 3/4;
    }
  }
  &__summary-action-button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__summary-chips-container {
    grid-column: 3/4;
    grid-row: 2/3;
    justify-self: end;
    display: inline-flex;
    @media (max-width: 650px) {
      grid-column: 3/4;
      grid-row: 1/2;
      align-self: start;
    }
    @media (max-width: 525px) {
      grid-column: 1/4;
      grid-row: 2/3;
      justify-self: start;
    }
  }
  &__location-container {
    height: 315px;
    border-radius: 8px;
    overflow: hidden;
  }

  .past-cancelled-container {
    padding: 16px;
    background-color: #f7f7f7;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .conference-container {
    padding: 16px;
    background: #f0e9ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  &__location-heading {
    font-weight: 500;
  }
  &__footer {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  &__footer-button {
    height: 24px;
  }
  &__button {
    background: #6737ff;
  }

  &__button:disabled {
    opacity: 0.33;
  }
  &__messaging {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__meeting-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__button:disabled:hover {
    background: #6737ff;
    opacity: 0.33;
  }
}
</style>
