<script>
import { SuawSection, SuawInputGroup, SuawHeading, SuawEmptyState, SuawButton, SuawTable, SuawTableItem, SuawTextInput } from "@/components";
import { EventSeriesListForChapterAdmin } from "../../operations.gql";

export default {
  name: "ChapterViewSeries",
  components: {
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState,
    SuawButton,
    SuawTable,
    SuawTableItem,
    SuawTextInput
  },
  props: {
    chapterId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //Basic
      loadingQueriesCount: 0,
      resultEventSeriesListForChapterAdmin: {
        id: "",
        photo_url: "",
        chapter: {
          id: "",
          slug: ""
        },
        slug: "",
        title: ""
      },
      filterEventSeriesQuery: "",
      offsetEventSeries: 0,
      limitEventSeries: 4
    };
  },
  apollo: {
    resultEventSeriesListForChapterAdmin: {
      query: EventSeriesListForChapterAdmin,
      variables() {
        return {
          chapterId: this.chapterId
        };
      },
      loadingKey: "loadingQueriesCount",
      skip() {
        return !this.chapterId;
      }
    }
  },
  computed: {
    //Basic Info
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultEventSeriesListForChapterAdmin.id !== "";
    },
    //Event Series Info
    eventSeriesList() {
      return this.resultEventSeriesListForChapterAdmin;
    },
    filteredEventSeries() {
      if (!this.filterEventSeriesQuery) {
        return this.eventSeriesList.slice(this.offsetEventSeries, this.offsetEventSeries + this.limitEventSeries);
      }
      const lowerFilterQuery = this.filterEventSeriesQuery.toLowerCase();
      return this.eventSeriesList
        .filter(x => x.title.toLowerCase().includes(lowerFilterQuery)) //!!and more
        .slice(this.offsetEventSeries, this.offsetEventSeries + this.limitEventSeries);
    },
    showPrevEventSeries() {
      return this.offsetEventSeries !== 0;
    },
    showNextEventSeries() {
      return this.filteredEventSeries.length === this.limitEventSeries;
    }
  },
  methods: {
    buildEventSeriesItem(x) {
      return {
        id: x.id,
        avatar: x.photo_url ?? "  ",
        color: "blue",
        type: x.photo_url ? "picture" : "blue",
        contents: [
          {
            text: x.title
          }
        ],
        button: "Organizer",
        buttonCritical: "Admin",
        slug: x.slug,
        chapterSlug: x.chapter.slug,
        chapterId: x.chapter.id
      };
    },
    handleEventSeriesFilter(filterTerm) {
      if (!filterTerm) {
        this.filterEventSeriesQuery = "";
        return;
      }
      this.filterEventSeriesQuery = filterTerm;
    },
    onPrevEventSeries() {
      this.offsetEventSeries -= this.limitEventSeries;
    },
    onNextEventSeries() {
      this.offsetEventSeries += this.limitEventSeries;
    },
    onOrganizerClick(item) {
      this.$router.push({
        name: "SeriesEdit",
        params: {
          chapterId: item.chapterId,
          seriesId: item.id
        },
        query: {
          step: "schedule"
        }
      });
    },
    onAdminClick(item) {
      this.$router.push({
        name: "SeriesView",
        params: {
          seriesId: item.id
        }
      });
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawInputGroup direction="column">
      <SuawHeading content="Event Series" level="5" />
      <SuawTextInput v-model="filterEventSeriesQuery" placeholder="Filter Event Series" @input="handleEventSeriesFilter" />
      <SuawSection v-if="eventSeriesList.length > 0">
        <SuawButton
          v-if="showPrevEventSeries"
          icon-left="IconCircleChevronLeft"
          button-text="Prev"
          type="ghost"
          class="suaw-button--pull-left"
          size="small"
          @click="onPrevEventSeries"
        />
        <SuawButton
          v-if="showNextEventSeries"
          icon-left="IconCircleChevronRight"
          button-text="Next"
          type="ghost"
          class="suaw-button--pull-right"
          size="small"
          @click="onNextEventSeries"
        />
      </SuawSection>
      <SuawTable v-if="eventSeriesList.length > 0">
        <SuawTableItem v-for="u in filteredEventSeries" :key="u.id" :item="buildEventSeriesItem(u)" @message-click="onOrganizerClick" @report-click="onAdminClick" />
      </SuawTable>
      <SuawSection v-if="eventSeriesList.length > 0">
        <SuawButton
          v-if="showPrevEventSeries"
          icon-left="IconCircleChevronLeft"
          button-text="Prev"
          type="ghost"
          class="suaw-button--pull-left"
          size="small"
          @click="onPrevEventSeries"
        />
        <SuawButton
          v-if="showNextEventSeries"
          icon-left="IconCircleChevronRight"
          button-text="Next"
          type="ghost"
          class="suaw-button--pull-right"
          size="small"
          @click="onNextEventSeries"
        />
      </SuawSection>
      <SuawEmptyState v-else message="No Event Series" />
    </SuawInputGroup>
  </SuawInputGroup>
</template>

<style lang="css" scoped></style>
