<script>
import EditableBlock from "../EditableBlock/EditableBlock.vue";
import Label from "../Label/Label.vue";
import TextInput from "../TextInput/TextInput.vue";
import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  name: 'LocationInput',
  components: {
    EditableBlock,
    Label,
    TextInput,
    gmapsMap,
    gmapsMarker,
  },
  props: {
    label: {
      type: String,
      default: 'Region',
    },
    actionText: {
      type: String,
      default: 'Edit',
    },
    confirmText: {
      type: String,
      default: "Save",
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    locationName: {
      type: String,
      default: "",
    },
    locationAddress: {
      type: String,
      default: "",
    },
    lat: {
      type: Number,
      default: 37.7829006,
    },
    lng: {
      type: Number,
      default: -122.3889702,
    },
    zoom: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      showFields: false,
    };
  },
  computed: {
    actionTexts() {
      return this.showFields == true ? this.confirmText : this.actionText;
    },
    mapOptions() {
      return {
        zoom: this.zoom,
        center: { lat: this.lat, lng: this.lng },
      };
    },
  },
  methods: {
    toggleFormFields() {
      this.showFields = !this.showFields;
    },
  },
};
</script>

<template>
  <div class="suaw-location-input">
    <Label
      v-if="label"
      :labelText="label"
      size="small"
    />
    <div class="map-wrapper">
      <gmaps-map :options="mapOptions">
        <gmaps-marker :position="{ lat: lat, lng: lng }" />
      </gmaps-map>
    </div>
    <EditableBlock
      :isEditable="isEditable"
      :label="locationName"
      :description="locationAddress"
      :buttonText="actionTexts"
      blockType="ghost"
      :click="toggleFormFields"
    />
    <div
      v-if="showFields"
      class="suaw-location-input__fields"
    >
      <TextInput
        class="suaw-location-input__field"
        type="address"
        label="Location / Address"
        placeholder="Search by Location"
      />
      <slot name="address-fields" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-location-input {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-base);

  &__field {
    margin-top: var(--sem-space-double);
  }
  .map-wrapper {
    width: 100%;
    height: 250px;
    border: 1px solid rgba(97, 97, 97, 0.29);
  }
}
</style>
