<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas">
      <SuawParagraph
        text="You’re lying on the floor in crumpled, coffee-stained clothes, your hair a mess from all the times you’ve pulled at it. Over in the corner, your laptop lights up with the blinking of your cursor on a background that sends chills down your spine—an empty page. Why, oh why, did you use up all your&nbsp;<em>good</em> writing ideas already? What were you thinking? And now, you’re out of fuel, your mind as empty as a bird’s nest in December."
      />
      <SuawParagraph
        text="This wasn’t always you. You used to have ideas—<em>so many</em> ideas! At the beginning of your project, you had no shortage of them. Maybe you even set a New Year’s resolution to finally get that novel, memoir, thesis, or blog going in 2023. You couldn’t wait to jump out of bed on January 1st and hit the page running. This was going to be your year."
      />
      <SuawParagraph
        text="If this is you, it’s okay! You’re experiencing a normal part of being a writer. Questioning your lack of writing ideas can be a common occurrence once you’re past the initial excitement of starting a new project. It’s easy to take off sprinting at the starting line, but somewhere along the route, your energy will drop, your motivation will flag, and you might even wonder why you’re making this effort in the first place."
      />
      <SuawParagraph text="So don’t worry—we’ve all been there. Here are a few ways to find inspiration when the well is dry:" />
      <SuawHeading level="3" content="Five Ways to Find New Writing Ideas" />
      <SuawHeading level="4" content="1. Go to a Coffee Shop or Bar" />
      <SuawParagraph
        text="I love this particular exercise because a delicious treat is part and parcel of the assignment! Whether you’re into coffee, decaf, cocktails, or mocktails, you’ll probably have access to your favorite drink somewhere near your home base."
      />
      <SuawParagraph text="Pack a notebook and pen or laptop before you go." />
      <SuawParagraph
        text="Once you’re there, find a comfortable seat where you can sip on your beverage for an hour or two and take notes. Observe the ambience and the people around you. How does this space feel? What are the people saying? What can you glean about their hopes, desires, and worries? Is there someone with a unique haircut or outfit who might become a character in your short story? Or, is the barista someone who reminds you of the adults present in your childhood home? How do the people behave here, and how does that match or contrast with what you know about the human condition?"
      />
      <SuawParagraph text="I’ve found so many story ideas by simply visiting my local coffee shop during a moderately busy afternoon." />
      <SuawHeading level="4" content="2. Talk to People About Their Lives" />
      <SuawParagraph
        text="This exercise can double as a bonding activity with someone in your life whose story you’re curious about. All you have to do is ask a friend or family member if you can interview them to get inspiration for your writing. Pick a person you know likes to share anecdotes. Did your uncle grow up in a very different era, and does he like to talk about it? Use that! His story might be just the thing to get your synapses firing with old family stories you can shape into your own memoir. For best results, remember to get your interviewee’s permission to use details from their story in your writing, whether you use the Voice Recording app on your phone, take notes, or simply listen for details you plan to use later on."
      />
      <SuawParagraph
        text="If you’re writing fiction, you can use a twist on this technique. Do you have a chatty neighbor, plumber, or lingerer in your life who can’t seem to stop talking every time you run into them? Pay attention to their mannerisms and turns of phrases. Can you create a side character or even a powerful villain by mixing and matching their characteristics? Use as much creative license as you need to remix their eccentricities into something even more outrageous for your short story or novel. If nothing else, it might be satisfying to turn your next-door neighbor into the power-mad antagonist you know she truly is."
      />
      <SuawHeading level="4" content="3. Keep a Dream Journal" />
      <SuawParagraph
        text="This one can work wonders for those of you who remember your dreams. Get yourself a notebook or open up a new document on your computer. Make sure it’s named and organized in a way that’s easy to find. Every day, when you wake up, immediately turn to your notebook or document and write down everything you remember about your dream, however mundane or bizarre. Over time, you’ll start to see patterns and archetypes. Your subconscious mind will guide you in the search for the stories you want to tell. Mine your sleeping brain for hints on what preoccupations and desires you have as a storyteller. Are you dreaming big stories about life or death? Or, do you have recurring dreams about being at work when something goes wrong? How about themes of family? What do you see in your mind’s eye that you can capture and shape into a powerful story?"
      />
      <SuawParagraph
        text="You can take liberties with this technique to generate your writing ideas. Sometimes, a dream will offer you an intriguing story which you can retell word for word, barely making any changes. But usually, you’ll need to shape the raw material into a story with a narrative arc that’s satisfying for your reader. Once you’ve written down your journal entry, create a bulleted list at the bottom of the page (or in a separate document or journal—your process can vary with your preferences) and list out images, motifs, and themes from your latest dream. After doing this exercise a few times, sit down with your list and start imagining a story that uses the people, places, images, and emotions you’ve found in your dreamscapes."
      />
      <SuawParagraph
        text="If you’re one of those people who has trouble remembering their dreams, you can always use&nbsp;<a target='_blank' title='Dreams on Reddit' href='https://www.reddit.com/r/Dreams/'><u>other people’s dreams</u></a> for inspiration!"
      />
      <SuawHeading level="4" content="4. Go to an Art Museum" />
      <SuawParagraph
        text="All these exercises are ultimately about shaking yourself loose from the ordinary context of your life. When we encounter something new, it helps dust the cobwebs off our imaginations, which is important preparation for writing. This last technique is no exception, but we’re about to get meta with it."
      />
      <SuawParagraph
        text="Take yourself to an art museum for an afternoon. Feel free to bring a notebook or voice recording app for taking notes on whatever inspires you there. Imagine a character walking from one painting to another. What happens to your character when they go from a serene natural landscape to a garish space filled with abstract shapes in clashing colors? How about when they go from a lonely desert to a thronged party? These kinds of movements can inspire the next plot twist in your story."
      />
      <SuawHeading level="4" content="5. Use Our Writing Prompts and Challenges" />
      <SuawParagraph
        text="Shut Up &amp; Write has&nbsp;<a title='Writing Prompts on Shut Up &amp; Write' href='/prompts'><u>writing prompts</u></a> specifically designed to kick your imagination into high gear. Whether&nbsp;<a  title='Writing Prompt on Shut Up &amp; Write' href='/prompts/3965c35d-0878-42dd-9a2c-bbb7067eb107'><u>a mysterious sign is spray-painted on a brick wall</u></a> or your&nbsp;<a title='Writing Prompt on Shut Up &amp; Write' href='/prompts/fd22b735-1593-490f-9767-c5ac7137e860'><u>lawn gnomes suddenly come to life</u></a>, you’ll get to put yourself or your character into a situation that will force you to write. Will you write your character a way out of this scenario? Or will your character get involved in more complications as they grapple with the situation that you’ve put them in? Whichever it is, all that matters is that you write your way forward, word by word, sentence by sentence."
      />
      <SuawParagraph
        text="You can also work on specific aspects of your craft by utilizing one of our monthly&nbsp;<a title='Writing Challenges on Shut Up &amp; Write' href='/challenges'><u>writing challenges</u></a>, which consist of daily prompts that are focused on different aspects of writing. For example, if you want to work on&nbsp;<a title='Writing Challenge on Shut Up &amp; Write' href='/challenges/five-senses-challenge/86c8fbb6-4c75-4c0c-bd49-f016c59d742a'><u>improving the sensory language</u></a> in your writing, we’ve got&nbsp;<a title='Writing Challenge on Shut Up &amp; Write' href='/challenges/five-senses-challenge/86c8fbb6-4c75-4c0c-bd49-f016c59d742a'><u>a challenge</u></a> for that! If you’ve always wanted to get started on your&nbsp;<a title='Memoir Writing Challenge on Shut Up &amp; Write' href='/challenges/memoir/135cba05-13d0-4e7a-a154-a5dc50882b90'><u>memoir</u></a>, we have&nbsp;<a title='Memoir Writing Challenge on Shut Up &amp; Write' href='/challenges/memoir/135cba05-13d0-4e7a-a154-a5dc50882b90'><u>a challenge</u></a> for that too."
      />
      <SuawHeading level="4" content="Go to a Shut Up &amp; Write!" />
      <SuawParagraph
        text="After you’ve completed one or more of the tips above, make a date with yourself to attend a&nbsp;<a title='Writing Events at Shut Up &amp; Write' href='/in-person-events'><u>Shut Up &amp; Write</u></a> event. You can go&nbsp;<a title='In-Person Writing Events at Shut Up &amp; Write' href='/in-person-events'><u>in person</u></a> or log in&nbsp;<a title='Online Writing Events at Shut Up &amp; Write' href='/online-events'><u>online</u></a> from anywhere in the world. At your event, channel the sights, sounds, and feelings you experienced into your writing. Create new characters or reconnect with old ones. Remember, you are the writer and this is your story!"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
