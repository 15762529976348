var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$slots.default)?_c('p',{staticClass:"suaw-paragraph",class:[
    'suaw-paragraph--' + _vm.size,
    'suaw-paragraph--' + _vm.weight,
    'suaw-paragraph--' + _vm.alignment,
    _vm.className
  ],domProps:{"innerHTML":_vm._s(_vm.processedText)}}):_c('p',{staticClass:"suaw-paragraph",class:[
    'suaw-paragraph--' + _vm.size,
    'suaw-paragraph--' + _vm.weight,
    'suaw-paragraph--' + _vm.alignment,
    _vm.className
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }