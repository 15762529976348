<script>
import TableItem from './TableItem.vue';

export default {
  name: 'Table',
  components: {
    TableItem,
  },
  props: {
    headers: {
      type: Array,
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    messageClick: {
      type: Function,
      default: () => { }
    },
    reportClick: {
      type: Function,
      default: () => { }
    },
  },
  methods: {
    handleMessageClick() {
      this.messageClick();
    },
    handleReportClick() {
      this.reportClick();
    },
  }
}
</script>

<template>
  <table class="suaw-table">
    <thead
      v-if="headers && headers.length"
      class="suaw-table__head"
    >
      <tr>
        <th
          v-for="(header, index) in headers"
          :key="index"
          class="suaw-table__header"
        >{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="data && data.length">
        <TableItem
          v-for="(item, index) in data"
          :key="index"
          :item="item"
          @message-click="handleMessageClick"
          @report-click="handleReportClick"
          @item-click="$emit('dropdown-item-click', $event)"
        />
      </template>
      <slot />
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.suaw-table {
  background-color: var(--sem-color-background-lightest);
  border: 1px solid var(--sem-color-border-regular);
  border-collapse: collapse;
  border-radius: var(--sem-radius-sm);
  box-shadow: var(--date-square-shadow);
  min-width: 100%;
  max-width: 100%;
  overflow: auto;
  table-layout: fixed;

  &__header {
    background-color: var(--sem-color-background-light);
    border-bottom: 1px solid var(--sem-color-border-regular);
    padding: var(--sem-space-double);
  }
}
</style>
