<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Card from "../Card/Card.vue";

export default {
  name: 'LocationBoxInPerson',
  components: {
    Paragraph,
    Card,
  },
  props: {
    locationName: String,
    locationAddress: String,
    linkContent: String,
    titleLeft: String,
    copyLeft: String,
    titleRight: String,
    copyRight: String,
  }
}
</script>

<template>
  <div class="suaw-location-box__content--in-person">
    <Card
      cardType="CardMap"
      :locationName="locationName"
      :locationAddress="locationAddress"
      :linkContent="linkContent"
    />
    <footer class="suaw-location-box__footer">
      <div class="suaw-location-box__block">
        <Paragraph
          v-if="titleLeft"
          class="suaw-location-box__label"
          weight="bold"
          size="medium"
          :text="titleLeft"
        />
        <Paragraph
          v-if="copyLeft"
          class="suaw-location-box__label"
          weight="normal"
          size="medium"
          :text="copyLeft"
        />
      </div>
      <div class="suaw-location-box__block">
        <Paragraph
          v-if="titleRight"
          class="suaw-location-box__label"
          weight="bold"
          size="medium"
          :text="titleRight"
        />
        <Paragraph
          v-if="copyRight"
          class="suaw-location-box__label"
          weight="normal"
          size="medium"
          :text="copyRight"
        />
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-location-box {

  &__content--in-person {
    gap: var(--sem-space-double);
  }

  &__content--in-person,
  &__block {
    flex-direction: column;
  }

  &__content--in-person,
  &__block,
  &__footer {
    display: flex;
  }

  &__footer {
    justify-content: space-between;
  }
}
</style>
