<script>
import SearchInput from "../SearchInput/SearchInput.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Button from "../Button/Button.vue";
import Divider from "../Divider/Divider.vue";

export default {
  name: "SearchBar",
  components: {
    SearchInput,
    Dropdown,
    Button,
    Divider,
  },
  props: {
    placeholder: {
      type: String,
      default: "Search...",
    },
    showLocation: {
      type: Boolean,
      default: true,
    },
    location: {
      type: String,
      default: "Tulsa, OK",
    },
    buttonText: {
      type: String,
      default: "Search",
    },
    dropdownItems: {
      type: Array,
    },
    // SearchInput props
    useGoogle: {
      type: Boolean,
      default: false,
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
    citiesOnly: {
      type: Boolean,
      default: false,
    },
    searchLimit: {
      type: Number,
      default: 5,
    },
    debounceTime: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      searchTerm: "",
      selectedItem: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleSubmit() {
      this.$emit("submit", this.selectedItem);
      this.searchTerm = "";
      this.selectedItem = null;
    },
    handleItemSelect(item) {
      this.selectedItem = item;
      this.$emit("item-select", item);
    },
  },
};
</script>

<template>
  <div class="suaw-search-box">
    <template v-if="showLocation">
      <slot name="autocomplete">
        <Dropdown
          :label="location"
          buttonType="ghost"
          :items="dropdownItems"
          iconDropdown=""
          iconDropdownLeft="IconLocation"
          dropdownType="mutable"
          className="suaw-search-box__dropdown"
          buttonClassName="suaw-dropdown__button--fit"
          hasSearch
        />
      </slot>
      <Divider
        v-if="windowWidth > 681"
        type="inner"
        orientation="vertical"
      />
    </template>
    <SearchInput
      v-model="searchTerm"
      :placeholder="placeholder"
      :useGoogle="useGoogle"
      :searchResults="searchResults"
      :citiesOnly="citiesOnly"
      :searchLimit="searchLimit"
      :debounceTime="debounceTime"
      borderStyle="ghost"
      class="suaw-search-box__input"
      showIcon
      @input="$emit('input', $event)"
      @item-select="handleItemSelect"
    />
    <Button
      size="medium"
      :buttonText="buttonText"
      type="primary"
      class="suaw-search-box__button"
      @click="handleSubmit"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-search-box {
  @media screen and (min-width: 681px) {
    --comp-search-bar-height: 64px;
    --comp-search-bar-dropdown-max-width: 270px;
    --comp-search-bar-alignment: center;
    --comp-search-bar-direction: row;
    --comp-search-bar-input-width: auto;
    --comp-search-bar-padding: 0 var(--sem-space-base) 0 0;
    --comp-search-bar-button-width: auto;
  }

  @media screen and (max-width: 680px) {
    --comp-search-bar-height: auto;
    --comp-search-bar-dropdown-max-width: 100%;
    --comp-search-bar-alignment: flex-start;
    --comp-search-bar-direction: column;
    --comp-search-bar-input-width: 100%;
    --comp-search-bar-padding: var(--sem-space-base);
    --comp-search-bar-button-width: calc(100% - var(--sem-space-double));
  }

  align-items: var(--comp-search-bar-alignment);
  background-color: var(--sem-color-background-lightest);
  border-radius: var(--sem-radius-md);
  border: 1px solid var(--sem-color-border-medium);
  box-shadow: var(--date-square-shadow);
  display: flex;
  flex-direction: var(--comp-search-bar-direction);
  height: var(--comp-search-bar-height);
  padding: var(--comp-search-bar-padding);
  width: 100%;

  &__dropdown {
    flex-grow: 1;
    width: var(--comp-search-bar-dropdown-max-width);
  }

  &__input {
    flex-grow: 4;
    margin-left: var(--sem-space-base);
    width: var(--comp-search-bar-input-width);
  }

  &__button {
    margin: var(--sem-space-base);
    width: var(--comp-search-bar-button-width);
  }
}
</style>
