<script>
import DirectMessageItem from "./DirectMessageItem.vue";
import TextArea from "../TextArea/TextArea.vue";
import Button from "../Button/Button.vue";

export default {
  name: "DirectMessageList",
  components: {
    DirectMessageItem,
    Button,
    TextArea,
  },
  props: {
    notificationListItems: {
      type: Array,
      default: () => [],
    },
    posted: {
      type: String,
    },
    chipText: {
      type: String,
    },
    chipContent: {
      type: String,
    },
    isOwner: {
      type: Boolean,
    },
    buttonLabel: {
      type: String,
      default: "Mark all as Read"
    },
    id: {
      type: [String, Number, Object],
    },
    replyButtonText: {
      type: String,
      default: "Reply",
    },
    userFullName: {
      type: String,
    },
    replyPlaceholder: {
      type: String,
      default: "Type your reply here...",
    },
  },
  data() {
    return {
      isSidepanelDirectMessage: true,
      userInput: { type: "doc", content: [{ type: "paragraph" }] },
    };
  },
  computed: {
  },
  methods: {
    handleInput(value) {
      this.userInput = value;
    },
    handleSubmit() {
      if (this.$refs.textArea.isEmpty()) {
        return;
      } else {
        const payload = {
          text: this.userInput,
          parentPostId: this.id,
        };
        this.$emit("submit-reply", payload);
        this.userInput = { type: "doc", content: [{ type: "paragraph" }] };
      }
    }
  }
};
</script>

<template>
  <div class="suaw-direct-message">
    <ul class="suaw-direct-message__list">
      <DirectMessageItem
        v-for="item in notificationListItems"
        :key="item.id"
        :headingText="item.text"
        :posted="item.posted"
        :chipContent="item.chipContent"
        :chipText="item.chipText"
        :chipColor="item.chipColor"
        :chipInitials="item.chipInitials"
        :isSidepanelDirectMessage="isSidepanelDirectMessage"
        :isOwner="item.isOwner"
        :id="item.id"
      />
      <slot />
    </ul>

    <div class="suaw-direct-message__block">
      <TextArea
        ref="textArea"
        v-model="userInput"
        :placeholder="replyPlaceholder"
        borderStyle="ghost"
        :use-tip-tap="true"
      />
      <Button
        size="small"
        type="primary-light"
        :buttonText="replyButtonText"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-direct-message {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__list {
    border-radius: var(--sem-radius-sm);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
    list-style-type: none;
    // padding: 0 0 calc(var(--sem-space-sextuple)* 5);
    // padding: 0 0 calc(var(--sem-space-sextuple) * 5) 0;
    padding: 0;
  }

  &__block {
    background-color: var(--sem-color-background-lightest);
    align-items: flex-end;
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    gap: var(--sem-space-base);
    margin: var(--sem-space-double) auto;
    padding: var(--sem-space-base);
    position: sticky;
    width: 100%;
    z-index: 994;
  }
}
</style>
