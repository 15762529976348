<script>
export default {
  name: 'InputGroup',
  props: {
    fieldOneSize: {
      type: String,
      default: '1'
    },
    fieldTwoSize: {
      type: String,
      default: '1'
    },
    groupGap: {
      type: String,
      default: 'double'
    },
    centered: {
      type: Boolean,
    },
    direction: {
      type: String,
      default: 'row',
      validator: function (value) {
        return ["row", "column"].indexOf(value) !== -1;
      },
    },
    groupStyle: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "border"].indexOf(value) !== -1;
      },
    },
    preventFlexGrow: {
      type: Boolean,
      default: false,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alignmentOptions() {
      return this.direction == 'row' ? 'flex-end' : 'flex-start';
    },
    fieldWidth() {
      return this.direction == 'row' ? '' : '100%';
    },
    isCentered() {
      return this.centered == true ? 'suaw-input-group--center' : '';
    },
    inlineWrapContent() {
      return this.noWrap ? "nowrap" : null;
    },
  }
}
</script>

<template>
  <div
    class="suaw-input-group"
    :class="['suaw-input-group--' + groupStyle, isCentered]"
    :style="{
      '--field-1-size': fieldOneSize,
      '--field-2-size': fieldTwoSize,
      '--direction': direction,
      '--alignment': alignmentOptions,
      '--field-width': fieldWidth,
      '--group-gap': 'var(--sem-space-' + groupGap + ')',
      '--prevent-flex-grow': preventFlexGrow ? 0 : 1,
      '--comp-input-group-wrap-content': inlineWrapContent
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.suaw-input-group {
  align-items: var(--alignment);
  display: flex;
  flex-direction: var(--direction);
  flex-wrap: var(--comp-input-group-wrap-content);
  gap: var(--group-gap);
  width: 100%;

  @media screen and (min-width: 1025px) {
    --comp-input-group-wrap-content: no-wrap;
  }

  @media screen and (max-width: 1024px) {
    --comp-input-group-wrap-content: wrap;
  }

  &--center {
    justify-content: center;
  }

  > *:first-child {
    flex-grow: calc(var(--prevent-flex-grow) * var(--field-1-size));
  }

  > *:last-child {
    flex-grow: calc(var(--prevent-flex-grow) * var(--field-2-size));
  }

  > * {
    width: var(--field-width);
  }

  &--border {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-regular);
    border-radius: var(--sem-radius-sm);
    padding: var(--sem-space-triple);
  }
}
</style>
