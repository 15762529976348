<script>
import Button from "../Button/Button.vue";
import Heading from "../Heading/Heading.vue";

export default {
  name: "Sidepanel",
  components: {
    Button,
    Heading,
  },
  props: {
    sidepanelTitle: {
      type: String,
    },
    clickMethod: {
      type: Function,
    },
    isDirectMessages: {
      type: Boolean,
      default: false,
    },
    hasSidepanelDiscussionList: {
      type: Boolean,
    },
  },
  data() {
    return {
      headingText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt aliquam posuere purus donec morbi at morbi dignissim ut. Rhoncus amet suscipit mi viverra tortor, ut sagittis donec.",
      chipText: "",
      discussionLikes: "",
      discussionResponses: "",
      posted: "",
      textBoxPrimaryButton: "",
      textBoxSecondaryButton: "",
      textBoxSize: "",
    };
  },
  methods: {
    handleClick() {
      if (this.clickMethod) {
        this.clickMethod();
      }
      this.$emit("button-clicked");
    },
  },
};
</script>

<template>
  <div class="suaw-sidepanel">
    <div 
      class="suaw-sidepanel__overlay"
      @click="handleClick"
    ></div>
    <div 
      class="suaw-sidepanel__content"
      :class="{
        'suaw-sidepanel__content--direct-messages': isDirectMessages
      }"
    >
      <div class="suaw-sidepanel__title">
        <Button
          size="medium"
          type="secondary-ghost"
          class="suaw-button--no-x-pd"
          iconRight="IconCircleChevronLeft"
          @click="handleClick"
        />
        <Heading
          v-if="sidepanelTitle"
          :level="4"
          :content="sidepanelTitle"
        />
      </div>
      <div class="suaw-sidepanel__block">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-sidepanel {
  z-index: 99996;

  &,
  &__overlay {
    left: 0;
    height: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &__overlay {
    background-color: rgba(10, 10, 10, 0.86);
    cursor: pointer;
  }

  &,
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    box-shadow: var(--soft-shadow-draft);
    gap: var(--sem-space-triple);
    padding: var(--sem-space-quintuple);
    position: absolute;
    right: 0;
    min-width: 50%;
    max-width: 100%;
    width: 470px;
    height: 100%;
    overflow-y: auto;
  }
  &__content--direct-messages {
    justify-content: space-between;
  }

  &__content--medium {
    align-items: center;
    text-align: center;
    width: 50%;
  }

  &__content--small {
    width: 420px;
  }

  &__title {
    display: flex;
    gap: var(--sem-space-base);
    width: 100%;
    align-items: center;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-triple);
    width: 100%;
  }

  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 0.25s linear;
  }

  &__fade-enter,
  &__fade-leave-to {
    opacity: 0;
  }
}
</style>
