<script>
import Icon from "../Icon/Icon.vue";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Button from "../Button/Button.vue";
export default {
  name: "EmptyState",
  components: {
    Icon,
    Heading,
    Paragraph,
    Button,
  },
  props: {
    message: {
      type: String,
      default: "No items to display"
    },
    submessage: {
      type: String,
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "View"
    },
    icon: {
      type: String,
      default: "IconErrorOutline"
    },
    size: {
      type: String,
      default: "large",
      validator: function (value) {
        return ["large", "medium"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    headingSizes() {
      return this.size == 'large' ? '3' : '5';
    },
  },
}
</script>

<template>
  <div 
    class="suaw-empty-state"
    :class="'suaw-empty-state--' + size"
  >
    <Icon
      v-if="icon"
      class="suaw-empty-state__icon"
      :icon="icon"
      :size="size"
    />
    <Heading
      :level="headingSizes"
      :content="message"
      class="suaw-empty-state__message"
      :key="headingSizes.toString()"
    />
    <Paragraph
      v-if="submessage"
      class="suaw-empty-state__submessage"
      weight="normal"
      size="medium"
      alignment="center"
      :text="submessage"
    />
    <Button
      v-if="showActionButton"
      class="suaw-empty-state__button"
      @click="$emit('click')"
      size="large"
      type="ghost-outline"
      iconLeft=""
      :buttonText="buttonText"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-empty-state {
  background: var(--sem-color-text-lightest);
  border: 1px solid var(--sem-color-border-medium);
  border-radius: var(--sem-radius-sm);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--sem-space-base);
  justify-content: center;
  text-align: center;
  min-height: var(--comp-empty-state-min-height);
  padding: var(--sem-space-septuple);
  width: 100%;

  &--large {
    --comp-empty-state-min-height: 350px;
  }

  &--medium {
    --comp-empty-state-min-height: 184px;
  }

  &__message {
    color: var(--sem-color-neutral-medium);
    display: block;
    font-weight: var(--h-3-font-weight);
    text-align: center;
  }

  &__submessage {
    margin-top: 8px;
    color: var(--sem-color-neutral-medium);
  }

  &__icon {
    fill: var(--sem-color-neutral-medium);
  }

  &__button {
    margin-top: 16px;
  }
}
</style>
