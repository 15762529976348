<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Button from "../Button/Button.vue";
import { gmapsMap, gmapsMarker } from "x5-gmaps";


export default {
  name: 'CardMap',
  components: {
    Paragraph,
    Button,
    gmapsMap,
    gmapsMarker,
  },
  props: {
    locationName: String,
    locationAddress: String,
    locationLat: Number,
    locationLng: Number,
    zoom: {
      type: Number,
      default: 10,
    },
    linkContent: {
      type: String,
      default: "https://#"
    },
    linkText: {
      type: String,
      default: "Center on Map"
    },
    buttonText: {
      type: String,
      default: "Directions"
    },
  },
  computed: {
    mapOptions() {
      return {
        zoom: this.zoom,
        zoomControl: true,
        center: { lat: this.locationLat, lng: this.locationLng },
      };
    },
  },
  methods: {
    async copyUrl() {
      const linkToCopy = this.linkContent;
      try {
        await navigator.clipboard.writeText(linkToCopy);
        this.$emit("link-copied", { success: true });
      } catch (err) {
        this.$emit("link-copied", { success: false });
      }
    },
    recenterMap() {
      const mapInstance = this.$refs.map.map;
      if (mapInstance) {
        const newCenter = { lat: this.locationLat, lng: this.locationLng };
        mapInstance.setCenter(newCenter);
      }
    },
  },
};
</script>

<template>
  <div class="suaw-card__content">
    <div class="suaw-card__map">
      <div class="map-wrapper">
        <gmaps-map
          ref="map"
          :options="mapOptions"
        >
          <gmaps-marker
            :position="{ lat: locationLat, lng: locationLng }"
          />
        </gmaps-map>
      </div>
    </div>
    <footer class="suaw-card__footer">
      <div class="suaw-card__block">
        <Paragraph
          v-if="locationName"
          class="suaw-card__headline"
          weight="bold"
          size="small"
          :text="locationName"
        />
        <Paragraph
          v-if="locationAddress"
          class="suaw-card__text"
          size="small"
          :text="locationAddress"
        />
        <Button
          :buttonText="linkText"
          class="button center-on-map suaw-button--no-x-pd"
          type="ghost"
          size="medium"
          @click="recenterMap"
        />
      </div>
      <div class="suaw-card__block">
        <Button
          :buttonText="buttonText"
          class="button"
          size="small"
          type="ghost-outline"
          iconLeft="IconLink02"
          className="suaw-card__cta"
          @click="copyUrl"
        />
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {

  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__map {
    overflow: hidden;
  }
  .map-wrapper {
    width: 100%;
    height: 250px;
    border: 1px solid rgba(97, 97, 97, 0.29);
  }

  &__footer {
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: space-between;
  }
}
</style>
