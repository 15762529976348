<script>
import NotificationItem from "./NotificationItem.vue";
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";

export default {
  name: "NotificationList",
  components: {
    NotificationItem,
    Heading,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
  },
  props: {
    notificationListItems: {
      type: Array,
      default: () => [],
    },
    noticeStatus: {
      type: String,
      validator(value) {
        return ["Unseen", "Seen", "Read"].includes(value);
      },
    },
    posted: {
      type: String,
    },
    hasNotifications: {
      type: Boolean,
    },
    hasReadAll: {
      type: Boolean,
      default: true,
    },
    hasLink: {
      type: Boolean,
    },
    linkContent: {
      type: String,
    },
    buttonLabel: {
      type: String,
      default: "Mark all as Read",
    },
    eyebrow: {
      type: String,
      default: "New",
    },
    id: {
      required: true,
      type: [String, Number, Object],
    },
  },
  // data() {
  //   return {
  //     isSidepanelNotification: true,
  //     headingText: " Notifications",
  //     notificationCheck: this.hasNotifications,
  //   };
  // },
  computed: {
    // formattedNotificationLabel() {
    //   return this.notificationListItems.length === 0 ? 'No Notifications' : `${this.notificationListItems.length} Notification${this.notificationListItems.length > 1 ? 's' : ''}`;
    // },
    // unreadNotificationCount() {
    //   return this.notificationListItems.filter(
    //     (item) => item.noticeStatus !== "Read"
    //   ).length;
    // },
    unseenNotificationCount() {
      return this.notificationListItems.filter(
        (item) => item.noticeStatus === "Unseen"
      ).length;
    },
    formattedNotificationLabel() {
      const count = this.unseenNotificationCount;
      if (count === 0) {
        return "No New Notifications";
      } else if (count === 1) {
        return "1 New Notification";
      } else {
        return `${count} New Notifications`;
      }
    },
  },
  methods: {
    handleMarkRead() {
      this.$emit("read-all", this.notificationListItems);
    },
    handleStatusChange(payload) {
      this.$emit("status-change", payload);
    },
    handleLinkClick(payload) {
      this.$emit("link-click", payload);
    },
  },
};
</script>

<template>
  <div class="suaw-notification">
    <header class="suaw-notification__header">
      <Heading
        :level="5"
        :content="formattedNotificationLabel"
      />
      <Button
        v-if="hasReadAll"
        size="medium"
        class="suaw-button--no-y-pd"
        type="ghost"
        :buttonText="buttonLabel"
        @click="handleMarkRead"
      />
    </header>

    <ul class="suaw-notification-list">
      <NotificationItem
        v-for="(item, index) in notificationListItems"
        :key="index"
        :id="item.id"
        :headingText="item.text"
        :posted="item.posted"
        :eyebrow="item.eyebrow"
        :hasNotifications="item.hasNotifications"
        :hasLink="item.hasLink"
        :noticeStatus="item.noticeStatus"
        :linkContent="item.linkContent"
        @status-change="handleStatusChange"
        @link-click="handleLinkClick"
      />
      <slot />
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.suaw-notification {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-list {
    border-radius: var(--sem-radius-sm);
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
  }
}
</style>
