var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"suaw-main-content",class:[
    _vm.size === 'hero' ? 'suaw-main-content--hero' : '',
    _vm.showGradient ? 'suaw-main-content--gradient' : ''
  ]},[(_vm.showSubHeader)?_c('SubHeader',{attrs:{"headlineTitle":_vm.subHeaderTitle,"headlineDesc":_vm.subHeaderDesc}}):_vm._e(),_c('div',{staticClass:"suaw-main-content__holder",class:[
      'suaw-main-content__holder--' + _vm.size,
      _vm.smallTopMargin ? 'suaw-main-content__holder--top-margin' : ''
    ]},[_vm._l((_vm.mainItems),function(item,index){return _c(item.component,_vm._b({key:index,tag:"component"},'component',item.props,false))}),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }