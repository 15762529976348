<script>
import { SuawMainContent, SuawSection, SuawInputGroup, SuawTextInput, SuawTable, SuawTableItem, SuawEmptyState, SuawButton } from "@/components";
import { ChapterListForChapterAdmin } from "../../operations.gql";

export default {
  name: "ChapterSearch",
  metaInfo: {
    title: "Writing Groups - ",
    meta: [{ vmid: "description", name: "description", content: "Find your writing group at a Shut Up & Write event designed so you can focus on getting writing done." }]
  },
  components: {
    SuawMainContent,
    SuawSection,
    SuawInputGroup,
    SuawTextInput,
    SuawTable,
    SuawTableItem,
    SuawEmptyState,
    SuawButton
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultChapterList: [
        // {
        //   id
        //   title
        //   slug
        //   photo_url
        //   deleted_at
        //   place {
        //     city
        //     region
        //     country_abbrev
        //     timezone
        //   }
        // }
      ],
      searchQuery: "",
      offset: 0,
      limit: 8,
      filterQuery: ""
    };
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    chaptersList() {
      return this.resultChapterList;
    },
    filteredChapters() {
      if (!this.filterQuery) {
        return this.chaptersList;
      }
      const lowerFilterQuery = this.filterQuery.toLowerCase();
      return this.chaptersList.filter(u => {
        return (
          u.title.toLowerCase().includes(lowerFilterQuery) ||
          u.slug.toLowerCase().includes(lowerFilterQuery) ||
          u.place?.city?.toLowerCase().includes(lowerFilterQuery) ||
          u.place?.region?.toLowerCase().includes(lowerFilterQuery) ||
          u.place?.timezone?.toLowerCase().includes(lowerFilterQuery) ||
          u.place?.country_abbrev?.toLowerCase() === lowerFilterQuery ||
          (u.deleted_at && "deactivated".includes(lowerFilterQuery))
        );
      });
    },
    hasNoData() {
      return !this.isLoading && this.filteredChapters.length === 0;
    },
    showPrev() {
      return this.offset !== 0;
    },
    showNext() {
      return this.chaptersList.length === this.limit;
    }
  },
  watch: {
    searchQuery() {
      this.offset = 0;
    }
  },
  apollo: {
    resultChapterList: {
      query: ChapterListForChapterAdmin,
      debounce: 1000,
      variables() {
        return {
          limit: this.limit,
          offset: this.offset,
          like: `%${this.searchQuery}%`,
          ilike: `%${this.searchQuery}%`,
          eq: `${this.searchQuery}`
        };
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  methods: {
    handleSearch(searchTerm) {
      if (!searchTerm) {
        this.searchQuery = "";
        return;
      }
      this.searchQuery = searchTerm;
    },
    handleFilter(filterTerm) {
      if (!filterTerm) {
        this.filterQuery = "";
        return;
      }
      this.filterQuery = filterTerm;
    },
    buildChapterItem(x) {
      if (x.deleted_at) {
        return {
          id: x.id,
          avatar: x.photo_url ?? "  ",
          color: "blue",
          type: x.photo_url ? "picture" : "blue",
          contents: [
            {
              text: `${x.title} (deactivated)`,
              subtext: `${x.place.city}, ${x.place.region}, ${x.place.country_abbrev} (${x.place.timezone})`
            }
          ],
          buttonCritical: "Admin"
        };
      } else {
        return {
          id: x.id,
          avatar: x.photo_url ?? "  ",
          color: "blue",
          type: x.photo_url ? "picture" : "blue",
          contents: [
            {
              text: `${x.title} (${x.slug})`,
              subtext: `${x.place.city}, ${x.place.region}, ${x.place.country_abbrev} (${x.place.timezone})`
            }
          ],
          button: "Organizer",
          buttonCritical: "Admin"
        };
      }
    },
    handleViewAsOrganizerClick(chapter) {
      this.$router.push({ name: "Chapter", params: { chapterId: chapter.id, chapterSlug: chapter.slug } });
    },
    handleViewAsAdminClick(chapter) {
      this.$router.push({ name: "ChapterView", params: { chapterId: chapter.id } });
    },
    onPrev() {
      this.offset -= this.limit;
    },
    onNext() {
      this.offset += this.limit;
    },
    onCreateClick() {
      this.$router.push({ name: "ChapterCreate" });
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
      <SuawSection section-size="medium">
        <SuawButton icon-left="IconPlus" button-text="Create Chapter" type="ghost" size="small" @click="onCreateClick" />
      </SuawSection>
      <SuawSection section-size="medium">
        <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
          <SuawTextInput v-model="searchQuery" placeholder="Search chapter database" @input="handleSearch" />
        </SuawInputGroup>
        <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
          <SuawTextInput v-model="filterQuery" placeholder="Filter visible results" @input="handleFilter" />
        </SuawInputGroup>
      </SuawSection>
      <SuawSection section-size="medium">
        <SuawButton v-if="showPrev" icon-left="IconCircleChevronLeft" button-text="Prev" type="ghost" class="suaw-button--pull-left" size="small" @click="onPrev" />
        <SuawButton v-if="showNext" icon-left="IconCircleChevronRight" button-text="Next" type="ghost" class="suaw-button--pull-right" size="small" @click="onNext" />
      </SuawSection>
      <SuawSection section-size="medium">
        <SuawEmptyState v-if="isLoading" message="Loading data..." />
        <SuawEmptyState v-else-if="hasNoData" message="No data" />
        <SuawTable v-else>
          <SuawTableItem
            v-for="u in filteredChapters"
            :key="u.id"
            :item="buildChapterItem(u)"
            @message-click="handleViewAsOrganizerClick"
            @report-click="handleViewAsAdminClick"
          />
        </SuawTable>
      </SuawSection>
    </SuawInputGroup>
  </SuawMainContent>
</template>
