<script>
import Icon from "../Icon/Icon.vue";

export default {
  name: 'Link',
  components: {
    Icon,
  },
  props: {
    weight: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "bold"].indexOf(value) !== -1;
      },
    },
    alignment: {
      type: String,
      default: "left",
      validator: function (value) {
        return ["left", "center", "right"].indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "dark"].indexOf(value) !== -1;
      },
    },
    to: {
      type: [String, Object],
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    textClassName: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconActive: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
      validator: function (value) {
        return ["large", "medium", "small"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    isActive() {
      return (
        this.$route.path === this.to ||
        (typeof this.to === "object" && this.$route.name === this.to.name)
      );
    },
    isExternal() {
      return /^(https?:|mailto:|tel:)/.test(this.to);
    },
    computedTo() {
      return this.isExternal ? null : this.to;
    },
    computedTarget() {
      return this.isExternal ? '_blank' : '_self';
    },
  },
};
</script>

<template>
  <component 
    :is="isExternal ? 'a' : 'router-link'"
    :to="computedTo"
    :target="computedTarget"
    :href="isExternal ? to : null"
    v-bind="$attrs"
    :class="[
      'suaw-link',
      { 'suaw-link--active': isActive },
      'suaw-link--' + color,
      'suaw-link--' + alignment,
      className
    ]"
    :disabled="isActive"
  >
    <Icon
      v-if="icon || iconActive"
      class="suaw-link__icon"
      :class="{ 'suaw-link__icon--active': isActive }"
      :icon="isActive ? iconActive : icon"
    />
    <span
      class="suaw-link__text"
      :class="[
        'suaw-link__text--' + weight,
        'suaw-link__text--' + size,
        textClassName
      ]"
    >
      <slot></slot>
    </span>
    <Icon
      v-if="iconRight"
      class="suaw-link__icon icon-right"
      :class="{ 'suaw-link__icon--active': isActive }"
      :icon="iconRight"
    />
  </component>
</template>

<style lang="scss" scoped>
.suaw-link {
  color: var(--sem-color-info-medium);
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
  transition: color, .25s linear;

  &:hover {
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:visited {
    color: var(--sem-color-info-dark);
  }

  &--active {
    color: var(--sem-color-text-darkest);
    pointer-events: none;
  }

  &--dark {
    color: var(--sem-color-text-darkest);
  }

  &--left {
    text-align: left;
  }

  &--right {
    justify-content: flex-end;
    text-align: right;
  }

  &--center {
    justify-content: center;
    text-align: center;
  }

  &__icon {
    fill: var(--sem-color-info-medium);
    align-self: center;
    &--active {
      fill: var(--sem-color-text-darkest);
    }
  }
  .icon-right {
    margin-bottom: 2px;
  }

  &__text--bold {
    font-weight: 500;
  }

  &__text--small {
    font-size: var(--body-copy-font-size);
  }

  &__text--medium {
    font-size: var(--p-font-size);
  }

  &__text--large {
    font-size: var(--h-5-font-size);
  }

  &__text--fill {
    width: 100%;
  }
}
</style>
