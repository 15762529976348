<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "ShouldYouSelfPublishOrTraditionallyPublish",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="ShouldYouSelfPublishOrTraditionallyPublish">
      <SuawParagraph
        text="When you think about the word “publishing,” you probably picture a bespectacled editor, red pen in hand, poring over your manuscript from behind their mahogany desk. This scene belongs in the realm of traditional publishing, a world that authors typically cannot access without first connecting with industry players (starting with literary agents) who can help deliver a manuscript into the hands of such an editor."
      />
      <SuawParagraph
        text="Traditional publishers favor authors who already have a large following, making it prohibitive to get your foot in the door unless your name is already out there. In contrast, another option is to self-publish, a path favored by those who value ease of access and a fine level of creative control. A final option is to go with a hybrid publisher, where the author pays the publishing company for publishing services."
      />
      <SuawParagraph
        text="As an author, you will have to decide between these very different paths when it comes to sharing your writing with the world. But fear not—we will help you figure out whether traditional publishing, self-publishing, or hybrid publishing is right for you."
      />
      <SuawHeading level="3" content="How Do Self-Publishing and Traditional Publishing Differ?" />
      <SuawParagraph text="There are a few key differences between traditional publishing and self-publishing." />
      <SuawParagraph
        text="In order to go the traditional publishing route, you’ll need to find a literary agent, whose job it is to take your manuscript to their contacts at publishing houses in hopes of finding it a long-term home. It can take months to find a literary agent, and then longer while you wait for them to introduce your manuscript to publishers. Once a publishing house shows interest, your agent helps you negotiate your contract, including your author advance, i.e. the upfront payment you receive from the publisher, as well as the percentage of profits you get to keep as royalties. A typical literary agent will take a standard 15% commission on your advance and royalties."
      />
      <SuawParagraph
        text="Once you and the publisher sign the contract, the publishing house takes care of the rest: layout, cover design, printing, distribution to physical stores, and promotional activities leading up to the date of your publication. As the author, you may want to use your social media platforms to add to the buzz about your book’s upcoming publication, as well as help to promote it over time. Then, sit back and wait for your royalties to come in as long as your book is able to sell."
      />
      <SuawParagraph
        text="In contrast, if you choose to self-publish, you don’t have to wait to find literary agents or for your manuscript to be introduced to publishers. Both are processes that typically take months to years to resolve, and this is time you can spend instead on the publication process itself."
      />
      <SuawParagraph
        text="With self-publishing, you have ultimate creative control over all your book’s assets, from cover design down to the font used for your page numbers. Some authors elect to go the DIY route, but many opt to hire professional book designers so they are assured of getting a book design they’re proud of. Once your book is designed, you can do your own marketing to promote your book, or hire marketing help as well."
      />
      <SuawParagraph
        text="One notable difference in self-publishing lies in how much you get to keep in royalties for your book. In traditional publishing, the royalty percentage will be on the lower end, in the range of 10% to 15% for hardcover books, and 8% to 10% for paperbacks. When self-publishing, on the other hand, you can keep 60% to 70% of your profits, because once you’ve recouped the costs of publishing your own book, the rest goes straight into your pocket."
      />
      <SuawHeading level="3" content="Hybrid Publishing" />
      <SuawParagraph
        text="Although the decision to publish is typically framed in terms of traditional vs. self-publishing, a third option is gaining popularity—hybrid publishing. In hybrid publishing, authors pay for the cost of publishing to a hybrid publisher, such as Inkshares or Iguana Books, and do not receive an advance on royalties. In this way, the author assumes the financial risk for getting their writing published, with the hope that the support offered by the hybrid publisher in design, marketing, and editorial services will pay off in book sales after publication."
      />
      <SuawParagraph
        text="The Independent Book Publishers Association released a list of standards in 2018 for hybrid publishing, which you can also review when choosing between hybrid publishing firms. As with any service purchased online, you’ll want to be discerning so you can separate the legitimate hybrid publishers from the disreputable ones."
      />
      <SuawHeading level="3" content="Questions To Ask Yourself" />
      <SuawParagraph text="When deciding which path to pursue, consider these questions:" />
      <ul>
        <li><SuawParagraph text="Am I willing to spend some time finding a literary agent, and subsequently, a publisher?" /></li>
        <li><SuawParagraph text="How much creative control do I want over my book and the publication process as a whole?" /></li>
        <li><SuawParagraph text="What kind of budget do I have for hiring freelance book designers, marketing professionals, or hybrid publishers?" /></li>
        <li><SuawParagraph text="Does mainstream literary credibility, usually achieved through traditional publishing, matter to me?" /></li>
        <li>
          <SuawParagraph
            text="Do I have a large platform (for example, on Twitter or YouTube) where I can market myself to my target audience, as would be necessary for self-publishing?"
          />
        </li>
        <li>
          <SuawParagraph
            text="Which of these is a better fit: Selling ebooks to a niche target audience I’ve already identified, or having a traditional publisher sell physical and electronic copies of my book by marketing it far and wide?"
          />
        </li>
      </ul>
      <SuawParagraph
        text="The path you choose will largely depend on your answers above, so take the time you need to consider these variables before moving forward with your decision."
      />
      <SuawParagraph text="Whichever option you choose, know what an accomplishment it is to have reached this phase of the writing process! Congratulations and good luck!" />
    </ArticleFrame>
  </SuawMainContent>
</template>
