<script>
import ResourceFrame from "../../components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";
export default {
  name: "ListingYourOnlineEvent",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Listing Your <b>Online Event</b></h1>
      <p class="suaw-paragraph">
        To help our organizers reach as many writers as possible, we list all events on both <a href='http://Meetup.com' target='_blank'>Meetup.com</a> and our own <a href='https://shutupwrite.com' target='_blank'>Shut Up & Write! website</a>. As the organizer of an online event, you'll start by creating a listing on the <a href='http://shutupwrite.com' target='_blank'>shutupwrite.com</a> website, and then duplicate it on Meetup.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">
        Shut Up & Write! Website
      </h2>
      <p class="suaw-paragraph">
        First, you'll list your event on the <a href='https://shutupwrite.com' target='_blank'>Shut Up & Write! website</a>. Your online event will show up in our <a href='https://www.shutupwrite.com/events/online' target='_blank'>Online Events directory</a>. Here's how to create a listing on <a href='http://shutupwrite.com' target='_blank'>shutupwrite.com</a>:
      </p>

      <ol class="suaw-organizer-resource-steps">
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Log into the <a href='https://shutupwrite.com' target='_blank'>Shut Up & Write! website</a>. Our team will promote your account to the Organizer access level so you can set up an event. If you haven't been promoted on our website, let us know.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">From your <a href='https://shutupwrite.com/dashboard' target='_blank'>Shut Up & Write Dashboard</a>, click the <b>Create Event Series</b> button in the upper right of your screen:</p>
          <img src="/assets/img/organizer/listing-online-event/create-event-series.png" alt="Screenshot showing Create Event Series button" class="suaw-organizer-resource-image" />
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Leave the event title as the default or create your own custom title.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Choose what day of the week you'll host your event.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Set a starting week.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Set your event's duration. Make sure to account for about a half an hour before and after your writing time.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Set your start time.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Change <b>Venue Type</b> to Online.</p>
          <img src="/assets/img/organizer/listing-online-event/venue-type.png" alt="Screenshot showing Venue Type selection" class="suaw-organizer-resource-image" />
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Set what city you'll be hosting from.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Add the Meeting URL of your event room.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Click <b>Create This Event Series.</b></p>
          <img src="/assets/img/organizer/listing-online-event/create-series.png" alt="Screenshot showing Create This Event Series button" class="suaw-organizer-resource-image" />
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">On the next page, confirm that all the info is correct, and then click the button to create your event series!</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph"><b>Make note of the URL of your event series page.</b> Bookmark it, if possible, since you will be returning to this page to maintain your event listing. You can click the blue &quot;Share&quot; link on the upper right of your event page to easily copy the URL.</p>
          <img src="/assets/img/organizer/listing-online-event/share-button.png" alt="Screenshot showing Share button" class="suaw-organizer-resource-image" />
        </li>
      </ol>

      <h2 class="suaw-organizer-resource-subtitle">
        Meetup
      </h2>
      <p class="suaw-paragraph">
        Shut Up & Write! funds a <a href='https://www.meetup.com/pro/shut-up-write/' target='_blank'>Meetup Pro</a> account for our entire network of organizers. That means the cost of your Meetup listing is on us! By listing events on the Meetup network, we help our organizers connect with writers all over the world.
      </p>

      <SuawHeading
        level="4"
        content="Chapters"
      />
      <p class="suaw-paragraph">
        Meetup organizes events into groups by location. At Shut Up & Write!, we call these &quot;chapters.&quot; Because you're hosting online, our team will assign your event to our <a href='https://www.meetup.com/shutupandwriteonlineevents/' target='_blank'>online events chapter</a>.
      </p>

      <SuawHeading
        level="4"
        content="Setup"
      />
      <ol class="suaw-organizer-resource-steps">
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Copy the URL of the <b>event link you set up on shutupwrite.com</b> by clicking the blue Share button at the top right of your event page.</p>
          <img src="/assets/img/organizer/listing-online-event/share-button-2.png" alt="Screenshot showing Share button" class="suaw-organizer-resource-image" />
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Open your Meetup.com event template. <b>A Community Team member will be sending a link to your event template via questions@shutupwrite.com.</b></p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Click the Edit button at the top of your screen.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Change the fields below:</p>
          <ol class="suaw-organizer-resource-substeps">
            <li class="suaw-organizer-resource-substeps__item">
              <p class="suaw-paragraph">Date</p>
            </li>
            <li class="suaw-organizer-resource-substeps__item">
              <p class="suaw-paragraph">Time</p>
            </li>
            <li class="suaw-organizer-resource-substeps__item">
              <p class="suaw-paragraph">Duration</p>
            </li>
          </ol>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Location: Paste the event link that you copied at the beginning of this section.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Check that all of your event details are correct, and then click the red Publish button at the bottom of your screen.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph"><b>Make note of the URL of your Meetup event page.</b> Bookmark it, if possible, since you will be returning to this page to maintain your event listing.</p>
        </li>
      </ol>

      <p class="suaw-paragraph">Congrats! Your writing event is now listed on Meetup.com.</p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-image {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
//steps
.suaw-organizer-resource-steps {
  list-style: decimal;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}
//substeps
.suaw-organizer-resource-substeps {
  list-style: lower-alpha;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}
</style>
