<script>
import Heading from "../Heading/Heading.vue";
import Link from "../Link/Link.vue";
import InputBox from "../InputBox/InputBox.vue";

export default {
  name: 'Newsletter',
  components: {
    Heading,
    Link,
    InputBox,
  },
  props: {
    headingText: {
      type: String,
      default: 'Resources, Cool Finds, and Community Updates\nSend Once a Month to Your Email'
    },
    copyText: {
      type: String,
      default: 'Subscribe now and Stay informed.'
    },
    linkText: {
      type: String,
    },
    linkUrl: {
      type: String,
    },
    inputBoxClickMethod: {
      type: Function,
      default: null,
    },
    inputBoxPlaceholder: {
      type: String,
    },
    inputBoxButtonText: {
      type: String,
    },
  },
};
</script>

<template>
  <div class="suaw-newsletter">
    <div class="suaw-newsletter__content">
      <Heading
        level="3"
        :content="headingText"
        alignment="center"
        class="suaw-newsletter__heading"
      />
      <p class="suaw-newsletter__text">
        {{ copyText }}
        <Link
          v-if="linkUrl || linkText"
          :to="linkUrl"
          weight="default"
          class="suaw-newsletter__link"
        >{{ linkText }}</Link>
      </p>
      <InputBox
        :click-method="inputBoxClickMethod"
        :placeholder="inputBoxPlaceholder"
        :button-text="inputBoxButtonText"
        @submit="$emit('submit', $event)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-newsletter {
  background-color: var(--sem-color-background-lightest);
  border-radius: var(--sem-radius-md);
  box-shadow: var(--date-square-shadow);
  gap: var(--sem-space-quadruple);
  min-height: 300px;
  padding: var(--sem-space-triple);
  width: 100%;

  &,
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &__content {
    gap: var(--sem-space-double);
  }

  &__heading {
    font-weight: var(--h-2-font-weight);
  }

  &__text {
    color: var(--sem-color-text-regular);
    font-size: var(--p-font-size);
    font-weight: var(--p-font-weight);
  }

  &__link {
    margin-left: var(--sem-space-half);
  }
}
</style>
