<script>
import ResourceFrame from "../../components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";
export default {
  name: "OnlineEventChecklist",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Online Event <b>Checklist</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Print this page or copy this list into a document so you can prepare for an event."
      />

      <h2 class="suaw-organizer-resource-subtitle">
        1 Week Before Your Event
      </h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph"><router-link :to="{ name: 'PromotingYourEvent' }">Promote your event!</router-link> Post on your social media accounts, and print out flyers to post locally.</p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        1 Day Before Your Event
      </h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Meetup Message the members on your RSVP list with any relevant info about your upcoming event.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Check your event listing on <a href='http://shutupwrite.com' target='_blank'>shutupwrite.com</a> and make sure your room link is correct.</p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        The Day of Your Event
      </h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Log into your virtual room 5 minutes before your event's start time</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Turn on security settings (if using Zoom, this is accessible via the Security Button at the bottom of the screen)</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Turn off: &quot;Allow participants to share screen&quot;</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Turn off: &quot;Allow participants to rename themselves&quot;</p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        During Your Event
      </h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">As people arrive, let them in from the waiting room and welcome them to your Shut Up & Write! event!</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Introduce yourself and get to know your writers: where are they located, how is their day going?</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Officially start the event by sharing what you'll be working on. Keep this part brief! This helps keep the event moving forward.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Call on people by name to avoid any confusion about who goes next.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Once everyone has stated what they're writing, let the group know you're about to set the timer and that it's time to <b>Shut Up & Write!</b></p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Remember to set your timer!</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph"><b>Shut Up & Write!</b> ⏱️</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">After the timer goes off, ensure people can unmute themselves (if using Zoom, check the Security button.) Unmute yourself and welcome everyone back. Tip: Try welcoming your group back softly because an enthusiastic &quot;welcome back&quot; can make some writers jump!</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Lead a round of check-ins with your writers: How did the session go?</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Feel free to linger as long as you like to connect with writers and foster additional conversations. This is the fun part of community building!</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <input type="checkbox" />
            <p class="suaw-paragraph">Thank the group for showing up, invite them to come back again, and paste the link to your next event in the chat.</p>
          </li>
        </ul>
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
//guide
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      cursor: pointer;
      flex-shrink: 0;
      margin: 3px 0 0 0;
    }
  }
}
</style>
