<script>
import Label from "../Label/Label.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
export default {
  name: "DescriptionSection",
  components: { Label, Paragraph },
  props: {
    hasLabel: {
      type: Boolean,
      default: true,
    },
    labelText: {
      type: String,
      default: "Label",
    },
    descriptionObject: {
      type: Object,
      default: () => ({
        type: "doc",
        content: [{ type: "paragraph" }],
      }),
    },
    descriptionString: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasDescriptionObjectContent() {
      // Check if descriptionObject has content and it contains non-empty text
      return (
        this.descriptionObject?.content?.length &&
        (this.descriptionObject.content[0].type !== "paragraph" ||
          this.descriptionObject.content[0].content?.[0]?.text)
      );
    },
  },
};
</script>

<template>
  <div class="suaw-description-section">
    <Label v-if="hasLabel" size="large" weight="bold" :label-text="labelText" />
    <Paragraph v-if="hasDescriptionObjectContent" :text="descriptionObject" />
    <p v-if="descriptionString" class="suaw-description-section__paragraph">
      {{ descriptionString }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.suaw-description-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  &__paragraph {
    color: var(--sem-color-text-medium);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
}
</style>
