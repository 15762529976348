<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawInputGroup, SuawSelect, SuawEmptyState } from "@/components";
import { Timezones } from "@/features/series/operations.gql";
import { GetEventScheduleById } from "../../operations.gql";
import { SeriesScheduleFromICalText, ParseDurationMinutesFromDuration } from "../../../series/common.js";
import EventEditFormButtons from "../components/EventEditFormButtons.vue";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as EventApi from "../../api.js";
import { zonedTimeToUtc } from "date-fns-tz";
import { formatTimeFromISO } from "../../../../utils/formatting/dates.js";

export default {
  name: "EventEditSchedule",
  components: { EventEditFormButtons, SuawForm, SuawHeading, SuawTextInput, SuawInputGroup, SuawSelect, SuawEmptyState },
  props: {
    eventId: {
      type: String,
      required: true
    },
    isCancelled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      timezoneOptions: [],
      editEventScheduleForm: {
        startDate: null,
        startTime: null,
        durationMinutes: null,
        timeZoneId: null
      },
      resultGetEventScheduleById: {
        id: "",
        starts_at: "",
        scheduled_starts_at: "",
        duration: "",
        event_series: {
          id: "",
          ical_text: ""
        },
        place: {
          id: "",
          timezone: ""
        }
      }
    };
  },
  apollo: {
    timezoneOptions: {
      query: Timezones,
      update(data) {
        if (data) {
          return data.timezones.value;
        }
        return null;
      }
    },
    resultGetEventScheduleById: {
      query: GetEventScheduleById,
      variables() {
        return {
          id: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingQueriesCount",
      result({ data }) {
        this.resultGetEventScheduleById = data.resultGetEventScheduleById;
        this.editEventScheduleForm.startDate = this.startDate;
        this.editEventScheduleForm.startTime = this.startTime;
        this.editEventScheduleForm.durationMinutes = this.durationMinutes;
        this.editEventScheduleForm.timeZoneId = this.timezone;
      }
    }
  },
  validations: {
    editEventScheduleForm: {
      startDate: { required },
      startTime: { required },
      durationMinutes: { required },
      timeZoneId: { required }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEventSchedule() {
      return this.resultGetEventScheduleById.id !== "";
    },
    event() {
      return this.hasEventSchedule ? this.resultGetEventScheduleById : null;
    },
    iCalText() {
      return this.hasEventSchedule ? this.event.event_series.ical_text : null;
    },
    parsedICalText() {
      return this.hasEventSchedule ? SeriesScheduleFromICalText(this.iCalText, this.duration) : null;
    },
    timezone() {
      return this.hasEventSchedule ? this.parsedICalText.timeZoneId : null;
    },
    weeklyFrequency() {
      return this.hasEventSchedule ? this.parsedICalText.weeklyFrequency : null;
    },
    startDate() {
      return this.hasEventSchedule ? this.event.starts_at.split("T")[0] : null;
    },
    scheduledStartDate() {
      return this.hasEventSchedule ? this.event.scheduled_starts_at.split("T")[0] : null;
    },
    minDate() {
      if (!this.scheduledStartDate || !this.weeklyFrequency) return null;
      const daysBefore = this.weeklyFrequency * 7 - 1;
      const date = new Date(this.scheduledStartDate);
      date.setDate(date.getDate() - daysBefore);
      return date.toISOString().split("T")[0];
    },
    maxDate() {
      if (!this.scheduledStartDate || !this.weeklyFrequency) return null;
      const daysAfter = this.weeklyFrequency * 7 - 1;
      const date = new Date(this.scheduledStartDate);
      date.setDate(date.getDate() + daysAfter);
      return date.toISOString().split("T")[0];
    },
    duration() {
      return this.hasEventSchedule ? this.event.duration : null;
    },
    durationMinutes() {
      return this.hasEventSchedule ? ParseDurationMinutesFromDuration(this.duration) : null;
    },
    startTime() {
      return this.hasEventSchedule ? formatTimeFromISO(this.event.starts_at) : null;
    },
    durationOptions() {
      return [
        { text: "1.5 Hours", value: 90 },
        { text: "2 Hours", value: 120 },
        { text: "2.5 Hours", value: 150 },
        { text: "3 Hours", value: 180 },
        { text: "3.5 Hours", value: 210 }
      ];
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    onCancelClicked() {
      this.$emit("cancel");
    },
    onBackClicked() {
      this.$emit("back");
    },
    onUncancelClicked() {
      this.$emit("uncancel");
    },
    calculateStartsAt(startDate, startTime, timezone) {
      const dateTime = `${startDate}T${startTime}:00`;
      const utcDate = zonedTimeToUtc(dateTime, timezone);
      return utcDate.toISOString();
    },
    async updateEventOccurrenceSchedule() {
      //not grabbing timezoneId from form because it's disabled it's only displayed for the user's benefit of knowing the tz of the time they're entering
      const { startDate, startTime, durationMinutes } = this.editEventScheduleForm;
      const parsedDurationMinutes = parseInt(durationMinutes);
      const startsAt = this.calculateStartsAt(startDate, startTime, this.timezone);
      const result = await EventApi.updateEventOccurrenceSchedule(this.eventId, startsAt, parsedDurationMinutes);
      return result;
    },
    async onConfirmClicked() {
      this.$v.editEventScheduleForm.$touch();
      if (this.$v.editEventScheduleForm.$invalid) {
        this.$root.$emit("universal-error-message", "The form is invalid.");
      } else {
        const updateResult = await this.updateEventOccurrenceSchedule();
        if (!updateResult.success) {
          this.$root.$emit("universal-error-message", updateResult.error);
          return;
        }
        this.$root.$emit("universal-success-message", "You have successfully updated your event schedule.");
        this.$emit("updated");
      }
    }
  }
};
</script>

<template>
  <SuawForm v-if="hasEventSchedule && !isLoading">
    <template #form>
      <SuawHeading level="4" content="Date & Time" />
      <SuawTextInput
        id="eventDate"
        v-model="editEventScheduleForm.startDate"
        type="date"
        :min="minDate"
        :max="maxDate"
        label="Date"
        placeholder="Enter Date"
        :state="formFieldState($v, 'editEventScheduleForm', 'startDate')"
        :error-message="validateErrors($v.editEventScheduleForm.startDate, 'Start date')"
        @blur="$v.editEventScheduleForm.startDate.$touch()"
      />
      <SuawInputGroup>
        <SuawTextInput
          id="eventTime"
          v-model="editEventScheduleForm.startTime"
          type="time"
          label="Start Time"
          placeholder="Start Time"
          :state="formFieldState($v, 'editEventScheduleForm', 'startTime')"
          :error-message="validateErrors($v.editEventScheduleForm.startTime, 'Start time')"
          @blur="$v.editEventScheduleForm.startTime.$touch()"
        />
        <SuawSelect
          v-if="timezoneOptions.length > 0"
          id="timezone"
          v-model="editEventScheduleForm.timeZoneId"
          placeholder="Timezone"
          :options="timezoneOptions"
          is-required
          state="disabled"
        />
      </SuawInputGroup>
      <SuawSelect id="duration" v-model="editEventScheduleForm.durationMinutes" label="Duration" placeholder="Duration" :options="durationOptions" />
      <EventEditFormButtons :is-cancelled="isCancelled" @cancel="onCancelClicked" @back="onBackClicked" @confirm="onConfirmClicked" @uncancel="onUncancelClicked" />
    </template>
  </SuawForm>
  <SuawEmptyState v-else-if="isLoading" message="Loading Your Event Schedule..." />
  <SuawEmptyState v-else message="No Event Found" />
</template>
