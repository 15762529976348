<script>
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";
import Chip from "../Chip/Chip.vue";
export default {
  name: "ChapterSummary",
  components: { Heading, Button, Chip },
  props: {
    title: {
      type: String,
      default: "Unknown Chapter Title",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    isJoined: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
    },
    memberCount: {
      type: Number,
      default: 0,
    },
    eventCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    chapterMembers() {
      return `${this.memberCount} Member${this.memberCount !== 1 ? "s" : ""}`;
    },
    chapterEvents() {
      return `${this.eventCount} Event${this.eventCount !== 1 ? "s" : ""}`;
    },
  },
};
</script>

<template>
  <div class="suaw-chapter-summary">
    <div class="suaw-chapter-summary__heading-container">
      <Heading
        class="suaw-chapter-summary__heading"
        level="2"
        :content="title"
      />
      <div v-if="isAdmin" class="suaw-chapter-summary__admin-button-container">
        <Button
          buttonText="Edit Chapter"
          class="suaw-chapter-summary__action-button"
          size="small"
          type="critical-light"
          iconLeft="IconRepeat"
          @click="$emit('edit-chapter-click')"
        />
      </div>
    </div>
    <div class="suaw-chapter-summary__action-container">
      <div v-if="isOrganizer" class="suaw-chapter-summary__button-container">
        <Button
          buttonText="Create Event Series"
          class="suaw-chapter-summary__action-button"
          size="large"
          type="primary-light"
          iconLeft="IconCalendarPlus"
          @click="$emit('create-series-click')"
        />
      </div>
      <div class="suaw-chapter-summary__button-container">
        <Button
          :key="isJoined"
          :buttonText="isJoined ? 'Joined' : 'Join Chapter'"
          class="suaw-chapter-summary__action-button"
          size="large"
          :type="isJoined ? 'success-light' : 'success'"
          :iconLeft="isJoined ? 'IconDownloadDone' : 'IconDownload'"
          @click="$emit('follow-click')"
        />
      </div>
    </div>
    <div class="suaw-chapter-summary__info-container">
      <Chip
        size="medium"
        status="default"
        icon="IconLocation"
        :label="location"
        chipType="icon"
      />
      <Chip
        size="medium"
        status="default"
        icon="IconCalendarCheck"
        :label="chapterEvents"
        chipType="icon"
      />
      <Chip
        size="medium"
        status="info"
        icon="IconUser"
        :label="chapterMembers"
        chipType="icon"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-summary {
  max-width: 1264px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 24px;
  row-gap: 8px;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  &__heading-container {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__heading {
    display: flex;
    justify-self: center;
    margin: 0;
    padding: 0;
  }
  &__admin-button-container {
    display: flex;
  }
  &__action-container {
    display: inline-flex;
    justify-content: end;
    gap: 8px;
    @media (max-width: 1023px) {
      grid-column: 2/3;
      grid-row: 1/3;
      flex-direction: column-reverse;
      justify-content: start;
      align-items: end;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }
  &__button-container {
    @media (max-width: 768px) {
      justify-self: end;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__action-button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__info-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
    @media (max-width: 768px) {
      grid-column: 1/2;
      grid-row: 2/3;
      align-items: center;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: 2/3;
    }
  }
}
</style>
