<script>
import Label from "../Label/Label.vue";

export default {
  name: 'Checkbox',
  components: {
    Label,
  },
  props: {
    id: {
      type: String,
      default: "check",
    },
    label: {
      type: String,
    },
    labelPosition: {
      type: String,
      default: "left",
      validator: function (value) {
        return ["left", "right"].indexOf(value) !== -1;
      },
    },
    state: {
      type: String,
      validator: function (value) {
        return [
          "valid",
          "invalid",
          "disabled",
          "default"
        ].indexOf(value) !== -1;
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    successMessage: {
      type: String,
      default: "This is valid",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["small", "medium"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.checked);
    },
  },
  computed: {
    checkboxClasses() {
      return {
        "suaw-checkbox--disabled": this.state === 'disabled',
        'suaw-checkbox--valid': this.state === 'valid',
        'suaw-checkbox--invalid': this.state === 'invalid',
        'suaw-checkbox--required': this.isRequired,
      };
    },
    setMessages() {
      if (this.state === "invalid") return this.errorMessage;
      return "";
    },
    messageClasses() {
      return {
        'suaw-checkbox__message--invalid': this.state === 'invalid',
        'suaw-checkbox__message--valid': this.state === 'valid',
      };
    }
  },
};
</script>

<template>
  <div 
    class="suaw-checkbox"
    :class="checkboxClasses"
  >
    <div 
      class="suaw-checkbox__holder"
      :class="[size === 'small' ? 'small-size' : 'medium-size']"
    >
      <Label
        v-if="label && labelPosition === 'left'"
        :labelFor="id"
        :labelText="label"
        :size="size"
        class="suaw-checkbox__label"
      />
      <input
        type="checkbox"
        class="suaw-checkbox__control"
        :checked="value"
        @change="updateValue"
        :disabled="disabled"
        :id="id"
        :class="[size === 'small' ? 'small-size' : 'medium-size']"
      />
      <Label
        v-if="label && labelPosition === 'right'"
        :labelFor="id"
        :labelText="label"
        :size="size"
        class="suaw-checkbox__label"
      />
    </div>
    <small
      v-if="isRequired"
      class="suaw-checkbox__message"
      :class="messageClasses"
    >
      {{ setMessages }}
    </small>
  </div>
</template>

<style scoped lang="scss">
.suaw-checkbox {
  flex-direction: column;
  justify-content: flex-start;

  &,
  &__holder {
    display: flex;
    align-items: center;
    position: relative;
    gap: var(--sem-space-base);
  }

  &--disabled * {
    cursor: not-allowed;
  }

  // Default size: medium
  .medium-size {
    .suaw-checkbox__control {
      width: 24px;
      height: 24px;
      border: 2px solid var(--sem-color-neutral-dark);
      border-radius: 3px;
      padding: 4px;
    }

    .suaw-checkbox__label {
      font-size: 16px;
    }
  }

  // Smaller size
  .small-size {
    .suaw-checkbox__control {
      width: 16px;
      height: 16px;
      border: 1px solid var(--sem-color-neutral-dark);
      border-radius: 2px;
      padding: 2px;
    }

    .suaw-checkbox__label {
      font-size: 12px;
    }
  }

  &__control:disabled + .suaw-checkbox__label {
    color: var(--sem-color-inactive-medium);
  }

  &__message--invalid {
    color: var(--sem-color-critical-medium);
  }

  &__message--valid {
    color: var(--sem-color-success-medium);
  }
}
</style>
