<script>
import { SuawModal } from "@/components";
import SeriesSchedule from "../components/SeriesSchedule.vue";
import SeriesDescription from "../components/SeriesDescription.vue";
import SeriesLocation from "../components/SeriesLocation.vue";
import { GetEventSeriesForEditById, GetChapterTimezoneSlugAndOrganizersById } from "../../operations.gql";
import { SeriesScheduleFromICalText, UserChip } from "../../common.js";
import { bottomOfTheHour } from "@/utils/formatting/dates.js";
import * as SeriesApi from "../../api.js";

export default {
  name: "SeriesEdit",
  components: { SeriesSchedule, SeriesLocation, SeriesDescription, SuawModal },
  data() {
    return {
      currentStep: this.$route.query.step,
      chapter: null,
      seriesId: this.$route.params.seriesId,
      series: null,
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null
    };
  },
  watch: {
    "$route.query.step"(newStep) {
      this.currentStep = newStep;
    }
  },
  apollo: {
    chapter: {
      query: GetChapterTimezoneSlugAndOrganizersById,
      variables() {
        return {
          id: this.$route.params.chapterId
        };
      },
      update(data) {
        if (data.result) {
          const chapterData = data.result;
          const organizers = data.result.chapter_members.map(org => {
            return UserChip(org.user_view);
          });
          return {
            id: chapterData.id,
            timezone: chapterData.place ? chapterData.place.timezone : null,
            organizers,
            slug: chapterData.slug
          };
        }
        return null;
      }
    },
    // GetEventSeriesById is called when this.seriesId is truthy
    series: {
      query: GetEventSeriesForEditById,
      variables() {
        return {
          id: this.seriesId,
          endsAfter: bottomOfTheHour(new Date()).toISOString()
        };
      },
      fetchPolicy: "no-cache",
      update(data) {
        // You should be able to use rrule.js (which is already in the project) to load at least the schedule part(s) from the iCal text
        // The alternative is a new Query on the backend that parses it and returns the fields you need how you need. That might be best so that it's the same library going to and from iCal and not two separate libraries in two languages doing it
        if (data.result) {
          const schedule = SeriesScheduleFromICalText(data.result.ical_text, data.result.duration);
          const seriesData = data.result;
          const organizers = seriesData.organizers.map(org => {
            return UserChip(org.user_view);
          });
          const formattedSeriesData = {
            ...seriesData,
            schedule,
            organizers
          };
          return formattedSeriesData;
        }
        return null;
      }
    }
  },
  methods: {
    handleNextStep(payload) {
      const { step } = payload;
      this.$router.push({
        name: "SeriesEdit",
        params: {
          chapterId: this.series.chapter.id,
          seriesId: this.seriesId
        },
        query: {
          step: step
        }
      });
      this.currentStep = step;
    },
    onCancelClicked() {
      this.showModal = true;
      this.modalTitle = "Are you sure you want to cancel your series? This cannot be undone.";
      this.modalConfirmAction = this.CancelEventSeries;
    },
    onBackClicked() {
      this.showModal = true;
      this.modalTitle = "Any unsaved changes to your series will be lost.";
      this.modalConfirmAction = this.backToSeriesPage;
    },
    onExitModal() {
      this.showModal = false;
      this.modalTitle = "";
      this.modalConfirmAction = null;
    },
    backToSeriesPage() {
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: this.series.chapter.slug,
          eventId: this.series.upcoming_event[0].id,
          seriesSlug: this.series.slug
        }
      });
    },
    async CancelEventSeries() {
      const result = await SeriesApi.cancelEventSeries(this.series.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      this.$router.push({ name: "DashboardEvents" });
      this.$root.$emit("universal-success-message", "Your series has been cancelled successfully!");
    }
  }
};
</script>

<template>
  <div>
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onExitModal" />
    <SeriesSchedule
      v-if="currentStep === 'schedule' && series && chapter"
      :chapter="chapter"
      :series="series"
      @next-step="handleNextStep"
      @cancel="onCancelClicked"
      @back="onBackClicked"
    />
    <SeriesLocation
      v-if="currentStep === 'location' && series && chapter"
      :series="series"
      :chapter="chapter"
      @next-step="handleNextStep"
      @cancel="onCancelClicked"
      @back="onBackClicked"
    />
    <SeriesDescription
      v-if="currentStep === 'description' && series && chapter"
      :series="series"
      :chapter="chapter"
      @next-step="handleNextStep"
      @cancel="onCancelClicked"
      @back="onBackClicked"
    />
  </div>
</template>
