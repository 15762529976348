<script>
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import LocationBoxInPerson from "./LocationBoxInPerson.vue";
import LocationBoxOnline from "./LocationBoxOnline.vue";

export default {
  name: 'LocationBox',
  components: {
    Heading,
    Paragraph,
    LocationBoxInPerson,
    LocationBoxOnline,
  },
  props: {
    headingText: String,
    description: String,
    locationBoxType: {
      type: String,
      validator: function (value) {
        return [
          "LocationBoxInPerson",
          "LocationBoxOnline"
        ].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    locationBoxComponentProps() {
      return { ...this.$attrs };
    },
  }
}
</script>

<template>
  <div class="suaw-location-box">
    <header class="suaw-location-box__header">
      <Heading
        level="5"
        :content="headingText"
      />
      <Paragraph
        v-if="description"
        class="suaw-location-box__description"
        weight="normal"
        size="medium"
        :text="description"
      />
    </header>
    <component
      :is="locationBoxType"
      v-bind="locationBoxComponentProps"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-location-box {
  background-color: var(--sem-color-background-lighten);
  border-radius: var(--sem-radius-sm);
  gap: var(--sem-space-double);
  overflow: hidden;
  padding: var(--sem-space-quadruple);

  &,
  &__header {
    display: flex;
    flex-direction: column;
  }
}
</style>
