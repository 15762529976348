<script>
import Modal from "../Modal/Modal.vue";
import Button from "../Button/Button.vue";
import Logo from "../Logo/Logo.vue";
import Heading from "../Heading/Heading.vue";

export default {
  name: "CheckInModal",
  components: {
    Modal,
    Button,
    Logo,
    Heading,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    toggleModalClasses: {
      type: Object,
      required: true,
    },
    isPrinting: {
      type: Boolean,
      required: true,
    },
    toggleModalVisibility: {
      type: Function,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    eventTitle: {
      type: String,
      required: true,
    },
    printContent: {
      type: Function,
      required: true,
    },
  },
  methods: {},
};
</script>

<template>
  <Modal
    v-if="showModal"
    :class="['printable-content', toggleModalClasses]"
    @click="toggleModalVisibility"
  >
    <Button
      v-if="!isPrinting"
      size="large"
      type="secondary-ghost"
      iconLeft="IconCloseBig"
      class="suaw-modal__close"
      @click="toggleModalVisibility"
    />
    <Logo
      type="LogoDefault"
      class="suaw-modal__logo"
    />
    <Heading
      level="1"
      class="suaw-checkin__title"
      :content="eventTitle"
    />
    <img
      class="suaw-modal__qr-code"
      :src="code"
    >
    <Button
      v-if="!isPrinting"
      size="large"
      type="secondary-outline"
      iconLeft="IconPrint"
      buttonText="Print"
      @click="printContent"
    />
  </Modal>
</template>
