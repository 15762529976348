var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'suaw-select',
    _vm.selectClasses,
    _vm.className
  ]},[(_vm.setLabels)?_c('Label',{attrs:{"labelFor":_vm.id,"label-text":_vm.setLabels,"weight":_vm.labelWeight,"size":"small"}}):_vm._e(),_c('select',{staticClass:"suaw-select__field",attrs:{"required":_vm.isRequired,"id":_vm.id,"disabled":_vm.state === 'disabled'},domProps:{"value":_vm.value},on:{"change":_vm.handleChange}},[(_vm.placeholder)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('option',{key:option.key ? option.key : index,domProps:{"value":option.value ? option.value : 'value-' + index}},[_vm._v(" "+_vm._s(option.text || 'Unnamed Option')+" ")])})],2),(_vm.isRequired)?_c('small',{class:[
      'suaw-select__message',
      _vm.messageClasses
    ]},[_vm._v(" "+_vm._s(_vm.setMessages)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }