<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawTextArea, SuawEmptyState } from "@/components";
import { GetEventDescriptionById } from "../../operations.gql";
import EventEditFormButtons from "./EventEditFormButtons.vue";
import { required } from "vuelidate/lib/validators";
import { validateErrors } from "../../../../utils/api/validationErrors.js";
import * as EventApi from "../../api.js";

export default {
  name: "EventEditDescription",
  components: { EventEditFormButtons, SuawForm, SuawHeading, SuawTextInput, SuawTextArea, SuawEmptyState },
  props: {
    eventId: {
      type: String,
      required: true
    },
    isCancelled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      editEventDescriptionForm: {
        title: null,
        descriptionJson: null,
        rsvpLimit: null
      },
      resultGetEventDescriptionById: {
        id: "",
        title: "",
        description_json: null,
        rsvp_limit: null
      }
    };
  },
  apollo: {
    resultGetEventDescriptionById: {
      query: GetEventDescriptionById,
      variables() {
        return {
          id: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingQueriesCount",
      result({ data }) {
        this.resultGetEventDescriptionById = data.resultGetEventDescriptionById;
        this.editEventDescriptionForm.title = this.title;
        this.editEventDescriptionForm.descriptionJson = this.descriptionJson;
        this.editEventDescriptionForm.rsvpLimit = this.rsvpLimit;
      }
    }
  },
  validations: {
    editEventDescriptionForm: {
      descriptionJson: { required }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEventDescription() {
      return this.resultGetEventDescriptionById.id !== "";
    },
    event() {
      return this.hasEventDescription ? this.resultGetEventDescriptionById : null;
    },
    title() {
      return this.hasEventDescription ? this.event.title : null;
    },
    descriptionJson() {
      return this.hasEventDescription ? this.event.description_json : null;
    },
    rsvpLimit() {
      return this.hasEventDescription ? this.event.rsvp_limit : null;
    }
  },
  methods: {
    validateErrors,
    onCancelClicked() {
      this.$emit("cancel");
    },
    onBackClicked() {
      this.$emit("back");
    },
    onUncancelClicked() {
      this.$emit("uncancel");
    },
    async updateEventOccurrenceDescription() {
      const { title, descriptionJson, rsvpLimit } = this.editEventDescriptionForm;
      const intRsvpLimit = parseInt(rsvpLimit);
      const result = await EventApi.updateEventOccurrenceDescription(this.eventId, title, descriptionJson, intRsvpLimit);
      return result;
    },
    async onConfirmClicked() {
      this.$v.editEventDescriptionForm.$touch();
      if (this.$v.editEventDescriptionForm.$invalid) {
        this.$root.$emit("universal-error-message", "The form is invalid.");
      } else {
        const updateResult = await this.updateEventOccurrenceDescription();
        if (!updateResult.success) {
          this.$root.$emit("universal-error-message", updateResult.error);
          return;
        }
        this.$root.$emit("universal-success-message", "You have successfully updated your event details.");
        this.$emit("updated");
      }
    }
  }
};
</script>

<template>
  <SuawForm v-if="hasEventDescription && !isLoading">
    <template #form>
      <SuawHeading level="4" content="Details" />
      <SuawTextArea
        id="eventDesc"
        v-model="editEventDescriptionForm.descriptionJson"
        :use-tip-tap="true"
        label="Description"
        placeholder="Enter Event Details (Minimum 90 Characters)"
      />
      <SuawTextInput id="eventRSVP" v-model="editEventDescriptionForm.rsvpLimit" type="number" :min="5" label="RSVP Limit (Optional)" placeholder="No RSVP Limit" />
      <EventEditFormButtons :is-cancelled="isCancelled" @cancel="onCancelClicked" @back="onBackClicked" @confirm="onConfirmClicked" @uncancel="onUncancelClicked" />
    </template>
  </SuawForm>
  <SuawEmptyState v-else-if="isLoading" message="Loading Your Event Details..." />
  <SuawEmptyState v-else message="No Event Found" />
</template>
