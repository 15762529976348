<script>
export default {
  name: "IconUndo",
}
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 2C5.55228 2 6 2.44772 6 3V5.29204C6.92143 4.46778 8.00832 3.83731 9.19679 3.44771C11.0259 2.84809 12.9989 2.85103 14.8262 3.45536C16.6535 4.05968 18.2394 5.2337 19.3503 6.80567C20.4612 8.3775 21.039 10.264 20.9986 12.1884C20.9583 14.1129 20.3018 15.9737 19.1262 17.4976C17.9504 19.0215 16.3176 20.1281 14.4664 20.6554C12.6153 21.1827 10.6435 21.1028 8.84116 20.4271C7.03887 19.7515 5.50065 18.5158 4.45235 16.9016C4.15155 16.4384 4.28319 15.8191 4.74638 15.5183C5.20956 15.2175 5.82889 15.3491 6.12969 15.8123C6.94504 17.0678 8.14142 18.0289 9.54321 18.5544C10.945 19.0799 12.4787 19.1421 13.9185 18.732C15.3583 18.3218 16.6282 17.4612 17.5426 16.2759C18.4572 15.0905 18.9677 13.6432 18.9991 12.1465C19.0304 10.6498 18.5811 9.18253 17.717 7.95996C16.8531 6.73752 15.6197 5.82432 14.1982 5.35421C12.7768 4.88411 11.2423 4.88189 9.81981 5.3482C8.7936 5.6846 7.86478 6.2519 7.10132 7H10C10.5523 7 11 7.44772 11 8C11 8.55228 10.5523 9 10 9H5C4.44772 9 4 8.55228 4 8V3C4 2.44772 4.44772 2 5 2Z"/>
</svg>
</template>
