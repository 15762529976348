<script>
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';

export default {
  name: 'Paragraph',
  props: {
    text: {
      type: [String, Object],
      default: "",
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["large", "medium", "small", "extra-small"].indexOf(value) !== -1;
      },
    },
    weight: {
      type: String,
      default: "normal",
      validator: function (value) {
        return ["normal", "bold", "bolder"].indexOf(value) !== -1;
      },
    },
    alignment: {
      type: String,
      default: "left",
      validator: function (value) {
        return ["left", "center", "right"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    processedText() {
      if (typeof this.text === "object") {
        return this.generateHtmlFromJson(this.text);
      }
      return this.text;
    },
  },
  methods: {
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    generateHtmlFromJson(json) {
      const tempEditor = new Editor({
        extensions: [
          StarterKit.configure({
            image: false // Disable StarterKit's image handling
          }),
          Underline,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          Image.configure({
            inline: true,
            allowBase64: true
          })
        ],
        content: json,
      });
      // Convert empty paragraphs to <br /> for consistent spacing
      const html = tempEditor.getHTML().replace(/<p><\/p>/g, "<br />");
      tempEditor.destroy();
      return html;
    },
  },
}
</script>

<template>
  <p 
    v-if="!$slots.default"
    class="suaw-paragraph"
    :class="[
      'suaw-paragraph--' + size,
      'suaw-paragraph--' + weight,
      'suaw-paragraph--' + alignment,
      className
    ]"
    v-html="processedText"
  ></p>
  <p
    v-else
    class="suaw-paragraph"
    :class="[
      'suaw-paragraph--' + size,
      'suaw-paragraph--' + weight,
      'suaw-paragraph--' + alignment,
      className
    ]"
  >
    <slot></slot>
  </p>
</template>

<style lang="scss" scoped>
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);

  &--extra-small {
    font-size: var(--body-small-font-size);
  }

  &--small {
    font-size: var(--body-copy-font-size);
  }

  &--medium {
    font-size: var(--p-font-size);
  }

  &--large {
    font-size: var(--h-5-font-size);
  }

  &--normal {
    font-weight: var(--p-font-weight);
  }

  &--bold {
    font-weight: 500;
  }

  &--bolder {
    font-weight: 700;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }

  &--tab-space-bottom {
    margin-bottom: calc(var(--sem-space-double) - var(--sem-space-quadruple));
  }

  &--tab-space-top {
    margin-top: calc(var(--sem-space-double) - var(--sem-space-quadruple));
  }

  :deep(img) {
    max-width: 100%;
    height: auto;
  }
}
</style>
