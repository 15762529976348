<script>
import { mixin as clickaway } from "vue-clickaway";
import Button from "../Button/Button.vue";
import CalendarCard from "../Card/CalendarCard.vue";

export default {
  name: "CalendarDropdown",
  components: {
    Button,
    CalendarCard,
  },
  mixins: [clickaway],
  props: {
    label: {
      type: String,
    },
    buttonType: {
      type: String,
      default: "primary-light",
    },
    dropdownButtonSize: {
      type: String,
      default: "small",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "",
    },
    eventDates: {
      type: Array,
      required: true,
    },
    selectedDate: {
      type: String,
      default: null,
    },
    iconDropdown: {
      type: String,
      default: "IconChevronDown",
    },
    direction: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ["left", "right"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      showList: false,
    };
  },
  computed: {
    toggleClassName() {
      return {
        'is-visible': this.showList,
      };
    },
    directionClasses() {
      return {
        'suaw-dropdown__box--left': this.direction == 'left',
        'suaw-dropdown__box--right': this.direction == 'right',
      };
    },
  },
  methods: {
    away() {
      this.showList = false;
    },
    toggleListVisibility() {
      this.showList = !this.showList;
    },
    handleDateSelected(date) {
      this.$emit("date-selected", date); // Emit to parent
      this.away();
    },
    handleClearSelection() {
      this.$emit("clear-selected");
    },
  },
  directives: {
    clickaway: clickaway.directive,
  },
};
</script>

<template>
  <div
    class="suaw-dropdown"
    :class="[toggleClassName, className]"
    v-on-clickaway="away"
  >
    <Button
      :icon-right="iconDropdown"
      :size="dropdownButtonSize"
      :buttonText="label"
      :type="buttonType"
      class="suaw-dropdown__button"
      @click="toggleListVisibility"
    />
    <div
      class="suaw-dropdown__box"
      :class="directionClasses"
    >
      <CalendarCard
        :event-dates="eventDates"
        :show-footer="showFooter"
        :selected-date="selectedDate"
        @date-selected="handleDateSelected"
        @clear-selected="handleClearSelection"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-dropdown {
  display: inline-block;
  position: relative;

  &__box {
    background: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    box-shadow: var(--soft-shadow-draft);
    // padding: var(--sem-space-double);
    position: absolute;
    width: 320px;

    opacity: 0;
    overflow: hidden;
    transition: opacity .25s linear, visibility .25s linear;
    visibility: hidden;
    z-index: 97;
    width: 100%;

    .suaw-dropdown.is-visible & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__box--left {
    width: 320px;

    left: 0;
  }

  &__box--right {
    right: 0;
  }
}
</style>
