var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.isExternal ? 'a' : 'router-link',_vm._b({tag:"component",class:[
    'suaw-link',
    { 'suaw-link--active': _vm.isActive },
    'suaw-link--' + _vm.color,
    'suaw-link--' + _vm.alignment,
    _vm.className
  ],attrs:{"to":_vm.computedTo,"target":_vm.computedTarget,"href":_vm.isExternal ? _vm.to : null,"disabled":_vm.isActive}},'component',_vm.$attrs,false),[(_vm.icon || _vm.iconActive)?_c('Icon',{staticClass:"suaw-link__icon",class:{ 'suaw-link__icon--active': _vm.isActive },attrs:{"icon":_vm.isActive ? _vm.iconActive : _vm.icon}}):_vm._e(),_c('span',{staticClass:"suaw-link__text",class:[
      'suaw-link__text--' + _vm.weight,
      'suaw-link__text--' + _vm.size,
      _vm.textClassName
    ]},[_vm._t("default")],2),(_vm.iconRight)?_c('Icon',{staticClass:"suaw-link__icon icon-right",class:{ 'suaw-link__icon--active': _vm.isActive },attrs:{"icon":_vm.iconRight}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }