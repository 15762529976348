<script>
export default {
  name: "Form",
  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<template>
  <form 
    class="suaw-form"
    @submit.prevent="handleSubmit"
  >
    <slot name="form"></slot>
  </form>
</template>

<style lang="scss" scoped>
.suaw-form {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-quadruple);
  margin: auto;
  min-width: 100%;
}
</style>
