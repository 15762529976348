<script>
import { SuawMainContent, SuawParagraph, SuawThumbnail, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToWorkWithAnIllustrator",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawThumbnail,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToWorkWithAnIllustrator">
      <SuawParagraph
        text="Working with an illustrator can be a transformative experience. As a writer, you get to see the images you’ve been imagining in your mind take shape in the physical world. Often, collaborating with another artist can create an explosion of creativity as they ask questions you wouldn’t think to ask yourself."
      />
      <SuawParagraph
        text="But because writing and visual art are different, it can be a struggle to communicate your desires to your fellow artist. Whether you’re developing a book cover or other illustrations for your project, translating those images from your mind to the mind of your illustrator can be hard. Beautiful things can happen when you come together, but you must learn how to speak each other’s language in order to make this process an enjoyable experience."
      />
      <SuawParagraph
        text="As the Content Manager of Shut Up &amp; Write!, I sat down with illustrator and concept artist Brandon Schooner to ask him what writers can do to make his job easier. He gave me some valuable feedback on the writer-illustrator relationship, and his suggestions below will make your time with an illustrator much smoother."
      />
      <SuawHeading level="3" content="Make sure to familiarize yourself with your artist’s portfolio before hiring them." />
      <SuawParagraph
        text="You’re more likely to get a finished product you like if you also like their overall style. If you’re not looking for a comic book feel, don’t ask a comic book artist to make your cover. Just as writers write in different genres, visual artists specialize as well. Know what you want and do your homework ahead of time. The process will be much easier (and cheaper) if you’ve done your research before choosing to hire an illustrator."
      />
      <SuawHeading level="3" content="Be clear about what you’re trying to “sell” with your cover art or illustrations." />
      <SuawParagraph
        text="What are you trying to communicate through the art you’ve commissioned? Would you like a viewer to feel a specific emotion when they pick up your book? Depending on the emotion, your illustrator may use a different color palette, and small details—like the color of shadows—may change. If you are instead trying to sell a character, what themes do they represent in your book, and how do you want them to be presented? Theme examples: “dark,” “mysterious,” or “grimy.”"
      />
      <SuawHeading level="3" content="Show your illustrator the closest piece of existing work, with visuals, that you’d like to emulate." />
      <SuawParagraph
        text="It’s time to find your favorite book covers, comic books, Etsy accounts, and even movies or TV series. If they relate to your project, they can be very helpful for your illustrator because they can clearly show moods and color schemes that have inspired your work. But remember that telling your illustrator “My world is pretty much like ‘Buffy the Vampire Slayer’” may get you 90% of the way there, but not all the way. Make sure to explain the details that differentiate your world from Buffy’s. Even if your world looks a lot like Buffy’s, you’re probably not telling the exact same story. List those differences so your illustrator can weave them into their representation of your “Buffy-like” world."
      />
      <div>
        <SuawThumbnail size="fit" content="https://media.graphassets.com/EtqwopdrSrmr4yLC3WN2" />
      </div>
      <SuawHeading level="3" content="Have your illustrator read a few important scenes from your work." />
      <SuawParagraph
        text="This is a crucial step in helping illustrators understand your vision. You can give them all the notes you want about color, technology, and architecture, but your illustrator won’t get to see the story in motion until they read a bit of your work. If you’d like your illustrator to do a book cover, ask them to read a few excerpts from the scene you’d like to feature on the cover. (For an in-depth look at book cover design, check out our article, <a target='_blank' href='https://www.shutupwrite.com/articles/how-to-get-a-book-cover-youre-proud-of'>How to Get a Book Cover You’re Proud Of</a>.)"
      />
      <SuawHeading level="3" content="Describe your characters in granular detail." />
      <SuawParagraph text="If your project takes place in a world much like our own, Schooner suggested providing a list like this for each character:" />
      <ul>
        <li><SuawParagraph text="Gender Identity" /></li>
        <li><SuawParagraph text="Occupation" /></li>
        <li><SuawParagraph text="Clothing" /></li>
        <li><SuawParagraph text="Personality" /></li>
        <li><SuawParagraph text="Pose" /></li>
        <li><SuawParagraph text="Relevance to the plot" /></li>
      </ul>
      <SuawParagraph
        text="If you’re working in a genre like science fiction or fantasy, you may need to provide more detail. For example, if your character is a spacefaring tardigrade who drives a space cactus and whose only method of communication consists of vibrating the space cactus’ needles at an AM radio frequency… well, then you may have to dig deeper."
      />
      <SuawParagraph
        text="Provide examples of what your character’s species looks like. If your character looks like the combination of a human and a beetle, what kind of beetle? What kind of markings does it have? In what ways does the character look like a human and in what ways do they look like a beetle? A human head with a beetle body is a very different image than a beetle version of a centaur. Make sure to be specific, especially when you’re working with unusual species. How do you want to represent scale when drawing this character? Are you going to go for realism, or are you hoping for a fantastical setting with burger joints in the style of Spongebob?"
      />
      <SuawParagraph text="Also, what even is a tardigrade?" />
      <SuawHeading level="3" content="Describe the environment in the same level of detail." />
      <SuawParagraph
        text="Just like describing your characters to your illustrator, you’ll want to do the same for your setting. If you’ve already talked about the mood you want to set, this becomes a lot easier. Here are a few other details you’ll want to cover for your illustrator:"
      />
      <ul>
        <li><SuawParagraph text="Type of environment (desert, mountain, ocean, etc.)" /></li>
        <li><SuawParagraph text="Architectural style (19th century England, current day Ecuador, 2035 Chicago from the movie “I, Robot?”)" /></li>
        <li><SuawParagraph text="Materials used in architecture (brick, wicker, gene-altered redwoods braided together into massive wooden towers?)" /></li>
        <li><SuawParagraph text="Types of flora/fauna" /></li>
      </ul>
      <SuawHeading level="3" content="Give specific feedback." />
      <SuawParagraph
        text="When an illustrator comes back to you with a draft, take some time to really comb over the draft before you offer feedback. No matter how much you like their art, the draft, or the illustrator themselves, offer concrete details that you’d like to change. An enthusiastic “I love it!” doesn’t give them any direction, and unless you think the piece is truly done and you’d be proud to have it in your book, don’t force the artist to make all the choices for you. Remember, a professional artist won’t be hurt if you don’t like one of their drafts—they are used to this revision process."
      />
      <SuawHeading level="1" content="Give the illustrator the details you have set in your mind and let them build on it, even if you don’t have a fully-formed picture." />
      <SuawParagraph
        text="If you don’t have an image fully formed, work with what you do know. Describe everything that is set in stone and then just start riffing with your illustrator. What is it they’ll be drawing? What color is it? What does it do? Keep filling in the details until your illustrator has enough to start with,and then trust them to fill in gaps. You may not know exactly what your sci-fi centipede tank looks like (you just thought it was a cool idea to have a tank burrow through the ground!) but if you give an artist something to start with, they will undoubtedly surprise you with their creativity."
      />
      <SuawParagraph
        text="It’s one thing to have your characters come alive in your pages, but it’s something completely different to see them staring back at you from a piece of art. We hope that every one of you gets to experience that joy. It all comes down to being very clear with your illustrator and sharing plenty of examples—many, many examples."
      />
      <SuawParagraph
        text="<b>Brandon Schooner</b> is a Conceptual Design Artist for video games, films, and written works. His personal focus lies in celebrating the cultural richness of the world we live in, and exploring the beauty of the natural world. He guides writers in the development of their ideas and stories, bringing beauty and diversity to his art when paired with the written word. Check out Schooner’s work on his <a target='_blank' href='http://www.brandonschooner.com/'>website</a>, and find him on Instagram at <a target='_blank' href='https://www.instagram.com/earlofconcept/'>@earlofconcept</a>."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
