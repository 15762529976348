<script>
import ResponseItem from "./ResponseItem.vue";
import Heading from "../Heading/Heading.vue";

export default {
  name: "ResponseList",
  components: {
    ResponseItem,
    Heading,
  },
  props: {
    expandableDiscussionListItems: {
      type: Array,
      default: () => [],
    },
    chipText: {
      type: String,
    },
    discussionResponses: {
      type: String,
    },
    discussionLikes: {
      type: String,
    },
    showDiscussionLikes: {
      type: Boolean,
      default: true
    },
    posted: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    discussionReplies: {
      type: Array,
    },
    textBoxPrimaryButton: {
      type: String,
    },
    textBoxSecondaryButton: {
      type: String,
    },
    textBoxSize: {
      type: String,
    },
    responseNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSidepanelDiscussion: true,
      headingText: " Responses",
    };
  },
  computed: {
    formattedResponseLabel() {
      return this.responseNumber === 0 ? 'No Responses' : `${this.responseNumber} Response${this.responseNumber > 1 ? 's' : ''}`;
    },
    computedChipSize() {
      return this.isSidepanelDiscussion ? "small" : "medium";
    },
    computedDiscussionLikes() {
      return this.discussionLikes ? this.discussionLikes : "";
    },
  },
};
</script>

<template>
  <div class="suaw-discussion">
    <Heading
      :level="5"
      :content="formattedResponseLabel"
    />
    <ul class="suaw-discussion-list">
      <li
        v-for="(item, index) in expandableDiscussionListItems"
        :key="index"
        class="suaw-discussion-list__item"
      >
        <ResponseItem
          :headingText="item.text"
          :chipContent="item.chipContent"
          :chipText="item.chipText"
          :posted="item.posted"
          :showDiscussionLikes="item.showDiscussionLikes"
          :discussionLikes="item.discussionLikes"
          :discussionResponses="item.discussionResponses"
          :dropdownItems="item.dropdownItems"
          :discussionReplies="item.discussionReplies"
          :isSidepanelDiscussion="isSidepanelDiscussion"
          :textBoxPrimaryButton="item.textBoxPrimaryButton"
          :textBoxSecondaryButton="item.textBoxSecondaryButton"
          :textBoxSize="item.textBoxSize"
          :totalResponses="item.discussionResponses"
        />
      </li>
      <slot></slot>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.suaw-discussion {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);

  &-list {
    border-radius: var(--sem-radius-sm);
    list-style-type: none;
    padding: var(--sem-space-base) 0 0;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);

    &__item {
      background-color: var(--sem-color-background-lightest);
      box-shadow: var(--soft-shadow-draft);
      border-radius: var(--sem-radius-sm);
      display: var(--comp-card-display);
      flex-direction: column;
      padding: var(--sem-space-triple);
    }
  }
}
</style>
