<script>
export default {
  name: 'LogoTeams',
}
</script>

<template>
<svg width="81" height="75" viewBox="0 0 81 75" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1749_2)">
<path d="M56.2369 28.125H77.0625C79.0301 28.125 80.625 29.72 80.625 31.6875V50.6569C80.625 57.888 74.763 63.75 67.5319 63.75H67.47C60.2389 63.751 54.376 57.8899 54.375 50.6587C54.375 50.6581 54.375 50.6575 54.375 50.6568V29.9869C54.375 28.9586 55.2086 28.125 56.2369 28.125Z" fill="#5059C9"/>
<path d="M70.3125 24.375C74.9724 24.375 78.75 20.5974 78.75 15.9375C78.75 11.2776 74.9724 7.5 70.3125 7.5C65.6526 7.5 61.875 11.2776 61.875 15.9375C61.875 20.5974 65.6526 24.375 70.3125 24.375Z" fill="#5059C9"/>
<path d="M44.0625 24.375C50.7935 24.375 56.25 18.9185 56.25 12.1875C56.25 5.45654 50.7935 0 44.0625 0C37.3315 0 31.875 5.45654 31.875 12.1875C31.875 18.9185 37.3315 24.375 44.0625 24.375Z" fill="#7B83EB"/>
<path d="M60.3131 28.125H25.9369C23.9928 28.1731 22.4549 29.7865 22.5 31.7306V53.3663C22.2285 65.033 31.4587 74.7145 43.125 75C54.7914 74.7145 64.0215 65.033 63.75 53.3663V31.7306C63.7951 29.7865 62.2572 28.1731 60.3131 28.125Z" fill="#7B83EB"/>
<path opacity="0.1" d="M45 28.125V58.4438C44.9907 59.8341 44.1481 61.0831 42.8625 61.6125C42.4532 61.7857 42.0132 61.8749 41.5687 61.875H24.15C23.9062 61.2563 23.6812 60.6375 23.4937 60C22.8374 57.8485 22.5025 55.6119 22.5 53.3625V31.725C22.4549 29.784 23.9902 28.1731 25.9312 28.125H45Z" fill="black"/>
<path opacity="0.2" d="M43.125 28.125V60.3187C43.1249 60.7632 43.0357 61.2032 42.8625 61.6125C42.3331 62.8981 41.0841 63.7407 39.6938 63.75H25.0312C24.7125 63.1312 24.4125 62.5125 24.15 61.875C23.8875 61.2375 23.6812 60.6375 23.4937 60C22.8374 57.8486 22.5025 55.6119 22.5 53.3625V31.725C22.4549 29.784 23.9902 28.1731 25.9312 28.125H43.125Z" fill="black"/>
<path opacity="0.2" d="M43.125 28.125V56.5688C43.1107 58.4579 41.5829 59.9857 39.6938 60H23.4938C22.8374 57.8486 22.5025 55.6119 22.5 53.3625V31.725C22.4549 29.784 23.9903 28.1731 25.9312 28.125H43.125Z" fill="black"/>
<path opacity="0.2" d="M41.25 28.125V56.5688C41.2357 58.4579 39.7078 59.9857 37.8187 60H23.4938C22.8374 57.8486 22.5025 55.6119 22.5 53.3625V31.725C22.4549 29.784 23.9903 28.1731 25.9312 28.125H41.25Z" fill="black"/>
<path opacity="0.1" d="M45 18.4312V24.3375C44.6812 24.3562 44.3812 24.375 44.0625 24.375C43.7437 24.375 43.4437 24.3562 43.125 24.3375C42.4921 24.2955 41.8644 24.1951 41.25 24.0375C37.4531 23.1383 34.3163 20.4755 32.8125 16.875C32.5537 16.2703 32.3529 15.6425 32.2125 15H41.5687C43.4608 15.0072 44.9928 16.5392 45 18.4312Z" fill="black"/>
<path opacity="0.2" d="M43.125 20.3062V24.3375C42.4921 24.2955 41.8644 24.1951 41.25 24.0375C37.4531 23.1383 34.3163 20.4755 32.8125 16.875H39.6938C41.5858 16.8822 43.1178 18.4142 43.125 20.3062Z" fill="black"/>
<path opacity="0.2" d="M43.125 20.3062V24.3375C42.4921 24.2955 41.8644 24.1951 41.25 24.0375C37.4531 23.1383 34.3163 20.4755 32.8125 16.875H39.6938C41.5858 16.8822 43.1178 18.4142 43.125 20.3062Z" fill="black"/>
<path opacity="0.2" d="M41.25 20.3063V24.0375C37.4531 23.1383 34.3163 20.4755 32.8125 16.875H37.8188C39.7108 16.8822 41.2428 18.4142 41.25 20.3063Z" fill="black"/>
<path d="M3.43686 16.875H37.8131C39.7112 16.875 41.25 18.4138 41.25 20.3119V54.6881C41.25 56.5862 39.7112 58.125 37.8131 58.125H3.43686C1.53872 58.125 0 56.5862 0 54.6881V20.3119C0 18.4138 1.53875 16.875 3.43686 16.875Z" fill="url(#paint0_linear_1749_2)"/>
<path d="M29.67 29.9587H22.7981V48.6712H18.42V29.9587H11.58V26.3287H29.67V29.9587Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_1749_2" x1="7.16596" y1="14.1895" x2="34.0841" y2="60.8105" gradientUnits="userSpaceOnUse">
<stop stop-color="#5A62C3"/>
<stop offset="0.5" stop-color="#4D55BD"/>
<stop offset="1" stop-color="#3940AB"/>
</linearGradient>
<clipPath id="clip0_1749_2">
<rect width="80.625" height="75" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<style scoped>
</style>
