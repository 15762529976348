<script>
import Pill from "../Pill/Pill.vue";
import Icon from "../Icon/Icon.vue";
import Avatar from "../Avatar/Avatar.vue";

export default {
  name: "Button",
  components: {
    Pill,
    Icon,
    Avatar,
  },
  props: {
    item: {
      default: null,
    },
    clickMethod: {
      type: Function,
      default: null,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    iconSize: {
      type: String,
      default: "medium",
    },
    buttonText: {
      type: String,
      default: "",
    },
    pillText: {
      type: String,
      default: "",
    },
    pillStatus: {
      type: String,
      default: "",
    },
    avatarContent: {
      type: String,
      default: "",
    },
    avatarType: {
      type: String,
      default: "",
    },
    avatarBefore: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "primary",
      validator: function (value) {
        return [
          "critical",
          "critical-light",
          "critical-ghost",
          "disabled",
          "ghost",
          "ghost-active",
          "ghost-outline",
          "primary",
          "primary-light",
          "secondary",
          "secondary-light",
          "secondary-outline",
          "secondary-ghost",
          "success",
          "success-light",
          "success-ghost",
        ].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return (
          ["mobile-nav", "hero", "large", "medium", "small", "med-square"].indexOf(value) !==
          -1
        );
      },
    },
    className: {
      type: String,
      default: "",
    },
    classNameText: {
      type: String,
      default: "",
    },
  },
  computed: {
    iconClasses() {
      return ['primary', 'critical', 'success'].includes(this.type) ? 'suaw-icon--white' :
      ['ghost-active', 'ghost-outline', 'ghost', 'primary-light'].includes(this.type) ? 'suaw-icon--blue' :
      ['success-light'].includes(this.type) ? 'suaw-icon--green' :
      ['critical-light'].includes(this.type) ? 'suaw-icon--red' :
      this.type === 'disabled' ? 'suaw-icon--disabled' : ''
    },
    textClasses() {
      return ['primary', 'critical', 'success'].includes(this.type) ? 'suaw-button__text--white' :
      ['ghost-active', 'ghost-outline', 'ghost', 'primary-light'].includes(this.type) ? 'suaw-button__text--blue' :
      ['secondary-ghost'].includes(this.type) ? 'suaw-button__text--regular' :
      ['success-light'].includes(this.type) ? 'suaw-button__text--green' :
      ['critical-light'].includes(this.type) ? 'suaw-button__text--red' :
      this.type === 'disabled' ? 'suaw-button__text--disabled' : ''
    },
    computedPillStatus() {
      if (this.pillStatus) {
        return this.pillStatus;
      }
      return ['primary', 'success', 'critical'].includes(this.type) ? 'lightest' :
        ['success-light'].includes(this.type) ? 'success' :
        ['critical-light'].includes(this.type) ? 'critical' :
        ['primary-light', 'ghost', 'ghost-outline', 'ghost-active'].includes(this.type) ? 'active' :
        ['secondary', 'secondary-outline'].includes(this.type) ? 'dark' :
        this.type === 'disabled' ? 'disabled' : ''
    },
    pillLabelClasses() {
      return ['success'].includes(this.type) ? 'pill__label--success' :
        ['critical'].includes(this.type) ? 'pill__label--critical' :
        ['primary'].includes(this.type) ? 'pill__label--info' : ''
    },
    computedAvatarSize() {
      return this.size === 'mobile-nav' ? 'medium' : 'small';
    },
  },
  methods: {
    handleClick() {
      if (typeof this.clickMethod === "function") {
        this.clickMethod();
      }
      this.$emit("click", this.item);
    },
  },
};
</script>

<template>
  <button 
    :class="['suaw-button', 'suaw-button--' + type, 'suaw-button--' + size, className]" 
    @click="handleClick"
  >
    <Icon 
      v-if="iconLeft"
      :class="['suaw-button__icon', iconClasses]"
      :icon="iconLeft"
      :size="iconSize"
    />
    <Avatar
      v-if="avatarContent && avatarBefore"
      :type="avatarType"
      :size="computedAvatarSize"
      :content="avatarContent"
    />
    <span
      v-if="buttonText"
      :class="['suaw-button__text', 'suaw-button__text--' + size, textClasses, classNameText]"
    >
      {{ buttonText }}
    </span>
    <Pill
      v-if="pillText"
      :labelClassName="pillLabelClasses"
      :text="pillText"
      :status="computedPillStatus"
    />
    <Avatar
      v-if="avatarContent && !avatarBefore"
      :type="avatarType"
      :size="computedAvatarSize"
      :content="avatarContent"
    />
    <Icon
      v-if="iconRight"
      :class="['suaw-button__icon', iconClasses]"
      :icon="iconRight"
      :size="iconSize"
    />
    <slot></slot>
  </button>
</template>

<style lang="scss" scoped>
.suaw-button {
  align-items: center;
  display: inline-flex;
  border-radius: var(--sem-radius-sm);
  justify-content: center;
  position: relative;
  transition-property: background, border, box-shadow;
  transition-duration: .25s;
  transition-timing-function: linear;

  &--primary-light {
    background-color: var(--sem-color-info-light);
  }

  &--secondary {
    background-color: var(--sem-color-background-light);
  }

  &--secondary-light {
    background-color: var(--sem-color-background-lightest);
  }

  &--disabled {
    background-color: var(--sem-color-inactive-light);
    cursor: default;
    pointer-events: none;
  }

  &--primary {
    background-color: var(--sem-color-info-medium);
  }

  &--ghost,
  &--ghost-active,
  &--secondary-ghost {
    background-color: transparent;
  }

  &:disabled,
  &:disabled:hover {
    box-shadow: none;
    opacity: .65;
    border-color: var(--sem-color-inactive-light);
  }

  &--ghost-active {
    border: 1px solid;
    border-color: var(--sem-color-info-medium);
  }

  &--ghost-active:hover {
    border-color: var(--sem-color-info-dark);
  }

  &--ghost-outline:hover {
    border-color: var(--sem-color-info-medium);
  }

  &--secondary-light:hover,
  &--secondary-outline:hover {
    border-color: var(--sem-color-neutral-medium);
  }

  &--large {
    gap: var(--sem-space-base);
    height: 48px;
    padding: 12px var(--sem-space-triple);
  }

  &--hero {
    gap: var(--sem-space-base);
    height: 50px;
    padding: 12px 24px;
  }

  &--mobile-nav {
    gap: var(--sem-space-base);
    height: 38px;
    padding: 0;
  }

  &--success:hover {
    background-color: var(--sem-color-success-dark);
  }

  &--success-light {
    background-color: var(--sem-color-success-light);
  }

  &--success-ghost {
    color: var(--sem-color-success-medium);
  }

  &--critical {
    background-color: var(--sem-color-critical-medium);
  }

  &--critical:hover {
    background-color: var(--sem-color-critical-dark);
  }

  &--success {
    background-color: var(--sem-color-success-medium);
  }

  &--critical-light:hover {
    background-color: var(--sem-color-critical-light);
    box-shadow: var(--date-square-shadow);
  }

  &--critical-ghost {
    color: var(--sem-color-critical-medium);
  }

  &--success-light:hover {
    background-color: var(--sem-color-success-light);
    box-shadow: var(--date-square-shadow);
  }

  &--medium {
    gap: var(--sem-space-half);
    height: 40px;
    padding: var(--sem-space-base);
  }

  &--med-square {
    height: 43px;
    width: 43px;
  }

  &--no-x-pd {
    padding-left: var(--sem-space-none) !important;
    padding-right: var(--sem-space-none) !important;
  }

  &--no-y-pd {
    padding-top: var(--sem-space-none) !important;
    padding-bottom: var(--sem-space-none) !important;
  }

  &--pull-left {
    margin-right: auto;
  }

  &--pull-right {
    margin-left: auto;
  }

  &--primary-light:hover {
    background-color: var(--sem-color-info-light);
    box-shadow: var(--date-square-shadow);
  }

  &--secondary-light,
  &--secondary-outline {
    border: 1px solid;
    border-color: var(--sem-color-border-medium);
  }

  &--primary:hover {
    background-color: var(--sem-color-info-dark);
  }

  &--critical-light {
    background-color: var(--sem-color-critical-light);
  }

  &--secondary:hover {
    background-color: var(--sem-color-background-light);
    box-shadow: var(--date-square-shadow);
  }

  &--small {
    gap: var(--sem-space-half);
    height: 24px;
    padding: var(--sem-space-half) var(--sem-space-base) var(--sem-space-half) var(--sem-space-base);
  }

  &--ghost-outline {
    border: 1px solid;
    border-color: var(--sem-color-border-medium);
  }

  &__text {
    color: var(--sem-color-text-medium);
    position: relative;
    transition-property: color;
    transition-duration: .25s;
    transition-timing-function: linear;
    white-space: nowrap;

    .suaw-button--secondary-outline:active & {
      color: var(--sem-color-text-darken);
    }

    .suaw-button--critical-ghost:hover &,
    .suaw-button--critical-light:active & {
      color: var(--sem-color-critical-dark);
    }

    .suaw-button--critical-ghost & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-button--success-ghost:hover &,
    .suaw-button--success-light:active & {
      color: var(--sem-color-success-dark);
    }

    .suaw-button--success-ghost & {
      color: var(--sem-color-success-medium);
    }

    .suaw-button--primary-light:active &,
    .suaw-button--ghost:not(:disabled):hover &,
    .suaw-button--ghost:active & {
      color: var(--sem-color-info-dark);
    }

    .suaw-button--secondary:active & {
      color: var(--sem-color-text-darken);
    }

    .suaw-button--secondary-ghost:not(:disabled):hover & {
      color: var(--sem-color-text-darkest);
    }
  }

  &__text--hero {
    font-family: var(--button-bold-font-family);
    font-size: var(--h-4-font-size);
    font-style: var(--button-bold-font-style);
    font-weight: var(--button-bold-font-weight);
    letter-spacing: var(--button-bold-letter-spacing);
    line-height: var(--button-bold-line-height);
  }

  &__text--mobile-nav {
    color: var(--sem-color-text-regular);
    font-family: var(--button-bold-font-family);
    font-size: 32px;
    font-style: var(--button-bold-font-style);
    font-weight: var(--button-bold-font-weight);
    letter-spacing: var(--button-bold-letter-spacing);
    line-height: var(--button-bold-line-height);
    position: relative;
    text-decoration: none;
    transition: color, .25s linear;
    white-space: nowrap;
  }

  &__text--large {
    font-family: var(--button-bold-font-family);
    font-size: var(--button-bold-font-size);
    font-style: var(--button-bold-font-style);
    font-weight: var(--button-bold-font-weight);
    letter-spacing: var(--button-bold-letter-spacing);
    line-height: var(--button-bold-line-height);
  }

  &__text--medium {
    font-family: var(--body-bold-is-size-7-font-family);
    font-size: var(--body-bold-is-size-7-font-size);
    font-style: var(--body-bold-is-size-7-font-style);
    font-weight: var(--body-bold-is-size-7-font-weight);
    letter-spacing: var(--body-bold-is-size-7-letter-spacing);
    line-height: var(--body-bold-is-size-7-line-height);
  }

  &__text--small {
    font-family: var(--body-small-bold-font-family);
    font-size: var(--body-small-bold-font-size);
    font-style: var(--body-small-bold-font-style);
    font-weight: var(--body-small-bold-font-weight);
    letter-spacing: var(--body-small-bold-letter-spacing);
    line-height: var(--body-small-bold-line-height);
  }

  &__text--blue {
    color: var(--sem-color-info-medium);
  }

  &__text--disabled {
    color: var(--sem-color-inactive-medium);
  }

  &__text--white {
    color: var(--sem-color-text-lightest);
  }

  &__text--green {
    color: var(--sem-color-success-medium);
  }

  &__text--red {
    color: var(--sem-color-critical-medium);
  }

  &__text--regular {
    color: var(--sem-color-text-regular);
  }

  &__text--darkest {
    color: var(--sem-color-text-darkest);
  }
  .font-weight-regular {
    font-weight: 400;
  }
}
</style>
