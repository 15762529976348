<script>
export default {
  name: "Agenda",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="agenda">
    <table class="agenda-table">
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <td class="agenda-item">{{ item.time_at }}</td>
          <td class="agenda-item">{{ item.description_text }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.agenda {
  background-color: var(--sem-color-background-lightest);
  border: 1px solid var(--sem-color-border-regular);
  border-radius: var(--sem-radius-sm);
  box-shadow: var(--date-square-shadow);
  list-style-type: none;
  overflow: hidden;

  h3 {
    margin-bottom: 12px;
  }

  .agenda-table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid #e0e0e0;
      min-height: 63px;
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 40px;
      padding: 12px 16px;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
    }
    .agenda-item {
      color: var(---sem-color-text-darken);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
