<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "BeginningTipsForWritersWorkingStylesAndWip",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="BeginningTipsForWritersWorkingStylesAndWip">
      <SuawHeading level="3" content="Working Styles" />
      <SuawParagraph
        text="When you’re starting out and getting used to writing on a regular basis, learning about your working style is probably the next step. Everyone has their own style. Even if you’re familiar and comfortable with yours you might have to change or adapt it to suit a particular project you’re working on. And, over time, your working style may change."
      />
      <SuawParagraph
        text="Most people divide working styles into three categories, often referred to as plotters and pantsers or a combination of the two. A plotter is a planner. This writer will outline the story, come up with maps, character biographies and clothes, histories of the time and place, etc. all before even writing down one word. A pantser (that is, “fly by the seat of your pants”) will write the story down without knowing much about it. This person will allow the story to grow “organically,” letting the characters and events pull them along. The combination type does some of both."
      />
      <SuawParagraph text="Here are some tips for learning your style:" />
      <SuawParagraph
        text="<ul><li>Experimentation is everything. Try playing around with writing “in the style of” some author you love or imitating the structure of a story you really like. Everything you do will be instructive and you don’t have to show anything to anyone.</li><li>Write it down. This seems obvious but I’ve been guilty of this time and again. I’ll run through ideas, scenes, stories in my head and think they’re pretty cool but they’re nothing until I write it down. Remember when you write it down it may come out differently than you expected.</li><li>Try different ways of working: handwriting the story, typing on a computer screen (or a combination of both). Try creative writing software (almost all of them have a trial period so you can try it out). I use Scrivener and it has revolutionized the way I work. Try writing tools such as books with writing prompts or checklists, and cards. You may not like it but you might find an idea that works for you.</li></ul>"
      />
      <SuawHeading level="3" content="Creative DNA" />
      <SuawParagraph
        text="Years ago, I bought a wonderful book about the creative process by Twyla Tharp, the dancer/choreographer, entitled “The Creative Habit.” Though the book is written in the context of dance, it’s for anyone who wants to be more creative so I suggest checking it out. In it she talks about your “Creative DNA,” that is, how you tend to approach the way you work on a project. She used writing as her example. How do you sense your story? By any kind of sensory perception? Many writers tend to “see” their story or parts of it but others might “hear” instead, such as two characters talking or “hear” what’s going on in a setting. If you tend to “see” your story, how do you see it? Is it all about details, minutiae? Such as:"
      />
      <SuawParagraph
        text="“Samuel Spade’s jaw was long and bony, his chin a jutting V under the more flexible V of his mouth. His nostrils curved back to make another smaller V. His yellow-grey eyes were horizontal. The V motif was picked up again by thickish brows rising outward from twin creases above a hooked nose, and his pale brown hair grew down – from high flat temples – in a point on his forehead. He looked rather pleasantly like a blond Satan.” – Dashiell Hammett, Maltese Falcon."
      />
      <SuawParagraph text="Or do you see sweeping epics such as:" />
      <SuawParagraph
        text="“This is a novel. Its characters and scenery are imaginary. There was no Venneford Ranch, no prairie town of Line Camp, no Skimmerhorn cattle drive in 1868, no Centennial. None of the families depicted here were real, nor founded upon real precedents. There was no Lame Beaver, nor Skimmerhorn nor Zendt nor Grebe. On the other hand, certain background incidents and characters are real. There was a great convocation in 1851 at Fort Laramie. There was a drought in 1931-1935….The South Platte River did behave as described.” – James A. Michener, Centennial."
      />
      <SuawParagraph
        text="Some people see stories in what Tharp describes as “sitcom,” people sitting in the living room or kitchen having a conversation. Others see their stories cinematically, like watching a movie. There are other styles. These are just examples to get you thinking."
      />
      <SuawParagraph
        text="Thinking about and identifying your “Creative DNA” can be helpful. It doesn’t mean you’re a slave to it. It just helps to be aware of what feels natural and when you’re experimenting with a style that is different."
      />
      <SuawHeading level="3" content="Work in Progress (WIP)" />
      <SuawParagraph
        text="Your Work in Progress (“WIP”) is very important. Choose whatever you want but choose carefully. Even if you’re writing a short short story you’re probably going to spend a lot of time and creative energy working on it and if you can’t stand it you’re never going to finish. This is doubly true when you’re working on a novel. You are going to get sick of it. You’re going to want to throw the whole thing out because it’s all you’ve been thinking about for weeks, months, years. You must love what you’re working on because that love will be the only thing that will bring you back and keep you moving forward until it’s finished."
      />
      <SuawParagraph text="Choose your WIP carefully. Don’t settle for anything less than a story you love that much." />
      <SuawParagraph
        text="We’d like to hear from you! What have you learned about your own working style? What kind of experimentation have you done? In terms of your work in progress, how do you decide what to work on for both short term and long term projects?"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
