<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawParagraph, SuawButton, SuawLink } from "@/components";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, email } from "vuelidate/lib/validators";
import * as UserApi from "../../api";

export default {
  name: "ForgotPasswordForm",
  components: { SuawForm, SuawHeading, SuawTextInput, SuawParagraph, SuawButton, SuawLink },
  data() {
    return {
      forgotPasswordForm: {
        email: ""
      }
    };
  },
  validations: {
    forgotPasswordForm: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async requestPasswordReset() {
      const result = await UserApi.requestPasswordReset(this.forgotPasswordForm.email);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSubmit() {
      this.$v.forgotPasswordForm.$touch();
      if (this.$v.forgotPasswordForm.$invalid) {
        this.$root.$emit("universal-error-message", "request password reset form invalid");
        return;
      }
      const requestPasswordResetResult = await this.requestPasswordReset();
      if (!requestPasswordResetResult.success) {
        return;
      }
      this.$root.$emit(
        "universal-success-message",
        `We just sent a verification email to <b>${this.forgotPasswordForm.email}</b>. To complete your reset password request, please check your mail and follow the instructions provided.`
      );
      this.$emit("submit", this.forgotPasswordForm.email);
    }
  }
};
</script>

<template>
  <SuawForm @submit="onSubmit">
    <template #form>
      <SuawHeading level="3" content="Forgot your password?" alignment="center" />
      <SuawTextInput
        id="email-pw-reset"
        v-model="forgotPasswordForm.email"
        type="email"
        :is-required="true"
        label="Email"
        :state="formFieldState($v, 'forgotPasswordForm', 'email')"
        :error-message="validateErrors($v.forgotPasswordForm.email, 'Email')"
        @blur="$v.forgotPasswordForm.email.$touch()"
      />
      <SuawParagraph size="small" text="Enter your email address and we'll send you an email with a link to reset your password." alignment="left" />
      <SuawButton size="large" type="primary" button-text="Send Password Reset" />
      <SuawLink to="/sign-in" alignment="center">Return to login page</SuawLink>
    </template>
  </SuawForm>
</template>
