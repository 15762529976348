<script>
import Button from "../Button/Button.vue";
import CheckInModal from "./CheckInModal.vue";
import Logo from "../Logo/Logo.vue";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
// import { Printd } from "printd";

export default {
  name: "CheckIn",
  components: {
    Button,
    CheckInModal,
    Logo,
    Heading,
    Paragraph,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    eventTitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    toggleModalClasses() {
      return {
        "is-visible": this.showModal,
      };
    },
  },
  data() {
    return {
      showModal: false,
      isPrinting: false,
    };
  },
  methods: {
    toggleModalVisibility() {
      this.showModal = !this.showModal;
    },
    // async printContent() {
    //   //https://www.npmjs.com/package/printd

    //   let stylesHtml = "";
    //   for (const node of [
    //     ...this.$el.querySelectorAll('link[rel="stylesheet"], style'),
    //   ]) {
    //     stylesHtml += node.outerHTML;
    //   }

    //   const content = this.$el.querySelector(".printable-content");

    //   content.addEventListener("beforeprint", () => (this.isPrinting = true)); //hide close and print buttons for the print preview
    //   content.addEventListener("afterprint", () => (this.isPrinting = false)); //show close and print buttons for the print preview

    //   const d = new Printd();
    //   // d.print(content, [stylesHtml], [], ({ launchPrint }) => {
    //   //   launchPrint();
    //   // });
    //   d.print(content, [stylesHtml]);
    // },
  },
};
</script>

<template>
  <div class="suaw-checkin">
    <Paragraph
      v-if="label"
      class="suaw-checkin__label"
      weight="bold"
      size="medium"
      :text="label"
    />
    <div class="suaw-checkin__holder printable-content">
      <Logo
        v-if="isPrinting"
        type="LogoDefault"
        className="suaw-modal__logo"
      />
      <Heading
        v-if="isPrinting"
        level="1"
        class="suaw-checkin__title"
        :content="eventTitle"
      />
      <img
        class="suaw-checkin__qr-code"
        :src="code"
      >
      <nav
        v-if="!isPrinting"
        class="suaw-checkin__actions"
      >
        <Button
          size="medium"
          type="ghost"
          iconLeft="IconShow"
          buttonText="Preview"
          @click="toggleModalVisibility"
        />
        <Button
          size="medium"
          type="ghost"
          iconLeft="IconPrint"
          buttonText="Print Table Tent"
          @click="printContent"
        />
      </nav>
    </div>
    <transition name="suaw-modal__fade">
      <CheckInModal
        :showModal="showModal"
        :toggleModalClasses="toggleModalClasses"
        :isPrinting="isPrinting"
        :toggleModalVisibility="toggleModalVisibility"
        :code="code"
        :eventTitle="eventTitle"
        :printContent="printContent"
      />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.suaw-checkin {
  min-width: 320px;
  width: 100%;

  &__label {
    display: block;
    font-size: 16px;
    margin-bottom: var(--sem-space-double);
  }

  &__holder {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-regular);
    border-radius: var(--sem-radius-sm);
    box-shadow: var(--date-square-shadow);
    padding: var(--sem-space-double);
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: var(--sem-space-double) 0 0;
  }

  &__title {
    font-size: var(--comp-title-font-size);

    @media screen and (min-width: 1025px) {
      --comp-title-font-size: var(--h-1-font-size);
    }

    @media screen and (max-width: 1024px) {
      --comp-title-font-size: var(--h-3-font-size);
    }
  }

  &__qr-code {
    display: block;
    margin: auto;
    max-width: 100%;
    text-align: center;
  }
}
</style>
