<script>
import {
  SuawMainContent,
  SuawSummary,
  SuawTextInput,
  SuawInputGroup,
  SuawFileUpload,
  SuawLabel,
  SuawRadio,
  SuawDropdown,
  SuawButton,
  SuawForm,
  SuawTextArea
} from "@/components";
import { required, maxLength } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";
import { convertFileToBase64String } from "@/utils/formatting/files.js";
import { getAvatarColorOptions } from "../../common.js";
import { UserSettingsById } from "../../operations.gql";
export default {
  name: "DashboardProfileEdit",
  components: {
    SuawMainContent,
    SuawTextInput,
    SuawSummary,
    SuawInputGroup,
    SuawFileUpload,
    SuawLabel,
    SuawRadio,
    SuawDropdown,
    SuawButton,
    SuawForm,
    SuawTextArea
  },
  data() {
    return {
      avatarColorOptions: getAvatarColorOptions(),
      resultUserSettingsById: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        is_last_name_hidden: null,
        country: "",
        country_abbrev: "",
        postal_code: "",
        unit_system: "",
        description_json: null,
        avatar_color: "",
        languages: null,
        city: "",
        region: "",
        is_preferred_chapter_discussions: [
          // {
          //   is_preferred: null
          // }
        ],
        is_preferred_series_discussions: [
          // {
          //   is_preferred: null
          // }
        ]
      },
      editForm: {
        userId: "",
        firstName: "",
        lastName: "",
        isLastNameHidden: null,
        countryAbbrev: "",
        postalCode: "",
        unitSystem: "",
        descriptionJson: { type: "doc", content: [{ type: "paragraph" }] },
        avatarColor: "",
        languages: null,
        city: null,
        region: null,
        avatarUrl: "",
        avatarType: "color"
      }
    };
  },
  apollo: {
    resultUserSettingsById: {
      query: UserSettingsById,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      update(data) {
        if (data && data.resultUserSettingsById && data.resultUserSettingsById.length > 0) {
          this.populateEditForm(data.resultUserSettingsById[0]);
        }
        return data.resultUserSettingsById?.[0] || this.resultUserSettingsById;
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  validations: {
    editForm: {
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      }
    }
  },
  computed: {
    originalFirstName() {
      return this.$auth.user.first_name;
    },
    originalLastName() {
      return this.$auth.user.last_name;
    },
    showImageUpload() {
      return this.editForm.avatarType === "image";
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    populateEditForm(userData) {
      this.editForm = {
        userId: userData.id ?? "",
        firstName: userData.first_name ?? "",
        lastName: userData.last_name ?? "",
        isLastNameHidden: userData.is_last_name_hidden ?? null,
        countryAbbrev: userData.country_abbrev ?? "",
        postalCode: userData.postal_code ?? "",
        unitSystem: userData.unit_system ?? "",
        descriptionJson: userData.description_json ?? null,
        avatarColor: userData.avatar_color ?? "",
        avatarType: this.$auth.user.avatar_url ? "image" : "color",
        languages: userData.languages ?? null,
        city: userData.city ?? "",
        region: userData.region ?? ""
      };
    },
    async uploadUserAvatar() {
      const { userId, avatarUrl } = this.editForm;
      const result = await UserApi.uploadUserAvatar(userId, avatarUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateUserProfile() {
      const { userId, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, unitSystem, descriptionJson, avatarColor, languages, city, region } = this.editForm;
      const result = await UserApi.updateUserProfile(
        userId,
        firstName,
        lastName,
        isLastNameHidden,
        countryAbbrev,
        postalCode,
        unitSystem,
        descriptionJson,
        avatarColor,
        languages,
        city,
        region
      );
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    onCancelClicked() {
      this.$router.push({ name: "DashboardProfileView" });
    },
    async onConfirmClicked() {
      this.$v.editForm.$touch();
      if (this.$v.editForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete required fields of form.");
      } else {
        if (this.editForm.avatarUrl) {
          const uploadUserAvatarResult = await this.uploadUserAvatar();
          if (!uploadUserAvatarResult.success) {
            return;
          }
        }

        const updateUserProfileResult = await this.updateUserProfile();
        if (!updateUserProfileResult.success) {
          return;
        }

        try {
          this.$router.push({ name: "DashboardProfileView" });
          this.$root.$emit("universal-success-message", "You successfully updated your profile!");
        } catch (error) {
          this.$root.$emit("universal-error-message", "Unable to update your profile. Try again or contact support if issues persist."); //!!not exactly true though is it?
        }
      }
    },
    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.editForm.avatarUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    handleAvatarColorSelect(avatar) {
      this.editForm.avatarColor = avatar.avatarType;
    },
    redirectToSignIn() {
      this.$router.push({
        name: "SignIn",
        query: {
          redirectUrl: `${this.$route.fullPath}`
        }
      });
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawForm v-bind="$props">
      <template #form>
        <SuawSummary summary-type="text" heading="Edit Profile" />
        <SuawTextInput
          v-model="editForm.firstName"
          type="text"
          :placeholder="originalFirstName"
          label="First name"
          label-weight="bold"
          is-required
          :state="formFieldState($v, 'editForm', 'firstName')"
          :error-message="validateErrors($v.editForm.firstName, 'First name')"
          @blur="$v.editForm.firstName.$touch()"
        />
        <SuawTextInput
          v-model="editForm.lastName"
          type="text"
          :placeholder="originalLastName"
          label="Last name"
          label-weight="bold"
          is-required
          :state="formFieldState($v, 'editForm', 'lastName')"
          :error-message="validateErrors($v.editForm.lastName, 'Last name')"
          @blur="$v.editForm.lastName.$touch()"
        />
        <SuawInputGroup direction="column" group-gap="base">
          <SuawLabel label-text="Show As" size="small" weight="bold" />
          <SuawInputGroup direction="row" field-one-size="1" field-two-size="10">
            <SuawRadio id="full" v-model="editForm.isLastNameHidden" name="chooseName" label="Full Name" :native-value="false" />
            <SuawRadio id="firstLast" v-model="editForm.isLastNameHidden" name="chooseName" label="First Name & Last Initial" :native-value="true" />
          </SuawInputGroup>
        </SuawInputGroup>
        <SuawTextArea id="eventDesc" v-model="editForm.descriptionJson" label="About you" label-weight="bold" placeholder="Your Bio" use-tip-tap />
        <!-- <SuawInputGroup direction="column" group-gap="base">
          <SuawLabel label-text="Avatar Style" size="small" weight="bold" />
          <SuawInputGroup direction="row" field-one-size="1" field-two-size="10">
            <SuawRadio id="color" v-model="editForm.avatarType" name="chooseAvatar" label="Color" native-value="color" />
            <SuawRadio id="image" v-model="editForm.avatarType" name="chooseAvatar" label="Image" native-value="image" />
          </SuawInputGroup>
        </SuawInputGroup> -->
        <SuawInputGroup direction="column" group-gap="half">
          <SuawLabel label-text="Color" size="small" weight="bold" />
          <SuawDropdown
            v-model="editForm.avatarColor"
            class-name="dropdown--full-width"
            dropdown-button-size="medium"
            :items="avatarColorOptions"
            dropdown-type="mutable"
            button-type="ghost-outline"
            button-class-name="suaw-dropdown__button--fill"
            label="Avatar Color"
            @item-click="handleAvatarColorSelect"
          />
        </SuawInputGroup>
        <SuawInputGroup direction="column" group-gap="half">
          <SuawLabel label-text="Profile picture (optional)" size="small" weight="bold" />
          <SuawFileUpload upload-title="Upload Profile Picture" @file-selected="convertFile" />
        </SuawInputGroup>
      </template>
    </SuawForm>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
      <SuawButton size="large" type="secondary-outline" button-text="Cancel" @click="onCancelClicked" />
      <SuawButton size="large" type="primary" button-text="Confirm Changes" @click="onConfirmClicked" />
    </SuawInputGroup>
  </SuawMainContent>
</template>
