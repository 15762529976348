export const ListItemItemFactory = {
  id: null,
  title: null,
  avatarUrl: null,
  avatarColor: null,
  buttonText: null,
  buttonIcon: null,
  linkUrl: null,
  linkText: null,
  picture: null,
  initials: null,
  isAvatarHidden: false,
  isDeactivated: false,

  clean() {
    this.id = null;
    this.title = null;
    this.avatarUrl = null;
    this.avatarColor = null;
    this.buttonText = null;
    this.buttonIcon = null;
    this.linkUrl = null;
    this.linkText = null;
    this.picture = null;
    this.initials = null;
    this.isAvatarHidden = false;
    this.isDeactivated = false;
    return this;
  },
  // Begin Basic Factory Methods
  withId(id) {
    this.id = id;
    return this;
  },
  withTitle(title) {
    this.title = title;
    return this;
  },
  withAvatarUrl(avatarUrl) {
    this.avatarUrl = avatarUrl;
    return this;
  },
  withAvatarColor(avatarColor) {
    this.avatarColor = avatarColor;
    return this;
  },
  withButton(buttonText, buttonIcon) {
    this.buttonText = buttonText;
    this.buttonIcon = buttonIcon;
    return this;
  },
  withLink(linkUrl, linkText) {
    this.linkUrl = linkUrl;
    this.linkText = linkText;
    return this;
  },
  withPicture(picture) {
    this.picture = picture;
    return this;
  },
  // End Basic Factory Methods
  // Begin Convenience Factory Methods
  withUserObject(user) {
    this.id = user.id;
    this.title = user.display_name;
    this.avatarUrl = user.avatar_url;
    this.avatarColor = user.avatar_color;
    this.isAvatarHidden = user.is_avatar_hidden;
    this.isDeactivated = user.deleted_at !== null;
    this.initials = user.initials;
    return this;
  },
  withChapterObject(chapter) {
    this.id = chapter.id;
    this.title = chapter.title;
    this.picture = `${chapter.photo_url}?t=${new Date().getTime()}`;
    // this.avatarUrl = `${chapter.photo_url}?t=${new Date().getTime()}`;
    // this.avatarColor = null;
    return this;
  },
  withSeriesObject(series) {
    this.id = series.id;
    this.title = series.title;
    this.picture = `${series.photo_url}?t=${new Date().getTime()}`;
    return this;
  },
  // End Convenience Factory Methods
  build() {
    if (!this.id) throw "id";
    if (!this.title) throw "title";
    if (!this.avatarUrl && !this.avatarColor && !this.picture) throw "avatarUrl, avatarColor, or picture";
    if (this.buttonText && !this.buttonIcon) throw "buttonText and buttonIcon or neither";
    if (!this.buttonText && this.buttonIcon) throw "buttonText and buttonIcon or neither";
    if (this.linkUrl && !this.linkText) throw "linkText and linkUrl or neither";
    if (!this.linkUrl && this.linkText) throw "linkText and linkUrl or neither";

    let aColor = this.avatarColor ? this.avatarColor : "light-gray";
    let aType = !this.isAvatarHidden && this.avatarUrl ? "picture" : aColor;
    let aAvatar = !this.isAvatarHidden && aType === "picture" ? this.avatarUrl : !this.isDeactivated ? this.initials : "";
    let aDisplayName = !this.isDeactivated ? this.title : "Community Member";

    let result = {
      id: this.id,
      text: aDisplayName,
      avatar: aAvatar,
      avatarType: aType,
      buttonText: this.buttonText,
      buttonIcon: this.buttonIcon ? this.buttonIcon : null,
      picture: this.picture,
      linkUrl: this.linkUrl,
      linkText: this.linkText ? this.linkText : null
    };
    return result;
  }
};
