<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToGetABookCoverYoureProudOf",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToGetABookCoverYoureProudOf">
      <SuawParagraph
        text="If you’re like most authors, you’ve already daydreamed about what your book will look like. Once you’re ready to move along the path to publication, you have the chance to see those daydreams become reality. Whether you’re set on a particular vision or are still thinking through the possibilities, the first decision you’ll make will be to choose how to get your book published."
      />
      <SuawHeading level="3" content="If you’re going down the route to traditional publishing, know that your publisher will have final say over your book design." />
      <SuawParagraph
        text="The cover art will most likely be designed by in-house cover designers at your publisher, and you may or may not have the opportunity to weigh in on the art direction. Some publishers may offer you a few options, but <a target='_blank' href='https://bookriot.com/authors-dont-have-much-control-over-covers-heres-why/'>most will retain full decision-making power</a> during the cover design process."
      />
      <SuawParagraph text="However, if you are going down the self-publishing route, you will encounter a plethora of options for your cover." />
      <SuawParagraph
        text="Though the internet is full of online tutorials and ready-made templates to help with making your own cover, we at Shut Up &amp; Write believe that the best option for most authors is to hire a professional cover designer who can balance the standards of your genre with your personal vision."
      />
      <SuawHeading level="3" content="Book design involves so much more than the cover." />
      <SuawParagraph
        text="When you hire a book cover designer, you can rest assured that they will take into account <a target='_blank' href='https://99designs.com/blog/book-design/anatomy-of-a-book-cover/'>all the elements of book design</a>, including the front and back covers, imagery, typography, subtitles, author bio, and spine. Furthermore, your reader will expect your cover to look a certain way based on the genre of your book, so the design process will need to incorporate certain genre-specific visual cues to entice the right audience. For example, fantasy and sci-fi books often feature elements like spaceships, battles, aliens, or other creatures on the cover. Thrillers, on the other hand, showcase motifs that hint at the unknown, such as forests or urban scenes with shadowy lighting."
      />
      <SuawParagraph
        text="Unless you have substantial graphic design experience, it may be challenging to go the DIY route on this critical set of tasks before your book launch. As author and former literary agent <a target='_blank' href='https://www.youtube.com/watch?v=Z_Ekf2FVxHQ'>Meg LaTorre points out</a>, trying to make your own book cover can lead to amateur results, which can prevent your book from reaching its full potential in the marketplace. A qualified cover designer will put the same hard work and professionalism into your cover design as you did on the actual content of your book."
      />
      <SuawParagraph
        text="To find a book designer, you can use marketplaces specific to book design, such as <a target='_blank' href='https://bespokebookcovers.com/'>Bespoke Book Covers</a>, <a target='_blank' href='https://damonza.com/penn/'>Damonza.com</a>, and <a target='_blank' href='https://www.17studiobookdesign.com/'>17 Studio Book Design</a>, or freelance designer marketplaces like <a target='_blank' href='https://www.fiverr.com/categories/graphics-design/book-design'>Fiverr</a> and <a target='_blank' href='https://www.upwork.com/hire/book-cover-designers/'>Upwork</a>. Book design is typically priced per project rather than per page, and most designers will offer pre-priced packages for you to choose from. Prices can range from $500 to $2000, or even higher, depending on your needs as an author."
      />
      <SuawParagraph
        text="As you evaluate who to work with, be sure to ask about their end-to-end process, from initial brainstorming to final design. Every designer will have their own approach, but most will provide you with a few iterations and allow room for feedback. If you have any must-have requirements, like a specific number of final options you want to be able to choose from, or a motif that must be on the cover, bring those up sooner rather than later."
      />
      <SuawHeading level="3" content="Have a general idea of what you’re looking for before you meet with your designer." />
      <SuawParagraph
        text="To get ideas for your cover design, go to a bookstore to find the sections where they would most likely shelve your book, once it is published. What do the other titles in your genre look like? What are some common elements across these book designs, and what stands out as different?"
      />
      <SuawParagraph text="Take notes on your impressions, then write up your ideas to bring to your first meeting with your cover designer." />
      <SuawParagraph
        text="You can expect the cover design process to take one to two months. After it is complete and you have a design you’re happy with, be sure to ask your designer for copies of all the source files. You’ll want them for posterity in case you need to use them in other places, like on your website, social media profiles, or even business cards. It may be hard to replicate the custom typeface or logo that your designer created for you, so it’s best to get your own copies of the creative assets before you find yourself needing them somewhere down the line."
      />
      <SuawParagraph
        text="In conclusion, working with a professional book designer is the best way to guarantee you’ll end up with an overall book design with which you’re completely satisfied."
      />
      <SuawParagraph text="We hope this was helpful! Have any thoughts or comments? Let us know by commenting below." />
    </ArticleFrame>
  </SuawMainContent>
</template>
