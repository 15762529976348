<script>
export default {
  name: "IconItalic",
}
</script>

<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 5C11 4.44772 11.4477 4 12 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H14.7543L11.3257 18H12C12.5523 18 13 18.4477 13 19C13 19.5523 12.5523 20 12 20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H9.2457L12.6743 6H12C11.4477 6 11 5.55228 11 5Z" />
</svg>
</template>
