<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Card from "../Card/Card.vue";

export default {
  name: 'LocationBoxOnline',
  components: {
    Paragraph,
    Card,
  },
  props: {
    copy: String,
    meetingUrl: String,
    hasRSVPed: Boolean,
  }
}
</script>

<template>
  <div class="suaw-location-box__content--online">
    <Card
      cardType="CardOnlineMeeting"
      :meetingUrl="meetingUrl"
      :hasRSVPed="hasRSVPed"
    />
    <footer class="suaw-location-box__footer">
      <Paragraph
        v-if="copy"
        class="suaw-location-box__label"
        weight="normal"
        size="small"
        :text="copy"
      />
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-location-box {

  &__content--online {
    gap: var(--sem-space-double);
  }

  &__content--online,
  &__footer {
    display: flex;
    flex-direction: column;
  }
}
</style>
