<script>
export default {
  name: "Label",
  props: {
    labelText: {
      type: String,
    },
    labelFor: {
      type: String,
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["large", "medium", "small"].indexOf(value) !== -1;
      },
    },
    weight: {
      type: String,
      default: "normal",
      validator: function (value) {
        return ["normal", "bold"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
  },
}
</script>

<template>
  <label 
    class="suaw-label"
    :for="labelFor"
    :class="[
      'suaw-label--' + size,
      'suaw-label--' + weight,
      className
    ]"
    v-html="labelText"
  ></label>
</template>

<style lang="scss" scoped>
.suaw-label {
  color: var(--sem-color-text-dark);
  font-family: var(--font-family);
  line-height: var(--base-line-height);

  &--small {
    font-size: var(--body-copy-font-size);
  }

  &--medium {
    font-size: var(--button-normal-font-size);
  }

  &--large {
    font-size: var(--h-4-font-size);
  }

  &--normal {
    font-weight: var(--p-font-weight);
  }

  &--bold {
    font-weight: 500;
  }
}
</style>
