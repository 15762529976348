<script>
import Button from "../Button/Button.vue";
export default {
  name: "EventNavigation",
  components: { Button },
  props: {
    eventDates: {
      type: Array,
      required: true,
    },
    selectedDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    isPrevDisabled() {
      return this.currentIndex === 0;
    },
    isNextDisabled() {
      return this.currentIndex >= this.eventDates.length - 1;
    },
    currentEventDate() {
      if (!this.eventDates) return null;
      // const currentEventDate = this.eventDates[this.currentIndex]
      return this.eventDates[this.currentIndex];
    },
    formattedSelectedDate() {
      if (this.currentEventDate) {
        const [year, month, day] = this.currentEventDate.split("T")[0].split("-");
        return `${month}-${day}-${year}`;
      }
      return "";
    },
  },
  methods: {
    next() {
      if (this.currentIndex < this.eventDates.length - 1) {
        this.currentIndex++;
        this.emitSelectedEvent();
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.emitSelectedEvent();
      }
    },
    emitSelectedEvent() {
      this.$emit("date-selected", this.currentEventDate);
    },
    setInitialEvent() {
      const initialEventIndex = this.eventDates.findIndex(
        (event) => event === this.selectedDate
      );
      this.currentIndex = initialEventIndex !== -1 ? initialEventIndex : 0;
    },
  },
  created() {
    this.setInitialEvent();
  },
};
</script>

<template>
  <div class="suaw-event-navigation">
    <Button
      :disabled="isPrevDisabled"
      class="suaw-button--no-x-pd prev-button"
      size="large"
      type="ghost"
      iconLeft="IconChevronBigLeft"
      icon-size="extra-large"
      buttonText=""
      @click="prev"
    />
    <div class="event-date">
      {{ formattedSelectedDate }}
    </div>
    <Button
      :disabled="isNextDisabled"
      class="suaw-button--no-x-pd next-button"
      size="large"
      type="ghost"
      iconRight="IconChevronBigRight"
      icon-size="extra-large"
      buttonText=""
      @click="next"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-event-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .prev-button,
  .next-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }

  .event-date {
    font-size: 24px;
    font-weight: bold;
    color: var(--sem-color-text-dark);
  }
}
</style>
