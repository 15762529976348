<script>
import { SuawButton, SuawHeading } from "@/components";
import joinImage from "../../../../public/img/home/how-repeat-image.png";
export default {
  name: "JoinSection",
  components: { SuawHeading, SuawButton },
  data() {
    return {
      joinImage
    };
  }
};
</script>

<template>
  <div class="suaw-join-section">
    <div class="suaw-join-section__main-container">
      <div class="suaw-join-section__image-container">
        <img :src="joinImage" />
      </div>
      <div class="suaw-join-section__cta-container">
        <div class="suaw-join-section__heading-container">
          <SuawHeading class="suaw-join-section__heading" content="Get Started Today" level="1" />
        </div>
        <div class="suaw-join-section__subheading-container">
          <SuawHeading
            class="suaw-join-section__subheading"
            level="4"
            content="Ready to join Shut Up & Write? Sign up on our site to find a nearby writing community or start your own. Together, we can ensure that every writer has the support and resources they need to thrive."
          />
        </div>
        <div class="suaw-join-section__button-container">
          <SuawButton class="suaw-join-section__button" type="primary" size="hero" button-text="Join Today!" @click="$emit('click')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-join-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 88px;
  @media screen and (max-width: 1024px) {
    padding-top: var(--sem-space-octuple);
  }
  @media screen and (max-width: 600px) {
    padding-top: var(--sem-space-sextuple);
  }
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    @media (max-width: 767px) {
      margin: 0 16px;
    }
    @media (max-width: 650px) {
      grid-template-columns: 1fr;
      row-gap: 32px;
    }
  }
  &__image-container {
    max-height: 387px;
    grid-column: 1/2;
    grid-row: 1/2;
    border-radius: 16px;
    overflow: hidden;
    @media (max-width: 840px) {
      max-height: none;
    }
  }
  &__image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__cta-container {
    grid-column: 2/3;
    grid-row: 1/2;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    align-content: center;
    @media (max-width: 650px) {
      grid-column: 1/2;
      grid-row: 2/3;
      row-gap: 32px;
    }
  }

  &__heading-container {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: end;
  }

  &__heading {
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 500;
    font-family: var(--secondary-font-family);
    @media (max-width: 650px) {
      text-align: center;
    }
  }

  &__subheading {
    color: var(--sem-color-neutral-dark);
    margin-bottom: 8px;
    @media (max-width: 650px) {
      text-align: center;
    }
  }

  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
