<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "carbon",
      validator: function (value) {
        return [
          "carbon",
          "red",
          "blue",
          "purple",
          "white",
          "green",
          "gray",
        ].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["nav", "extra-large", "large", "medium", "small"].indexOf(value) !== -1;
      },
    }
  },
  data() {
    return {
      iconComponent: null,
    };
  },
  mounted() {
    this.loadIconComponent();
  },
  methods: {
    loadIconComponent() {
      const iconName = this.icon;
      try {
        import(`./icons/${iconName}.vue`)
          .then((iconModule) => {
            this.iconComponent = iconModule.default || iconModule;
          })
          .catch((error) => {
            console.error(
              `Could not load icon component for "${iconName}"`,
              error
            );
          });
      } catch (error) {
        console.error(`Error loading icon "${iconName}"`, error);
      }
    },
  },
}
</script>

<template>
  <component 
    v-if="iconComponent"
    :is="iconComponent"
    class="suaw-icon"
    :class="['suaw-icon--' + color, 'suaw-icon--' + size, className]"
  />
</template>

<style lang="scss" scoped>
.suaw-icon {
  box-sizing: border-box;
  display: inline-block;
  height: var(--comp-icon-size);
  min-height: var(--comp-icon-size);
  min-width: var(--comp-icon-size);
  width: var(--comp-icon-size);

  &--extra-large {
    --comp-icon-size: 54px;
  }

  &--nav {
    --comp-icon-size: 42px;
  }

  &--large {
    --comp-icon-size: 36px;
  }

  &--medium {
    --comp-icon-size: 20px;
  }

  &--small {
    --comp-icon-size: 12px;
  }

  &--carbon {
    fill: var(--sem-color-text-medium);
  }

  &--white {
    fill: var(--sem-color-text-lightest);
  }

  &--blue {
    fill: var(--sem-color-info-medium);
  }

  &--purple {
    fill: var(--sem-color-accent-purple);
  }

  &--red {
    fill: var(--sem-color-critical-medium);
  }

  &--green {
    fill: var(--sem-color-success-medium);
  }

  &--gray {
    fill: var(--sem-color-neutral-regular);
  }

  &--disabled {
    fill: var(--sem-color-inactive-medium);
  }
}
</style>
