<script>
export default {
  name: "Divider",
  props: {
    type: {
      type: String,
      default: "outer",
      validator: function (value) {
        return ["outer", "inner"].indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["lightest", "medium", "darkest"].indexOf(value) !== -1;
      },
    },
    orientation: {
      type: String,
      default: "horizontal",
      validator: function (value) {
        return ["horizontal", "vertical"].indexOf(value) !== -1;
      },
    },
    topDistance: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "closer", "farther" ].indexOf(value) !== -1;
      },
    },
    bottomDistance: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "closer", "farther" ].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
  }
}
</script>

<template>
  <hr 
    class="suaw-divider"
    :class="[
      'suaw-divider--' + color,
      'suaw-divider--' + orientation,
      'suaw-divider--bottom-' + bottomDistance,
      'suaw-divider--top-' + topDistance,
      'suaw-divider--' + type,
      className
    ]"
  />
</template>

<style lang="scss" scoped>
.suaw-divider {
  border-color: var(--comp-divider-border-color);
  position: relative;

  &--horizontal {
    border-top: 0;
  }

  &--inner {
    width: 100%;
  }

  &--outer {
    max-width: 100vw;
    width: 100%;
  }

  &--vertical {
    border-left: 0;
    display: inline-block;
    height: 100%;
    min-height: 56px;
    width: 1px;
  }

  &--lightest {
    --comp-divider-border-color: var(--sem-color-border-lightest);
  }

  &--medium {
    --comp-divider-border-color: var(--sem-color-border-medium);
  }

  &--darkest {
    --comp-divider-border-color: var(--sem-color-border-darkest);
  }

  &--bottom-closer {
    margin-bottom: calc(var(--sem-space-quadruple) * -1);
  }

  &--bottom-farther {
    margin-bottom: var(--sem-space-quadruple);
  }

  &--top-closer {
    margin-top: calc(var(--sem-space-quadruple) * -1);
  }

  &--top-farther {
    margin-top: var(--sem-space-quadruple);
  }
}
</style>
