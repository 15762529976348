<script>
import CalendarDate from "../CalendarDate/CalendarDate.vue";
import Chip from "../Chip/Chip.vue";
import Button from "../Button/Button.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Heading from "../Heading/Heading.vue";

export default {
  name: "EventSummary",
  components: {
    CalendarDate,
    Chip,
    Button,
    Dropdown,
    Heading,
  },
  props: {
    seriesId: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
    },
    month: {
      type: [Number],
    },
    day: {
      type: [Number],
    },
    title: {
      type: String,
      default: "Event Name",
    },
    spotsLeft: {
      type: [String, Number],
    },
    rsvped: {
      type: Boolean,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
    },
    timezone: {
      type: String,
    },
    location: {
      type: String,
    },
    memberCount: {
      type: [Number, String],
    },
    dropdownItems: {
      type: Array,
    },
  },

  computed: {
    eventSpotsLeft() {
      return `${this.spotsLeft} Spots Left`;
    },
    seriesMemberCount() {
      return `${this.memberCount} Members`;
    },
  },
}
</script>

<template>
  <div class="suaw-summary">
    <div class="suaw-summary__date">
      <CalendarDate
        v-if="month && day"
        type="shadow"
        size="small"
        :status="status"
        :month="month"
        :day="day"
        key="event-summary-event"
      />
    </div>
    <div class="suaw-summary__heading">
      <Heading level="2" :content="title" />
    </div>
    <div class="suaw-summary__actions">
      <div class="suaw-summary__action-spots-left">
        <Chip
          size="medium"
          status="default"
          icon="IconUserCheck"
          :label="eventSpotsLeft"
          chipType="icon"
        />
      </div>
      <div class="suaw-summary__action-rsvp">
        <Button
          :buttonText="rsvped ? 'RSVPed' : 'RSVP'"
          class="suaw-summary__action-button"
          size="large"
          :type="rsvped ? 'success-light' : 'success'"
          :iconLeft="rsvped ? 'IconDownloadDone' : 'IconDownload'"
          @click="$emit('rsvp-click', eventId)"
        />
      </div>
      <div v-if="isOrganizer" class="suaw-summary__action-edit-series">
        <Button
          buttonText="Edit Series"
          class="suaw-summary__action-button"
          size="large"
          type="primary-light"
          iconLeft="IconRepeat"
          @click="$emit('edit-series-click', seriesId)"
        />
      </div>
      <div class="suaw-summary__actions-dropdown">
        <Dropdown
          buttonType="secondary-outline"
          :items="dropdownItems"
          iconDropdown="IconMoreHorizontal"
          dropdown-button-size="med-square"
          @item-click="$emit('dropdown-item-click', $event)"
        />
      </div>
    </div>
    <div class="suaw-summary__chips">
      <div>
        <Chip
          size="medium"
          status="default"
          icon="IconClock"
          :label="duration"
          chipType="icon"
          :pill="timezone"
        />
      </div>
      <div>
        <Chip
          size="medium"
          status="default"
          icon="IconLocation"
          :label="location"
          chipType="icon"
        />
      </div>
      <div>
        <Chip
          size="medium"
          status="info"
          icon="IconUser"
          :label="seriesMemberCount"
          chipType="icon"
        />
      </div>
    </div>
    <div class="suaw-summary__extra-actions">
      <div>
        <Button
          buttonText="Share"
          class="suaw-summary__extra-action-button suaw-button--no-x-pd suaw-button--no-y-pd"
          size="medium"
          type="ghost"
          @click="$emit('share-click', eventId)"
        />
      </div>
      <div>
        <Button
          buttonText="Add to Calendar"
          class="suaw-summary__extra-action-button suaw-button--no-x-pd suaw-button--no-y-pd"
          size="medium"
          type="ghost"
          @click="$emit('add-to-calendar-click', eventId)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-summary {
  --comp-summary-grid-template-columns: auto 1fr auto;
  --comp-summary-actions-align-items: center;
  --comp-summary-extra-actions-align-items: flex-end;
  --comp-summary-action-display: inline-flex;
  --comp-summary-action-rsvp-display: inline-flex;
  --comp-summary-actions-dropdown-display: none;
  --comp-summary-heading-grid-column: 2/3;
  --comp-summary-chips-grid-column-start: 2;
  --comp-summary-chips-grid-column-end: 3;
  --comp-summary-row-gap: 0px;

  @media screen and (max-width: 1024px) {
    --comp-summary-actions-align-items: flex-start;
    --comp-summary-extra-actions-align-items: center;
    --comp-summary-action-display: none;
    --comp-summary-actions-dropdown-display: inline-flex;
    --comp-summary-chips-grid-column-start: 2;
    --comp-summary-chips-grid-column-end: 4;
    --comp-summary-row-gap: 8px;
  }

  @media screen and (max-width: 600px) {
    --comp-summary-grid-template-columns: auto auto auto;
    --comp-summary-actions-align-items: flex-start;
    --comp-summary-actions-dropdown-display: inline-flex;
    --comp-summary-action-rsvp-display: none;
    --comp-summary-heading-grid-column: 2/5;
    --comp-summary-chips-grid-column-start: 1;
    --comp-summary-actions-grid-row-start: 3;
    --comp-summary-actions-grid-column-start: 4;
  }

  width: 100%;
  display: grid;
  grid-template-columns: var(--comp-summary-grid-template-columns);
  grid-template-rows: auto auto;
  column-gap: 16px;
  row-gap: var(--comp-summary-row-gap);

  &__date {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &__heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column: var(--comp-summary-heading-grid-column);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: var(--comp-summary-actions-align-items); 
    gap: 12px;
    grid-row-start: var(--comp-summary-actions-grid-row-start);
    grid-column-start: var(--comp-summary-actions-grid-column-start);
  }

  &__action-spots-left {
    display: var(--comp-summary-action-display);
  }

  &__action-edit-series {
    display: var(--comp-summary-action-display);
  }
  &__actions-dropdown {
    display: var(--comp-summary-actions-dropdown-display);
  }
  &__action-rsvp {
    display: var(--comp-summary-action-rsvp-display);
  }
  &__extra-action-button {
    display: var(--comp-summary-action-display);
    height: 17px;
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 8px;
    grid-column-start: var(--comp-summary-chips-grid-column-start);
    grid-column-end: var(--comp-summary-chips-grid-column-end);
  }

  &__extra-actions {
    display: flex;
    justify-content: flex-end;
    align-items: var(--comp-summary-extra-actions-align-items);
    gap: 24px;
  }

  &__action-button {
    padding: 12px;
    height: 43px;
  }
}
</style>
